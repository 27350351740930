import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, forkJoin } from 'rxjs';

import { JobEventService } from '../job-events/job-event.service';
import { BulkEditJobEvent } from '../job-events/bulk-edit-job-event';
import { CancelJobDialogData } from './cancel-job-dialog-data';

@Component({
  selector: 'ruckit-cancel-job-dialog',
  templateUrl: './cancel-job-dialog.component.html',
  styleUrls: ['./cancel-job-dialog.component.scss']
})
export class CancelJobDialogComponent {
  callback: (_: any[]) => void;
  public jobEventsDate: Date;
  public jobEventIds: string[];
  public jobEventIdsExclude: string[];
  public allJobEventsCount: number;
  public isAllJobEventsSelected: boolean;
  public query: any;

  public showSummary: boolean;
  public successJobs: string[] = [];
  public errorsJobs: string[] = [];
  loading = false;
  errors = [];

  constructor(
    public dialogRef: MatDialogRef<CancelJobDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CancelJobDialogData,
    private jobEventService: JobEventService
  ) {
    if (this.data) {
      this.jobEventIds = this.data.jobEventIds;
      this.jobEventIdsExclude = this.data.jobEventIdsExclude;
      this.allJobEventsCount = this.data.allJobEventsCount;
      this.isAllJobEventsSelected = this.data.isAllJobEventsSelected;
      this.query = this.data.query;
      this.jobEventsDate = this.data.jobEventsDate;
    }
  }

  submit() {
    if (this.showSummary) {
      this.dialogRef.close();
      this.callback(this.successJobs);
      return;
    }

    this.loading = true;
    const bulkUpdated: BulkEditJobEvent = { cancelled: true };
    if (this.isAllJobEventsSelected) {
      bulkUpdated.exclude = this.jobEventIdsExclude;
    } else {
      bulkUpdated.include = this.jobEventIds;
    }

    this.jobEventService.bulkUpdate(bulkUpdated, this.query).subscribe((response) => {
      this.loading = false;
      if (response.errors && response.errors.length) {
        this.showSummary = true;
        this.successJobs = response.success && response.success.map((values) => (values[0]));
        this.errorsJobs = response.errors && response.errors.map((values) => (values[0]));
      } else {
        this.callback(response.success);
        this.dialogRef.close();
      }
    }, (err) => {
      this.errors = err;
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }
}
