"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var material_1 = require("@angular/material");
var RuckitConfirmDialogComponent = /** @class */ (function () {
    function RuckitConfirmDialogComponent(confirmDialog, data) {
        this.confirmDialog = confirmDialog;
        this.data = data;
        this.attributes = {};
        this.attributes = {
            title: 'Confirm your action!',
            body: 'Are you sure you wish to do this?',
            close: 'Cancel',
            accept: 'Continue',
            isErrorBtn: false
        };
        if (data) {
            if (data.title) {
                this.attributes.title = data.title;
            }
            if (data.message) {
                this.attributes.body = data.message;
            }
            if (data.cancelText) {
                this.attributes.close = data.cancelText;
            }
            if (data.acceptText) {
                this.attributes.accept = data.acceptText;
            }
        }
    }
    return RuckitConfirmDialogComponent;
}());
exports.RuckitConfirmDialogComponent = RuckitConfirmDialogComponent;
