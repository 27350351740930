import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

// angular material
import { MatDialog, MatSelectChange } from '@angular/material';

// libraries
import { cloneDeep, filter } from 'lodash';

// services
import { ApiService } from '../shared/api.service';
import { UserService } from '../users/user.service';
import { OrganizationService } from './organization.service';
import { parseErrors } from '../shared/api.service';
import { PreferenceService } from '../preferences/preference.service';
import { AuthenticationService } from '../shared';
import { AttachmentService } from '../attachments/attachment.service';

// models
import { User } from '../users/user';
import { Organization } from './organization';
import { Preference } from '../preferences/preference';
import { PreferenceSerializer } from '../preferences/preference.serializer';
import { Attachment } from '../attachments/attachment.interface';

// components
import { AB5QuestionsDialogComponent } from '../invitation/ab5-questions/ab5-questions-dialog.component';

// constants
import {
  ORGANIZATIONBUSINESSLICENSE,
  ORGANIZATIONIMAGETYPE,
  ORGANIZATIONINSURANCE,
  ORGANIZATIONOVERWEIGHTPERMIT,
} from '../app.constants';

@Component({
  selector: 'ruckit-edit-organization',
  templateUrl: './edit-organization.component.html',
  styleUrls: ['./edit-organization.component.scss'],
})
export class EditOrganizationComponent implements OnInit, OnDestroy {
  @Input() organization: Organization;
  businessLicense = ORGANIZATIONBUSINESSLICENSE;
  insurance = ORGANIZATIONINSURANCE;
  overweightPermit = ORGANIZATIONOVERWEIGHTPERMIT;
  loading = true;
  user: User;
  userReq: Subscription;
  accountUpdateLoading = false;
  documentLoading = false;
  billingLoading = true;
  uniqueBillingLoading = true;
  imageChanged = false;
  states = [];
  stateOptions = [];
  billingStateOptions = [];
  haulOptions = ['Load', 'Weight', 'Hour'];
  countryOptions = [];
  companyTypeOptions = [];
  errors = [];
  logoImage = {
    dataUri: null,
    file: null,
  };
  preference: Preference = new PreferenceSerializer().fromJson({
    blob: {
      mapStyle: 'ruckit-map-style',
      markerType: 'driver-initials',
      markerStatus: 'each-segment',
      checkinOptions: {
        ticketNumberConfig: 'optional',
        weightConfig: 'optional',
        ticketImageConfig: 'optional',
        signatureImageConfig: 'optional',
        activeTrackingConfig: 'optional',
        qrConfig: 'optional',
      },
      checkoutOptions: {
        ticketNumberConfig: 'optional',
        weightConfig: 'optional',
        ticketImageConfig: 'optional',
        signatureImageConfig: 'optional',
        activeTrackingConfig: 'optional',
        qrConfig: 'optional',
      },
    },
  });
  preferenceKey = 'GeneralOrganizationPreferences';
  configOptions = [
    { name: 'Required', id: 'required' },
    { name: 'Optional', id: 'optional' },
    { name: 'Hidden', id: 'hidden' },
  ];
  @ViewChild('editOrganization', { static: false }) editOrganization;
  @ViewChild('editBilling', { static: false }) editBilling;
  @ViewChild('editUniqueBilling', { static: false }) editUniqueBilling;
  @ViewChild('editPreferences', { static: false }) editPreferences;

  constructor(
    private router: Router,
    private apiService: ApiService,
    private preferenceService: PreferenceService,
    private userService: UserService,
    private organizationService: OrganizationService,
    private authenticationService: AuthenticationService,
    private attachmentService: AttachmentService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.user = this.authenticationService.user();
    this.getPreferences();
    this.states = this.apiService.getStates();
    this.countryOptions = this.apiService.getCountries().map((country) => {
      return {
        id: country.abbreviation,
        name: country.name,
      };
    });
    if (this.organization && this.organization.country) {
      this.selectCountry(this.organization.country, 'organization');
    } else {
      this.selectCountry('US', 'organization');
    }
    if (this.organization && this.organization.billingCountry) {
      this.selectCountry(this.organization.billingCountry, 'billing');
    } else {
      this.selectCountry('US', 'billing');
    }
    this.companyTypeOptions = this.apiService
      .getCompanyTypes()
      .map((companyType) => {
        return { id: companyType.id, name: companyType.name };
      });
    this.loading = false;
    this.billingLoading = false;
    this.uniqueBillingLoading = false;
  }

  ngOnDestroy() {
    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }
  }

  saveOrganization() {
    this.errors = [];
    this.loading = true;
    let _organization = cloneDeep(this.organization);
    delete _organization.tags;
    Object.keys(_organization).forEach(
      (key) => _organization[key] == null && delete _organization[key]
    );
    this.organizationService.save(_organization).subscribe(
      (organization) => {
        this.organization = new Organization(organization);
        this.router.navigate(['/settings', 'organization']);
        this.savePreferences();
        this.editOrganization.form.markAsPristine();
        this.editOrganization.form.markAsUntouched();
        this.loading = false;
      },
      (err) => {
        this.errors = parseErrors(err);
        this.loading = false;
      }
    );
  }

  saveBilling() {
    this.billingLoading = true;
    let _organization = cloneDeep(this.organization);
    delete _organization.tags;
    this.organizationService.save(_organization).subscribe(
      () => {
        this.billingLoading = false;
        this.editBilling.form.markAsPristine();
        this.editBilling.form.markAsUntouched();
      },
      (err) => {
        this.errors = parseErrors(err);
        this.billingLoading = false;
      }
    );
  }

  saveUniqueBillingId() {
    this.uniqueBillingLoading = true;
    this.organizationService
      .save({
        id: this.organization.id,
        uniqueBillingId: this.organization.uniqueBillingId,
      })
      .subscribe(
        () => {
          this.uniqueBillingLoading = false;
          this.editUniqueBilling.form.markAsPristine();
          this.editUniqueBilling.form.markAsUntouched();
        },
        (err) => {
          this.errors = parseErrors(err);
          this.uniqueBillingLoading = false;
        }
      );
  }

  selectCountry(country, area = 'organization', onInit = true) {
    const filteredStates = filter(this.states, { country: country }).map(
      (state) => {
        return {
          id: state.abbreviation,
          name: state.name,
        };
      }
    );
    if (area === 'organization') {
      this.stateOptions = filteredStates;
      this.organization.country = country;
      if (!onInit) {
        this.organization.state =
          filteredStates && filteredStates.length > 0
            ? filteredStates[0].id
            : null;
      }
    } else {
      this.billingStateOptions = filteredStates;
      this.organization.billingCountry = country;
      if (!onInit) {
        this.organization.billingState =
          filteredStates && filteredStates.length > 0
            ? filteredStates[0].id
            : null;
      }
    }
  }

  selectHaulType(event): void {
    this.organization.defaultHaulRateType = event.toLowerCase();
  }

  defaultHaulRateChange(event): void {
    const value = Number(event.target.value);
    if (value >= 0) {
      if (value !== this.organization.defaultHaulRate) {
        const newValue = Math.floor(value * 100) / 100;
        event.target.value = newValue;
        this.organization.defaultHaulRate = newValue;
      }
    } else {
      this.organization.defaultHaulRate = 0;
      event.target.value = 0;
    }
    this.editPreferences.form.markAsDirty();
  }

  selectCheckinOption(field: string, event: MatSelectChange): void {
    this.preference.blob['checkinOptions'][field] = event.value;
  }

  onAutoShiftEndChange(event: any) {
    // this.organization.shiftMonitorAutoClose = event.target.checked;
  }

  onShiftMonitorIntervalChange(event) {
    this.organization.features.shiftMonitorAutoCloseInterval =
      event > 0 ? event : 0;
  }

  selectCheckoutOption(field: string, event: MatSelectChange): void {
    this.preference.blob['checkoutOptions'][field] = event.value;
  }

  fileChange(e) {
    let logoImage = this.logoImage;
    const attachmentId = this.attachmentService.getFirstAttachmentIdFromAttachments(
      this.organization.attachments, ORGANIZATIONIMAGETYPE
    );

    let reader = new FileReader();
    let file = e.srcElement.files[0];
    logoImage.file = file;
    if (attachmentId) {
      file.id = attachmentId;
    }

    reader.onload = (loadEvent: any) => {
      logoImage.dataUri = loadEvent.target.result;
      file.file = reader.result;
      file.src = reader.result;
      this.attachmentService
      .saveOrganizationAttachment(
        this.organization.id,
        file,
        ORGANIZATIONIMAGETYPE
      )
      .subscribe(
        (res) => {
          console.log(res);
        },
        (err) => {
          console.log(err);
        }
      );
    };

    reader.readAsDataURL(file);
    this.imageChanged = true;
  }

  getPreferences(): void {
    if (this.preference && this.preference.id) {
      this.preferenceService.get(this.preference.id).subscribe((preference) => {
        this.preference = preference;
        this.setupOrganizationCheckinFeatures(preference);
      });
    } else {
      this.preferenceService
        .list({
          name: this.preferenceKey,
          type: 'organization',
          organization: this.organization && this.organization.id,
        })
        .subscribe((preferences) => {
          if (preferences && preferences.length) {
            const preference = preferences[0];
            if (preference && preference.blob) {
              this.preference = { ...preference };
              this.setupOrganizationCheckinFeatures(preference);
            }
          } else {
            this.setupOrganizationCheckinFeatures(this.preference);
          }
        });
    }
  }

  savePreferences(): void {
    if (this.preferenceKey && this.organization) {
      this.loading = true;
      this.preference = {
        ...this.preference,
        name: this.preferenceKey,
        type: 'organization',
        organization: this.organization && this.organization.id,
      };
      this.preferenceService.save(this.preference).subscribe((preference) => {
        this.preference = preference;
        this.loading = false;
      });
    }
  }

  setupOrganizationCheckinFeatures(preference: Preference) {
    // legacy)
    if (this.organization.features && this.organization.features.checkinOptions === undefined) {
      this.organization.features.checkinOptions = preference.blob.checkinOptions;
    }
    if (this.organization.features && this.organization.features.checkoutOptions === undefined) {
      this.organization.features.checkoutOptions = preference.blob.checkoutOptions;
    }
  }

  openAB5Questions() {
    const dialog = this.dialog.open(AB5QuestionsDialogComponent, {
      width: '660px',
    });
    dialog.componentInstance.callback = () => {
      this.organization.isAb5Compliant = true;
    };
  }

  setExpirationDate(e: Date[], type: string) {
    this.organization[type + 'ExpirationDate'] =
      e[0] && typeof e[0].toISOString === 'function'
        ? e[0].toISOString()
        : e[0];
  }

  uploadAB5Document(type: string, e: Event, propertyName: string) {
    this.documentLoading = true;
    if (e.target['files'] && e.target['files'].length > 0) {
      let file = e.target['files'][0];
      let reader = new FileReader();
      reader.onload = () => {
        file.file = reader.result;
        file.src = reader.result;
        this.attachmentService
          .saveOrganizationAttachment(this.organization.id, file, type)
          .subscribe(
            (res: Attachment) => {
              this.organization[propertyName] = res.file;
            },
            (err) => {
              console.log(err);
            },
            () => (this.documentLoading = false)
          );
      };
      reader.readAsDataURL(file);
    }
  }

  removeAB5Document(type: string, fileType: string, legacyType: string) {
    const attachmentsToDelete = this.organization.attachments.filter(
      (a) => a.fileType === fileType
    );
    if (attachmentsToDelete.length > 0) {
      attachmentsToDelete.forEach((attachment) => {
        this.attachmentService.deleteAttachment(attachment.id).subscribe(() => {
          this.organization[legacyType] = null;
        });
      });
    } else { // for legacy documents;
      this.organizationService
        .patch({ [type]: '' }, this.organization.id)
        .subscribe(() => {
          this.organization[legacyType] = null;
        });
    }
  }

  updateAccount() {
    this.accountUpdateLoading = true;
    if (this.userReq && typeof this.userReq.unsubscribe === 'function') {
      this.userReq.unsubscribe();
    }

    this.userReq = this.userService.get(this.user.id).subscribe(
      (user) => {
        this.authenticationService.storeUser(
          Object.assign(this.user, {
            features: user.features,
            enabledFeatures: user.enabledFeatures,
            organization: user.organization,
          })
        );
        this.organization = this.user.organization;
        this.accountUpdateLoading = false;
      },
      (err) => {
        this.accountUpdateLoading = false;
      }
    );
  }
}
