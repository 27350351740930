import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subject, combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { pull, remove, difference, get, find as _find } from 'lodash';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';

import { PunchCardService } from './punch-card.service';
import { PunchCardFiltersDialogComponent } from './punch-card-filters-dialog.component';
import { RuckitConfirmDialogComponent } from '../shared/dialogs/index';
import { AuthenticationService } from '../shared/authentication.service';
import { Preference } from '../preferences/preference';
import { PreferenceService } from '../preferences/preference.service';
import { ExportDialogComponent, ExportDialogData, FieldOption } from '../shared/export-dialog/export-dialog.component';
import { DriverContextEvent } from '../drivers/driver-context-menu/interfaces/driver-context-event';

// utils
import { AppUtilities } from '../shared/app-utilities';

const decamelizeKeysDeep = require('decamelize-keys-deep');

@Component({
  selector: 'ruckit-punch-cards',
  templateUrl: './punch-cards.component.html',
  styleUrls: ['./punch-cards.component.scss']
})

export class PunchCardsComponent implements OnInit, OnDestroy {
  datePipe = new DatePipe('en-US');
  punchCards: any = [];
  expandedPunchCards = {};
  enabledFeatures: string[] = [];
  count = 0;
  selectedCount = 0;
  allSelected = false;
  selectedPunchCards = [];
  excludePunchCards = [];
  loading = true;
  errors = [];
  punchCardsReq;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();
  sortBy = '-start_time';
  sortAsc = true;
  sortParameter;
  filters = [];
  filtersDialog;
  checkingDuplicates = false;
  confirmDialog: MatDialogRef<any>;
  multipleActionDropdownOptions = [
    { name: 'Export', action: 'export', button: true, link: false },
    // !allSelected && !selectedPunchCards.length
    { name: 'Void', action: 'void', button: true, link: false, disabled: true },
  ];
  menuOptions = [
    {name: 'Edit', action: 'edit', link: true},
    {name: 'Unvoid', action: 'unvoid', link: false},
    {name: 'Void', action: 'void', link: false}
  ];
  preferenceKey = 'PunchCardsComponent-CondensedPunchCardService';
  preference: Preference;
  lockedExportFields = null;

  contextMenuOpened = false;
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  viewTicketCallback = (e) => {
    // Update Ticket Icon
  }
  exportCallback = () => {
    this.allSelected = false;
    this.selectedPunchCards = [];
    this.selectedCount = 0;
    this.punchCards.forEach((_punchCard) => { _punchCard.selected = false; });
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private punchCardService: PunchCardService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog,
    private preferenceService: PreferenceService,
  ) {
    this.allSubscriptionsToUnsubscribe.push(
      this.searchChanged.pipe(
        debounceTime(300), distinctUntilChanged()
      ).subscribe(search => {
        this.search = search;
        this.getPunchCards();
      })
    );
  }

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.loading = true;
        this.search = result.qparams['search'] || '';
        this.sortBy = result.qparams['sortBy'] || this.sortBy;
        this.sortAsc = result.qparams['sortAsc'] || this.sortAsc;
        this.getPreferences();
      })
    );
    if (!this.loading) { this.getPreferences(); }

    this.enabledFeatures = this.authenticationService.enabledFeatures();
    if (this.enabledFeatures.includes('punchcardExportFields')) {
      this.lockedExportFields = this.authenticationService.getFeature('punchcardExportFields');
      this.multipleActionDropdownOptions.push(
        { name: 'Spectrum Export', action: 'locked-export', button: true, link: false }
      );
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  getPreferences(): void {
    let currentUser = this.authenticationService.user();
    if (this.preference && this.preference.id) {
      this.preferenceService.get(this.preference.id).subscribe(preference => {
        this.preference = preference;
        this.parsePreferences();
      });
    } else {
      this.preferenceService.list({
        name: this.preferenceKey,
        type: 'user',
        profile: currentUser.id
      }).subscribe(preferences => {
        if (preferences && preferences.length) {
          this.preference = preferences[0];
          this.parsePreferences();
        } else {
          this.getPunchCards();
        }
      });
    }
  }

  savePreferences(filters) {
    if (this.preferenceKey) {
      let currentUser = this.authenticationService.user();
      this.preference = {
        ...this.preference,
        name: this.preferenceKey,
        type: 'user',
        profile: currentUser.id,
        blob: { filters: filters }
      };
      this.preferenceService.save(this.preference).subscribe(preference => {
        this.preference = preference;
      });
    }
  }

  /**
   * Examine the found preference to determine if it has filters and set those
   * filters.
   *
   * Once the preferences data is handled, trigger getPunchCards()
   */
  parsePreferences(): void {
    if (this.preference.blob && this.preference.blob['filters']) {
      this.filters = this.preference.blob['filters'].map(filter => {
        if (['startDate', 'endDate'].includes(filter.key)) {
          filter.value = this.prettyDate(filter.value);
        }
        return filter;
      });
    }
    this.getPunchCards();
  }

  onScroll(e) {
    if ( ! this.loading &&
      e.target.scrollTop >  e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.punchCardService.getNextCondensed();
      if (o) {
        this.loading = true;
        o.subscribe(punchCards => {
          this.punchCards = this.punchCards.concat(punchCards);
          this.loading = false;
        }, err => {
          this.errors = err;
          this.loading = false;
        });
      }
    }
  }

  getPunchCards(query = {}, append = false) {
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;

    let formattedFilters = this.filters.map(filter => decamelizeKeysDeep(filter.query, '__'));

    let filters = formattedFilters.reduce((key, value) => {
      return Object.assign(key, value);
    }, {});

    if (this.punchCardsReq) { this.punchCardsReq.unsubscribe(); }

    this.punchCardsReq = this.punchCardService.getCondensedPunchCards({
      ordering: order,
      search: this.search,
      exclude_cf: 'True',
      ...filters,
      ...query
    }).subscribe(punchCards => {
      if (append) {
        this.punchCards = this.punchCards.concat(punchCards);
      } else {
        this.punchCards = punchCards;
      }
      this.count = this.punchCardService.count;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.loading = true;
    this.getPunchCards({ordering: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.loading = true;
    this.getPunchCards({[this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy});
  }

  openAddPunchCard() {
    this.router.navigate(['/punchcards/new'], {
      queryParams: { returnTo: '/punchcards' }
    });
  }

  changeSearch(term?: string) {
    this.searchChanged.next(term);
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  duplicateCheck() {
    this.checkingDuplicates = !this.checkingDuplicates;

    if (this.checkingDuplicates) {
      this.filters.push({
        key: 'duplicates', value: 'True', displayKey: 'Duplicates', query: {
          only_dupes: 'True'
        }
      });
      this.getPunchCards();
    } else {
      let filter = _find(this.filters, { key: 'duplicates' });
      if (filter) { this.removeFilter(filter); }
    }
  }

  openFilters() {
    const dialog = this.dialog.open(PunchCardFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.callback = res => this.filterChanges(res);

    let startDate = get(_find(this.filters, {key: 'startDate'}), 'value');
    if (startDate && startDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.startDate = startDate;
    }
    let endDate = get(_find(this.filters, {key: 'endDate'}), 'value');
    if (endDate && endDate.hasOwnProperty('year')) {
      dialog.componentInstance.model.endDate = endDate;
    }
    dialog.componentInstance.model.job = get(_find(this.filters, {key: 'job'}), 'value');
    dialog.componentInstance.model.project = get(_find(this.filters, {key: 'project'}), 'value');
    dialog.componentInstance.model.customer = get(_find(this.filters, {key: 'customer'}), 'value');
    dialog.componentInstance.model.driver = get(_find(this.filters, {key: 'driver'}), 'value');
    dialog.componentInstance.model.truck = get(_find(this.filters, {key: 'truck'}), 'value');
    dialog.componentInstance.model.carrier = get(_find(this.filters, {key: 'carrier'}), 'value');

    dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'jobevent__customer_organization',
      project: 'jobevent__job__project',
      job: 'jobevent__job',
      driver: 'driver',
      truck: 'truck',
      startDate: 'jobevent__shift1_start__gte',
      endDate: 'jobevent__shift1_start__lte',
      carrier: 'driver__carrier',
      duplicates: 'only_dupes',
      uninvoiced: 'uninvoiced'
    };
    const displayKeys = {
      duplicates: 'Duplicates'
    };
    let falseyFilters = [];
    this.filters = Object.keys(filterRes).map((key) => {
      const query = {};
      let value = filterRes[key];
      let displayValue;
      if (typeof(value) === 'boolean') {
        if (key === 'incomplete' && value) {
          displayValue = value.toString();
          displayValue = displayValue.charAt(0).toUpperCase() + displayValue.slice(1);
          value = !value;
        } else if (key === 'retake' && value) {
          value = 'requested';
          query[queryKeys[key]] = value;
        } else if (value) {
          value = value.toString();
          value = value.charAt(0).toUpperCase() + value.slice(1);
          query[queryKeys[key]] = value;
        }
      } else {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id;
      }
      let filter = {
        key: key,
        displayKey: displayKeys[key] || null,
        value: displayValue || value,
        query: query
      };
      if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
      return filter;
    });
    this.filters = difference(this.filters, falseyFilters);
    this.savePreferences(this.filters);
    this.getPunchCards();
  }

  removeFilter(filter) {
    remove(this.filters, filter);
    let duplicateFilter = _find(this.filters, { key: 'duplicates' });
    if (!duplicateFilter) { this.checkingDuplicates = false; }
    this.savePreferences(this.filters);
    this.getPunchCards();
  }

  menuAction(name, punchCard) {
    switch (name) {
      case 'void':
        this.voidPunchCard(punchCard);
        break;
      case 'unvoid':
        this.unvoidPunchCard(punchCard);
        break;
    }
  }

  setSelectedAction(option) {
    switch (option.name) {
      case 'Export':
        this.exportSelectedPunchCards();
        break;
      case 'Void':
        this.voidSelectedPunchCards();
        break;
    }
  }

  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'export':
        this.exportSelectedPunchCards();
        break;
      case 'locked-export':
        this.exportSelectedPunchCards(null, true);
        break;
      case 'void':
        this.voidSelectedPunchCards();
        break;
    }
  }

  exportSelectedPunchCards(punchCards = null, locked = false) {
    let {params, scope } = AppUtilities.getExportParamsAndScope(
      this.filters,
      punchCards || this.selectedPunchCards,
      this.excludePunchCards,
      this.allSelected,
      this.search
    );
    if (this.enabledFeatures.includes('hasExportTracking')) {
      scope['markAsExported'] = true;
    }
    if (this.enabledFeatures.includes('hasExportProtection')) {
      params = params.set('unexported', 'True');
    }

    this.punchCardService.getExportFields().subscribe((fields: FieldOption[]) => {
      this.dialog.open(ExportDialogComponent, {
        width: 'auto',
        data: <ExportDialogData>{
          type: 'punchcards',
          buttonText: 'Export Data to CSV',
          callback: this.exportCallback,
          fields,
          params,
          scope,
          service: this.punchCardService,
          lockedFields: locked ? this.lockedExportFields : null
        }
      });
    }, (err) => { this.errors = err; });
  }

  voidSelectedPunchCards(punchCards = null) {
    punchCards = punchCards ? punchCards : this.selectedPunchCards;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Punch Cards?',
      body: 'These punch cards will be marked as \'Void\' and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        punchCards.forEach((punchCardId, index) => {
          let punchCard = _find(this.punchCards, { id: punchCardId });
          this.punchCardService.save({ id: punchCard.id, void: true })
            .subscribe((result) => {
              punchCard['void'] = true;
              punchCard['loading'] = false;
              if (index === punchCards.length - 1) {
                this.allSelected = false;
                this.selectedPunchCards = [];
                this.selectedCount = 0;
                setTimeout(() => {
                  this.getPunchCards();
                }, 2000);
              }
            }, (err) => {
              this.errors = err;
            });
        });
      }
      this.confirmDialog = null;
    });
  }

  voidPunchCard(punchCard) {
    punchCard.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Void Punch Card?',
      body: 'This Punch Card will be marked as \'Void\' and will not be visible for the Job.',
      close: 'Cancel',
      accept: 'Void'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.punchCardService.save({id: punchCard.id, void: true})
          .subscribe(
            (result) => {
              punchCard.void = true;
              punchCard.loading = false;
            },
            (err) => {
              this.errors = err;
              punchCard.loading = false;
            }
          );
      }
      this.confirmDialog = null;
    });
  }

  unvoidPunchCard(punchCard) {
    punchCard.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Unvoid Punch Card?',
      body: 'This Punch Card will be unmarked as \'Void\' and will be visible for the Job.',
      close: 'Cancel',
      accept: 'Unvoid'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.punchCardService.save({id: punchCard.id, void: false})
          .subscribe(
            (result) => {
              punchCard.void = false;
              punchCard.loading = false;
            },
            (err) => {
              this.errors = err;
              punchCard.loading = false;
            }
          );
      }
      this.confirmDialog = null;
    });
  }

  formattedDuration(startTime): string {
    let duration = moment.duration(moment().diff(startTime));
    return duration.format('D[ days], H[ hrs], m[ mins]');
  }

  expandPunchCard(punchCard) {
    if (this.expandedPunchCards[punchCard.id]) {
      delete this.expandedPunchCards[punchCard.id];
    } else {
      this.expandedPunchCards[punchCard.id] = true;
      this.expandedPunchCards = { ...this.expandedPunchCards };
    }
  }

  // isPunchCardExpanded(punchCard): boolean {
  //   if (_find(this.expandedPunchCards, {id: punchCard.id})) {
  //     return true;
  //   }
  //   return false;
  // }

  selector(event, punchCard = null) {
    if (punchCard) {
      if (!event.target.checked) {
        punchCard.selected = false;
        pull(this.selectedPunchCards, punchCard.id);
        if (this.allSelected) {
          this.excludePunchCards.push(punchCard.id);
          this.selectedCount = (this.count - this.excludePunchCards.length);
        } else {
          this.selectedCount = this.selectedPunchCards.length;
        }
      } else {
        punchCard.selected = true;
        if (this.allSelected) {
          pull(this.excludePunchCards, punchCard.id);
          this.selectedCount = (this.count - this.excludePunchCards.length);
        } else {
          this.selectedPunchCards.push(punchCard.id);
          this.selectedCount = this.selectedPunchCards.length;
        }
      }
    } else {
      if (!event.target.checked) {
        this.allSelected = false;
        this.punchCards.forEach((_punchCard) => { _punchCard.selected = false; });
        this.selectedCount = 0;
      } else {
        this.allSelected = true;
        this.selectedCount = (this.count - this.excludePunchCards.length);
      }
      this.selectedPunchCards = [];
      this.excludePunchCards = [];
    }

    this.multipleActionDropdownOptions = this.multipleActionDropdownOptions.map((opt) => (
      opt.action === 'void' ? { ...opt, disabled: this.selectedCount === 0 } : opt
    ));
  }

  prettyDate(dateString: string): string {
    const date = new Date(dateString);
    return date instanceof Date && !isNaN(date.getTime()) ? this.datePipe.transform(date, 'MM/dd/yyyy') : '';
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuOpened = true;
    this.contextMenuEventSubject.next({
      event,
      driverId,
    });
  }
}
