"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var lodash_1 = require("lodash");
var core_1 = require("@angular/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var ngx_device_detector_1 = require("ngx-device-detector");
var core_2 = require("@ngx-translate/core");
// angular material
var material_1 = require("@angular/material");
// services
var api_service_1 = require("../../shared/api.service");
var driver_service_1 = require("../../drivers/driver.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var assignment_service_1 = require("../../assignments/assignment.service");
var dispatch_service_1 = require("../dispatch.service");
var truck_service_1 = require("../../trucks/truck.service");
var collaborator_service_1 = require("../../collaborators/collaborator.service");
var connection_service_1 = require("../../connections/connection.service");
var shared_1 = require("../../shared");
// components
var ruckit_dropdown_component_1 = require("../../shared/ruckit-dropdown/ruckit-dropdown.component");
var assignment_error_dialog_component_1 = require("../../messages/assignment-error-dialog.component");
// constants
var app_constants_1 = require("../../app.constants");
var CarrierDispatchDialogComponent = /** @class */ (function () {
    function CarrierDispatchDialogComponent(dialogRef, jobEventService, driverService, assignmentService, dispatchService, collaboratorService, deviceDetectorService, translateService, authenticationService, dialog) {
        this.dialogRef = dialogRef;
        this.jobEventService = jobEventService;
        this.driverService = driverService;
        this.assignmentService = assignmentService;
        this.dispatchService = dispatchService;
        this.collaboratorService = collaboratorService;
        this.deviceDetectorService = deviceDetectorService;
        this.translateService = translateService;
        this.authenticationService = authenticationService;
        this.dialog = dialog;
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.mobileView = 'assignments';
        this.assignments = [];
        this.truckCount = 0;
        this.requestedAmount = 0;
        this.loading = false;
        this.errors = [];
        this.allDriversSelected = false;
        this.drivers = [];
        this.driversConfig = {
            nameProperty: 'name',
            selectText: 'Select Driver',
            loadingText: 'Loading Drivers...',
            noResultsText: 'No Drivers',
            service: driver_service_1.DriverService
        };
        this.trucksConfig = {
            nameProperty: 'displayName',
            selectText: 'Select Truck',
            loadingText: 'Loading Trucks...',
            noResultsText: 'No Trucks',
            service: truck_service_1.TruckService
        };
        this.weightOptions = [
            { value: 'load', name: 'Loads' },
            { value: 'hour', name: 'Hours' }
        ].concat(app_constants_1.JOBWEIGHTOPTIONS);
        this.carriersConfig = {
            nameProperty: 'name',
            searchable: true,
            loadingOptions: false,
            selectText: this.translateService.instant('My Drivers'),
            idProperty: 'organization.carrier.id',
            service: connection_service_1.ConnectionService,
            prefilledOptions: [],
            initialLoad: true,
            query: {
                ordering: 'organization__name',
                allow_dispatch: 'True',
                is_carrier: 'True'
            }
        };
        this.hasLoadListsEnabled = false;
        this.loadList = [];
    }
    CarrierDispatchDialogComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
        this.loading = true;
        this.setCarrierDropdownPrefilledOptions();
        this.getDrivers();
        var hasLoadListsEnabled = this.authenticationService.hasLoadListsEnabled();
        this.hasLoadListsEnabled = hasLoadListsEnabled;
        this.jobEventReq = this.jobEventService.getJobEvent(this.jobEventId).subscribe(function (jobEvent) {
            _this.jobEvent = jobEvent;
            _this.assignmentService.listAll(5, { jobevent: _this.jobEventId, can_dispatch: 'True' }).subscribe(function (res) {
                _this.loading = false;
                _this.assignments = _this.assignments.concat(res);
                _this.truckCount = _this.share.confirmedTrucks || _this.assignments.length;
                if (_this.share.haulType === app_constants_1.JobHaulType.hour || _this.share.haulType === app_constants_1.JobHaulType.load) {
                    _this.weightOptions = _this.weightOptions.map(function (opt) { return (__assign({}, opt, { selected: _this.share.haulType === opt.value })); });
                }
                else if (_this.share.haulWeightUnit) {
                    _this.weightOptions = _this.weightOptions.map(function (opt) { return (__assign({}, opt, { selected: _this.share.haulWeightUnit === opt.value })); });
                }
            }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
        }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
    };
    CarrierDispatchDialogComponent.prototype.ngOnDestroy = function () {
        if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
            this.jobEventReq.unsubscribe();
        }
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
    };
    CarrierDispatchDialogComponent.prototype.setCarrierDropdownPrefilledOptions = function () {
        var carrierOptions = [
            { name: 'My Drivers', id: 'my_drivers' },
            { name: 'All Carriers', id: 'all_carriers' },
        ];
        if (this.jobEvent && this.jobEvent.job
            && this.jobEvent.job.project
            && this.jobEvent.job.project.customerOrganization
            && this.jobEvent.job.project.customerOrganization['hasLeasedOrgs']) {
            carrierOptions.push({ name: 'Leased', id: 'all_leased' });
        }
        this.carriersConfig.prefilledOptions = carrierOptions;
    };
    CarrierDispatchDialogComponent.prototype.getDrivers = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
            this.driversReq.unsubscribe();
        }
        this.loading = true;
        var staticOptions = ['all_carriers', 'all_leased', 'my_drivers'];
        if (this.driversReq) {
            this.driversReq.unsubscribe();
        }
        var carrierId;
        if (this.carrier && this.carrier.id && !staticOptions.includes(this.carrier.id)) {
            carrierId = this.carrier.id;
        }
        this.driversReq = this.driverService.list(__assign({ search: this.search }, query, { carrier: carrierId, all_carriers: this.carrier && this.carrier.id === 'all_carriers' ? 'True' : null, all_leased: this.carrier && this.carrier.id === 'all_leased' ? 'True' : null })).subscribe(function (drivers) {
            _this.drivers = drivers;
            _this.loading = false;
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
            _this.loading = false;
        });
    };
    CarrierDispatchDialogComponent.prototype.selectCarrier = function (carrier) {
        this.carrier = carrier;
        this.getDrivers();
    };
    CarrierDispatchDialogComponent.prototype.onScroll = function (event) {
        var _this = this;
        if (!this.loading && event.target.scrollTop > event.target.scrollHeight - event.target.clientHeight * 3) {
            var request = this.driverService.listNext();
            if (request) {
                this.loading = true;
                this.driversReq = request.subscribe(function (drivers) {
                    _this.drivers = _this.drivers.concat(drivers);
                    _this.loading = false;
                }, function (err) {
                    _this.errors = err;
                    _this.loading = false;
                });
            }
        }
    };
    CarrierDispatchDialogComponent.prototype.changeDriverSearch = function (term) {
        this.search = term;
        this.getDrivers();
    };
    CarrierDispatchDialogComponent.prototype.onSelect = function (driver, event) {
        if (event.target['checked']) {
            if (!this.jobEvent.requestedAmount || this.assignments.length < this.jobEvent.requestedAmount) {
                this.assignments.push({
                    driver: driver,
                    truck: driver.truck,
                    jobevent: this.jobEvent,
                    shift: 'shift1'
                });
                this.truckCount = this.share.confirmedTrucks || this.assignments.length;
            }
            else {
                this.errors = ['No more trucks are allowed on this job'];
                event.target['checked'] = false;
            }
        }
        else if (event.target['checked'] === false) {
            this.assignments = this.assignments.filter(function (a) { return a.driver.id !== driver.id; });
        }
        else if (this.device.mobile) {
            var existingIndex = this.assignments.findIndex(function (a) { return (a.driver.id === driver.id); });
            if (existingIndex > -1) {
                this.assignments.splice(existingIndex, 1);
                this.truckCount = this.share.confirmedTrucks || this.assignments.length;
            }
        }
    };
    CarrierDispatchDialogComponent.prototype.changeDriver = function (driver, index) {
        this.assignments[index].driver = driver;
    };
    CarrierDispatchDialogComponent.prototype.changeTruck = function (truck, index) {
        this.assignments[index].truck = truck;
    };
    CarrierDispatchDialogComponent.prototype.onAssignmentChange = function (assignment) {
        assignment.maxNumberOfLoads = assignment.numberOfLoadsType === 'allDay' ? 0 : assignment.maxNumberOfLoads || 1;
    };
    CarrierDispatchDialogComponent.prototype.updateAssignment = function (assignment) {
        var _this = this;
        var _assignment = lodash_1.clone(assignment);
        this.assignmentService.save(_assignment, {
            can_dispatch: 'True'
        }).subscribe(function () {
            // Do nothing
        }, function (err) {
            _this.errors = api_service_1.parseErrors(err);
        });
    };
    CarrierDispatchDialogComponent.prototype.removeAssignment = function (assignment, index) {
        var _this = this;
        if (assignment.id) {
            this.assignmentService.remove(assignment.id)
                .pipe(operators_1.first())
                .subscribe(function () {
                _this.assignments.splice(index, 1);
                _this.truckCount = _this.share.confirmedTrucks || _this.assignments.length;
            }, function (err) { return _this.errors = api_service_1.parseErrors(err); });
        }
        else {
            this.assignments.splice(index, 1);
            this.truckCount = this.share.confirmedTrucks || this.assignments.length;
        }
    };
    CarrierDispatchDialogComponent.prototype.save = function (dispatch) {
        var _this = this;
        if (dispatch === void 0) { dispatch = false; }
        var newAssignments = this.assignments.filter(function (a) { return (!a.id); });
        var existingAssignments = this.assignments.filter(function (a) { return (a.id); });
        if (this.share) {
            this.loading = true;
            this.collaboratorService.save(this.jobEventId, Object.assign(__assign({}, this.share, { requestedUnit: this.share.requestedUnit ? this.share.requestedUnit.id : null }), { confirmedTrucks: this.truckCount })).pipe(operators_1.mergeMap(function () {
                return _this.collaboratorService.accept(__assign({}, _this.share, { requestedUnit: _this.share.requestedUnit ? _this.share.requestedUnit.id : null }));
            })).subscribe(function () {
                _this.loading = false;
                if (newAssignments.length || existingAssignments.length) {
                    _this.assignAndDispatch(newAssignments, existingAssignments, dispatch);
                }
                else {
                    _this.dialogRef.close();
                    _this.callback();
                }
            }, function (err) {
                _this.loading = false;
                _this.errors = api_service_1.parseErrors(err.errors);
            });
        }
        else {
            if (newAssignments.length || existingAssignments.length) {
                this.assignAndDispatch(newAssignments, existingAssignments, dispatch);
            }
            else {
                this.dialogRef.close();
                this.callback();
            }
        }
    };
    CarrierDispatchDialogComponent.prototype.assignAndDispatch = function (newAssignments, existingAssignments, dispatch) {
        var _this = this;
        if (dispatch === void 0) { dispatch = false; }
        if (newAssignments.length || existingAssignments.length) {
            var saveOptions_1 = {
                jobEvent: this.jobEventId,
                notify_new: true
            };
            var reqs = [];
            var assignmentErrors_1 = [];
            if (newAssignments.length > 0) {
                var bulkCreateReq = this.assignmentService.bulkCreate(newAssignments).pipe(operators_1.catchError(function (resp) {
                    if (resp.errors && resp.errors.length) {
                        assignmentErrors_1 = assignmentErrors_1.concat(resp.errors);
                        return rxjs_1.of(resp);
                    }
                    else {
                        return rxjs_1.throwError(resp);
                    }
                }));
                reqs.push(bulkCreateReq);
            }
            if (existingAssignments.length > 0) {
                var bulkUpdateReq = this.assignmentService.bulkUpdate(existingAssignments).pipe(operators_1.catchError(function (resp) {
                    if (resp.errors && resp.errors.length) {
                        var mappedErrors = resp.errors.map(function (obj) { return ({ errors: obj.error, data: obj.data }); });
                        assignmentErrors_1 = assignmentErrors_1.concat(mappedErrors);
                        return rxjs_1.of(resp);
                    }
                    else {
                        return rxjs_1.throwError(resp);
                    }
                }));
                reqs.push(bulkUpdateReq);
            }
            this.loading = true;
            rxjs_1.forkJoin(reqs).pipe(operators_1.switchMap(function (resp) {
                var checkAssignments = resp.some(function (obj) { return obj.assignments && obj.assignments.length; });
                if (dispatch && checkAssignments) {
                    return _this.dispatchService.save(saveOptions_1);
                }
                else {
                    return rxjs_1.of(resp);
                }
            })).subscribe(function () {
                _this.loading = false;
                _this.callback();
                if (assignmentErrors_1.length) {
                    _this.openErrorModal(assignmentErrors_1);
                }
                else {
                    _this.dialogRef.close();
                }
            }, function (err) {
                _this.loading = false;
                _this.errors = api_service_1.parseErrors(err);
                _this.callback();
            });
        }
    };
    CarrierDispatchDialogComponent.prototype.openErrorModal = function (errors) {
        var _this = this;
        var dialogErrors = [];
        if (errors && errors.length) {
            dialogErrors = errors.map(function (error) {
                var errorMessage = error.errors && error.errors.non_field_errors;
                var driver;
                if (error.item) {
                    driver = lodash_1.find(_this.drivers, { id: error.item && error.item.driver });
                }
                else if (error.data) {
                    var assignment = lodash_1.find(_this.assignments, { id: error.data && error.data.id });
                    driver = lodash_1.find(_this.drivers, { id: error.data && error.data.driver });
                    driver.truck = assignment.truck;
                }
                if (driver) {
                    return {
                        error: errorMessage,
                        driverName: driver.name,
                        truckName: driver.truck && driver.truck.displayName
                    };
                }
                else {
                    return {
                        error: errorMessage
                    };
                }
            });
        }
        if (dialogErrors.length) {
            var dialog = this.dialog.open(assignment_error_dialog_component_1.AssignmentErrorDialogComponent, {
                width: '430px',
                height: 'auto'
            });
            dialog.componentInstance.errors = dialogErrors;
        }
    };
    CarrierDispatchDialogComponent.prototype.weightUnitChange = function (val) {
        var selected = val.value;
        if (selected === app_constants_1.JobHaulType.load || selected === app_constants_1.JobHaulType.hour) {
            this.share.haulType = selected;
        }
        else {
            this.share.haulWeightUnit = selected;
            this.share.haulType = app_constants_1.JobHaulType.weight;
        }
    };
    CarrierDispatchDialogComponent.prototype.isDriverAssigned = function (driverId) {
        return this.assignments.findIndex(function (a) { return (a.driver.id === driverId); }) > -1;
    };
    return CarrierDispatchDialogComponent;
}());
exports.CarrierDispatchDialogComponent = CarrierDispatchDialogComponent;
