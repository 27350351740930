"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var user_notification_1 = require("./user-notification");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var moment = require("moment");
var UserNotificationSerializer = /** @class */ (function () {
    function UserNotificationSerializer() {
    }
    UserNotificationSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var notification = new user_notification_1.UserNotification();
        if (!json) {
            return notification;
        }
        notification.verb = json.verb;
        notification.description = json.description;
        notification.data = json.data;
        notification.target = json.target;
        notification.targetId = json.targetId;
        notification.actor = json.actor;
        notification.timestamp = json.timestamp;
        notification.unread = json.unread;
        var kind = user_notification_1.UserNotificationKind;
        var kindJob = [kind.JobCreated, kind.JobDayChanged, kind.JobEventUpdated, kind.JobEventShared];
        var kindShare = [kind.JobEventShareAccepted, kind.JobEventShareDeclined];
        var kindConnection = [kind.NewConnectionInvitation, kind.NewConnectionAccepted];
        var kindAssignment = [kind.OwnDriversDispatched, kind.SharedDriversDispatched, kind.AugmentedFleetDriversDispatched,
            kind.AssignmentAccepted, kind.AssignmentRejected, kind.AssignmentUpdated, kind.TruckTicketedWithoutAssignment,
            kind.Dispatch, kind.QRCodeScanned, kind.Retake];
        var kindApexUpdate = [kind.JobCancelled, kind.EsbOrderUpdate];
        if (kindJob.includes(notification.verb)) {
            notification.icon = 'icon-projects';
            notification.url = (notification.verb === kind.JobEventShared)
                ? '/jobs/daily?date=' + this.getDateQueryParam(notification.timestamp) : '/jobs';
        }
        else if (kindConnection.includes(notification.verb)) {
            notification.icon = 'icon-team';
            notification.url = (notification.verb === kind.NewConnectionInvitation) ? '/connections/received' : '/connections';
        }
        else if (kindAssignment.includes(notification.verb)) {
            notification.url = '/jobs/' + notification.targetId;
            notification.icon = ([kind.QRCodeScanned, kind.Retake, kind.TruckTicketedWithoutAssignment].indexOf(notification.verb) > -1) ? 'icon-ticket' :
                ([kind.AssignmentAccepted, kind.AssignmentRejected].indexOf(notification.verb) > -1) ? 'icon-dispatch' :
                    'icon-fleet';
        }
        else if (kindShare.includes(notification.verb)) {
            notification.icon = 'icon-projects';
            notification.url = '/jobs/daily?date=' + this.getDateQueryParam(notification.timestamp);
        }
        else if (kindApexUpdate.includes(notification.verb)) {
            notification.icon = 'icon-bell-outlined';
            notification.url = '/jobs/daily?date=' + this.getDateQueryParam(notification.timestamp);
        }
        return notification;
    };
    UserNotificationSerializer.prototype.toJson = function (notification) {
        var json = {
            verb: notification.verb,
            description: notification.description,
            data: notification.data,
            target: notification.target,
            targetId: notification.targetId,
            actor: notification.actor,
            timestamp: notification.timestamp,
            unread: notification.unread,
            icon: notification.icon
        };
        return decamelizeKeysDeep(json);
    };
    UserNotificationSerializer.prototype.getDateQueryParam = function (timestamp) {
        var dateFormatted = moment(timestamp);
        var year = dateFormatted.year();
        var month = dateFormatted.month() < 9 ? '0' + (dateFormatted.month() + 1) : (dateFormatted.month() + 1);
        var date = dateFormatted.date() < 10 ? '0' + dateFormatted.date() : dateFormatted.date();
        return "" + year + month + date;
    };
    return UserNotificationSerializer;
}());
exports.UserNotificationSerializer = UserNotificationSerializer;
