import {
  Component, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild
} from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {
  combineLatest as observableCombineLatest, Subject, Subscription
} from 'rxjs';
import { delay } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

// libraries
import { DeviceDetectorService } from 'ngx-device-detector';
import { uniq, clone, find as _find, groupBy } from 'lodash';
import * as moment from 'moment';

// services
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../../shared';
import { parseErrors } from '../../shared/api.service';
import { EndOfDayService } from './end-of-day.service';
import { PreferenceService } from '../../preferences/preference.service';
import { EndOfDayDriversService } from './end-of-day-drivers.service';

// components
import { DriverDailyComponent } from '../../drivers/driver-daily/driver-daily.component';
import { DriverDailyTasksComponent } from '../../drivers/driver-daily-tasks/driver-daily-tasks.component';
import { EndOfDayOverallStatsComponent } from '../end-of-day-overall-stats/end-of-day-overall-stats.component';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs';
import { EndOfDayFiltersDialogComponent } from '../end-of-day-filters/end-of-day-filters.component';
import { FieldOption, ExportDialogComponent, ExportDialogData } from '../../shared/export-dialog/export-dialog.component';

// models
import { Trip } from '../../trips/trip';
import { Organization } from '../../organizations/organization';
import { Driver } from '../../drivers/driver';
import { EodFilter } from '../eod-filter';
import { Preference } from '../../preferences/preference';

// pipes
import { EodFilterPipe } from '../../shared/pipes/eod-filter.pipe';
import { defaultFilters } from '../end-of-day-filters/default-filters';

// constants
import { DEFAULT_DIALOG_SIZE } from '../../app.constants';


@Component({
  selector: 'end-of-day',
  templateUrl: './end-of-day.component.html',
  styleUrls: ['./end-of-day.component.scss']
})
export class EndOfDayComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('driverDaily', { static: false }) driverDaily: DriverDailyComponent;
  @ViewChild('driverDailyTasks', { static: false }) driverDailyTasks: DriverDailyTasksComponent;
  @ViewChild('overallStats', { static: false }) overallStats: EndOfDayOverallStatsComponent;
  confirmDialog: MatDialogRef<any>;
  loading = true;
  driverDataLoading = false;
  errors = [];
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  page = 1;
  pageSize = 25;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();
  overallStatsEnabled = false;
  reportDate: Date;
  driverId: string;
  drivers: Driver[] = [];
  originalDrivers: Driver[] = [];
  driversReq: Subscription;
  selectedDriver: Driver;
  organization: Organization;
  keyStatus: {driverId: string, key: string, loading: boolean, statuses: string[]}[] = [];
  enabledFeatures: string[] = [];

  filtersDialog: EndOfDayFiltersDialogComponent;
  appliedFilters: EodFilter[] = [...defaultFilters(this.translationService)];
  uniqueCarriers: {id: string, name: string, selected: boolean}[] = [];
  preference: Preference;
  preferenceKey = 'EndOfDayReports-Filters';
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private endOfDayService: EndOfDayService,
    private endOfDayDriversService: EndOfDayDriversService,
    private translationService: TranslateService,
    private preferenceService: PreferenceService,
    private eodFilterPipe: EodFilterPipe,
    public dialog: MatDialog
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnInit() {
    let query = {};
    this.organization = this.authenticationService.getOrganization();
    this.enabledFeatures = this.authenticationService.enabledFeatures();
    this.getFiltersPreference();
    if (this.enabledFeatures.includes('endOfDayOverallStatsEnabled')) {
      this.overallStatsEnabled = true;
    }
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }

    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.pipe(delay(0)).subscribe(result => {
        this.loading = true;
        if (result.qparams['date']) {
          this.reportDate = moment(result.qparams['date'], 'YYYYMMDD').toDate();
        } else {
          this.setDefaultDate();
        }
        if (result.qparams['driver']) {
          this.driverId = result.qparams['driver'];
        }
        if (result.qparams['search']) {
          this.search = result.qparams['search'];
        }
        this.getDrivers();
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    let reportDate = changes['reportDate'];
    if (reportDate) {
      let previousReportDate = reportDate.previousValue && reportDate.previousValue.toDateString();
      let currentReportDate = reportDate.currentValue && reportDate.currentValue.toDateString();
      if (previousReportDate !== currentReportDate) {
        this.getDrivers();
      }
    }
  }

  ngOnDestroy() {
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  updatedMarkets(): void {

  }

  setDefaultDate(): void {
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    let date = moment();
    queryParams['date'] = date.format('YYYYMMDD');
    this.reportDate = date.toDate();
    this.router.navigate([], { queryParams: queryParams });
  }

  onDateChanged(dates: Date[]): void {
    if (dates && dates[0]) {
      this.loading = true;
      this.reportDate = dates[0];
      const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
      let date = moment(this.reportDate).format('YYYYMMDD');
      queryParams['date'] = date;
      queryParams['driver'] = null;
      this.router.navigate(['end-of-day-reports'], { queryParams: queryParams });
      this.drivers = [];
      this.selectedDriver = null;
    }
  }

  changedSearch(search): void {
    this.search = search;
    this.updateUrl({ search: search });
    this.searchChanged.next(search);
  }

  selectDriver(driver: Driver = null): void {
    let queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    if (driver) {
      this.selectedDriver = driver;
      this.driverId = driver.id;
      queryParams['driver'] = driver.id;
      this.getSingleDriverData(driver.id, queryParams);
    } else {
      if (this.driverId) {
        let selectedDriver = _find(this.drivers, { id: this.driverId });
        if (selectedDriver) {
          this.selectDriver(selectedDriver);
        } else {
          this.selectDriver(this.drivers[0]);
        }
      } else if (this.drivers && this.drivers.length) {
        this.selectDriver(this.drivers[0]);
      }
    }
  }

  getSingleDriverData(driverId: string, queryParams: Params) {
    this.driverDataLoading = true;
    let startDate = this.reportDate;
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);
    const filters = this.parseFiltersToQuery([...this.appliedFilters]);
    this.endOfDayService.get(
      driverId,
      {
        active_range: startDate.toISOString() + ',' + endDate.toISOString(),
        all_carriers: 'True',
        omit: 'invoice_total,expense_total',
        ...(filters),
      }
    ).subscribe(driver => {
      this.selectedDriver = driver;
      this.drivers = this.drivers.map(d => d.id === driver.id ? driver : d);
      const url = this.router.createUrlTree([], {
        relativeTo: this.route,
        queryParams: queryParams
      }).toString();
      this.location.go(url);
      this.driverDailyTasks.view = 'trips';
      this.driverDataLoading = false;
    }, (error) => {
      this.driverDataLoading = false;
      this.errors = parseErrors(error);
    });
  }

  getDrivers(query = {}, skipLoading = false) {
    if (!skipLoading) { this.loading = true; }
    // if (this.authenticationService.hasFavoriteTags() && !!!this.filters.find(f => (f.key === 'tags'))) { query['user_tags'] = 'True'; }
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
    let startDate = this.reportDate;
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);
    const filters = this.parseFiltersToQuery([...this.appliedFilters]);

    this.driversReq = this.endOfDayDriversService.listAll(100, {
      page_size: this.pageSize,
      search: this.search,
      page: this.page,
      active_range: startDate.toISOString() + ',' + endDate.toISOString(),
      all_carriers: 'True',
      ...(filters),
      ...query
    }).subscribe(drivers => {
      this.drivers = drivers;
      this.originalDrivers = [...drivers];
      if (this.drivers && this.drivers.length) {
        this.auditAllDecisions();
        this.selectDriver();
      } else {
        this.selectedDriver = null;
      }


      // optimize this!!
      this.uniqueCarriers = drivers
      .map((driver) => driver.carrierId)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((uniqCarrierId) => ({
        id: uniqCarrierId,
        name: drivers.find(
          (d) => d.carrierId === uniqCarrierId
        ).carrierOrganizationName,
        selected: false,
      }));

      this.filterCarriers(this.appliedFilters);

      this.loading = false;
    }, error => {
      this.errors = parseErrors(error);
      this.loading = false;
    });
  }

  updateUrl(params) {
    params['search'] = params['search'] ? params['search'] : this.search;
    this.page = this.page > 1 && this.search ? 1 : this.page;
    params['page'] = this.page > 1 ? this.page : null;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        ...params
      }
    });
  }

  /**
   * Analyzes each decision and caches the resulting statuses by Driver.
   */
  auditAllDecisions(): void {
    this.drivers.forEach((driver: Driver) => {
      this.auditDecisions(driver);
    });
  }

  /**
   * Analyzes each trip and decision and caches the result on the Driver.
   *
   * @param {Driver} driver The Driver to consider when caching the approval statuses
   */
  auditDecisions(driver: string | Driver): void {
    let allDecisionsApproved = true;
    if (!(driver instanceof Driver)) {
      driver = _find(this.drivers, {id: driver});
    }
    if (driver && driver.trips) {
      driver.trips.forEach((trip: Trip) => {
        let decisionStatus = trip.latestDecisionStatus;
        if (!decisionStatus || decisionStatus !== 'approved') { allDecisionsApproved = false; }
      });
      if (!driver.trips.length) { allDecisionsApproved = false; }
    } else {
      allDecisionsApproved = false;
    }
    driver.allDecisionsApproved = allDecisionsApproved;
    this.determineApprovedJobs();
  }

  rateChanged(): void {
    this.getDrivers();
    this.overallStats.getEndOfDayOverallStats();
  }

  determineApprovedJobs(): void {
    this.drivers.forEach((driver: Driver) => {
      this.processStatuses(driver);
      this.keyStatus.forEach(status => {
        let hasUnapprovedStatus = false;
        if (!status.statuses.length) {
          hasUnapprovedStatus = true;
        } else {
         hasUnapprovedStatus = status.statuses.some(element => element !== 'approved');
        }

        if (!hasUnapprovedStatus && !driver.allTripsApproved.includes(status.key)) {
          driver.allTripsApproved.push(status.key);
        } else if (hasUnapprovedStatus) {
          driver.allTripsApproved = driver.allTripsApproved.filter(e => e !== status.key);
        }
      });
    });
  }

  processStatuses(driver: Driver): void {
    let jobs = groupBy(driver.trips, 'jobDisplayName');
    Object.keys(jobs).forEach(key => {
      let trips: Trip[] = jobs[key];
      let newStatus = false;
      let status = _find(this.keyStatus, { driverId: driver.id, key: key });
      if (status) {
        status.loading = true;
        status.statuses = [];
      } else {
        newStatus = true;
        status = {driverId: driver.id, key: key, loading: true, statuses: [] };
      }

      if (trips && trips.length) {
        trips.forEach((trip: Trip) => {
          if (trip.latestDecision) { status.statuses.push(trip.latestDecisionStatus); }
        });
        status.loading = false;
        status.statuses = uniq(status.statuses);
        if (newStatus) { this.keyStatus.push(status); }
      }
    });

    this.keyStatus = [...this.keyStatus];
  }

  /**
   * Presents a modal dialog using {@link RuckitConfirmDialogComponent} to
   * verify the export action. Upon an affermative confirmation, the API request
   * is submitted.
   */
  export(): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, DEFAULT_DIALOG_SIZE);

    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Export End of Day Report?'),
      body: this.translationService.instant('This will export all voided data.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Export')
    };
    let exportBody = {};
    if (this.enabledFeatures.includes('tripExportFields')) {
      exportBody['fields'] = this.authenticationService.getFeature('tripExportFields');
    }

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        let startDate = this.reportDate;
        startDate.setHours(0, 0, 0, 0);
        let endDate = clone(startDate);
        endDate.setHours(23, 59, 59, 999);

        let params = {
          page_size: this.pageSize,
          search: this.search,
          page: this.page,
          dispatched: null,
          unexported: null,
          trips_with_decision_status: null,
          void: 'True',
          all_carriers: 'True',
          active_range: startDate.toISOString() + ',' + endDate.toISOString()
        };

        if (this.enabledFeatures.includes('hasExportProtection')) {
          params['unexported'] = 'True';
        }
        if (this.enabledFeatures.includes('hasExportTracking')) {
          exportBody['mark_as_exported'] = true;
        }

        // probably no need to unsubscribe from this, but just in case
        this.allSubscriptionsToUnsubscribe.push(
          this.endOfDayService.export(exportBody, params, 'trips/export/').subscribe(() => {
            this.loading = false;
          }, err => {
            this.errors = parseErrors(err);
            this.loading = false;
          })
        );
      }
      this.confirmDialog = null;
    });
  }

  exportPunchcards(type = null): void {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, DEFAULT_DIALOG_SIZE);
    let body = this.translationService.instant('This will export all approved data and the data will be marked as exported.');
    if (type === 'all') {
      body = this.translationService.instant('This will export all data.');
    }
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Export End of Day Report?'),
      body: body,
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Export')
    };
    let exportBody = {};
    if (this.enabledFeatures.includes('punchcardExportFields')) {
      exportBody['fields'] = this.authenticationService.getFeature('punchcardExportFields');
    }

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.loading = true;
        let startDate = this.reportDate;
        startDate.setHours(0, 0, 0, 0);
        let endDate = clone(startDate);
        endDate.setHours(23, 59, 59, 999);

        let params = {
          page_size: this.pageSize,
          search: this.search,
          page: this.page,
          dispatched: 'True',
          unexported: null,
          void: null,
          punchcards_with_decision_status: 'approved',
          all_carriers: 'True',
          active_range: startDate.toISOString() + ',' + endDate.toISOString()
        };

        if (this.enabledFeatures.includes('hasExportProtection')) {
          params['unexported'] = 'True';
        }
        if (this.enabledFeatures.includes('hasExportTracking')) {
          exportBody['mark_as_exported'] = true;
        }

        this.allSubscriptionsToUnsubscribe.push(
          this.endOfDayService.export(exportBody, params, 'punchcards/export/').subscribe(() => {
            this.loading = false;
          }, err => {
            this.errors = parseErrors(err);
            this.loading = false;
          })
        );
      }
      this.confirmDialog = null;
    });
  }

  customExport(type: 'trips' | 'punchcards', approvedOnly = false) {
    let params = new HttpParams();
    let scope = { };
    if (this.enabledFeatures.includes('hasExportTracking')) {
      scope['markAsExported'] = true;
    }
    if (this.enabledFeatures.includes('hasExportProtection')) {
      params = params.set('unexported', 'True');
    }

    if (this.search) {
      params = params.set('search', this.search);
    }

    params = params.set('all_carriers', 'True');
    if (approvedOnly) {
      params = params.set(type === 'trips' ? 'trips_with_decision_status' : 'punchcards_with_decision_status', 'approved');
    }

    this.appliedFilters.forEach(filter => {
      if (filter.values && filter.values.length) {
        params = params.set(filter.key, filter.values[0].value);
      }
    });

    this.endOfDayService.getExportFields(`${type}/pandas-export/`).subscribe((fields: FieldOption[]) => {
      let startDate = this.reportDate;
      startDate.setHours(0, 0, 0, 0);
      let endDate = clone(startDate);
      endDate.setHours(23, 59, 59, 999);
      params = params.set('active_range', startDate.toISOString() + ',' + endDate.toISOString());

      this.dialog.open(ExportDialogComponent, {
        width: 'auto',
        data: <ExportDialogData>{
          title: type === 'trips' ? this.translationService.instant('trips') : this.translationService.instant('punchcards'),
          type: `eod-${type}`,
          buttonText: this.translationService.instant('Export Data to CSV'),
          callback: () => {},
          fields,
          params,
          scope,
          service: this.endOfDayService,
          customUrl: `endofday/${type}/export/`
        }
      });
    }, (err) => { this.errors = err; });
  }

  openFilters() {
    const dialog = this.dialog.open(EndOfDayFiltersDialogComponent, {
      width: '430px',
    });
    if (dialog) {
      dialog.componentInstance.title =
        this.translationService.instant('Filter Drivers');
      dialog.componentInstance.callback = (res, carriers) => this.filterChanges(res, carriers);
      dialog.componentInstance.uniqueCarriers = [...this.uniqueCarriers];
      dialog.componentInstance.appliedFilters = [...this.appliedFilters];
      dialog.componentInstance.date = this.reportDate;
      this.filtersDialog = dialog.componentInstance;
    }
  }

  filterCarriers(filters: EodFilter[]) {
    const carrier = filters.find(f => f.key === 'carrier');
    if (carrier && carrier.values.length) {
      // only allow one filter for now. TODO ZAN
      const drivers =  this.originalDrivers.filter(d => d.carrierId === carrier.values[0].value);
      this.drivers = [...drivers];
      if (drivers.length) {
        this.selectDriver();
      } else {
        this.driverId = null;
        this.selectedDriver = null;
      }
    }
  }

  filterChanges(filters: EodFilter[], carriers: any[] ): void {
    this.uniqueCarriers = carriers;
    this.appliedFilters = [...filters];
    this.getDrivers();
    this.saveFiltersPreference(filters);
  }

  saveFiltersPreference(filters: any) {
    const currentUser = this.authenticationService.user();
    const preference = {
      ...this.preference,
      name: this.preferenceKey,
      type: 'user',
      profile: currentUser.id,
      page_size: 1,
      blob: {filters}
    };

    this.preferenceService
    .save(preference)
    .subscribe((updatedPreference) => {
      this.preference = updatedPreference;
    });
  }

  getFiltersPreference() {
    const currentUser = this.authenticationService.user();
    const preference = {
      ...this.preference,
      name: this.preferenceKey,
      type: 'user',
      profile: currentUser.id,
    };
    this.preferenceService.list(preference).subscribe(preferences => {
      if (
        preferences.length &&
        preferences[0].blob &&
        preferences[0].blob.filters &&
        this.eodFilterPipe.transform(preferences[0].blob.filters)
      ) {
        const filters = preferences[0].blob.filters;
        this.appliedFilters = [...filters];
        this.filterCarriers([...filters]);
        this.getDrivers();
      }
    });
  }

  removeFilterOption(filter: EodFilter): void {
    // only one filter for now. TODO ZAN
    if (filter.key === 'carrier') {
      this.drivers = [...this.originalDrivers];
      this.uniqueCarriers = this.uniqueCarriers.map(u => ({...u, selected: false}));
    }
    this.appliedFilters = this.appliedFilters.map(a => {
     if ( a.key === filter.key) {
       return a.selected ? {...a, selected: false} : {...a, values: []};
     } else {
       return a;
     }
    });
    this.saveFiltersPreference(this.appliedFilters);
    this.getDrivers();
  }

  resetFilters(): void {
    this.drivers = [...this.originalDrivers];
    this.uniqueCarriers = this.uniqueCarriers.map(u => ({...u, selected: false}));

    this.appliedFilters = [...defaultFilters(this.translationService)];
    this.saveFiltersPreference(this.appliedFilters);
    this.getDrivers();
  }

  parseFiltersToQuery(filters: EodFilter[]) {
    const activeFilters = filters.filter(
      (f) =>
        (f.key !== 'carrier' && f.values && f.values.length > 0) ||
        f.selected === true
    );
    return activeFilters.reduce(
      (result, item) => ({
        [item.key]:
          item.values && item.values.length ? item.values[0].value : 'True',
      }),
      {}
    );
  }
}
