"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var product_items_1 = require("./product-items");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var ProductItemSerializer = /** @class */ (function () {
    function ProductItemSerializer() {
    }
    ProductItemSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var productItem = new product_items_1.ProductItem();
        if (!json) {
            return productItem;
        }
        productItem.deleted = json.deleted;
        productItem.createdAt = json.createdAt;
        productItem.lastModified = json.lastModified;
        productItem.description = json.description;
        productItem.materialCode = json.materialCode;
        productItem.itemType = json.itemType;
        productItem.itemSubType = json.itemSubType;
        productItem.itemCategory = json.itemCategory;
        productItem.createdBy = json.createdBy;
        productItem.lastModifiedBy = json.lastModifiedBy;
        productItem.organization = json.organization;
        return productItem;
    };
    ProductItemSerializer.prototype.toJson = function (productItem) {
        var json = {
            id: productItem.id,
            deleted: productItem.deleted,
            createdAt: productItem.createdAt,
            lastModified: productItem.lastModified,
            description: productItem.description,
            materialCode: productItem.materialCode,
            itemType: productItem.itemType,
            itemSubType: productItem.itemSubType,
            itemCategory: productItem.itemCategory,
            createdBy: productItem.createdBy,
            lastModifiedBy: productItem.lastModifiedBy,
            organization: productItem.organization
        };
        Object.keys(json).forEach(function (k) {
            if (json[k] === undefined) {
                delete json[k];
            }
        });
        return decamelizeKeysDeep(json);
    };
    return ProductItemSerializer;
}());
exports.ProductItemSerializer = ProductItemSerializer;
