import { clone, remove, find as _find } from 'lodash';

import { Location } from '../locations/location';
import { Resource } from '../shared/resource';
import { JobEvent } from '../job-events/job-event';
import { JobLoad } from '../dispatch/dispatch-by-job/job-load';

export class JobEventShare extends Resource {
  id: string;
  organization: string;
  organizationId: string;
  organizationLeasedOrg: boolean;
  sharedBy: string;
  sharedById: string;
  job: string;
  jobId: string;
  jobNumber: string;
  jobOrderNumber: string;
  jobeventId: string;
  startLocation: Location;
  endLocation: Location;
  days: any[];
  jobDays: any[];
  numTrucks: number;
  confirmedTrucks: number;
  dispatched: number;
  invoiceRate: string;
  invoiceType: string;
  invoiceWeightUnit: string;
  invoiceTypeOption: any;
  weightOptions: any;
  rateTrackingOptions: any;
  haulRate: string;
  haulType: string;
  haulWeightUnit: string;
  haulTypeOption: any;
  haulRateUnit: string;
  weightOption: any;
  status: string;
  createdAt: string;
  senderFirstName: string;
  senderLastName: string;
  senderOrganizationPhoneNumber: string;
  shift1Start: string;
  shift1End: string;
  shift2Start: string;
  shift2End: string;
  material: string;
  checkinOptions: any;
  checkoutOptions: any;
  multipliers: any[];
  externalIdentifier: string;
  workOrderTemplate: string;
  workOrderRenderedHtml: string;
  workOrderRenderedPdf: string;
  workOrderSigned: boolean;
  workOrderSignedBy: string;
  workOrderSignedByName: string;
  notes: string;
  internalNotes: string;
  dateRange: string;
  loading: boolean;
  classes: string;
  editing = false;
  readonly = false;
  readonlyStatuses: any;
  selected = false;
  originalObject;
  jobevents: JobEvent[];
  jobEventOptions: any[] = [];
  jobEventsLoading: boolean;
  collaboration: any;
  brokerRateCode: string;
  brokerRateCodeKey: string;
  customFieldData: { [key: string]: string };
  requestedAmount: number;
  requestedUnit: any;
  confirmedAmount: number;
  carrierId: string;
  carrierName: string;
  loadSchedule: JobLoad [];
  isMultipleMaterials: boolean;

  // pre-formatted fields for display
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;

  url(action): string {
    if (action === 'html') {
      return this.workOrderRenderedHtml;
    } else if (action === 'pdf') {
      return this.workOrderRenderedPdf;
    } else {
      return '/work-orders/' + this.id + '/' + action;
    }
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (!this.workOrderRenderedHtml) {
      let _option = _find(options, { action: 'html' });
      if (_option) { remove(options, _option); }
    }

    if (!this.workOrderRenderedPdf) {
      let _option = _find(options, { action: 'pdf' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }

}
