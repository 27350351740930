import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ResourceService } from '../../shared/resource.service';
import { NotificationsReport, ThresholdConfig } from './notifications-report';
import { NotificationsReportSerializer } from './notifications-report.serializer';
import { AuthenticationService } from '../../shared';

@Injectable({
  providedIn: 'root'
})
export class NotificationsReportService extends ResourceService<NotificationsReport>  {
  private defaulThresholds = [
    { value: 0.25, color: 'rgba(244, 238, 120, 1)' },
    { value: 0.5, color: 'rgba(250, 147, 21, 1)' },
    { value: 0.75, color: 'rgba(226, 70, 46, 1)' }
  ];
  preferenceKey = 'table-preferences[ControlReports-Notifications]';

  constructor(http: HttpClient, private authenticationService: AuthenticationService) {
    super(http, 'controls/notification-totals/', new NotificationsReportSerializer());
  }

  getReportConfig(): Observable<ThresholdConfig[]> {
    // TODO: Add call to report config endpoint when ready
    const organization = this.authenticationService.getOrgPropertyValue('id');
    if (!organization) {
      return of(this.defaulThresholds.sort((a: ThresholdConfig, b: ThresholdConfig) => b.value - a.value));
    }
    const thresholdsConfig = JSON.parse(localStorage.getItem(`${organization}_thresholdsConfig`));
    let userThresholds = !thresholdsConfig ? this.defaulThresholds : thresholdsConfig;
    return of(userThresholds.sort((a: ThresholdConfig, b: ThresholdConfig) => b.value - a.value));
  }

  saveReportConfig(thresholdConfig: ThresholdConfig[]): void {
    // TODO: Update with endpoint when ready
    const organization = this.authenticationService.getOrgPropertyValue('id');
    localStorage.setItem(`${organization}_thresholdsConfig`, JSON.stringify(thresholdConfig));
  }
}
