<div
  class="fancy-table-wrapper {{ customClasses }}"
  [ngClass]="{
    'custom-height': config.customHeight,
    'condensed-layout': config.condensedLayout
  }"
>
  <div *ngIf="config.hasHeader" class="header">
    <ng-content select="[header-content]"></ng-content>
  </div>
  <div class="applied-filter-content">
    <ng-content select="[applied-filter-content]"></ng-content>
  </div>
  <mat-table
    #fancyTable
    *ngIf="
      !config.hasCustomFields ||
      (config.hasCustomFields && config.customFieldsLoaded)
    "
    [dataSource]="dataSource"
    matSort
    [matSortActive]="config.sortActive || config.sortBy"
    (matSortChange)="sortData($event)"
    [matSortDirection]="config.sortDirection"
    [multiTemplateDataRows]="true"
    matSortDisableClear
    [ngClass]="{
      'with-header': config.hasHeader,
      'has-filters':
        (filters && filters.length > 0) ||
        (search && search.length > 0) ||
        filtersDisplayed
    }"
  >
    <ng-container [matColumnDef]="column" *ngFor="let column of detailColumns">
      <mat-cell *matCellDef="let row">
        <ng-container
          *ngTemplateOutlet="
            detailTemplates ? detailTemplates : defaultColumn;
            context: {
              row: row,
              column: column
            }
          "
        ></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="$event ? masterToggle($event) : null"
          [checked]="selection.hasValue() && allSelected"
          [indeterminate]="exclusion.hasValue() && allSelected"
          #masterCheckbox
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="$event ? toggleSelection(selection, row, $event) : null"
          [checked]="
            (allSelected && !exclusion.isSelected(row)) ||
            selection.isSelected(row)
          "
        >
        </mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="expand">
      <mat-header-cell *matHeaderCellDef></mat-header-cell>
      <mat-cell *matCellDef="let row">
        <mat-icon
          (click)="row['expanded'] = true"
          *ngIf="rowCanExpand(row) && !rowIsExpanded(row)"
          >expand_more</mat-icon
        >
        <mat-icon
          (click)="row['expanded'] = false"
          *ngIf="rowCanExpand(row) && rowIsExpanded(row)"
          >expand_less</mat-icon
        >
      </mat-cell>
    </ng-container>

    <ng-container
      [matColumnDef]="column.key"
      *ngFor="let column of userColumns; let i = index"
    >
      <mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        [disabled]="!column['sortable']"
        translate
      >
        <span [ngClass]="{ required: column['required'] }">
          {{
            column['title'] && column['title'].length
              ? column['title']
              : column.key
          }}
        </span>
      </mat-header-cell>
      <mat-cell *matCellDef="let row" class="medium">
        <ng-container
          *ngTemplateOutlet="
            columnTemplates ? columnTemplates : defaultColumn;
            context: {
              row: row,
              column: column.key,
              value: row[column.key]
            }
          "
        ></ng-container>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <action-menu
          icon="icon-more"
          *ngIf="
            row.filterOptions && row.filterOptions(config.menuOptions).length
          "
        >
          <ng-container
            *ngFor="let option of row.filterOptions(config.menuOptions)"
          >
            <a
              *ngIf="option.link && !option.external"
              class="action-{{ option.action }}"
              mat-menu-item
              [routerLink]="row.url(option.action)"
              [queryParams]="{ returnTo: option.returnTo }"
              >{{ option.name }}</a
            >
            <a
              *ngIf="option.link && option.external"
              class="action-{{ option.action }}"
              mat-menu-item
              [href]="row.url(option.action)"
              target="_blank"
              >{{ option.name }}</a
            >
            <a
              *ngIf="!option.link"
              class="action-{{ option.action }}"
              mat-menu-item
              (click)="menuAction(option.action, row)"
              >{{ option.name }}</a
            >
          </ng-container>
        </action-menu>
      </td>
    </ng-container>
    <mat-header-row
      [dragula]="dragulaName"
      class="draggable"
      [(dragulaModel)]="displayedColumns"
      *matHeaderRowDef="displayedColumns"
    >
    </mat-header-row>
    <mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="primary-row {{ row.classes }}"
      [ngClass]="{ over: row.over }"
      (click)="clickAction($event, row)"
      (dragenter)="dragAction($event, row)"
      (dragleave)="dragAction($event, row)"
      (dragexit)="dragAction($event, row)"
      ondragover="event.preventDefault()"
      (drop)="dropAction($event, row)"
    ></mat-row>
    <mat-row
      *matRowDef="let row; columns: detailColumns"
      [@expandRow]="rowIsExpanded(row) ? 'expanded' : 'collapsed'"
      class="{{ row.classes }} detail-row"
      style="overflow: hidden"
    ></mat-row>

    <notification *ngFor="let error of errors">{{ error }}</notification>
  </mat-table>

  <mat-paginator
    *ngIf="
      !config.automaticallyHidePagination ||
      (config.automaticallyHidePagination && count > config.pageSize)
    "
    [length]="count"
    (page)="pageChange($event)"
    [pageSize]="config.pageSize"
    [hidePageSize]="config.hidePageSize"
    [pageSizeOptions]="config.pageSizeOptions"
  >
  </mat-paginator>

  <my-loader
    *ngIf="loading"
    [matTableLoader]="true"
    [ngClass]="{ 'full-height': count === 0 }"
  ></my-loader>
  <no-results
    [itemName]="config.collectionTitle"
    [addText]="config.noResultsText"
    [results]="count"
    [matTable]="true"
    [styles]="{ 'min-height': '400px' }"
    [search]="search"
    [filters]="filters"
    [loading]="loading"
    [bordered]="false"
    [hasHeader]="config.hasHeader"
    [addButtonText]="config.addButtonText"
    [hasAction]="config.hasNoResultsAction"
    (clearSearch)="clearSearch()"
    (addItem)="
      config.newRecordModal ? config.newRecordModal() : openNewRecord()
    "
  >
  </no-results>

  <ng-content></ng-content>
  <ng-template
    #defaultColumn
    let-row="row"
    let-column="column"
    let-value="value"
  >
    <div class="value">{{ value }}</div>
  </ng-template>
</div>
