import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'ruckit-confirm-dialog',
  templateUrl: './ruckit-confirm-dialog.component.html',
  styleUrls: ['./ruckit-confirm-dialog.component.scss']
})
export class RuckitConfirmDialogComponent {
  attributes: any = {};

  constructor(
    public confirmDialog: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: {
      title?: string,
      message?: string,
      cancelText?: string,
      acceptText?: string,
      isErrorBtn?: boolean,
    },
  ) {
    this.attributes = {
      title: 'Confirm your action!',
      body: 'Are you sure you wish to do this?',
      close: 'Cancel',
      accept: 'Continue',
      isErrorBtn: false
    };

    if (data) {
      if (data.title) { this.attributes.title = data.title; }
      if (data.message) { this.attributes.body = data.message; }
      if (data.cancelText) { this.attributes.close = data.cancelText; }
      if (data.acceptText) { this.attributes.accept = data.acceptText; }
    }
  }
}
