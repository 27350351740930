import { clone, find as _find } from 'lodash';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Observable, Subscription, forkJoin, of, throwError } from 'rxjs';
import { mergeMap, first, switchMap, catchError, map, filter, tap } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { TranslateService } from '@ngx-translate/core';

// angular material
import { MatDialogRef, MatDialog } from '@angular/material';

// services
import { parseErrors } from '../../shared/api.service';
import { DriverService } from '../../drivers/driver.service';
import { JobEventService } from '../../job-events/job-event.service';
import { AssignmentService } from '../../assignments/assignment.service';
import { DispatchService } from '../dispatch.service';
import { TruckService } from '../../trucks/truck.service';
import { CollaboratorService } from '../../collaborators/collaborator.service';
import { ConnectionService } from '../../connections/connection.service';

// models
import { Driver } from '../../drivers/driver';
import { JobEvent } from '../../job-events/job-event';
import { Assignment } from '../../assignments/assignment';
import { Truck } from '../../trucks/truck';
import { Collaborator } from '../../collaborators/collaborator';
import { Carrier } from './../../carriers/carrier';
import { JobLoad } from '../dispatch-by-job/job-load';
import { AuthenticationService } from '../../shared';

// components
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';
import { AssignmentErrorDialogComponent } from '../../messages/assignment-error-dialog.component';

// constants
import { JOBWEIGHTOPTIONS, JobHaulType } from '../../app.constants';

// animations
import { collaboratorsAnimation } from '../../shared/animations/collaborators.animation';

@Component({
  selector: 'carrier-dispatch-dialog',
  templateUrl: './carrier-dispatch-dialog.component.html',
  styleUrls: ['./carrier-dispatch-dialog.component.scss'],
  animations: [collaboratorsAnimation]
})
export class CarrierDispatchDialogComponent implements OnInit, OnDestroy {
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  mobileView: 'drivers' | 'assignments' = 'assignments';
  jobEventId: string;
  jobEvent: JobEvent;
  jobEventReq: Subscription;
  assignments: Assignment[] = [];
  share: Collaborator;
  truckCount = 0;
  requestedAmount = 0;
  loading = false;
  errors = [];
  callback: Function;
  search: string;
  allDriversSelected = false;
  drivers: Driver[] = [];
  driversReq: Subscription;

  driversConfig = {
    nameProperty: 'name',
    selectText: 'Select Driver',
    loadingText: 'Loading Drivers...',
    noResultsText: 'No Drivers',
    service: DriverService
  };
  trucksConfig = {
    nameProperty: 'displayName',
    selectText: 'Select Truck',
    loadingText: 'Loading Trucks...',
    noResultsText: 'No Trucks',
    service: TruckService
  };

  weightOptions = [
    { value: 'load', name: 'Loads' },
    { value: 'hour', name: 'Hours' },
    ...JOBWEIGHTOPTIONS,
  ];

  @ViewChild('carriersDropdown', { static: false }) carriersDropdown: RuckitDropdownComponent;
  carrier: Carrier;
  carriersConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false,
    selectText: this.translateService.instant('My Drivers'),
    idProperty: 'organization.carrier.id',
    service: ConnectionService,
    prefilledOptions: [],
    initialLoad: true,
    query: {
      ordering: 'organization__name',
      allow_dispatch: 'True',
      is_carrier: 'True'
    }
  };

  hasLoadListsEnabled = false;
  loadList: JobLoad[] = [];

  constructor(
    public dialogRef: MatDialogRef<CarrierDispatchDialogComponent>,
    private jobEventService: JobEventService,
    private driverService: DriverService,
    private assignmentService: AssignmentService,
    private dispatchService: DispatchService,
    private collaboratorService: CollaboratorService,
    private deviceDetectorService: DeviceDetectorService,
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.loading = true;
    this.setCarrierDropdownPrefilledOptions();
    this.getDrivers();
    const hasLoadListsEnabled = this.authenticationService.hasLoadListsEnabled();
    this.hasLoadListsEnabled = hasLoadListsEnabled;
    this.jobEventReq = this.jobEventService.getJobEvent(this.jobEventId).subscribe(jobEvent => {
      this.jobEvent = jobEvent;
      this.assignmentService.listAll(5, { jobevent: this.jobEventId, can_dispatch: 'True' }).subscribe(res => {
        this.loading = false;
        this.assignments = this.assignments.concat(res);
        this.truckCount = this.share.confirmedTrucks || this.assignments.length;

        if (this.share.haulType === JobHaulType.hour || this.share.haulType === JobHaulType.load) {
          this.weightOptions = this.weightOptions.map(opt => ({...opt, selected: this.share.haulType === opt.value}));
        } else if (this.share.haulWeightUnit) {
          this.weightOptions = this.weightOptions.map(opt => ({...opt, selected: this.share.haulWeightUnit === opt.value}));
        }
      }, err => this.errors = parseErrors(err));
    }, err => this.errors = parseErrors(err));
  }

  ngOnDestroy() {
    if (this.jobEventReq && typeof this.jobEventReq.unsubscribe === 'function') {
      this.jobEventReq.unsubscribe();
    }
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
  }

  setCarrierDropdownPrefilledOptions() {
    const carrierOptions = [
      { name: 'My Drivers', id: 'my_drivers' },
      { name: 'All Carriers', id: 'all_carriers' },
    ];
    if (this.jobEvent && this.jobEvent.job
      && this.jobEvent.job.project
      && this.jobEvent.job.project.customerOrganization
      && this.jobEvent.job.project.customerOrganization['hasLeasedOrgs']) {
      carrierOptions.push({ name: 'Leased', id: 'all_leased' });
    }
    this.carriersConfig.prefilledOptions = carrierOptions;
  }

  getDrivers(query = {}): void {
    if (this.driversReq && typeof this.driversReq.unsubscribe === 'function') {
      this.driversReq.unsubscribe();
    }
    this.loading = true;
    const staticOptions = ['all_carriers', 'all_leased', 'my_drivers'];
    if (this.driversReq) { this.driversReq.unsubscribe(); }
    let carrierId: string;
    if (this.carrier && this.carrier.id && !staticOptions.includes(this.carrier.id)) {
      carrierId = this.carrier.id;
    }
    this.driversReq = this.driverService.list({
      search: this.search,
      ...query,
      carrier: carrierId,
      all_carriers: this.carrier && this.carrier.id === 'all_carriers' ? 'True' : null,
      all_leased: this.carrier && this.carrier.id === 'all_leased' ? 'True' : null,
    }).subscribe(drivers => {
      this.drivers = drivers;
      this.loading = false;
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  selectCarrier(carrier: Carrier): void {
    this.carrier = carrier;
    this.getDrivers();
  }

  onScroll(event): void {
    if (!this.loading && event.target.scrollTop > event.target.scrollHeight - event.target.clientHeight * 3) {
      let request = this.driverService.listNext();
      if (request) {
        this.loading = true;
        this.driversReq = request.subscribe(drivers => {
          this.drivers = this.drivers.concat(drivers);
          this.loading = false;
        }, err => {
          this.errors = err;
          this.loading = false;
        });
      }
    }
  }

  changeDriverSearch(term: string): void {
    this.search = term;
    this.getDrivers();
  }

  onSelect(driver: Driver, event: MouseEvent) {
    if (event.target['checked']) {
      if (!this.jobEvent.requestedAmount || this.assignments.length < this.jobEvent.requestedAmount) {
        this.assignments.push(<Assignment>{
          driver: driver,
          truck: driver.truck,
          jobevent: this.jobEvent,
          shift: 'shift1'
        });
        this.truckCount = this.share.confirmedTrucks || this.assignments.length;
      } else {
        this.errors = ['No more trucks are allowed on this job'];
        event.target['checked'] = false;
      }
    } else if (event.target['checked'] === false) {
      this.assignments = this.assignments.filter(a => a.driver.id !== driver.id);
    } else if (this.device.mobile) {
      let existingIndex = this.assignments.findIndex(a => (a.driver.id === driver.id));
      if (existingIndex > -1) {
        this.assignments.splice(existingIndex, 1);
        this.truckCount = this.share.confirmedTrucks || this.assignments.length;
      }
    }
  }

  changeDriver(driver: Driver, index: number) {
    this.assignments[index].driver = driver;
  }

  changeTruck(truck: Truck, index: number) {
    this.assignments[index].truck = truck;
  }

  onAssignmentChange(assignment: Assignment) {
    assignment.maxNumberOfLoads = assignment.numberOfLoadsType === 'allDay' ? 0 : assignment.maxNumberOfLoads || 1;
  }

  updateAssignment(assignment: Assignment) {
    const _assignment = clone(assignment);
    this.assignmentService.save(_assignment, {
      can_dispatch: 'True'
    }).subscribe(() => {
      // Do nothing
    }, err => {
      this.errors = parseErrors(err);
    });
  }

  removeAssignment(assignment: Assignment, index: number) {
    if (assignment.id) {
      this.assignmentService.remove(assignment.id)
        .pipe(first())
        .subscribe(() => {
          this.assignments.splice(index, 1);
          this.truckCount = this.share.confirmedTrucks || this.assignments.length;
        }, err => this.errors = parseErrors(err));
    } else {
      this.assignments.splice(index, 1);
      this.truckCount = this.share.confirmedTrucks || this.assignments.length;
    }
  }

  save(dispatch = false) {
    const newAssignments = this.assignments.filter(a => (!a.id));
    const existingAssignments = this.assignments.filter(a => (a.id));
    if (this.share) {
      this.loading = true;
      this.collaboratorService.save(
        this.jobEventId, Object.assign(
          {...this.share, requestedUnit: this.share.requestedUnit ? this.share.requestedUnit.id : null},
          { confirmedTrucks: this.truckCount })
      ).pipe(
        mergeMap(() => {
          return this.collaboratorService.accept(
            {...this.share, requestedUnit: this.share.requestedUnit ? this.share.requestedUnit.id : null}
          );
        })
      ).subscribe(() => {
        this.loading = false;
        if (newAssignments.length || existingAssignments.length) {
          this.assignAndDispatch(newAssignments, existingAssignments, dispatch);
        } else {
          this.dialogRef.close();
          this.callback();
        }
      }, err => {
        this.loading = false;
        this.errors = parseErrors(err.errors);
      });
    } else {
      if (newAssignments.length || existingAssignments.length) {
        this.assignAndDispatch(newAssignments, existingAssignments, dispatch);
      } else {
        this.dialogRef.close();
        this.callback();
      }
    }
  }

  assignAndDispatch(newAssignments: Assignment[], existingAssignments: Assignment[], dispatch = false) {
    if (newAssignments.length || existingAssignments.length) {
      const saveOptions = {
        jobEvent: this.jobEventId,
        notify_new: true
      };
      const reqs: Observable<any>[] = [];
      let assignmentErrors = [];
      if (newAssignments.length > 0) {
        const bulkCreateReq = this.assignmentService.bulkCreate(newAssignments).pipe(
          catchError((resp) => {
            if (resp.errors && resp.errors.length) {
              assignmentErrors = [ ...assignmentErrors, ...resp.errors ];
              return of(resp);
            } else {
              return throwError(resp);
            }
          })
        );
        reqs.push(bulkCreateReq);
      }
      if (existingAssignments.length > 0) {
        const bulkUpdateReq = this.assignmentService.bulkUpdate(existingAssignments).pipe(
          catchError((resp) => {
            if (resp.errors && resp.errors.length) {
              const mappedErrors = resp.errors.map((obj) => ({ errors: obj.error, data: obj.data }));
              assignmentErrors = [ ...assignmentErrors, ...mappedErrors ];
              return of(resp);
            } else {
              return throwError(resp);
            }
          })
        );
        reqs.push(bulkUpdateReq);
      }
      this.loading = true;
      forkJoin(reqs).pipe(
        switchMap((resp) => {
          const checkAssignments = resp.some(obj => obj.assignments && obj.assignments.length);
          if (dispatch && checkAssignments) {
            return this.dispatchService.save(saveOptions);
          } else {
            return of(resp);
          }
        })
      ).subscribe(() => {
        this.loading = false;
        this.callback();
        if (assignmentErrors.length) {
          this.openErrorModal(assignmentErrors);
        } else {
          this.dialogRef.close();
        }
      }, (err) => {
        this.loading = false;
        this.errors = parseErrors(err);
        this.callback();
      });
    }
  }

  openErrorModal(errors: any[]) {
    let dialogErrors = [];
    if (errors && errors.length) {
      dialogErrors = errors.map((error) => {
        const errorMessage = error.errors && error.errors.non_field_errors;
        let driver;
        if (error.item) {
          driver = _find(this.drivers, { id: error.item && error.item.driver });
        } else if (error.data) {
          let assignment = _find(this.assignments, { id: error.data && error.data.id });
          driver = _find(this.drivers, { id: error.data && error.data.driver });
          driver.truck = assignment.truck;
        }
        if (driver) {
          return {
            error: errorMessage,
            driverName: driver.name,
            truckName: driver.truck && driver.truck.displayName
          };
        } else {
          return {
            error: errorMessage
          };
        }
      });
    }
    if (dialogErrors.length) {
      const dialog = this.dialog.open(AssignmentErrorDialogComponent, {
        width: '430px',
        height: 'auto'
      });
      dialog.componentInstance.errors = dialogErrors;
    }
  }

  weightUnitChange(val) {
    const selected = val.value;

    if (selected === JobHaulType.load || selected === JobHaulType.hour) {
      this.share.haulType = selected;
    } else {
      this.share.haulWeightUnit = selected;
      this.share.haulType = JobHaulType.weight;
    }
  }

  isDriverAssigned(driverId: string): boolean {
    return this.assignments.findIndex(a => (a.driver.id === driverId)) > -1;
  }
}
