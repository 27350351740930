import {
  Component, ElementRef, OnInit, Input, Output, ViewChild, EventEmitter, OnDestroy
} from '@angular/core';
import { Subscription } from 'rxjs';
import { MatDialogRef } from '@angular/material';
import { clone, find as _find, reject } from 'lodash';

import { PunchCardService } from './punch-card.service';
import { JobService } from '../jobs/job.service';
import { JobEventService } from '../job-events/job-event.service';
import { DriverService } from '../drivers/driver.service';
import { TruckService } from '../trucks/truck.service';
import { parseErrors } from '../shared/api.service';
import { TruckSerializer } from '../trucks/truck.serializer';
import { JobSerializer } from '../jobs/job.serializer';
import { PunchCard } from './punch-card';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'edit-punch-card-dialog',
  templateUrl: './edit-punch-card-dialog.component.html',
  styleUrls: ['./edit-punch-card-dialog.component.scss'],
  providers: [
    PunchCardService, JobService, TruckService, DriverService, JobEventService
  ]
})
export class EditPunchCardDialogComponent implements OnInit, OnDestroy {
  @Input() punchCard: PunchCard;
  @Input() punchCardId: string;
  @Output() completed = new EventEmitter();
  loading = false;
  errors = [];
  jobEventError: string = null;
  job;
  truck;
  driver;
  jobEvent;
  callback;
  jobsReq: Subscription;
  jobEventsReq: Subscription;
  trucksReq: Subscription;
  driversReq: Subscription;
  @ViewChild('jobDropdown', { static: false }) jobDropdown;
  @ViewChild('driverDropdown', { static: false }) driverDropdown;
  @ViewChild('truckDropdown', { static: false }) truckDropdown;
  jobEventDatePickerOptions = {
    min: null,
    max: null
  };
  jobEventDate: Date;
  startDate: Date;
  endDate: Date;
  jobDropdownOptions = [];
  jobDropdownConfig = {
    nameProperty: 'displayName',
    searchable: true,
    group: true,
    groupBy: job => job.project.name,
    loadingOptions: false
  };
  driverDropdownOptions = [];
  driverDropdownConfig = {
    nameProperty: 'name',
    searchable: true,
    loadingOptions: false
  };
  truckDropdownOptions = [];
  truckDropdownConfig = {
    nameProperty: 'displayName',
    searchable: true,
    loadingOptions: false
  };

  constructor(
    public dialogRef: MatDialogRef<EditPunchCardDialogComponent>,
    private punchCardService: PunchCardService,
    private jobService: JobService,
    private jobEventService: JobEventService,
    private driverService: DriverService,
    private truckService: TruckService,
    private translateService: TranslateService,
    private elementRef: ElementRef
  ) { }

  ngOnInit() {
    this.loading = true;

    if (this.punchCardId) {
      this.getPunchCard(this.punchCardId);
    } else {
      // Configure Dates
      this.jobEventDate = this.punchCard.startDate;
      this.startDate = this.punchCard.startDate;
      this.endDate = this.punchCard.endDate;

      this.getJobs();
      this.getTrucks();
      this.getDrivers();
    }
  }

  ngOnDestroy() {
    if (this.jobsReq) { this.jobsReq.unsubscribe(); }
    if (this.jobEventsReq) { this.jobEventsReq.unsubscribe(); }
    if (this.driversReq) { this.driversReq.unsubscribe(); }
    if (this.trucksReq) { this.trucksReq.unsubscribe(); }
  }

  submitPunchCard(): void {
    this.loading = true;
    this.punchCardService.save(this.punchCard).subscribe((res) => {
      this.dialogRef.close();
      this.callback();
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  getPunchCard(punchCardId: string): void {
    this.loading = true;
    this.punchCardService.getPunchCard(punchCardId).subscribe((punchCard) => {
      this.punchCard = punchCard;
      this.job = this.punchCard.job;
      this.jobEventDate = punchCard.startDate;
      this.startDate = punchCard.startDate;
      this.endDate = punchCard.endDate;

      this.getJobs();
      this.getTrucks();
      this.getDrivers();
    }, (err) => {
      this.loading = false;
    });
  }

  onJobEventDateChanged(values: Date[]): void {
    if (values && values.length) {
      this.jobEventDate = values[0];
    }
    this.getJobEvents();
  }

  onStartDateChanged(values: Date[]): void {
    if (values && values.length) {
      this.punchCard.startDate = values[0];
    }
  }

  onEndDateChanged(values: Date[]): void {
    if (values && values.length) {
      this.punchCard.endDate = values[0];
    }
  }

  onStartTimeChange(value: any) {
    const time = value.target.value;
    this.punchCard.startTime = time;
  }

  onEndTimeChange(value: any) {
    const time = value.target.value;
    this.punchCard.endTime = time;
  }

  onSelect(filterType: string, e): void {
    switch (filterType) {
      case 'job':
        this.job = e;
        this.disableDates(this.job.startDate, this.job.endDate);
        this.getJobEvents({}, true);
        break;
      case 'driver':
        this.driver = e;
        break;
      case 'truck':
        this.truck = e;
        break;
    }
    this.punchCard[filterType] = e;
  }

  getJobs(query = {}): void {
    this.jobDropdownOptions = [];
    this.jobDropdownConfig.loadingOptions = true;
    this.jobDropdownConfig = clone(this.jobDropdownConfig);
    if (this.jobsReq) { this.jobsReq.unsubscribe(); }

    this.jobsReq = this.jobService.list({
      ordering: 'project__name,name,start_date',
      ...query
    }).subscribe(
        jobs => {
          this.jobDropdownOptions = jobs;
          setTimeout(() => {
            let jobevent = this.punchCard.jobEvent;
            if (jobevent) {
              if (jobevent.job) {
                this.jobDropdownOptions = reject(this.jobDropdownOptions, jobevent.job);
                this.jobDropdownOptions.unshift(jobevent.job);
                this.job = new JobSerializer().fromJson(_find(this.jobDropdownOptions, {id: jobevent.job.id}));
                this.disableDates(this.job.startDate, this.job.endDate);
                if (this.job) {
                  this.jobDropdown.selectedOption = this.job;
                }
              }
            }
          }, 0);
        },
        err => this.errors = err,
        () => {
          this.loading = false;
          this.jobDropdownConfig.loadingOptions = false;
          this.jobDropdownConfig = clone(this.jobDropdownConfig);
        }
      );
  }

  getJobEvents(query = {}, setSelected = false): void {
    this.loading = true;
    this.jobEventError = null;
    if (this.jobEventsReq) { this.jobEventsReq.unsubscribe(); }

    let jobEventDateStart = new Date();
    jobEventDateStart.setHours(0, 0, 0, 0);
    let jobEventDateEnd = clone(jobEventDateStart);
    jobEventDateEnd.setHours(23, 59, 59, 999);

    if (this.jobEventDate) {
      jobEventDateStart = new Date(this.jobEventDate);
      jobEventDateStart.setHours(0, 0, 0, 0);
      jobEventDateEnd = clone(jobEventDateStart);
      jobEventDateEnd.setHours(23, 59, 59, 999);
    }

    this.jobEventsReq = this.jobEventService.getJobEvents({
      job: this.job ? this.job.id : this.punchCard.jobEvent.job.id,
      shift1_start__gte: jobEventDateStart.toISOString(),
      shift1_start__lte: jobEventDateEnd.toISOString(),
      ordering: '-shift1_start',
      ...query
    }).subscribe(
      jobEvents => {
        if (jobEvents.length) {
          if (setSelected) {
            let _jobEvent = jobEvents[0];
            let _date = new Date(this.jobEventDate);
            if (_jobEvent.jobStart.setHours(0, 0, 0, 0) === _date.setHours(0, 0, 0, 0)) {
              this.jobEvent = _jobEvent;
              this.punchCard.jobEvent = this.jobEvent;
            } else {
              this.jobEventDate = null;
              this.jobEventError = this.translateService.instant('No Job Event found for that date!');
            }
          } else {
            this.jobEvent = jobEvents[0];
            this.punchCard.jobEvent = this.jobEvent;
          }
        } else {
          this.jobEventDate = null;
          this.jobEventError = this.translateService.instant('No Job Event found for that date!');
        }
        this.loading = false;
      },
      err => this.errors = err,
      () => {
        this.loading = false;
      }
    );
  }

  getDrivers(query = {}): void {
    this.driverDropdownOptions = [];
    this.driverDropdownConfig.loadingOptions = true;
    this.driverDropdownConfig = clone(this.driverDropdownConfig);
    if (this.driversReq) { this.driversReq.unsubscribe(); }

    this.driversReq = this.driverService.list({
      ordering: 'name',
      all_carriers: 'True',
      ...query
    }).subscribe(
        drivers => {
          this.driverDropdownOptions = drivers;
          setTimeout(() => {
            let driver = this.punchCard.driver;
            this.driverDropdownOptions = reject(this.driverDropdownOptions, driver);
            this.driverDropdownOptions.unshift(driver);
            this.driverDropdown.selectedOption = _find(this.driverDropdownOptions, {id: driver.id});
          }, 0);
        },
        err => this.errors = err,
        () => {
          this.loading = false;
          this.driverDropdownConfig.loadingOptions = false;
          this.driverDropdownConfig = clone(this.driverDropdownConfig);
        }
      );
  }

  getTrucks(query = {}): void {
    this.truckDropdownOptions = [];
    this.truckDropdownConfig.loadingOptions = true;
    this.truckDropdownConfig = clone(this.truckDropdownConfig);
    if (this.trucksReq) { this.trucksReq.unsubscribe(); }

    this.trucksReq = this.truckService.list({
      ordering: 'name',
      ...query
    }).subscribe(
        trucks => {
          this.truckDropdownOptions = trucks;
          setTimeout(() => {
            let truck = (new TruckSerializer().fromJson(this.punchCard.truck));
            this.truckDropdownOptions = reject(this.truckDropdownOptions, truck);
            this.truckDropdownOptions.unshift(truck);
            this.truckDropdown.selectedOption = _find(this.truckDropdownOptions, {id: truck.id});
          }, 0);
        },
        err => this.errors = err,
        () => {
          this.loading = false;
          this.truckDropdownConfig.loadingOptions = false;
          this.truckDropdownConfig = clone(this.truckDropdownConfig);
        }
      );
  }

  dropdownSearch(term: string, type: string): void {
    switch (type) {
      case 'job':
        this.getJobs({search: term});
        break;
      case 'truck':
        this.getTrucks({search: term});
        break;
      case 'driver':
        this.getDrivers({search: term});
        break;
    }
  }
  dropdownNextPage(e, type: string): void {
    let config;
    let service;
    let options;

    switch (type) {
      case 'job':
        config = this.jobDropdownConfig;
        service = this.jobService;
        options = this.jobDropdownOptions;
        break;
      case 'driver':
        config = this.driverDropdownConfig;
        service = this.driverService;
        options = this.driverDropdownOptions;
        break;
      case 'truck':
        config = this.truckDropdownConfig;
        service = this.truckService;
        options = this.truckDropdownOptions;
        break;
    }

    if (!config.loadingOptions) {
      let o = service.listNext();
      if (o) {
        config.loadingOptions = true;
        o.subscribe(
          results => {
            switch (type) {
              case 'job':
                this.jobDropdownOptions = this.jobDropdownOptions.concat(results);
                break;
              case 'driver':
                this.driverDropdownOptions = this.driverDropdownOptions.concat(results);
                break;
              case 'truck':
                this.truckDropdownOptions = this.truckDropdownOptions.concat(results);
                break;
            }
            config.loadingOptions = false;
          }, (err) => {
            this.errors = parseErrors(err);
            config.loadingOptions = false;
          }
        );
      }
    }
  }

  disableDates(startDate: Date, endDate: Date): void {
    let copy = JSON.parse(JSON.stringify(this.jobEventDatePickerOptions));
    let min = new Date(startDate);
    let max = new Date(endDate);
    max.setDate(max.getDate() + 2);

    copy.min = min;
    copy.max = max;
    this.jobEventDatePickerOptions = copy;
  }
}
