import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import Cropper from 'cropperjs';

// utilities
import { AppUtilities } from '../app-utilities';

@Component({
  selector: 'image-edit',
  templateUrl: './image-edit.component.html',
  styleUrls: ['./image-edit.component.scss'],
})
export class ImageEditComponent implements OnInit {
  @Input() image;
  @Input() options = {
    editable: true,
    light: false,
    showDelete: false,
    disabled: false,
  };
  @Output() uploading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() uploadData: EventEmitter<any> = new EventEmitter<any>();
  cropperImage;
  cropperId = Math.floor(Math.random() * 10000 + 1);
  imageElement: HTMLImageElement;
  imageElementId = 'cropper-image-' + this.cropperId;
  imageFile: File;
  uploadedImageURL: string;
  inputElementId = 'image-input-' + this.cropperId;
  cropper;
  existingImage = false;
  imageId = null;
  @ViewChild('fileInput', { static: false }) fileInputElement;

  constructor(private translateService: TranslateService) {}

  ngOnInit() {
    this.onInit();
  }

  ngAfterViewInit() {
    this.initImageCropper();
  }

  onInit() {
    if (this.image) {
      this.imageId = this.image.id ? this.image.id : '';
      this.cropperImage = this.uploadedImageURL = this.image.src
        ? this.image.src
        : URL.createObjectURL(this.image);
      this.existingImage = true;
    }
  }

  initImageCropper() {
    this.imageElement = <HTMLImageElement>(
      document.getElementById(this.imageElementId)
    );
    this.initCropper(this.imageElement);
  }

  initCropper(el?) {
    el = el
      ? el
      : <HTMLImageElement>document.getElementById(this.imageElementId);
    this.cropper = new Cropper(el, {
      autoCrop: false,
      autoCropArea: 1,
      background: false,
      guides: false,
      highlight: false,
      modal: false,
      movable: false,
      restore: false,
      toggleDragModeOnDblclick: false,
      viewMode: 2,
      zoomable: false,
    });

    // for legacy images - temporary
    if (this.image && this.image.legacy) {
      this.imgUrlToBase64();
    }
  }

  async imgUrlToBase64() {
    const base64Img = await this.legacyImgToDataURL(this.image.src);
    this.image['file'] = base64Img;
  }

  legacyImgToDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      )

  convertBlobtoFile(blob: Blob): File {
    let b: any = blob;
    b.lastModifiedDate = new Date();
    b.name = 'uploaded-image-' + b.lastModifiedDate.toISOString() + '.jpg';
    b.isEdited = true;
    b.isNew = this.image && this.image.isNew ? true : false;
    b.id = this.image && this.image.id ? this.image.id : null;

    return <File>blob;
  }

  newImage(e) {
    let files = e.target.files;
    let file;
    let uploadedImageType, uploadedImageName;

    if (this.cropper && files && files.length > 0) {
      this.uploading.emit(true);
      file = files[0];

      if (AppUtilities.fileSmallerThanAllowed(file.size)) {
        if (/^image\/\w+/.test(file.type)) {
          this.image = file;
          uploadedImageType = file.type;
          uploadedImageName = file.name;

          if (this.uploadedImageURL) {
            URL.revokeObjectURL(this.uploadedImageURL);
          }

          this.imageElement.src = this.uploadedImageURL =
            URL.createObjectURL(file);
          if (this.imageId) {
            file['isEdited'] = true;
            file['id'] = this.imageId;
          } else {
            file['isNew'] = true;
          }
          this.cropper.destroy();
          if (file.size > 1000000) {
            // 1mb
            this.initCropper(this.imageElement);
            let timesRun = 0;
            let interval = setInterval(() => {
              timesRun += 1;
              if (this.cropper && this.cropper.getCroppedCanvas()) {
                if (file.size > 5000000) {
                  // if image is bigger than 5mb, reduce the quality by 95%
                  this.resizeImage(file.type, 0.05);
                } else {
                  // else reduce the quality by 85%
                  this.resizeImage(file.type);
                }
                clearInterval(interval);
              }
              if (timesRun === 10) {
                clearInterval(interval);
              }
            }, 500);
          } else {
            this.emitData(file);
            this.initCropper(this.imageElement);
          }
        } else {
          window.alert(
            this.translateService.instant('Please choose an image file.')
          );
        }
      } else {
        window.alert(
          this.translateService.instant(
            'Please choose a smaller image (under 25MB).'
          )
        );
      }
    }
  }

  resizeImage(fileType: string, quality = 0.15) {
    // lowering quality by 85%
    const croppedCanvas = this.cropper.getCroppedCanvas();
    if (croppedCanvas) {
      croppedCanvas.toBlob(
        (blob) => {
          const edittedImage = this.convertBlobtoFile(blob);
          this.emitData(edittedImage);
        },
        fileType,
        quality
      );
    }
  }

  emitData(image) {
    image['legacy'] = this.image && this.image.legacy ? true : false;
    this.uploadData.emit(image);
    this.uploading.emit(false);
  }

  rotateImage(fileType: string) {
    this.uploading.emit(true);
    const croppedCanvas = this.cropper.rotate(90).getCroppedCanvas();
    if (croppedCanvas) {
      croppedCanvas.toBlob(
        (blob) => {
          const edittedImage = this.convertBlobtoFile(blob);
          this.emitData(edittedImage);
        },
        fileType,
        1.0
      );
    }
  }

  removeImage() {
    // this.image = null;
    this.image['isDeleted'] = true;
    this.imageElement.src = null;
    this.uploadedImageURL = null;

    this.fileInputElement.nativeElement.value = '';
    this.cropper.destroy();
    this.initImageCropper();
    this.uploadData.emit(this.image);
  }
}
