"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var core_2 = require("@ngx-translate/core");
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var material_1 = require("@angular/material");
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var thresholds_dialog_component_1 = require("../thresholds-dialog/thresholds-dialog.component");
var notifications_report_service_1 = require("../services/notifications-report.service");
var preference_service_1 = require("../../preferences/preference.service");
var shared_1 = require("../../shared");
var chart_dialog_component_1 = require("../chart-dialog/chart-dialog.component");
var notifications_constants_1 = require("./notifications.constants");
var NotificationsComponent = /** @class */ (function () {
    function NotificationsComponent(authenticationService, notificationReportService, preferenceService, translationService, dialog) {
        this.authenticationService = authenticationService;
        this.notificationReportService = notificationReportService;
        this.preferenceService = preferenceService;
        this.translationService = translationService;
        this.dialog = dialog;
        this.availableColumns = [
            { key: 'name', title: this.translationService.instant('Organization'), sortable: true, sortBy: 'name' },
            { key: 'lastPeriod', title: this.translationService.instant('Last 7 day period'), sortable: true, sortBy: 'last_period' },
            { key: 'currentPeriod', title: this.translationService.instant('Current 7 day period'), sortable: true, sortBy: 'current_period' },
            { key: 'percentageChange', title: this.translationService.instant('Change %') },
            { key: 'chart', title: this.translationService.instant('Chart') },
        ];
        this.displayedColumns = notifications_constants_1.CONSTANTS.displayedColumns;
        this.tableConfig = {
            hasHeader: true,
            ignorePreferences: true,
            service: notifications_report_service_1.NotificationsReportService,
            hasNoResultsAction: false,
            query: {}
        };
        this.frameDropdownData = notifications_constants_1.CONSTANTS.frameDropdownData;
        this.search = '';
        this.currentTimeFrame = notifications_constants_1.CONSTANTS.defaultTimeFrame;
        this.thresholdConfig$ = new rxjs_1.BehaviorSubject([]);
        this.allSubscriptionsToUnsubscribe = [];
        this.showChart = false;
        this.barChartType = 'bar';
        this.barChartOptions = notifications_constants_1.CONSTANTS.barChartOptions;
    }
    NotificationsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.allSubscriptionsToUnsubscribe.push(this.thresholdConfig$.subscribe(function (config) { return _this.handleThresholdConfigChange(config); }));
        this.getReportConfig();
        this.getPreferences();
    };
    NotificationsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) { return sub.unsubscribe(); });
    };
    NotificationsComponent.prototype.handleThresholdConfigChange = function (config) {
        if (!config.length || !this.dataSource || !this.dataSource.data.length) {
            return;
        }
        // config rules have to be ordered in descending order by value
        this.dataSource.data.forEach(function (el) {
            var matchingElement = config.find(function (rule) { return Math.abs(el.percentageChange) >= rule.value; });
            if (matchingElement) {
                var color = matchingElement.color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);
                var r = Number(color[1]);
                var g = Number(color[2]);
                var b = Number(color[3]);
                var luma = (0.2126 * r) + (0.7152 * g) + (0.0722 * b);
                el.textColor = luma <= 128 ? 'rgba(255, 255, 255, 1)' : 'rgba(0, 0, 0, .78)';
                el.ruleColor = matchingElement.color;
            }
        });
        this.notificationsReportTable.dataSource.data = this.dataSource.data;
    };
    NotificationsComponent.prototype.getDataSource = function (dataSource) {
        this.dataSource = dataSource;
        this.barChartData = {
            labels: dataSource.data.map(function (el) { return el.name; }),
            datasets: [
                {
                    data: dataSource.data.map(function (el) { return el.lastPeriod; }),
                    label: this.currentTimeFrame.lastPeriodColumnName,
                    backgroundColor: 'rgba(174, 214, 241, 0.8)',
                    hoverBackgroundColor: '#AED6F1'
                },
                {
                    data: dataSource.data.map(function (el) { return el.currentPeriod; }),
                    label: this.currentTimeFrame.currentPeriodColumnName,
                    backgroundColor: 'rgba(255, 218, 185, 0.8)',
                    hoverBackgroundColor: '#FFDAB9'
                }
            ]
        };
        if (this.thresholdConfig$.value.length) {
            var currentRules = this.thresholdConfig$.value;
            this.thresholdConfig$.next(currentRules);
        }
    };
    NotificationsComponent.prototype.getReportConfig = function () {
        var _this = this;
        this.notificationReportService.getReportConfig().subscribe(function (resp) { return _this.thresholdConfig$.next(resp); });
    };
    NotificationsComponent.prototype.getPreferences = function () {
        var _this = this;
        var currentUser = this.authenticationService.user();
        this.preferenceService.list({
            name: this.notificationReportService.preferenceKey,
            type: 'user',
            profile: currentUser.id
        }).pipe(operators_1.switchMap(function (resp) {
            if (resp && resp.length) {
                return rxjs_1.of(resp[0]);
            }
            else {
                return _this.preferenceService.save({
                    name: _this.notificationReportService.preferenceKey,
                    type: 'user',
                    profile: currentUser.id,
                    blob: {
                        timeFrame: 'seven-days'
                    }
                });
            }
        })).subscribe(function (preference) {
            _this.preference = preference;
            var selectedFrame = _this.frameDropdownData.frames.find(function (opt) { return opt.id === _this.preference.blob.timeFrame; });
            _this.currentTimeFrame = selectedFrame;
            _this.tableConfig.query['frame'] = _this.preference.blob.timeFrame;
            _this.notificationsReportTable.getRecords({ frame: _this.preference.blob.timeFrame });
            _this.availableColumns.forEach(function (col) {
                if (col.key === 'lastPeriod') {
                    col.title = _this.translationService.instant(_this.currentTimeFrame.lastPeriodColumnName);
                }
                if (col.key === 'currentPeriod') {
                    col.title = _this.translationService.instant(_this.currentTimeFrame.currentPeriodColumnName);
                }
            });
        });
    };
    NotificationsComponent.prototype.changeFrameSelection = function (data) {
        var _this = this;
        this.currentTimeFrame = data;
        this.preference.blob.timeFrame = data.id;
        this.tableConfig.query['frame'] = data.id;
        this.preferenceService.save(this.preference).subscribe();
        if (this.notificationsReportTable) {
            this.notificationsReportTable.getRecords({ frame: data.id, page: 1 });
            this.notificationsReportTable.updateUrl({ frame: data.id, page: 1 }, false, false);
            this.availableColumns.forEach(function (col) {
                if (col.key === 'lastPeriod') {
                    col.title = _this.translationService.instant(data.lastPeriodColumnName);
                }
                if (col.key === 'currentPeriod') {
                    col.title = _this.translationService.instant(data.currentPeriodColumnName);
                }
            });
        }
    };
    NotificationsComponent.prototype.openThresholdsDialog = function () {
        var _this = this;
        var dialog = this.dialog.open(thresholds_dialog_component_1.ThresholdsDialogComponent, {
            width: '380px',
            maxHeight: '600px'
        });
        dialog.componentInstance.thresholdConfig$.next(this.thresholdConfig$.value);
        dialog.componentInstance.callback = function (rules) { return _this.thresholdConfig$.next(rules); };
    };
    NotificationsComponent.prototype.openChartDialog = function (data) {
        var dialog = this.dialog.open(chart_dialog_component_1.ChartDialogComponent);
        dialog.componentInstance.reportData = data;
        dialog.componentInstance.currentTimeFrame = this.currentTimeFrame;
    };
    return NotificationsComponent;
}());
exports.NotificationsComponent = NotificationsComponent;
