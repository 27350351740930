import { FormGroup } from '@angular/forms';
import moment = require('moment');

// Start time: Required, always
// Loading Checkin, optional overall, date/time must be later than start time and earlier than unloading date/time and end time
// Unloading Checkin, optional overall, date/time must be later than start time and loading checkin date/time, and earlier than end time
// End time: Optional, must be later than start time, loading / unloading date/time.

export const globalTripTimeValidator = (c: FormGroup) => {
  if (!c) { return null; }
  const startDate = c.get('start').value.date;
  const startTime = c.get('start').value.time;

  const endDate = c.get('end').value.date;
  const endTime = c.get('end').value.time;

  const startLoadingDate = c.get('loadingCheckin').value.date;
  const startLoadingTime = c.get('loadingCheckin').value.time;

  const startUnloadingDate = c.get('unloadingCheckin').value.date;
  const startUnloadingTime = c.get('unloadingCheckin').value.time;

  if (
    endDate == null &&
    startLoadingDate == null &&
    startUnloadingDate == null
  ) {
    return null;
  }

  const start = moment(
    `${moment(startDate).format('YYYY-MM-DD')} ${startTime}`
  );
  const end = moment(`${moment(endDate).format('YYYY-MM-DD')} ${endTime}`);

  const startLoading = moment(
    `${moment(startLoadingDate).format('YYYY-MM-DD')} ${startLoadingTime}`
  );

  const endLoading = moment(
    `${moment(startUnloadingDate).format('YYYY-MM-DD')} ${startUnloadingTime}`
  );

  // conditionally add date to array if not null or undefined by correct order
  const array = [
    { date: start, name: 'start' },
    ...(!!startLoadingDate && !!startLoadingTime
      ? [{ date: startLoading, name: 'startLoadingTime' }]
      : []),
    ...(!!startUnloadingDate && !!startUnloadingTime
      ? [{ date: endLoading, name: 'endLoadingTime' }]
      : []),
    ...(!!endDate && !!endTime ? [{ date: end, name: 'end' }] : []),
  ];

  // check if date is before previous date and add an globalTripTimeError to the form
  for (let i = 1; i < array.length; i++) {
    if (array[i].date.isBefore(array[i - 1].date)) {
      return {
        globalTripTimeError: array[i].name,
      };
    }
  }

  return null;
};
