import {
  Component, OnInit, Input, SimpleChanges, Output, EventEmitter, OnDestroy, ViewChild
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { Subscription, forkJoin, of } from 'rxjs';
import { first, mergeMap, switchMap, tap, map, catchError, defaultIfEmpty } from 'rxjs/operators';
import { DeviceDetectorService } from 'ngx-device-detector';
import { clone } from 'lodash';
import * as moment from 'moment';

import { DropdownComponent, parseErrors } from '../../shared';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs/';
import { AuthenticationService } from './../../shared/authentication.service';
import { JobEventService } from '../../job-events/job-event.service';
import { CondensedJobEventService } from '../../job-events/condensed-job-event.service';
import { JobEventStatService } from '../../job-event-stats/job-event-stat.service';
import { CondensedJobEvent } from '../../job-events/condensed-job-event';
import { CollaboratorService } from '../../collaborators/collaborator.service';
import { Collaborator } from '../../collaborators/collaborator';
import { CarrierDispatchDialogComponent } from '../../dispatch/carrier-dispatch/carrier-dispatch-dialog.component';
import { MoveAssignmentsDialogComponent } from '../../assignments/move-assignments/move-assignments-dialog.component';
import { AssignmentService } from '../../assignments/assignment.service';


@Component({
  selector: 'ruckit-daily-board-grid',
  templateUrl: './daily-board-grid.component.html',
  styleUrls: ['./daily-board-grid.component.scss']
})
export class DailyBoardGridComponent implements OnInit, OnDestroy {
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  @Input() hideCancelled = true;
  @Input() sortBy: string;
  @Input() filters = [];
  @Input() jobEventDate: Date;
  @Input() search = '';
  @Output() jobEventCount = new EventEmitter();

  jobEvents: CondensedJobEvent[] = [];
  jobEvent: CondensedJobEvent = new CondensedJobEvent();
  jobEventStatuses = [
    {
      action: (jobEvent: CondensedJobEvent) => this.changeStatus('cancel', jobEvent),
      name: 'Cancel Job'
    }
  ];
  shares: Collaborator[] = [];
  loading = true;
  errors = [];
  count = 0;
  canCreateJobs = true;
  sortAsc = true;
  jobEventsReq: Subscription;
  statsReqs: Subscription[] = [];
  statsReqLoading = false;
  polylineOptions = {
    strokeColor: '#002649',
    strokeWeight: '4',
    strokeOpacity: 0.6
  };
  displayAsGrid = true;
  enabledFeatures: string[] = [];
  filterOptions = [
    { value: 'active', name: 'Active Jobs' },
    { value: 'cancelled', name: 'Cancelled Jobs' },
    { value: 'all', name: 'All Jobs' }
  ];
  carrierMode = false;
  @ViewChild('actionsDropdown', { static: false }) actionsDropdown: DropdownComponent;
  jobActionsDropdownOptions = [
    { name: 'Edit Dispatches' },
    { name: 'Move to New Job' },
    { name: 'Reject Job' }
  ];
  actionsDropdownConfig = {
    nameProperty: 'name',
    showLoading: false
  };
  confirmDialog: MatDialogRef<any>;
  isAcceptedCountRequired: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private condensedJobEventService: CondensedJobEventService,
    private jobEventService: JobEventService,
    private jobEventStatService: JobEventStatService,
    private collaboratorService: CollaboratorService,
    private assignmentService: AssignmentService,
    private deviceDetectorService: DeviceDetectorService,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
    this.canCreateJobs = this.authenticationService.canCreateJobs();
    this.enabledFeatures = this.authenticationService.enabledFeatures();
    this.carrierMode = !this.enabledFeatures.includes('ruckitBilling');
    this.isAcceptedCountRequired = this.authenticationService.getOrgPropertyValue('driverFleetRequireAssignmentConfirmation');
    this.getJobs();
  }

  ngOnDestroy() {
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }
    if (this.statsReqs && this.statsReqs.length) {
      try {
        this.statsReqs.forEach(req => {
          try {
            if (req && typeof req.unsubscribe === 'function') {
              req.unsubscribe();
            }
          } catch (e) { }
        });
      } catch (e) { }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let jobEventDateStart = new Date();
    jobEventDateStart.setHours(0, 0, 0, 0);
    let jobEventDateEnd = clone(jobEventDateStart);
    jobEventDateEnd.setHours(23, 59, 59, 999);

    if (changes['jobEventDate']) {
      const jobEventDate = changes['jobEventDate'].currentValue;
      if (jobEventDate) {
        jobEventDateStart = new Date(this.jobEventDate);
        jobEventDateStart.setHours(0, 0, 0, 0);
        jobEventDateEnd = clone(jobEventDateStart);
        jobEventDateEnd.setHours(23, 59, 59, 999);
      }
    }

    this.getJobs();
    this.jobEventCount.emit(this.count);
  }

  getJobs(query = {}, append = false): void {
    if (this.jobEventsReq && typeof this.jobEventsReq.unsubscribe === 'function') {
      this.jobEventsReq.unsubscribe();
    }

    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }

    if (!append) {
      this.jobEvents = [];
    }
    let filters = this.filters.filter(Boolean).reduce((acc, filter) => {
      return { ...acc, ...filter.query };
    }, {});

    this.loading = true;
    let order = (this.sortAsc ? '' : '-') + this.sortBy;

    let jobEventDateStart = new Date();
    jobEventDateStart.setHours(0, 0, 0, 0);
    let jobEventDateEnd = clone(jobEventDateStart);
    jobEventDateEnd.setHours(23, 59, 59, 999);

    if (this.jobEventDate) {
      jobEventDateStart = new Date(this.jobEventDate);
      jobEventDateStart.setHours(0, 0, 0, 0);
      jobEventDateEnd = clone(jobEventDateStart);
      jobEventDateEnd.setHours(23, 59, 59, 999);
    }

    this.statsReqLoading = true;
    this.jobEventsReq = this.condensedJobEventService.list({
      ordering: order,
      search: this.search,
      exclude_pending: this.carrierMode ? 'False' : 'True',
      exclude_cf: 'True',
      shift1_start__gte: jobEventDateStart.toISOString(),
      shift1_start__lte: jobEventDateEnd.toISOString(),
      cancelled: this.hideCancelled && !this.carrierMode ? 'False' : undefined,
      ...filters,
      ...query
    }).pipe(
      mergeMap((jobEvents) => {
        this.count = this.condensedJobEventService.count;
        if (jobEvents && !Array.isArray(jobEvents)) { jobEvents = [jobEvents]; }

        let effectiveRateCalculator = '';
        if (this.enabledFeatures && this.enabledFeatures.includes('effectiveRateCalculator')) {
          effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
        }
        return forkJoin(jobEvents.map(jobEvent =>
          this.jobEventStatService.getStats(jobEvent.id, {
            calculator: effectiveRateCalculator
          }).pipe(
            catchError(() => of(null))
          )
        )).pipe(
          map(jobEventStats => {
            jobEvents.forEach((jobEvent, index) => {
              jobEvent.stats = jobEventStats[index];
            });
            return jobEvents;
          }),
          defaultIfEmpty([])
        );
      }),
      tap((jobEvents) => {
        if (append) {
          this.jobEvents = this.jobEvents.concat(jobEvents);
        } else {
          this.jobEvents = jobEvents;
        }
      }),
      switchMap((jobEvents) => {
        if (this.carrierMode) {
          const carrierQuery = {
            ordering: 'jobevent__job__name',
            start__gte: jobEventDateStart.toISOString(),
            start__lte: jobEventDateEnd.toISOString()
          };
          if (this.search) {
            carrierQuery['search'] = this.search;
          }
          return this.collaboratorService.getShares(carrierQuery).pipe(
            tap((shares) => this.shares = shares)
          );
        } else {
          return of(jobEvents);
        }
      })
    ).subscribe(
      () => {
        this.loading = false;
        this.statsReqLoading = false;
      }, (err) => {
        this.errors = err;
        this.loading = false;
        this.statsReqLoading = false;
      }
    );
  }

  sort(sortKey: string): void {
    if (this.sortBy === sortKey) { this.sortAsc = !this.sortAsc; }
    this.sortBy = sortKey;
    this.loading = true;
    this.getJobs({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
  }

  onScroll(e): void {
    if (!this.loading &&
      e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
      let o = this.condensedJobEventService.listNext();
      if (o) {
        this.loading = true;
        this.statsReqLoading = true;
        o.pipe(
          mergeMap((jobEvents) => {
            let effectiveRateCalculator = '';
            if (this.enabledFeatures && this.enabledFeatures.includes('effectiveRateCalculator')) {
              effectiveRateCalculator = this.authenticationService.getFeature('effectiveRateCalculator');
            }
            return forkJoin(jobEvents.map(jobEvent =>
              this.jobEventStatService.getStats(jobEvent.id, {
                calculator: effectiveRateCalculator
              }).pipe(
                catchError(() => of(null))
              )
            )).pipe(
              map(jobEventStats => {
                jobEvents.forEach((jobEvent, index) => {
                  jobEvent.stats = jobEventStats[index];
                });
                return jobEvents;
              })
            );
          }),
          tap((jobEvents) => this.jobEvents = this.jobEvents.concat(jobEvents)),
          switchMap((jobEvents) => {
            const nextShares = this.collaboratorService.getNext();
            if (this.carrierMode && nextShares) {
              return nextShares.pipe(
                tap((shares) => this.shares = this.shares.concat(shares))
              );
            } else {
              return of(jobEvents);
            }
          })
        ).subscribe(() => {
          this.loading = false;
          this.statsReqLoading = false;
        }, err => {
          this.errors = err;
          this.loading = false;
          this.statsReqLoading = false;
        });
      }
    }
  }

  selectJobEvent(e, jobEvent: CondensedJobEvent): void {
    let target = e.target || e.srcElement || e.currentTarget;
    if (target && jobEvent && !this.carrierMode && !this.device.mobile) {
      this.router.navigate(['/jobs/' + jobEvent.jobId + '/' + jobEvent.id]);
    }
  }

  changeStatus(status: 'cancel', jobEvent: CondensedJobEvent) {
    let jobEventUpdates: CondensedJobEvent = <CondensedJobEvent>{ id: jobEvent.id };
    switch (status) {
      case 'cancel':
        jobEventUpdates.cancelled = true;
        break;
    }
    this.jobEventService.save(jobEventUpdates).subscribe(() => {
      this.getJobs();
    }, (err) => { this.errors = err; });
  }

  isSelected(jobEvent: CondensedJobEvent): boolean {
    return jobEvent.id === this.jobEvent.id;
  }

  openCarrierDispatchDialog(jobEvent: CondensedJobEvent) {
    const dialog = this.dialog.open(CarrierDispatchDialogComponent, {
      width: '1040px'
    });
    dialog.componentInstance.jobEventId = jobEvent.id;
    dialog.componentInstance.share = this.shares.find(s => (s.jobEventId === jobEvent.id));
    dialog.componentInstance.callback = () => this.getJobs();
  }

  rejectShare(jobEventId: string) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Are you sure?',
      body: 'This job will be hidden if rejected!',
      close: 'Cancel',
      accept: 'Reject Job'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const share = this.shares.find(s => (s.jobEventId === jobEventId));
        if (share) {
          this.collaboratorService.reject(share.id).subscribe(() => {
            this.getJobs();
          }, err => this.errors = err);
        }
      }
      this.confirmDialog = null;
    });
  }

  setJobAction(option, jobEvent: CondensedJobEvent) {
    switch (option.name) {
      case 'Edit Dispatches':
        this.openCarrierDispatchDialog(jobEvent);
        break;
      case 'Move to New Job':
        this.modifyAssignments(jobEvent);
        break;
      case 'Copy to New Job':
        this.modifyAssignments(jobEvent, true);
        break;
      case 'Remove Assignments':
        this.removeAssignments(jobEvent);
        break;
      case 'Reject Job':
        this.rejectShare(jobEvent.id);
    }
    this.actionsDropdown.deselectAll();
  }

  selectFilter(filter: 'active' | 'cancelled' | 'all') {
    let query = {};
    switch (filter) {
      case 'active':
        query = { exclude_pending: 'True' };
        break;
      case 'cancelled':
        query = { cancelled: 'True' };
        break;
      case 'all':
        break;
    }
    this.getJobs(query);
  }

  modifyAssignments(jobEvent: CondensedJobEvent, copy = false): void {
    const dialog = this.dialog.open(MoveAssignmentsDialogComponent, {width: '850px'});
    if (dialog) {
      const convertedJobEvent = this.jobEventService.convertCondensedJobEvent(jobEvent);
      dialog.componentInstance.job = convertedJobEvent.job;
      dialog.componentInstance.start = moment(this.jobEventDate).startOf('day').toDate();
      dialog.componentInstance.jobEvent = convertedJobEvent;
      dialog.componentInstance.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
      dialog.componentInstance.trucksEditable = true;
      dialog.componentInstance.copy = copy;
      dialog.componentInstance.callback = () => this.getJobs();
    }
  }

  removeAssignments(jobEvent: CondensedJobEvent) {
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: 'Are you sure?',
      body: 'You are about to remove all Assignments for this Job Day',
      close: 'Cancel',
      accept: 'Remove'
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.assignmentService.listAll(10, { jobevent: jobEvent.id }).pipe(first()).subscribe(assignments => {
          this.assignmentService.bulkRemove(assignments.map(a => (a.id))).pipe(first()).subscribe(() => {
            this.getJobs();
          }, err => this.errors = parseErrors(err));
        }, err => this.errors = parseErrors(err));
      }
      this.confirmDialog = null;
    });
  }
}
