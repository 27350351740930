import { remove, find, clone } from 'lodash';

import { Project } from '../projects/project';
import { Location } from '../locations/location';
import { TruckType } from '../trucks/truck-type';
import { WorkOrderTemplate } from '../work-orders/work-order-templates/work-order-template';
import { CheckinOption } from '../checkins/checkin-option';
import { Resource } from '../shared/resource';
import { Organization } from '../organizations/organization';

export interface MultipleItems {
  isMultipleMaterials:  boolean;
  materialsList:        string[];
  quantitiesList:       string[];
  uomList:              string[];
}

export class Job extends Resource {
  id: string;
  name: string;
  nameWithDate: string;
  displayName: string;
  group: string;
  poNumber: string;
  jobNumber: string;
  orderNumber: string;
  material: string;
  materialCode: string;
  phaseCode: string;
  invoiceType: string;
  invoiceWeightUnit: string;
  rate: string;
  haulType: string;
  haulRate: string;
  haulWeightUnit: string;
  amountNeeded: string;
  startDate: string;
  endDate: string;
  startsAt: string;
  endsAt: string;
  shift1StartTime: string;
  shift1EndTime: string;
  shift2StartTime: string;
  shift2EndTime: string;
  shift1Overnight: boolean;
  shift2Overnight: boolean;
  shift1Start: string;
  shift1End: string;
  shift2Start: string;
  shift2End: string;
  endTime: string;
  numTrucks: string;
  dailyDeliveryTarget: string;
  dailyDeliveryTargetType: string;
  totalAmountType: string;
  deliveryInterval: number;
  deliveryIntervalUnit: string;
  notes: string;
  project: Project;
  checkinOptions: CheckinOption;
  checkoutOptions: CheckinOption;
  endLocation: Location;
  startLocation: Location;
  distance: string;
  distanceInMiles: string;
  distanceInKilometers: string;
  estimatedRouteTime: string;
  routePolyline: string;
  truckTypes: TruckType[];
  truckTypeNames: string;
  tags: any[];
  allowAnyTruckType: boolean;
  stats: any;
  shifts: number;
  totalAmount: string;
  allowWeight: boolean;
  archived: boolean;
  invoiceablePunchcardCount: string;
  invoiceableTripCount: string;
  canDelete = false;
  canEdit: boolean;
  qrJobType: string;
  customFieldData: { [key: string]: string };
  mapImageURL: string;
  mapImageURLWide: string;
  deliveryIntervalMinutes: number;
  deliveryIntervalHours: number;
  startLocationMappable: boolean;
  endLocationMappable: boolean;
  workOrderTemplate: WorkOrderTemplate;
  legaleseUrl: string;
  invoiceRateUnit: string;
  haulRateUnit: string;
  startLocationDescription: string;
  endLocationDescription: string;
  notice: any;
  startCoordinates: any;
  endCoordinates: any;
  dateRange: string;
  checkinOptionDescriptions: any[];
  checkoutOptionDescriptions: any[];
  secondShift = false;
  dates: Date[] = [];
  dsorderId: string;
  slorderId: string;
  ownerOrganization: Organization;
  customerOrganization: Organization;
  multipleItemsData: MultipleItems;

  // buffer times
  defaultRoundTripTime: number;
  defaultYardPrepTime: number;
  defaultYardBufferTime: number;
  defaultYardBufferMinutes: number;

  measurementUnit(plural: boolean): string {
    if (this.invoiceType === 'weight') {
      if (this.invoiceWeightUnit === 'cuyds') {
        return plural ? 'cu. yds.' : 'cu. yd.';
      } else {
        return plural ? this.invoiceWeightUnit + 's' : this.invoiceWeightUnit;
      }
    } else if (this.haulType === 'weight') {
      if (this.haulWeightUnit === 'cuyds') {
        return plural ? 'cu. yds.' : 'cu. yd.';
      } else {
        return plural ? this.haulWeightUnit + 's' : this.haulWeightUnit;
      }
    } else {
      return 'ton';
    }
  }

  url(action: string): string {
    return '/jobs/' + this.id + '/' + action;
  }

  filterOptions(options): any[] {
    options = clone(options);

    if (this.archived) {
      let _option = find(options, { action: 'archive' });
      if (_option) { remove(options, _option); }
    }

    if (!this.canDelete) {
      let _option = find(options, { action: 'delete' });
      if (_option) { remove(options, _option); }
    }

    if (!this.archived) {
      let _option = find(options, { action: 'unarchive' });
      if (_option) { remove(options, _option); }
    }

    return options;
  }
}
