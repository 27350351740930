import { User } from '../users/user';
import { UserSerializer } from '../users/user.serializer';
import { Carrier } from '../carriers/carrier';
import { Broker } from '../brokers/broker';
import { Contractor } from '../contractors/contractor';
import { PriceList } from '../price-lists/price-list';
import { Tag } from '../tags/tag';
import { TagSerializer } from '../tags/tag.serializer';
import { Attachment } from '../attachments/attachment.interface';
import moment = require('moment');

// constants
import {
  ORGANIZATIONBUSINESSLICENSE,
  ORGANIZATIONIMAGETYPE,
  ORGANIZATIONINSURANCE,
  ORGANIZATIONOVERWEIGHTPERMIT,
} from '../app.constants';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class Organization {
  id: string;
  createdAt: string;
  lastModified: string;
  name: string;
  email: string;
  contactName: string;
  inviteCode: string;
  image: string;
  attachments: Attachment[];
  street: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  zipcode: string;
  phoneNumber: string;
  faxNumber: string;
  paymentTerms: string;
  primaryContact: User;
  billingContact: User;
  billingAddress1: string;
  billingAddress2: string;
  billingCity: string;
  billingState: string;
  billingCountry: string;
  billingZipcode: string;
  billingPhoneNumber: string;
  billingEmail: string;
  broker: Broker;
  carrier: Carrier;
  contractor: Contractor;
  startingInvoiceNumber: string;
  remittanceInfo: string;
  driverFleetCanViewRates: boolean;
  driverFleetQuickTicket: boolean;
  dvirRequired: boolean;
  driverFleetRequireAssignmentConfirmation: boolean;
  avoidTolls: boolean;
  activeTrackingEnabled: boolean;
  canCreateJobs: boolean;
  invoiceablePunchcardCount: string;
  invoiceableTripCount: string;
  posEnabled: boolean;
  isCrh: boolean;
  advBillingEnabled: boolean;
  allDriversEnabled: boolean;
  driverFleetCanViewOrderDetails: boolean;
  leasedOrg: boolean;
  hasLeasedOrgs: boolean;
  hasTrackables: boolean;
  hasRuckit3: boolean;
  startingTicketNumber: string;
  posPriceList: PriceList;
  uniqueBillingId: string;
  qrEnabled: boolean;
  tags: Tag;
  hasCopyAssignment = false;
  hasWorkOrder: boolean;
  workOrderDbaName: string;
  workOrderCompanyType: string;
  features: any;
  defaultAverageLoadingTime: string;
  defaultAverageUnloadingTime: string;
  enabledFeatures: string[];

  shiftMonitorAutoCloseInterval: number;
  shiftMonitorAutoClose: boolean;
  senderEmail: string;
  senderName: string;

  // ab5 fields
  dotNumber: string;
  mcNumber: string;
  dirNumber: string;
  einNumber: string;
  isAb5Compliant: boolean;
  ab5SubmittedDate: string;
  ab5SubmittedBy: string;
  insuranceDocument: string;
  insuranceExpirationDate: string;
  overweightPermitExpirationDate: string;
  overweightPermit: string;
  businessLicense: string;

  // viewpoint fields
  viewpointCompanyNumber: string;
  viewpointJcco: string;

  // buffer times
  defaultRoundTripTime: number;
  defaultRoundTripMinutes: number;
  defaultYardPrepTime: number;
  defaultYardPrepMinutes: number;
  defaultYardBufferTime: number;
  defaultYardBufferMinutes: number;

  defaultHaulRate: number;
  defaultHaulRateType: string;

  lafargeRegion: string;

  private _detail: string;

  constructor(organizationInfo: any) {
    organizationInfo = camelcaseKeysDeep(organizationInfo);

    this.id = organizationInfo.id;
    if (organizationInfo.name || organizationInfo.name === '') {
      this.name = organizationInfo.name;
    } else {
      this.name = organizationInfo.carrier && organizationInfo.carrier.name;
    }
    this.email = organizationInfo.email;
    this.image = organizationInfo.image;
    this.contactName = organizationInfo.contactName;
    if (organizationInfo.primaryContact && typeof (organizationInfo.primaryContact) === 'object') {
      this.primaryContact = (new UserSerializer).fromJson(organizationInfo.primaryContact);
    } else if (organizationInfo.primaryContact) {
      this.primaryContact = (new UserSerializer).fromJson({ id: organizationInfo.primaryContact });
    } else {
      this.primaryContact = (new UserSerializer).fromJson({});
    }
    // NOTE: tags are undefined
    if (organizationInfo.tags && typeof (organizationInfo.tags) === 'object') {
      this.tags = new TagSerializer().fromJson(organizationInfo.tags);
    }
    this.createdAt = organizationInfo.createdAt;
    this.lastModified = organizationInfo.lastModified;
    this.street = organizationInfo.street;
    this.address2 = organizationInfo.address2;
    this.city = organizationInfo.city;
    this.state = organizationInfo.state;
    this.inviteCode = organizationInfo.inviteCode;
    this.zipcode = organizationInfo.zipcode;
    this.phoneNumber = organizationInfo.phoneNumber;
    this.faxNumber = organizationInfo.faxNumber;
    this.paymentTerms = organizationInfo.paymentTerms;
    this.country = organizationInfo.country;
    if (organizationInfo.billingContact) {
      this.billingContact = (new UserSerializer).fromJson(organizationInfo.billingContact);
    }
    this.billingAddress1 = organizationInfo.billingAddress1;
    this.billingAddress2 = organizationInfo.billingAddress2;
    this.billingCity = organizationInfo.billingCity;
    this.billingState = organizationInfo.billingState;
    this.billingCountry = organizationInfo.billingCountry;
    this.billingZipcode = organizationInfo.billingZipcode;
    this.billingPhoneNumber = organizationInfo.billingPhoneNumber;
    this.billingEmail = organizationInfo.billingEmail;
    this.broker = organizationInfo.broker;
    if (organizationInfo.carrier) {
      this.carrier = new Carrier(organizationInfo.carrier);
    }
    this.contractor = organizationInfo.contractor;
    this.startingInvoiceNumber = organizationInfo.startingInvoiceNumber;
    this.remittanceInfo = organizationInfo.remittanceInfo;
    this.driverFleetCanViewRates = organizationInfo.driverFleetCanViewRates;
    this.driverFleetQuickTicket = organizationInfo.driverFleetQuickTicket;
    this.activeTrackingEnabled = organizationInfo.activeTrackingEnabled;
    this.canCreateJobs = organizationInfo.canCreateJobs;
    this.invoiceablePunchcardCount = organizationInfo.invoiceablePunchcardCount;
    this.invoiceableTripCount = organizationInfo.invoiceableTripCount;
    this.posEnabled = organizationInfo.posEnabled;
    this.isCrh = organizationInfo.isCrh;
    this.advBillingEnabled = organizationInfo.advBillingEnabled;
    this.allDriversEnabled = organizationInfo.allDriversEnabled;
    this.driverFleetCanViewOrderDetails = organizationInfo.driverFleetCanViewOrderDetails;
    this.leasedOrg = organizationInfo.leasedOrg;
    this.hasLeasedOrgs = organizationInfo.hasLeasedOrgs;
    this.hasTrackables = organizationInfo.features && organizationInfo.features['hasTrackables'];
    this.hasRuckit3 = organizationInfo.features && organizationInfo.features['hasRuckit3'];
    this.startingTicketNumber = organizationInfo.startingTicketNumber;
    this.dvirRequired = organizationInfo.dvirRequired;
    this.driverFleetRequireAssignmentConfirmation = organizationInfo.driverFleetRequireAssignmentConfirmation;
    this.avoidTolls = organizationInfo.avoidTolls;
    this.uniqueBillingId = organizationInfo.uniqueBillingId;
    this.qrEnabled = organizationInfo.qrEnabled;
    this.hasCopyAssignment = organizationInfo.hasCopyAssignment;
    this.hasWorkOrder = organizationInfo.hasWorkOrder;
    this.workOrderDbaName = organizationInfo.workOrderDbaName;
    this.workOrderCompanyType = organizationInfo.workOrderCompanyType;
    this.features = organizationInfo.features;
    this.defaultAverageLoadingTime = organizationInfo.defaultAverageLoadingTime;
    this.defaultAverageUnloadingTime = organizationInfo.defaultAverageUnloadingTime;
    this.shiftMonitorAutoCloseInterval = organizationInfo.shiftMonitorAutoCloseInterval;
    this.shiftMonitorAutoClose = organizationInfo.shiftMonitorAutoClose;
    this.senderEmail = organizationInfo.senderEmail;
    this.senderName = organizationInfo.senderName;

    // ab5 fields
    this.dotNumber = organizationInfo.dotNumber;
    this.mcNumber = organizationInfo.mcNumber;
    this.dirNumber = organizationInfo.dirNumber;
    this.einNumber = organizationInfo.einNumber;
    this.isAb5Compliant = organizationInfo.isAb5Compliant;
    this.ab5SubmittedDate = organizationInfo.ab5SubmittedDate;
    this.ab5SubmittedBy = organizationInfo.ab5SubmittedBy;
    this.insuranceDocument = organizationInfo.insuranceDocument;
    this.insuranceExpirationDate = organizationInfo.insuranceExpirationDate;
    this.overweightPermitExpirationDate = organizationInfo.overweightPermitExpirationDate;
    this.overweightPermit = organizationInfo.overweightPermit;
    this.businessLicense = organizationInfo.businessLicense;

    this.attachments = organizationInfo.attachments;

    // attachments backward compatibility
    if (organizationInfo.attachments && organizationInfo.attachments.some(a => a.fileType === ORGANIZATIONIMAGETYPE)) {
      this.image = organizationInfo.attachments.find(a => a.fileType === ORGANIZATIONIMAGETYPE).file;
    }
    if (organizationInfo.attachments && organizationInfo.attachments.some(a => a.fileType === ORGANIZATIONINSURANCE)) {
      this.insuranceDocument = organizationInfo.attachments.find(a => a.fileType === ORGANIZATIONINSURANCE).file;
    }
    if (organizationInfo.attachments && organizationInfo.attachments.some(a => a.fileType === ORGANIZATIONBUSINESSLICENSE)) {
      this.businessLicense = organizationInfo.attachments.find(a => a.fileType === ORGANIZATIONBUSINESSLICENSE).file;
    }
    if (organizationInfo.attachments && organizationInfo.attachments.some(a => a.fileType === ORGANIZATIONOVERWEIGHTPERMIT)) {
      this.overweightPermit = organizationInfo.attachments.find(a => a.fileType === ORGANIZATIONOVERWEIGHTPERMIT).file;
    }

    // viewpoint fields
    this.viewpointCompanyNumber = organizationInfo.viewpointCompanyNumber;
    this.viewpointJcco = organizationInfo.viewpointJcco;

    this.lafargeRegion = organizationInfo.lafargeRegion;

    this.defaultRoundTripTime = organizationInfo.defaultRoundTripTime;
    if (organizationInfo.defaultRoundTripTime) {
      const roundTripTimeMinutes = moment.duration(organizationInfo.defaultRoundTripTime).asMinutes();
      if (roundTripTimeMinutes) {
        this.defaultRoundTripMinutes = roundTripTimeMinutes;
      }
    }

    this.defaultYardPrepTime = organizationInfo.defaultYardPrepTime;
    if (organizationInfo.defaultYardPrepTime) {
      const yardPrepTimeMinutes = moment.duration(organizationInfo.defaultYardPrepTime).asMinutes();
      if (yardPrepTimeMinutes) {
        this.defaultYardPrepMinutes = yardPrepTimeMinutes;
      }
    }
    this.defaultYardBufferTime = organizationInfo.defaultYardBufferTime;
    if (organizationInfo.defaultYardBufferTime && parseFloat(organizationInfo.defaultYardBufferTime) > 0) {
      this.defaultYardBufferMinutes = parseFloat(organizationInfo.defaultYardBufferTime) / 60;
    }

    this.defaultHaulRate = organizationInfo.defaultHaulRate;
    this.defaultHaulRateType = organizationInfo.defaultHaulRateType;

    if (this.features) {
      this.enabledFeatures = [];
      Object.keys(this.features).forEach(key => {
        if (this.features[key]) {
          this.enabledFeatures.push(key);
        }
      });
    }
  }

  get types(): string {
    let values = [];
    if (this.carrier && this.carrier.id) { values.push('Carrier'); }
    if (this.contractor && this.contractor.id) { values.push('Contractor'); }
    if (this.broker && this.broker.id) { values.push('Broker'); }

    return values.filter(function (v) {
      return (v !== '' && v !== null);
    }).join(' | ');
  }

  get detail(): string {
    let value = this.city + ', ' + this.state + '<br />';
    return value + this.types;
  }

  set detail(value: string) {
    this._detail = value;
  }

  get indentifier(): string {
    return 'NEED DOT';
  }

  get location(): string {
    let value = [this.city, this.state];
    return value.filter(Boolean).join(', ');
  }

  get coordinates(): any[] {
    return [30.271095, -97.728443];
  }
}
