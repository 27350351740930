import {
  Component, OnInit, OnDestroy,
  EventEmitter, Output, ViewChild, ElementRef
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { Location, formatNumber } from '@angular/common';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

// angular material
import { MatDialog, MatDialogRef, MatCheckboxChange } from '@angular/material';

// libraries
import * as moment from 'moment';
import { remove, difference, pull, find as _find, clone } from 'lodash';

// services
import { parseErrors } from '../../shared/api.service';
import { AssignmentService } from './../assignment.service';
import { DriverAssignmentsService } from './../driver-assignments.service';
import { AuthenticationService } from '../../shared/authentication.service';
import { DriverAssignmentsListComponent } from './../driver-assignments-list.component';

// models
import { Driver } from './../../drivers/driver';
import { DriverAssignment } from './../driver-assignment';
import { DateTimeRange, TimelineRowData, TimelineConfig } from '../../shared';
import { Preference } from '../../preferences/preference';
import { DriverContextEvent } from '../../drivers/driver-context-menu/interfaces/driver-context-event';
import { MenuSelectionEvent } from '../../drivers/driver-context-menu/interfaces/menu-selection-event';

// components
import { DriverMapComponent } from './../driver-map.component';
import { ExportDialogComponent, ExportDialogData } from '../../shared/export-dialog/export-dialog.component';
import { QuickDispatchDialogComponent } from '../../dispatch/quick-dispatch-dialog.component';
import { CopyAssignmentsDialogComponent } from './../copy-assignments-dialog.component';
import { DropdownConfig } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';
import { FiltersDialogComponent } from '../../shared/filters-dialog/filters-dialog.component';

// serializers
import { DriverSerializer } from './../../drivers/driver.serializer';

// services
import { PreferenceService } from '../../preferences/preference.service';
import { CollaboratorService } from '../../collaborators/collaborator.service';
import { AssignmentFilterService } from './../assignment.filter.service';
import { LocationService } from '../../locations/location.service';
import { TagService } from '../../tags/tag.service';
import { AllDriversMetadataService } from '../../drivers/all-drivers-metadata/all-drivers-maps-metadata.service';

// contants
import { ViewAssignmentsOptionAction, FleetHealthAction } from '../../drivers/driver-context-menu/data/options';

// animations
import { assignmentExpandMapAnimation } from '../../shared/animations/assignment-expand-map';
import { assignmentExpandTimelineAnimation } from '../../shared/animations/assignment-expand-timeline';
import { MenuItem } from '../../drivers/driver-context-menu/interfaces/menu-item';

@Component({
  selector: 'all-driver-assignments',
  templateUrl: './all-driver-assignments.component.html',
  styleUrls: ['./all-driver-assignments.component.scss'],
  animations: [
    assignmentExpandMapAnimation,
    assignmentExpandTimelineAnimation
  ],
})
export class AllDriverAssignmentsComponent implements OnInit, OnDestroy {
  @ViewChild(DriverAssignmentsListComponent, { static: false }) driverAssignmentsList: DriverAssignmentsListComponent;
  @ViewChild(DriverMapComponent, { static: false }) driverMap: DriverMapComponent;
  selectedDriver;
  displayedColumns = [];
  rows: any[] = [];
  loading = false;
  pendingJobsCount = 0;
  view = 'list';
  type = 'latest';
  date;
  assignmentDate: Date;
  assignmentDateChanged: Subject<Date> = new Subject<Date>();
  assignmentISODate;
  errors = [];
  assignments: DriverAssignment[] = [];
  expandedAssignments: any = [];
  hours: string[] = [];
  assignmentsReq: Subscription;
  timeInterval: any;
  queryParams: { [x: string]: string } = {};
  sortBy = 'date';
  sortAsc = true;
  sortParameter: string;
  search = '';
  searchChanged: Subject<string> = new Subject<string>();
  filters = [];
  filtersDialog;
  dispatchDialog;
  selectedCount = 0;
  allSelected = false;
  selectedAssignments = [];
  excludeAssignments = [];
  confirmDialog: MatDialogRef<any>;
  count = 0;
  page = 1;
  pageSize = 10;
  invitationsReq: Subscription;
  multipleActionDropdownOptions = [
    { name: 'Export', button: true },
    { name: 'Operator\'s Export', button: true }
  ];
  timeIntervalDropdownOptions = [
    { id: 1, name: this.translateService.instant('1 hour') },
    { id: 2, name: this.translateService.instant('30 minutes') }
  ];
  timeIntervalDropdownConfig = {
    nameProperty: 'name',
    loadingOptions: false,
    multiselect: false,
  };

  timelineRange: DateTimeRange;
  timelineData: TimelineRowData[] = [];
  timelineConfig: TimelineConfig = {
    visibleBars: ['trips', 'predictedTrips'],
    headerHeight: 62,
    rowHeight: 99,
    scroll: true,
    currentTime: true,
    selectedTime: false
  };

  preference: Preference;
  preferencesReq: Subscription;
  pendingCountReq: Subscription;
  menuOptions = [
    { name: this.translateService.instant('Export'), action: 'export', link: false, external: false },
    { name: this.translateService.instant('Void'), action: 'void', link: false, external: false },
  ];
  @Output() changeSearchEmitter: EventEmitter<any> = new EventEmitter<any>();
  // The reason to use read option is that mat-table is an angular component (which itself is a directive) and
  // ViewChild returns the whole component if we do not specify the read option
  @ViewChild('driverList', { static: false, read: ElementRef }) driverList: ElementRef;
  expandedElement: any;
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  driverIdToOpenAssignments: string;
  viewAssignmentsOptionAction = ViewAssignmentsOptionAction;
  showNoAssignmentsAlert = false;
  additionalMenuItems: MenuItem[] = [FleetHealthAction];
  showInvoiceTotal = false;
  isExpansionTripRow = (i: number, row: Object) => row.hasOwnProperty('tripRow');
  copyAssignmentCallback = (e) => {
    this.getDriverAssignments(); // gets called on success of copy assignments
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private assignmentService: AssignmentService,
    private collaboratorService: CollaboratorService,
    private driverAssignmentsService: DriverAssignmentsService,
    private authenticationService: AuthenticationService,
    private preferenceService: PreferenceService,
    private translateService: TranslateService,
    private allDriversMetadataService: AllDriversMetadataService,
    private dialog: MatDialog
  ) {
    this.searchChanged.pipe(
      debounceTime(300), distinctUntilChanged()
    ).subscribe(search => {
      this.search = search;
      this.queryParams['search'] = search;
      this.getDriverAssignments();
    });
  }

  ngOnInit() {
    if (!this.authenticationService.hasAllDriversEnabled()) {
      this.router.navigate(['/jobs/daily']);
    }

    this.route.queryParams.forEach((params: Params) => {
      this.queryParams = params;
      this.loading = true;
      this.sortBy = this.queryParams.sortBy ? this.queryParams.sortBy : 'driver__profile__last_name';
      this.sortAsc = this.queryParams.sortAsc ? (this.queryParams.sortAsc === 'true') : false;
      this.search = this.queryParams['search'];
      if (this.queryParams['view'] || this.queryParams['type']) {
        this.switchView(
          (this.queryParams['view'] ? this.queryParams['view'] : this.view),
          (this.queryParams['type'] ? this.queryParams['type'] : this.type)
        );
      }
      this.showInvoiceTotal = this.queryParams['showInvoiceTotal'] === 'true';
      this.getPreferences();
      this.page = this.queryParams['page'] ? Number(this.queryParams['page']) : 1;
      if (this.queryParams['date']) { this.date = moment(this.queryParams['date'], 'YYYYMMDD'); }
      this.setDefaultDate();
      if (this.queryParams['driver_assignments']) {
        const driverId = this.queryParams['driver_assignments'];
        this.driverIdToOpenAssignments = driverId;
      }
      if (this.queryParams['returnTo']) {
        history.pushState(null, null, this.queryParams['returnTo']);
      }
      this.getDriverAssignments();
    });

    this.getPendingCount();
  }

  switchView(view: string, type = 'geotrip') {
    this.queryParams = Object.assign(
      clone(this.queryParams),
      { type: type, view: view }
    );
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: this.queryParams
    }).toString();
    this.location.go(url);
    this.view = view;
    this.type = type;
    this.setView();
  }

  setView() {
    switch (this.view) {
      case 'latest':
        this.displayedColumns = this.type === 'geotrip' ? [
          'selector', 'driver', 'geo-status', 'location', 'geo-duration', 'assignments'
        ] : [
          'selector', 'driver', 'status', 'location', 'duration', 'assignments'
        ];
        break;
      case 'timeline':
        this.displayedColumns = this.type === 'geotrip' ? [
          'selector', 'driver', 'geo-status'
        ] : [
          'selector', 'driver', 'status'
        ];
        break;
      case 'stats':
        this.expandedAssignments = [];
        this.displayedColumns = this.type === 'geotrip' ? [
          'selector', 'driver', 'geo-status', 'geo-en-route1', 'geo-loading', 'geo-en-route2',
          'geo-unloading', 'load', 'weight', ... this.showInvoiceTotal ? ['invoice-total', 'expense-total'] : [] , 'assignments'
        ] : [
          'selector', 'driver', 'status', 'en-route1', 'loading', 'en-route2',
          'unloading', 'load', 'weight', ... this.showInvoiceTotal ? ['invoice-total', 'expense-total'] : [], 'assignments'
        ];
        break;
      case 'map':
        this.displayedColumns = this.type === 'geotrip' ? [
          'selector', 'driver', 'geo-status', 'assignments'
        ] : [
          'selector', 'driver', 'status', 'assignments'
        ];
        break;
      default:
          this.expandedAssignments = [];
        this.displayedColumns = this.type === 'geotrip' ? [
          'selector', 'driver', 'geo-status', 'location', 'geo-duration', 'assignments'
        ] : [
          'selector', 'driver', 'status', 'location', 'duration', 'assignments'
        ];
        break;
    }
    if (this.preference && this.preference.blob) {
      if (this.preference.blob.view !== this.view) {
        this.savePreferences(this.preference.blob.filters);
      }
    } else {
      this.savePreferences(this.preference && this.preference.blob && this.preference.blob.filters);
    }
  }

  /**
   * @param  {} selectedItem
   * Opens the copy assignment dialog of the selected driver.
   */
  copyAssignment(selectedItem): void {
    const dialog = this.dialog.open(CopyAssignmentsDialogComponent, {
      width: '500px',
      height: '600px',
      data: { selectedItem },
      disableClose: true
    });
    dialog.componentInstance.callback = this.copyAssignmentCallback;
  }

  ngOnDestroy() {
    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }
  }

  getDriverAssignments(query = {}, skipLoading = false): void {
    if (!skipLoading) { this.loading = true; }
    const allDriversRefresh = this.authenticationService.getFeature('allDriversRefresh') || 300000;
    if (this.authenticationService.hasFavoriteTags() && !!!this.filters.find(f => (f.key === 'tags'))) { query['user_tags'] = 'True'; }
    let order = (this.sortAsc ? '' : '-') + this.sortBy;
    let filters = this.filters.reduce((acc, filter) => ({ ...acc, ...filter.query }), {});
    delete filters['undefined'];

    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }

    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    if (this.assignmentDate) {
      startDate = new Date(this.assignmentDate);
      startDate.setHours(0, 0, 0, 0);
      endDate = clone(startDate);
      endDate.setHours(23, 59, 59, 999);
    }

    this.assignmentsReq = this.driverAssignmentsService.listUpdate(allDriversRefresh, {
      ordering: order,
      page_size: this.pageSize,
      search: this.search,
      page: this.page,
      dispatched: 'True',
      active_range: startDate.toISOString() + ',' + endDate.toISOString(),
      ...(!this.showInvoiceTotal && {omit: 'invoice_total,expense_total'}),
      ...filters,
      ...query
    }).subscribe(assignments => {
      assignments = assignments.sort((a, b) => (
        (
          moment.min(
            a.assignments.map(ass => (moment(ass.uniqueStart)))
          ).toISOString() < moment.min(
            b.assignments.map(ass => (moment(ass.uniqueStart)))
          ).toISOString()
        ) ? -1 :
        (
          moment.min(
            a.assignments.map(ass => (moment(ass.uniqueStart)))
          ).toISOString() > moment.min(
            b.assignments.map(ass => (moment(ass.uniqueStart)))
          ).toISOString()
        ) ? 1 : 0
      ));
      this.count = this.driverAssignmentsService.count || assignments.length;
      this.setAssignmentData(assignments);
      this.setupTimelineData(assignments);
    }, error => {
      this.errors = parseErrors(error);
      this.loading = false;
    });
    this.allDriversMetadataService.listUpdate(allDriversRefresh, {
      ordering: order,
      page_size: this.pageSize,
      search: this.search,
      page: this.page,
      dispatched: 'True',
      active_range: startDate.toISOString() + ',' + endDate.toISOString(),
      omit: 'invoice_total,expense_total',
      ...filters,
      ...query
    }).subscribe();
  }

  setAssignmentData(assignments: DriverAssignment[]) {
    let rowData = [];
    assignments.forEach(assignment => {
      if (assignment.tripStatus === 'Loading') {
        assignment.duration.target = assignment.latestTrip.job &&
          assignment.latestTrip.job.startLocation &&
          assignment.latestTrip.job.startLocation.averageLoadingTime ?
          Number(assignment.latestTrip.job.startLocation.averageLoadingTime) : 0;
      } else if (assignment.tripStatus === 'Unloading') {
        assignment.duration.target = assignment.latestTrip.job &&
          assignment.latestTrip.job.endLocation &&
          assignment.latestTrip.job.endLocation.averageUnloadingTime ?
          Number(assignment.latestTrip.job.endLocation.averageUnloadingTime) : 0;
      }
      if (assignment.geoTripStatus === 'Loading') {
        assignment.geoDuration.target = assignment.latestGeoTrip.job &&
          assignment.latestGeoTrip.job.startLocation &&
          assignment.latestGeoTrip.job.startLocation.averageLoadingTime ?
          Number(assignment.latestGeoTrip.job.startLocation.averageLoadingTime) : 0;
      } else if (assignment.geoTripStatus === 'Unloading') {
        assignment.geoDuration.target = assignment.latestGeoTrip.job &&
          assignment.latestGeoTrip.job.endLocation &&
          assignment.latestGeoTrip.job.endLocation.averageUnloadingTime ?
          Number(assignment.latestGeoTrip.job.endLocation.averageUnloadingTime) : 0;
      }
      rowData.push(assignment, { tripRow: true, assignment });
    });
    this.rows = rowData;
    this.assignments = assignments;
    if (this.driverIdToOpenAssignments) {
      const driverAssignment = assignments.find(a => a.id === this.driverIdToOpenAssignments);
      if (driverAssignment) {
        this.showNoAssignmentsAlert = false;
        this.openDriverAssignmentOnPageLoad(driverAssignment);
      } else {
        this.showNoAssignmentsAlert = true;
      }
      this.router.navigate([], {
        queryParams: {
          'driver_assignments': null,
        },
        queryParamsHandling: 'merge'
      });
    }
    this.loading = false;
  }

  getPendingCount(query = {}, append = false): void {
    this.pendingCountReq = this.collaboratorService.getPending({ page_size: 1 }).subscribe(invitations => {
      this.pendingJobsCount = this.collaboratorService.count;
    }, err => {
      this.errors = err;
    });
  }

  changeSearch(term?: string) {
    this.search = term;
    this.updateUrl({ search: term });
    this.searchChanged.next(term);
  }

  updateUrl(params) {
    params['search'] = params['search'] ? params['search'] : this.search;
    this.page = this.page > 1 && this.search ? 1 : this.page;
    params['page'] = this.page > 1 ? this.page : null;

    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {
        ...this.queryParams,
        ...params
      }
    }).toString();
    this.location.go(url);
  }

  sort(sortKey) {
    if (this.sortBy === sortKey) { this.sortAsc = !this.sortAsc; }
    this.sortBy = sortKey;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.queryParams,
        sortBy: this.sortBy,
        sortAsc: this.sortAsc
      }
    });
    this.loading = true;
    this.getDriverAssignments({ ordering: (this.sortAsc ? '' : '-') + this.sortBy });
  }

  customSort(sortParameter, sortKey) {
    if (this.sortParameter === sortParameter && this.sortBy === sortKey) {
      this.sortAsc = !this.sortAsc;
    }
    this.sortBy = sortKey;
    this.sortParameter = sortParameter;
    this.loading = true;
    this.getDriverAssignments({ [this.sortParameter]: (this.sortAsc ? '' : '-') + this.sortBy });
  }

  openFilters() {
    const dialog = this.dialog.open(FiltersDialogComponent, {
      width: '430px'
    });

    if (dialog) {
      let isCrh = false;
      let startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      let endDate = clone(startDate);
      endDate.setHours(23, 59, 59, 999);

      if (this.assignmentDate) {
        startDate = new Date(this.assignmentDate);
        startDate.setHours(0, 0, 0, 0);
        endDate = clone(startDate);
        endDate.setHours(23, 59, 59, 999);
      }

      const baseDropdownConfig = <DropdownConfig>{
        selectText: this.translateService.instant('Select Job'),
        loadingText: this.translateService.instant('Loading Jobs...'),
        noResultsText: this.translateService.instant('No Jobs'),
        service: AssignmentFilterService,
        serviceFunction: 'listFilters',
        searchKey: 'filter_search',
        serviceFunctionScope: 'jobs',
        query: { active_range: startDate.toISOString() + ',' + endDate.toISOString() }
      };

      dialog.componentInstance.filters = [
        (isCrh ? {
          type: 'dropdown', field: 'jobEvent',
          label: this.translateService.instant('Job'),
          dropdownConfig: {
            ...baseDropdownConfig,
            serviceFunctionScope: 'jobevents'
          }
        } : {
          type: 'dropdown', field: 'job',
          label: this.translateService.instant('Job'),
          dropdownConfig: baseDropdownConfig
        }), {
          type: 'text', field: 'orderNumber',
          label: this.translateService.instant('Order Number')
        }, {
          type: 'dropdown', field: 'customer',
          label: this.translateService.instant('Customer'),
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: this.translateService.instant('Select Customer'),
            loadingText: this.translateService.instant('Loading Customer...'),
            noResultsText: this.translateService.instant('No Customers'),
            serviceFunctionScope: 'customers'
          }
        }, {
          type: 'dropdown', field: 'project',
          label: this.translateService.instant('Project'),
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: this.translateService.instant('Select Project'),
            loadingText: this.translateService.instant('Loading Project...'),
            noResultsText: this.translateService.instant('No Projects'),
            serviceFunctionScope: 'projects'
          }
        }, {
          type: 'dropdown', field: 'truckType',
          label: this.translateService.instant('Truck Type'),
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: this.translateService.instant('Select Truck Type'),
            loadingText: this.translateService.instant('Loading Truck Type...'),
            noResultsText: this.translateService.instant('No Truck Types'),
            serviceFunctionScope: 'trucktypes'
          }
        }, {
          type: 'dropdown', field: 'carrier',
          label: this.translateService.instant('Carrier'),
          dropdownConfig: {
            ...baseDropdownConfig,
            selectText: this.translateService.instant('Select Carrier'),
            loadingText: this.translateService.instant('Loading Carrier...'),
            noResultsText: this.translateService.instant('No Carriers'),
            serviceFunctionScope: 'organization'
          }
        }, {
          type: 'dropdown', field: 'startLocation',
          label: this.translateService.instant('Start Location'),
          dropdownConfig: {
            ...baseDropdownConfig,
            service: LocationService,
            serviceFunctionScope: null,
            serviceFunction: 'list',
            searchKey: 'search', query: {},
            selectText: this.translateService.instant('Select Location'),
            loadingText: this.translateService.instant('Loading Location...'),
            noResultsText: this.translateService.instant('No Locations')
          }
        }, {
          type: 'dropdown', field: 'endLocation',
          label: this.translateService.instant('End Location'),
          dropdownConfig: {
            ...baseDropdownConfig,
            service: LocationService,
            serviceFunctionScope: null,
            serviceFunction: 'list',
            searchKey: 'search', query: {},
            selectText: this.translateService.instant('Select Location'),
            loadingText: this.translateService.instant('Loading Location...'),
            noResultsText: this.translateService.instant('No Locations')
          }
        }, {
          type: 'dropdown', field: 'tags',
          label: this.translateService.instant('Markets'),
          dropdownConfig: {
            ...baseDropdownConfig,
            multiselect: true,
            service: TagService,
            serviceFunctionScope: null,
            serviceFunction: 'list',
            searchKey: 'search', query: {},
            selectText: this.translateService.instant('Select Markets'),
            loadingText: this.translateService.instant('Loading Markets...'),
            noResultsText: this.translateService.instant('No Markets')
          }
        }
      ];
      dialog.componentInstance.callback = res => {
        this.savePreferences(res);
        this.filterChanges(res);
      };
      dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
        acc[filter.key] = filter.value;
        return acc;
      }, {});

      this.filtersDialog = dialog.componentInstance;
    }
  }

  filterChanges(filterRes) {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      job: 'jobevent__job',
      orderNumber: 'jobevent__job__order_number',
      customer: 'jobevent__customer_organization',
      project: 'jobevent__job__project',
      truckType: 'truck__truck_type',
      jobEvent: 'jobevent',
      carrier: 'carrier__organization',
      startLocation: 'jobevent__job__start_location',
      endLocation: 'jobevent__job__end_location',
      tags: 'job_markets',
    };
    let falseyFilters = [];
    if (filterRes) {
      this.filters = Object.keys(filterRes).map((key) => {
        const query = {};
        let value = filterRes[key];
        let displayValue;
        if (typeof (value) === 'boolean') {
          if (value) {
            value = value.toString();
            value = value.charAt(0).toUpperCase() + value.slice(1);
            query[queryKeys[key]] = value;
          }
        } else if (key === 'tags') {
          if (value && value.length > 0) {
            value = value.map(tag => { return tag.name; }).join(',');
            query[queryKeys[key]] = value;
          }
        } else {
          query[queryKeys[key]] = filterRes[key] && filterRes[key].id || filterRes[key];
        }
        let displayKey = key;
        let hidden = false;
        if (key === 'defaultStatus') { hidden = true; }
        let filter = {
          key: displayKey,
          value: displayValue || value,
          query: query,
          hidden: hidden
        };
        if (filter.value === 'False' || !filter.value) { falseyFilters.push(filter); }
        return filter;
      });
      this.filters = difference(this.filters, falseyFilters);
      this.getDriverAssignments();
    }
  }

  removeFilter(filter) {
    if (this.preference && this.preference.blob && this.preference.blob.filters && this.preference.blob.filters[filter.key]) {
      delete this.preference.blob.filters[filter.key];
      this.savePreferences(this.preference.blob.filters);
    }
    remove(this.filters, filter);
    this.getDriverAssignments();
  }

  selector(event: MatCheckboxChange, assignment = null) {
    if (assignment) {
      if (event.checked) {
        if (this.allSelected) {
          pull(this.excludeAssignments, assignment.id);
          this.selectedCount = (this.count - this.excludeAssignments.length);
        } else {
          this.selectedAssignments.push(assignment.id);
          this.selectedCount = this.selectedAssignments.length;
        }
      } else {
        pull(this.selectedAssignments, assignment.id);
        if (this.allSelected) {
          this.excludeAssignments.push(assignment.id);
          this.selectedCount = (this.count - this.excludeAssignments.length);
        } else {
          this.selectedCount = this.selectedAssignments.length;
        }
      }
      assignment.selected = event.checked;
    } else {
      if (!event.checked) {
        this.allSelected = false;
        this.selectedAssignments = [];
        this.excludeAssignments = [];
        let values = [];
        if (this.rows) {
          values = this.rows;
        }
        values.forEach((_assignment) => {
          if (_assignment.hasOwnProperty('selected')) {
            _assignment['selected'] = false;
          }
        });
        this.selectedCount = 0;
      } else {
        this.allSelected = true;
        let values = [];
        if (this.rows) {
          values = this.rows;
        }
        values.forEach((_assignment) => {
          if (_assignment.hasOwnProperty('selected')) {
            _assignment['selected'] = true;
          }
        });
        this.selectedCount = (this.count - this.excludeAssignments.length);
      }
      this.selectedAssignments = [];
      this.excludeAssignments = [];
    }
  }

  setSelectedAction(option) {
    switch (option.name) {
      case 'Export':
        this.createExport(this.selectedAssignments, this.excludeAssignments);
        break;
      case 'Operator\'s Export':
        this.createOperatorsExport(this.selectedAssignments, this.excludeAssignments);
        break;
    }
  }

  createExport(selectedAssignments = null, excludeAssignments = null) {
    let scope = {
      assignments: selectedAssignments,
      exclude_assignments: excludeAssignments,
    };

    let filters = this.filters.reduce((acc, filter) => {
      return { ...acc, ...filter.query };
    }, {});
    delete filters['undefined'];

    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    if (this.assignmentDate) {
      startDate = new Date(this.assignmentDate);
      startDate.setHours(0, 0, 0, 0);
      endDate = clone(startDate);
      endDate.setHours(23, 59, 59, 999);
    }

    filters = {
      search: this.search,
      'jobevent__shift1_start__gte': startDate && startDate.toISOString(),
      'jobevent__shift1_start__lte': endDate && endDate.toISOString(),
      'exclude_assignments': excludeAssignments,
      'assignments': selectedAssignments,
      ...filters
    };

    this.assignmentService.export(scope, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments'
        }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          scope,
          params,
          service: this.assignmentService,
          buttonText: this.translateService.instant('Try to Export Again')
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  createOperatorsExport(selectedAssignments = null, excludeAssignments = null) {
    let scope = {
      assignments: selectedAssignments,
      exclude_assignments: excludeAssignments,
      endpoint: 'operators'
    };

    let filters = this.filters.reduce((acc, filter) => {
      return { ...acc, ...filter.query };
    }, {});
    delete filters['undefined'];

    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    if (this.assignmentDate) {
      startDate = new Date(this.assignmentDate);
      startDate.setHours(0, 0, 0, 0);
      endDate = clone(startDate);
      endDate.setHours(23, 59, 59, 999);
    }

    filters = {
      'active_range': `${startDate && startDate.toISOString()},${endDate && endDate.toISOString()}`,
      'exclude_assignments': excludeAssignments,
      'assignments': selectedAssignments,
      'ordering': 'jobevent__job__start__location__name,jobevent__job__order_number',
      ...filters
    };

    this.assignmentService.export(scope, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          endpoint: 'operators'
        }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          scope: scope,
          params: params,
          service: this.assignmentService,
          buttonText: this.translateService.instant('Try to Export Again')
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  setDefaultDate() {
    let d: Date = new Date();
    if (this.date) { d = this.date.toDate(); }
    this.assignmentDate = d;
    this.assignmentISODate = d;
    this.assignmentISODate.setHours(0, 0, 0, 0);
  }

  onDateChanged(dates: Date[]) {
    if (dates && dates.length) {
      this.assignmentDate = dates[0];
      this.assignmentISODate = dates[0];
      let date = moment(dates[0]).format('YYYYMMDD');
      this.queryParams = Object.assign({}, this.queryParams, { date, page: 1 });
      this.expandedAssignments = [];
      this.selectedAssignments = [];
      this.excludeAssignments = [];
      this.allSelected = false;
      this.page = 1;
      let values = [];
      if (this.rows) {
        values = this.rows;
      }
      values.forEach((_assignment) => {
        if (_assignment.hasOwnProperty('selected')) {
          _assignment['selected'] = false;
        }
      });
      this.assignmentDateChanged.next(dates[0]);
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: this.queryParams
      });
    }
  }

  showInvoiceTotalChange() {
    if (this.showInvoiceTotal) {
      this.displayedColumns.splice(this.displayedColumns.length - 1, 0, 'invoice-total', 'expense-total');
    } else {
      this.displayedColumns = this.displayedColumns.filter(col => col !== 'invoice-total' && col !== 'expense-total');
    }
    this.queryParams = Object.assign(
      clone(this.queryParams),
      { 'showInvoiceTotal': this.showInvoiceTotal ? 'true' : 'false', }
    );
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: this.queryParams
    }).toString();
    this.location.go(url);
    this.getDriverAssignments();
  }

  expandAssignment(assignment) {
    if (_find(this.expandedAssignments, { id: assignment.id })) {
      remove(this.expandedAssignments, { id: assignment.id });
    } else {
      this.expandedAssignments.push(assignment);
    }
  }

  expandedAssignment(assignment): boolean {
    if (_find(this.expandedAssignments, { id: assignment.id })) {
      return true;
    }
    return false;
  }

  openNewAssignment(assignment = null) {
    let driver, truck;
    if (assignment) {
      driver = new DriverSerializer().fromJson({ id: assignment.id, customName: assignment.name });
      truck = assignment.latestTruck;
    }

    const dialog = this.dialog.open(QuickDispatchDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.selectedDates = [new Date(this.assignmentDate)];
    dialog.componentInstance.selectedDriver = driver;
    dialog.componentInstance.selectedTruck = truck;
    dialog.componentInstance.callback = () => this.getDriverAssignments();
    this.dispatchDialog = dialog.componentInstance;
  }

  onCreateNewAssignment(driver) {
    const dialog = this.dialog.open(QuickDispatchDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.selectedDates = [new Date(this.assignmentDate)];
    dialog.componentInstance.selectedDriver = driver;
    // dialog.componentInstance.selectedTruck = truck;
    dialog.componentInstance.callback = () => this.getDriverAssignments();
    this.dispatchDialog = dialog.componentInstance;
  }

  expandSearch() {
    this.loading = true;
    setTimeout(() => {
      this.filters = [];
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  totalTripTimeDisplay(trips, jobName): string {
    let totalTime = 0;

    trips.forEach(trip => {
      if (trip.job && trip.job.name === jobName) {
        totalTime += trip.completedTripDuration;
      }
    });
    return moment.duration(totalTime, 'milliseconds').format('D[ days], H[ hrs], m[ mins] total');
  }

  totalWeightDisplay(trips, jobName?): string {
    if (!trips || !trips.length) { return ''; }
    let totalWeight = 0;
    let weightUnit = trips[0].weightUnit;

    trips.forEach(trip => {
      if (!jobName || (trip.job && trip.job.name === jobName)) {
        totalWeight += parseFloat(trip.weight);
      }
    });

    let plural = true;
    if (totalWeight && totalWeight === 1.0) { plural = false; }
    switch (weightUnit) {
      case 'cuyds':
        weightUnit = plural ? 'cu. yds.' : 'cu. yd.';
        break;
      default:
        weightUnit = plural ? weightUnit + 's' : weightUnit;
        break;
    }

    return formatNumber(totalWeight, 'en-US', '1.1-4') + ' ' + weightUnit;
  }

  pageChange(event) {
    this.page = event;
    this.queryParams = Object.assign(
      clone(this.queryParams),
      { page: this.page > 1 ? this.page : null, view: this.view }
    );
    const url = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: this.queryParams
    }).toString();
    this.location.go(url);
    this.getDriverAssignments({
      ordering: (this.sortAsc ? '' : '-') + this.sortBy,
      [this.sortParameter]: this.sortParameter ? ((this.sortAsc ? '' : '-') + this.sortBy) : null,
      page: this.page
    });
  }

  multiselectDisabled(): boolean {
    return !this.rows || !this.count || this.count === 0 ||
      (!this.allSelected && !this.selectedAssignments.length) ||
      this.count === this.excludeAssignments.length;
  }

  showDriverAssignments(driverData) {
    this.driverAssignmentsList.setOpen(driverData);
  }

  focusDriver(driver): void {
    if (driver && this.driverMap) {
      let drivers = this.rows;
      drivers.forEach((_driver: any) => {
        if (_driver.hasOwnProperty('highlighted') && _driver.id !== driver.id) {
          _driver['highlighted'] = false;
        } else if (_driver.hasOwnProperty('highlighted') && _driver.id === driver.id) {
          _driver['highlighted'] = true;
        }
      });
      this.driverMap.focusDriver(driver.id);
    }
  }

  updatedMarkets(): void {
    this.getDriverAssignments();
  }

  /**
   * @param  {} driver
   * @returns void
   * This function is triggered when driver icon is clicked on the map,
   * the driver list will be scrolled to the clicked driver.
   */
  scrollToSelectedDriver(driver: Driver): void {
    let drivers = this.rows;
    if (_find(drivers, { id: driver.id })) {
      this.driverList.nativeElement.scrollBy(0, this.driverList.nativeElement.scrollHeight);
    }
  }

  // driver timeline methods
  setupTimelineData(driverAssignments: DriverAssignment[]) {
    let startTimes = [], endTimes = [];
    this.timelineData = [];
    driverAssignments.forEach(driverAssignment => {
      let dataObj: TimelineRowData = {
        referenceId: driverAssignment.id,
        trips: [],
        predictedTrips: [],
        pavers: []
      };
      driverAssignment.activeTrips.forEach(trip => {
        if (trip.startTimeTimestamp) { startTimes.push(trip.startTimeTimestamp); }
        if (trip.endTimeTimestamp) { endTimes.push(trip.endTimeTimestamp); }
        dataObj.trips.push(trip);
      });
      driverAssignment.trips.forEach(trip => {
        if (trip.endTimeTimestamp && Date.parse(trip.endTimeTimestamp) < Date.parse(trip.startTimeTimestamp)) {
          if (trip.startTimeTimestamp) { startTimes.push(trip.startTimeTimestamp); }
          if (trip.startTimeTimestamp) { endTimes.push(trip.startTimeTimestamp); }
          trip.startTimeTimestamp = trip.startTimeTimestamp;
          trip.endTimeTimestamp = trip.startTimeTimestamp;
        } else {
          if (trip.startTimeTimestamp) { startTimes.push(trip.startTimeTimestamp); }
          if (trip.endTimeTimestamp) { endTimes.push(trip.endTimeTimestamp); }
        }
        if (trip.connexPaverStartUnloading) {
          dataObj.pavers.push({
            startDatetime: trip.connexPaverStartUnloading,
            endDatetime: trip.connexPaverEndUnloading
          });
        }
        dataObj.trips.push(trip);
      });
      driverAssignment.activeGeoTrips.forEach(geoTrip => {
        if (geoTrip.startTimeTimestamp) { startTimes.push(geoTrip.startTimeTimestamp); }
        if (geoTrip.endTimeTimestamp) { endTimes.push(geoTrip.endTimeTimestamp); }
        dataObj.predictedTrips.push(geoTrip);
      });
      driverAssignment.geoTrips.forEach(geoTrip => {
        if (geoTrip.startTimeTimestamp) { startTimes.push(geoTrip.startTimeTimestamp); }
        if (geoTrip.endTimeTimestamp) { endTimes.push(geoTrip.endTimeTimestamp); }
        dataObj.predictedTrips.push(geoTrip);
      });
      this.timelineData.push(dataObj);
    });
    this.timelineRange = this.setTimelineRange(startTimes, endTimes);
  }

  setTimelineRange(startTimes: string[], endTimes: string[]): DateTimeRange {
    const startMoments = startTimes.map(time => (moment(time)));
    const endMoments = endTimes.map(time => (moment(time)));
    endMoments.push(moment(this.date).endOf('day').subtract(2, 'hours'));
    return {
      startDatetime: moment.min(startMoments).startOf('hour').toISOString(),
      endDatetime: moment.max(endMoments).endOf('hour').toISOString()
    };
  }

  getPreferences(): void {
    if (this.preferencesReq && typeof this.preferencesReq.unsubscribe === 'function') {
      try { this.preferencesReq.unsubscribe(); } catch (e) { }
    }
    let currentUser = this.authenticationService.user();
    if (this.preference && this.preference.id) {
      this.preferencesReq = this.preferenceService.get(this.preference.id)
                                                  .subscribe(preference => {
        this.preference = preference;
        this.switchView(this.preference.blob.view, this.preference.blob.type);
        this.filterChanges(this.preference.blob.filters);
      });
    } else {
      this.preferenceService.list({
        name: 'AllDriverAssignmentsComponent-LastView',
        type: 'user',
        profile: currentUser.id,
        page_size: 1
      }).subscribe(preferences => {
        if (preferences && preferences.length) {
          this.preference = preferences[0];
          this.switchView(this.preference.blob.view, this.preference.blob.type);
          this.filterChanges(this.preference.blob.filters);
        } else {
          this.switchView(this.view, this.type);
        }
      });
    }
  }

  savePreferences(filters?) {
    let currentUser = this.authenticationService.user();
    this.preference = {
      ...this.preference,
      name: 'AllDriverAssignmentsComponent-LastView',
      type: 'user',
      profile: currentUser.id,
      blob: { view: this.view, type: this.type, filters: filters }
    };
    this.preferenceService.save(this.preference)
                          .subscribe(preference => this.preference = preference);
  }

  /**
   * Opens assignments drawer on page load if driver_assignments
   * query param is set with driverId
   *
   * @param driverAssignment which driver assignment to open
   */
  openDriverAssignmentOnPageLoad(driverAssignment: DriverAssignment) {
    this.showDriverAssignments(driverAssignment);
    this.router.navigate([], {
      queryParams: {
        'driver_assignments': null
      },
      queryParamsHandling: 'merge'
    });
    this.driverIdToOpenAssignments = null;
  }

  openContextMenu(event: any, driverAssignment: DriverAssignment) {
    this.contextMenuEventSubject.next({
      event,
      driverId: driverAssignment.id,
      name: driverAssignment.name
    });
  }

  onContextMenuOpenAssignment(selectionEvent: MenuSelectionEvent) {
    const driverAssignment = this.assignments.find(a => a.id === selectionEvent.driverId);
    if (driverAssignment) {
      this.showDriverAssignments(driverAssignment);
    }
  }
}
