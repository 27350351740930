"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var mock_project_1 = require("../projects/mock-project");
var checkin_option_1 = require("../checkins/checkin-option");
exports.JOB = {
    id: '00000000-0000-0000-0000-000000000001',
    name: 'Sample Job',
    nameWithDate: '12/11/2017 - Sample Job',
    displayName: 'Sample Job',
    group: '',
    poNumber: '1234',
    jobNumber: '',
    orderNumber: '',
    material: 'Concrete',
    materialCode: 'CRT',
    phaseCode: '',
    invoiceType: 'weight',
    invoiceWeightUnit: 'ton',
    invoiceRateUnit: 'ton',
    rate: '100',
    haulType: 'weight',
    haulWeightUnit: 'ton',
    haulRateUnit: 'ton',
    haulRate: '100',
    amountNeeded: '1',
    startDate: '2017-12-11T11:00:00',
    endDate: '2017-12-11T16:00:00',
    shift1StartTime: '',
    shift1EndTime: '',
    numTrucks: '1',
    dailyDeliveryTarget: '1',
    dailyDeliveryTargetType: '',
    totalAmountType: '',
    deliveryInterval: 120.00,
    deliveryIntervalUnit: 'minutes',
    notes: 'Sample Notes',
    project: mock_project_1.PROJECT,
    shift1Start: null,
    shift1End: null,
    shift2Start: null,
    shift2End: null,
    shift2StartTime: null,
    shift2EndTime: null,
    shift1Overnight: false,
    shift2Overnight: false,
    endTime: null,
    checkinOptions: new checkin_option_1.CheckinOption({ type: 'checkin' }),
    checkoutOptions: new checkin_option_1.CheckinOption({ type: 'checkout' }),
    startLocation: null,
    endLocation: null,
    distance: null,
    estimatedRouteTime: null,
    routePolyline: null,
    truckTypes: [],
    tags: [],
    allowAnyTruckType: true,
    stats: null,
    totalAmount: null,
    save: null,
    truckTypeNames: 'Tri-Axle',
    startsAt: null,
    endsAt: null,
    deliveryIntervalMinutes: 120,
    deliveryIntervalHours: 1.25,
    notice: null,
    startCoordinates: null,
    endCoordinates: null,
    shifts: 1,
    distanceInMiles: '10',
    distanceInKilometers: '16',
    mapImageURL: null,
    mapImageURLWide: null,
    startLocationDescription: null,
    endLocationDescription: null,
    dateRange: null,
    checkinOptionDescriptions: null,
    checkoutOptionDescriptions: null,
    customFieldData: {},
    archived: false,
    ownerOrganization: null,
    customerOrganization: null,
    url: function (action) {
        return '';
    },
    filterOptions: function (options) {
        return options;
    },
    allowWeight: true,
    invoiceablePunchcardCount: '0',
    invoiceableTripCount: '0',
    canDelete: false,
    canEdit: false,
    measurementUnit: function () {
        return null;
    },
    qrJobType: '',
    startLocationMappable: true,
    endLocationMappable: true,
    workOrderTemplate: null,
    legaleseUrl: null,
    secondShift: false,
    dates: [],
    loading: false,
    selected: false,
    classes: '',
    defaultRoundTripTime: null,
    defaultYardPrepTime: null,
    defaultYardBufferTime: null,
    defaultYardBufferMinutes: null,
    dsorderId: null,
    slorderId: null,
    multipleItemsData: {
        isMultipleMaterials: false,
        materialsList: [],
        quantitiesList: [],
        uomList: [],
    },
};
exports.JOB_LOCATIONS = {
    id: '00000000-0000-0000-0000-000000000001',
    name: 'Sample Job',
    nameWithDate: '12/11/2017 - Sample Job',
    displayName: 'Sample Job',
    group: '',
    poNumber: '1234',
    jobNumber: '',
    orderNumber: '',
    material: 'Concrete',
    materialCode: 'CRT',
    phaseCode: '',
    invoiceType: 'weight',
    invoiceWeightUnit: 'ton',
    invoiceRateUnit: 'ton',
    rate: '100',
    haulType: 'weight',
    haulWeightUnit: 'ton',
    haulRateUnit: 'ton',
    haulRate: '100',
    amountNeeded: '1',
    startDate: '2017-12-11T11:00:00',
    endDate: '2017-12-11T16:00:00',
    shift1StartTime: '',
    shift1EndTime: '',
    numTrucks: '1',
    dailyDeliveryTarget: '1',
    dailyDeliveryTargetType: '',
    totalAmountType: '',
    deliveryInterval: 120.00,
    deliveryIntervalUnit: 'minutes',
    notes: 'Sample Notes',
    project: mock_project_1.PROJECT,
    shift1Start: null,
    shift1End: null,
    shift2Start: null,
    shift2End: null,
    shift2StartTime: null,
    shift2EndTime: null,
    shift1Overnight: false,
    shift2Overnight: false,
    endTime: null,
    checkinOptions: new checkin_option_1.CheckinOption({ type: 'checkin' }),
    checkoutOptions: new checkin_option_1.CheckinOption({ type: 'checkout' }),
    startLocation: {
        paths: [
            { lat: 30.93604303849505, lng: -97.69526297436101 },
            { lat: 30.94892576812529, lng: -97.70238692150457 },
            { lat: 30.955955325270487, lng: -97.68328959331853 },
            { lat: 30.939723995581893, lng: -97.6757364927326 },
            { lat: 30.93604303849505, lng: -97.69496256695135 },
            { lat: 30.93604303849505, lng: -97.69526297436101 }
        ]
    },
    endLocation: {
        paths: [
            { lat: 31.053496070515322, lng: -97.43712685538958 },
            { lat: 31.053018124620117, lng: -97.43476651145647 },
            { lat: 31.051455592440956, lng: -97.43531368209551 },
            { lat: 31.05208060839147, lng: -97.43758819534014 },
            { lat: 31.053496070515322, lng: -97.43712685538958 }
        ]
    },
    distance: null,
    estimatedRouteTime: null,
    routePolyline: null,
    truckTypes: [],
    tags: [],
    allowAnyTruckType: true,
    stats: null,
    totalAmount: null,
    save: null,
    truckTypeNames: 'Tri-Axle',
    startsAt: null,
    endsAt: null,
    deliveryIntervalMinutes: 120,
    deliveryIntervalHours: 1.25,
    notice: null,
    startCoordinates: null,
    endCoordinates: null,
    shifts: 1,
    distanceInMiles: '10',
    distanceInKilometers: '16',
    mapImageURL: null,
    mapImageURLWide: null,
    startLocationDescription: null,
    endLocationDescription: null,
    dateRange: null,
    checkinOptionDescriptions: null,
    checkoutOptionDescriptions: null,
    customFieldData: {},
    archived: false,
    ownerOrganization: null,
    customerOrganization: null,
    url: function (action) {
        return '';
    },
    filterOptions: function (options) {
        return options;
    },
    allowWeight: true,
    invoiceablePunchcardCount: '0',
    invoiceableTripCount: '0',
    canDelete: false,
    canEdit: false,
    measurementUnit: function () {
        return null;
    },
    qrJobType: '',
    startLocationMappable: true,
    endLocationMappable: true,
    workOrderTemplate: null,
    legaleseUrl: null,
    secondShift: false,
    dates: [],
    loading: false,
    selected: false,
    classes: '',
    defaultRoundTripTime: null,
    defaultYardPrepTime: null,
    defaultYardBufferTime: null,
    defaultYardBufferMinutes: null,
    dsorderId: null,
    slorderId: null,
    multipleItemsData: {
        isMultipleMaterials: false,
        materialsList: [],
        quantitiesList: [],
        uomList: [],
    },
};
