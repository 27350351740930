import * as moment from 'moment';
import { uniq } from 'lodash';

import { Job } from './job';
import { ProjectSerializer } from '../projects/project.serializer';
import { LocationSerializer } from '../locations/location.serializer';
import { TruckSerializer } from '../trucks/truck.serializer';
import { TagSerializer } from '../tags/tag.serializer';
import { WorkOrderTemplateSerializer } from '../work-orders/work-order-templates/work-order-template.serializer';
import { CheckinOption } from '../checkins/checkin-option';
import { Organization } from '../organizations/organization';
import { CustomerOrganization } from '../scaleit/models/scaleit.orders';

const camelcaseKeysDeep = require('camelcase-keys-deep');
const decamelizeKeysDeep = require('decamelize-keys-deep');

export class JobSerializer {
  /**
  * @param  {any} json
  * @returns Job
  */
  fromJson(json: any): Job {
    json = camelcaseKeysDeep(json);
    const job = new Job();
    if (!json) { return job; }

    job.id = json.id;
    job.name = json.name;
    job.displayName = json.displayName;
    job.nameWithDate = moment(json.startDate).format('MM/DD/YYYY') + ' - ' + json.name;
    job.group = json.group || json.project && json.project.name;
    job.poNumber = json.poNumber;
    job.material = json.material;
    job.materialCode = json.materialCode;
    job.phaseCode = json.phaseCode;
    job.dailyDeliveryTarget = json.dailyDeliveryTarget;
    job.dailyDeliveryTargetType = json.dailyDeliveryTargetType;
    job.totalAmountType = json.totalAmountType;
    job.rate = json.rate;
    job.haulRate = json.haulRate;
    job.haulType = json.haulType;
    job.startDate = json.startDate;
    job.endDate = json.endDate;
    job.allowAnyTruckType = json.allowAnyTruckType;
    job.deliveryInterval = json.deliveryInterval;
    job.deliveryIntervalUnit = json.deliveryIntervalUnit;
    job.amountNeeded = json.amountNeeded;
    if (json.checkinOptions && typeof (json.checkinOptions) === 'object') {
      job.checkinOptions = new CheckinOption({...json.checkinOptions, type: 'checkin'});
    } else {
      job.checkinOptions = new CheckinOption({type: 'checkin'});
    }
    if (json.checkoutOptions && typeof (json.checkoutOptions) === 'object') {
      job.checkoutOptions = new CheckinOption(json.checkoutOptions);
    } else {
      job.checkoutOptions = new CheckinOption({});
    }
    job.haulWeightUnit = json.haulWeightUnit;
    job.invoiceType = json.invoiceType;
    job.invoiceWeightUnit = json.invoiceWeightUnit;
    job.numTrucks = json.numTrucks;
    job.shift1StartTime = json.shift1StartTime;
    job.shift1EndTime = json.shift1EndTime;
    job.shift2StartTime = json.shift2StartTime;
    job.shift2EndTime = json.shift2EndTime;
    if (job.shift1StartTime && job.shift1EndTime) {
      const startTime = moment(job.shift1StartTime, 'h:mm a');
      const endTime = moment(job.shift1EndTime, 'h:mm a');
      job.shift1Overnight = endTime.isBefore(startTime);
    } else if (job.shift2StartTime && job.shift2EndTime) {
      const startTime = moment(job.shift2StartTime, 'h:mm a');
      const endTime = moment(job.shift2EndTime, 'h:mm a');
      job.shift2Overnight = endTime.isBefore(startTime);
    }
    job.distance = json.distance;
    job.estimatedRouteTime = json.estimatedRouteTime;
    job.routePolyline = json.routePolyline;
    job.notes = json.notes;
    job.invoiceableTripCount = json.invoiceableTripCount;
    job.invoiceablePunchcardCount = json.invoiceablePunchcardCount;
    job.archived = json.archived;
    job.jobNumber = json.jobNumber;
    job.orderNumber = json.orderNumber;
    job.canDelete = json.canDelete;
    job.legaleseUrl = json.legaleseUrl;
    job.qrJobType = json.qrJobType;
    job.canEdit = json.canEdit;
    job.customFieldData = json.customFieldData || {};
    job.dsorderId = json.dsorderId;
    job.slorderId = json.slorderId;
    job.classes = `${json.classes} ${json.archived ? 'archived' : ''}`;

    if (json.project && typeof (json.project) === 'object') {
      job.project = new ProjectSerializer().fromJson(json.project);
    } else if (json.project) {
      job.project = new ProjectSerializer().fromJson({
        id: json.project,
        customerOrganization: new Organization({ id: '', name: '' })
      });
    } else {
      job.project = new ProjectSerializer().fromJson({ id: '', name: '' });
    }
    if (json.customerOrganization && typeof (json.customerOrganization) === 'object') {
      job.customerOrganization = new Organization(json.customerOrganization);
    } else if (json.customerOrganization) {
      job.customerOrganization = new Organization({
        id: json.customerOrganization, name: json.customerOrganization
      });
    } else {
      job.customerOrganization = new Organization({ id: '', name: '' });
    }
    if (json.ownerOrganization && typeof (json.ownerOrganization) === 'object') {
      job.ownerOrganization = new Organization(json.ownerOrganization);
    } else if (json.ownerOrganization) {
      job.ownerOrganization = new Organization({
        id: json.ownerOrganization, name: json.ownerOrganization
      });
    } else {
      job.ownerOrganization = new Organization({ id: '', name: '' });
    }
    if (json.startLocation && typeof (json.startLocation) === 'object') {
      job.startLocation = new LocationSerializer().fromJson(json.startLocation);
    } else if (json.startLocation) {
      job.startLocation = new LocationSerializer().fromJson({ id: json.startLocation });
    }
    if (json.endLocation && typeof (json.endLocation) === 'object') {
      job.endLocation = new LocationSerializer().fromJson(json.endLocation);
    } else if (json.endLocation) {
      job.endLocation = new LocationSerializer().fromJson({ id: json.endLocation });
    }
    if (json.truckTypes && json.truckTypes.length) {
      job.truckTypes = json.truckTypes.map(truckType => {
        if (truckType && typeof truckType === 'object') {
          return new TruckSerializer().fromJson(truckType);
        } else if (json.truckTypes) {
          return new TruckSerializer().fromJson({ id: truckType });
        }
      });
    } else {
      job.truckTypes = [];
    }
    if (json.tags && json.tags.length) {
      job.tags = json.tags.map(tag => {
        if (tag && typeof tag === 'object') {
          return new TagSerializer().fromJson(tag);
        } else if (json.tags) {
          return new TagSerializer().fromJson({ id: tag });
        }
      });
    } else {
      job.tags = [];
    }
    if (job.distance) {
      job.distanceInMiles = ((parseFloat(job.distance) / 1000) * 0.62137119).toString();
      job.distanceInKilometers = (parseFloat(job.distance) / 1000).toString();
    } else {
      job.distanceInMiles = '';
      job.distanceInKilometers = '';
    }

    if (job.invoiceType === 'weight') {
      job.invoiceRateUnit = job.invoiceWeightUnit === 'cuyds' ? 'cu. yd.' : job.invoiceWeightUnit;
    } else {
      job.invoiceRateUnit = job.invoiceType;
    }
    if (job.haulType === 'weight') {
      job.haulRateUnit = job.haulWeightUnit === 'cuyds' ? 'cu. yd.' : job.haulWeightUnit;
    } else {
      job.haulRateUnit = job.haulType;
    }
    if (json.workOrderTemplate && typeof (json.workOrderTemplate) === 'object') {
      job.workOrderTemplate = new WorkOrderTemplateSerializer().fromJson(json.workOrderTemplate);
    } else if (json.workOrderTemplate) {
      job.workOrderTemplate = new WorkOrderTemplateSerializer().fromJson({ id: json.workOrderTemplate });
    }

    if (job.startLocation) {
      job.startLocationDescription = `${job.startLocation.name}<br />`;
      job.startLocationDescription += `${job.startLocation.street}<br />`;
      job.startLocationDescription += `${job.startLocation.city}, ${job.startLocation.state} ${job.startLocation.zipcode}<br />`;
    }
    if (job.endLocation) {
      job.endLocationDescription = `${job.endLocation.name}<br />`;
      job.endLocationDescription += `${job.endLocation.street}<br />`;
      job.endLocationDescription += `${job.endLocation.city}, ${job.endLocation.state} ${job.endLocation.zipcode}<br />`;
    }

    job.notice = this.captureNotice(job);
    job.startCoordinates = this.captureCoordinates(job.startLocation);
    job.endCoordinates = this.captureCoordinates(job.endCoordinates);
    job.dateRange = this.captureDateRange(job.startDate, job.endDate);
    job.checkinOptionDescriptions = this.captureOptionDescriptions(job.checkinOptions);
    job.checkoutOptionDescriptions = this.captureOptionDescriptions(job.checkoutOptions);

    if (job.startDate && job.shift1StartTime) {
      job.shift1Start = moment(`${job.startDate} ${job.shift1StartTime}`).format('h:mm a');
    }
    if (job.endDate && job.shift1EndTime) {
      job.shift1End = moment(`${job.endDate} ${job.shift1EndTime}`).format('h:mm a');
    }
    if (job.endDate && job.shift2EndTime) {
      job.shift2End = moment(`${job.endDate} ${job.shift2EndTime}`).format('h:mm a');
    }
    if (job.startDate && job.shift2StartTime) {
      job.shift2Start = moment(`${job.startDate} ${job.shift2StartTime}`).format('h:mm a');
    }
    if (job.allowAnyTruckType) {
      job.truckTypeNames = 'Any Type';
    } else if (job.truckTypes && job.truckTypes.length) {
      const names = job.truckTypes.map(truckType => truckType.name);
      job.truckTypeNames = names.filter(Boolean).join(', ');
    }
    job.endTime = job.shift2EndTime ? job.shift2End : job.shift1End;
    job.startsAt = `${job.startDate} ${job.shift1StartTime}`;
    job.endsAt = `${job.endDate} ${job.shift1EndTime}`;
    job.shifts = job.shift2EndTime ? 2 : 1;
    if (job.routePolyline) {
      // tslint:disable-next-line:max-line-length
      job.mapImageURL = `https://maps.googleapis.com/maps/api/staticmap?size=418x178&path=color:0x002649|weight:4|enc:${job.routePolyline}&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ`;
      job.mapImageURLWide = `https://maps.googleapis.com/maps/api/staticmap?size=1024x239&path=color:0x002649|weight:4|enc:${job.routePolyline}&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ`;
    }
    job.startLocationMappable = job.startLocation && job.startLocation.location ? true : false;
    job.endLocationMappable = job.endLocation && job.endLocation.location ? true : false;
    job.allowWeight = job.invoiceType === 'weight' || job.haulType === 'weight';
    job.deliveryIntervalMinutes = job.deliveryInterval ? Math.round(Number(job.deliveryInterval)) : 0;
    job.deliveryIntervalHours = job.deliveryInterval ? Number((job.deliveryInterval / 60).toFixed(2)) : 0;
    if (job.shift2StartTime) { job.secondShift = true; }
    if (job.dates) { job.dates = json.dates; }

    job.defaultRoundTripTime = json.defaultRoundTripTime;
    job.defaultYardPrepTime = json.defaultYardPrepTime;
    job.defaultYardBufferTime = json.defaultYardBufferTime;
    if (json.defaultYardBufferTime && parseFloat(json.defaultYardBufferTime) > 0) {
      job.defaultYardBufferMinutes = Math.round(parseFloat(json.defaultYardBufferTime) / 60);
    }
    job.multipleItemsData = json.multipleItemsData;

    return job;
  }

  /**
  * @param  {Job} job
  * @returns any
  */
  toJson(job: Job): any {
    const deliveryIntervalUnit = 'minutes';
    let deliveryInterval = job.deliveryInterval;
    if (job.deliveryInterval && job.deliveryIntervalUnit === 'hours') {
      deliveryInterval = job.deliveryInterval * 60;
    }
    const tags = job.tags && job.tags.map((tag) => {
      if (typeof tag === 'object') { tag = tag.name; }
      return tag;
    }).filter(Boolean);

    let dates = [];
    if (job.dates && job.dates.length) {
      dates = job.dates.map(d => {
        if (d && typeof d.toISOString === 'function') {
          return d && moment(d).format('YYYY-MM-DD');
        } else {
          return d;
        }
      });
    }

    let json = {
      id: job.id,
      name: job.name,
      project: job.project && job.project.id,
      poNumber: job.poNumber,
      material: job.material,
      materialCode: job.materialCode,
      phaseCode: job.phaseCode,
      dailyDeliveryTarget: job.dailyDeliveryTarget,
      dailyDeliveryTargetType: job.dailyDeliveryTargetType,
      totalAmountType: job.totalAmountType,
      rate: job.rate,
      haulRate: job.haulRate,
      haulType: job.haulType,
      startDate: job.startDate,
      endDate: job.endDate,
      startLocation: job.startLocation && job.startLocation.id,
      endLocation: job.endLocation && job.endLocation.id,
      truckTypes: job.truckTypes && job.truckTypes.map(truckType => truckType.id),
      allowAnyTruckType: job.allowAnyTruckType,
      deliveryInterval: deliveryInterval,
      deliveryIntervalUnit: deliveryIntervalUnit,
      amountNeeded: job.amountNeeded && job.amountNeeded.replace(/[^\d.]/g, ''),
      checkinOptions: job.checkinOptions,
      checkoutOptions: job.checkoutOptions,
      haulWeightUnit: job.haulWeightUnit,
      invoiceType: job.invoiceType,
      invoiceWeightUnit: job.invoiceWeightUnit,
      numTrucks: job.numTrucks ? job.numTrucks : null,
      shift1StartTime: job.shift1StartTime,
      shift1EndTime: job.shift1EndTime,
      shift2StartTime: job.shift2StartTime,
      shift2EndTime: job.shift2EndTime,
      distance: job.distance,
      estimatedRouteTime: job.estimatedRouteTime,
      routePolyline: job.routePolyline,
      notes: job.notes,
      tags: tags,
      jobNumber: job.jobNumber,
      orderNumber: job.orderNumber,
      legaleseUrl: job.legaleseUrl,
      qrJobType: job.qrJobType,
      customFieldData: job.customFieldData,
      workOrderTemplate: job.workOrderTemplate && job.workOrderTemplate.id,
      dates: dates,
      defaultRoundTripTime: job.defaultRoundTripTime,
      defaultYardPrepTime: job.defaultYardPrepTime,
      defaultYardBufferTime: (job.defaultYardBufferMinutes * 60) || job.defaultYardBufferTime,
      checkinTicketImageConfig: job.checkinOptions && job.checkinOptions.ticketImageConfig,
      checkinTicketNumberConfig: job.checkinOptions && job.checkinOptions.ticketNumberConfig,
      checkinLoadImageConfig: job.checkinOptions && job.checkinOptions.loadImageConfig,
      checkinSignatureImageConfig: job.checkinOptions && job.checkinOptions.signatureImageConfig,
      checkinWeightConfig: job.checkinOptions && job.checkinOptions.weightConfig,
      checkinActiveTrackingConfig: job.checkinOptions && job.checkinOptions.activeTrackingConfig,
      checkinQrConfig: job.checkinOptions && job.checkinOptions.qrConfig,
      checkoutTicketImageConfig: job.checkoutOptions && job.checkoutOptions.ticketImageConfig,
      checkoutTicketNumberConfig: job.checkoutOptions && job.checkoutOptions.ticketNumberConfig,
      checkoutLoadImageConfig: job.checkoutOptions && job.checkoutOptions.loadImageConfig,
      checkoutSignatureImageConfig: job.checkoutOptions && job.checkoutOptions.signatureImageConfig,
      checkoutWeightConfig: job.checkoutOptions && job.checkoutOptions.weightConfig,
      checkoutActiveTrackingConfig: job.checkoutOptions && job.checkoutOptions.activeTrackingConfig,
      checkoutQrConfig: job.checkoutOptions && job.checkoutOptions.qrConfig,
      dsOrderId: job.dsorderId,
      slOrderId: job.slorderId
    };

    return decamelizeKeysDeep(json);
  }

  captureNotice(job: Job): any {
    let values = [];
    let statusType = 'standard';
    if (job.project && job.project.name) { values.push(job.project.name); }
    let statusText = values.filter(v => v !== '' && v !== null).join(' - ');

    return { statusText: statusText, statusType: statusType };
  }

  captureCoordinates(location: any): any {
    let longitude = '-91.093285';
    let latitude = '30.3178214';

    if (location && typeof location === 'object') {
      longitude = location.longitude;
      latitude = location.latitude;
    } else if (location && location.location) {
      if (location.location.coordinates) {
        longitude = location.location.coordinates[0];
        latitude = location.location.coordinates[1];
      }
    }

    return { longitude: longitude, latitude: latitude };
  }

  captureDateRange(startDate: string, endDate: string): string {
    let range = [];
    if (startDate) { range.push(moment(startDate).format('dddd, MMMM Qo')); }
    if (endDate) { range.push(moment(endDate).format('dddd, MMMM Qo')); }

    return uniq(range.filter(Boolean)).join(' - ');
  }

  captureOptionDescriptions(checkOption): any[] {
    let options = [];
    for (let option in checkOption) {
      if (checkOption[option] === true) { options.push(option); }
    }
    if (options.length === 0) { options.push('None'); }
    return options;
  }
}
