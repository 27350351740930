"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var ngx_device_detector_1 = require("ngx-device-detector");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var rxjs_1 = require("rxjs");
var shared_1 = require("../../shared");
var punch_card_decision_service_1 = require("../../punch-cards/punch-card-decision.service");
var edit_share_dialog_component_1 = require("../../collaborators/edit-share-dialog.component");
var driver_1 = require("../driver");
var new_punch_card_dialog_component_1 = require("../../punch-cards/new-punch-card-dialog.component");
var DriverDailyPunchCardsComponent = /** @class */ (function () {
    function DriverDailyPunchCardsComponent(route, router, authenticationService, deviceDetectorService, punchCardDecisionService, dialog) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.deviceDetectorService = deviceDetectorService;
        this.punchCardDecisionService = punchCardDecisionService;
        this.dialog = dialog;
        this.hideApproved = false;
        this.showVoided = false;
        this.showUnworkedJobs = false;
        this.tripTimes = [];
        this.view = 'trips';
        this.keyStatus = [];
        this.shouldAuditDecisions = new core_1.EventEmitter();
        this.rateChanged = new core_1.EventEmitter();
        this.approvedStatuses = [];
        this.loading = true;
        this.errors = [];
        this.device = {
            info: null,
            mobile: false,
            tablet: false,
            desktop: false
        };
        this.displayedColumns = [
            'total-time', 'schedule', 'loading-ticket-number',
            'unloading-ticket-number', 'amount', 'approved-by', 'approved', 'actions'
        ];
        this.invoiceRates = [];
        this.haulRates = [];
        this.invoiceRatesWeightUnits = [];
        this.haulRatesWeightUnits = [];
        this.allSubscriptionsToUnsubscribe = [];
        this.savePunchCardCallback = function () {
            if (_this.route.snapshot.queryParamMap.get('refreshPage')) {
                _this.router.navigate([], {
                    relativeTo: _this.route,
                    queryParams: {
                        'refreshPage': null,
                    },
                    queryParamsHandling: 'merge'
                });
            }
            else {
                _this.router.navigateByUrl(_this.router.url + "&refreshPage=true");
            }
        };
        this.saveShareCallback = function (result) {
            var jobEventShare = result.jobEventShare;
            var updates = lodash_1.pick(jobEventShare, [
                'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
                'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
            ]);
            updates['rate'] = updates['invoiceRate'];
            var jobIdx = _this.driver.jobEventShares
                .findIndex(function (j) { return j.jobId === jobEventShare.jobId && j.organizationId === jobEventShare.organizationId; });
            if (jobIdx > -1) {
                Object.assign(_this.driver.jobEventShares[jobIdx], updates);
            }
            _this.rateChanged.emit(true);
        };
        this.device = {
            info: this.deviceDetectorService.getDeviceInfo(),
            mobile: this.deviceDetectorService.isMobile(),
            tablet: this.deviceDetectorService.isTablet(),
            desktop: this.deviceDetectorService.isDesktop()
        };
    }
    DriverDailyPunchCardsComponent.prototype.ngOnInit = function () {
        this.organization = this.authenticationService.getOrganization();
        this.user = this.authenticationService.user();
    };
    DriverDailyPunchCardsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    DriverDailyPunchCardsComponent.prototype.switchView = function (view) {
        this.view = view;
    };
    DriverDailyPunchCardsComponent.prototype.updatePunchCardData = function (punchCards) {
        this.driver = Object.assign(this.driver, {
            punchCards: this.driver.punchCards.map(function (p) {
                var matchedIndex = punchCards.findIndex(function (punchCard) { return (punchCard.id === p.id); });
                if (matchedIndex > -1) {
                    return punchCards[matchedIndex];
                }
                else {
                    return p;
                }
            })
        });
    };
    DriverDailyPunchCardsComponent.prototype.approvedPunchCards = function (driver, jobEventShare) {
        var count = 0;
        if (driver && driver.punchCards.length) {
            var punchCardCount = driver.punchCards.map(function (p) {
                return (p.job.id === jobEventShare.jobId &&
                    p.job.project.ownerOrganization === jobEventShare.organizationId &&
                    p.latestDecisionStatus === 'approved');
            }).filter(Boolean).length;
            count = +punchCardCount;
        }
        return count;
    };
    DriverDailyPunchCardsComponent.prototype.unapprove = function (jobEventShare) {
        this.approve(jobEventShare, 'pending');
    };
    DriverDailyPunchCardsComponent.prototype.approve = function (jobEventShare, decisionStatus) {
        var _this = this;
        if (decisionStatus === void 0) { decisionStatus = 'approved'; }
        var requests = [];
        var newStatus = false;
        var status = lodash_1.find(this.keyStatus, { driverId: this.driver.id, key: jobEventShare.jobId });
        if (status) {
            status.loading = true;
        }
        else {
            newStatus = true;
            status = { driverId: this.driver.id, key: jobEventShare.jobId, loading: true, statuses: [] };
        }
        if (this.driver) {
            this.driver.punchCards.forEach(function (punchCard) {
                var alreadyApproved = punchCard.latestDecisionStatus === 'approved' && decisionStatus === 'approved';
                if (punchCard &&
                    punchCard.job.id === jobEventShare.jobId &&
                    punchCard.job.project.ownerOrganization === jobEventShare.organizationId &&
                    !alreadyApproved) {
                    punchCard.loading = true;
                    var data = {
                        punchcard: punchCard,
                        decisionStatus: decisionStatus,
                        decidedBy: _this.user,
                        organization: _this.organization,
                        decidedAt: new Date().toISOString()
                    };
                    requests.push(_this.punchCardDecisionService.save(data));
                }
            });
        }
        this.allSubscriptionsToUnsubscribe.push(rxjs_1.combineLatest(requests).subscribe(function (decisions) {
            decisions.forEach(function (decision) {
                var punchCard = lodash_1.find(_this.driver.punchCards, { id: decision.punchcard && decision.punchcard.id });
                if (punchCard) {
                    punchCard.latestDecision = decision.id;
                    punchCard.latestDecisionStatus = decision.decisionStatus;
                    punchCard.latestDecider = decision.decidedBy && decision.decidedBy.id;
                    punchCard.latestDeciderName = decision.decidedBy && decision.decidedBy.name;
                    status.statuses = [decision.decisionStatus];
                    punchCard.loading = false;
                }
            });
        }, function (errors) {
            console.log('Decision Errors: ', errors);
        }, function () {
            status.loading = false;
            _this.driver.punchCards = _this.driver.punchCards.slice();
            if (newStatus) {
                _this.keyStatus.push(status);
            }
            _this.auditDecisions();
        }));
    };
    /**
     * Determines if a Job (key) is in a loading state as defined by functions
     * creating grouped requests.
     *
     * @param {string} key The Job name to consider when finding the loading state
     * @returns {boolean} Loading state for the provided Job
     */
    DriverDailyPunchCardsComponent.prototype.keyIsLoading = function (key) {
        var status = lodash_1.find(this.keyStatus, { driverId: this.driver.id, key: key });
        if (status && status.loading) {
            return status.loading;
        }
        return false;
    };
    DriverDailyPunchCardsComponent.prototype.auditDecisions = function () {
        this.shouldAuditDecisions.emit(this.driver.id);
    };
    DriverDailyPunchCardsComponent.prototype.openEditShare = function (change, driver, jobEventShare) {
        var dialog = this.dialog.open(edit_share_dialog_component_1.EditShareDialogComponent, {
            width: '430px'
        });
        if (dialog) {
            dialog.componentInstance.change = change;
            dialog.componentInstance.callback = this.saveShareCallback;
            if (driver && jobEventShare) {
                dialog.componentInstance.jobEventShare = jobEventShare;
            }
        }
    };
    DriverDailyPunchCardsComponent.prototype.openAddPunchCard = function (driver, jobEventShare) {
        var dialog = this.dialog.open(new_punch_card_dialog_component_1.NewPunchCardDialogComponent, {
            width: '455px'
        });
        var carrier = {
            id: driver.carrierId,
            name: driver.carrierOrganizationId === this.organization.id ? 'My Drivers' : driver.carrierOrganizationName,
            carrier: {
                name: driver.carrierName,
                id: driver.carrierId
            }
        };
        dialog.componentInstance.jobName = jobEventShare && jobEventShare.job ? jobEventShare.job : '';
        dialog.componentInstance.lockedFields = jobEventShare && jobEventShare.job ? true : false;
        dialog.componentInstance.firstload = jobEventShare && jobEventShare.job ? false : true;
        dialog.componentInstance.model.jobEvent = jobEventShare && jobEventShare.jobeventId ? jobEventShare.jobeventId : '';
        dialog.componentInstance.model.jobevent = jobEventShare && jobEventShare.jobeventId ? jobEventShare.jobeventId : '';
        dialog.componentInstance.model.driver = driver;
        dialog.componentInstance.model.carrier = carrier;
        dialog.componentInstance.model.truck = driver.latestTruck;
        dialog.componentInstance.jobEventDate = this.reportDate;
        // dialog.componentInstance.model.job = this.getDriverJobById(driver, jobEventShare);
        dialog.componentInstance.model.job = {
            id: jobEventShare && jobEventShare.jobId ? jobEventShare.jobId : ''
        };
        dialog.componentInstance.callback = this.savePunchCardCallback;
    };
    DriverDailyPunchCardsComponent.prototype.getDriverJobById = function (driver, jobEventShare) {
        var job = null;
        var trip = driver.trips
            .find(function (t) { return t.jobEvent.job.id === jobEventShare.jobId && t.jobEvent.job.project.ownerOrganization === jobEventShare.organizationId; });
        if (trip && trip.jobEvent) {
            job = trip.jobEvent.job;
        }
        else {
            var punchCard = driver.punchCards
                .find(function (p) { return p.job.id === jobEventShare.jobId && p.job.project.ownerOrganization === jobEventShare.organizationId; });
            if (punchCard && punchCard.jobEvent) {
                job = punchCard.jobEvent.job;
            }
            else {
                job = { id: jobEventShare.jobId };
            }
        }
        return job;
    };
    return DriverDailyPunchCardsComponent;
}());
exports.DriverDailyPunchCardsComponent = DriverDailyPunchCardsComponent;
