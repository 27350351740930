"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var lodash_1 = require("lodash");
var truck_type_service_1 = require("../trucks/truck-type.service");
var authentication_service_1 = require("./../shared/authentication.service");
var connection_service_1 = require("../connections/connection.service");
var price_list_service_1 = require("../price-lists/price-list.service");
var organization_1 = require("../organizations/organization");
var multiplier_1 = require("../multipliers/multiplier");
var shared_1 = require("../shared");
var connection_serializer_1 = require("../connections/connection.serializer");
/**
 * Account Component: This component is used to create and edit an Account
 * {@link Connection}. If the route is `/accounts/new` no `accountId` is set and
 * the form is prepared to add a new Account. If the route is formatted as
 * `/accounts/:accountId/edit` the component requests the account data and
 * populates the form with that information.
 *
 * Note: This component is primarily designed to be a fully routed view.
 *
 * @example
 * <account></account>
 */
var AccountComponent = /** @class */ (function () {
    function AccountComponent(route, router, truckTypeService, connectionService, priceListService, apiService, authenticationService) {
        this.route = route;
        this.router = router;
        this.truckTypeService = truckTypeService;
        this.connectionService = connectionService;
        this.priceListService = priceListService;
        this.apiService = apiService;
        this.authenticationService = authenticationService;
        this.accountType = 'leased-fleet';
        this.account = new connection_serializer_1.ConnectionSerializer().fromJson({ oneRate: true });
        this.posEnabled = false;
        this.loading = false;
        this.states = [];
        this.stateOptions = [];
        this.billingStateOptions = [];
        this.countryOptions = [];
        this.errors = [];
        this.companyTypeOptions = [];
        this.paymentTypeOptions = [
            { id: 'none', name: 'None' },
            { id: 'card', name: 'Card' },
            { id: 'cash', name: 'Cash' },
            { id: 'check', name: 'Check' }
        ];
        this.truckTypeOptions = [];
        this.truckTypesLoading = true;
        this.rateTypeOptions = [{ id: 'percent', name: 'Percentage' }];
        this.priceListOptions = [];
        this.priceListDropdownConfig = {
            searchable: false,
            loadingOptions: false
        };
    }
    /**
    * Examines the route and query parameters to determine the Account Type and
    * persisted search value.
    *
    * If the route includes `leased-fleet` the account is marked as a Leased Org
    * by setting the `account.organization.leasedOrg` value to `true`.
    *
    * When initializaing the `account` variable, the One Default Rate option is
    * defaulted to `true` by seeing `oneRate` to `true`. Additionally, the
    * `multipliers` property is is stubbed as an array with an empty
    * {@link Multiplier} object.
    *
    * If the authenticated user's organization has the Point of Sale (POS) feature
    * enabled, the `posEnabled` variable is set to `true` and the POS section is
    * displayed in the template.
    *
    * When loading this component for a new account, the `getPriceLists()`
    * function is called to set the `priceListOptions` variable. In either case,
    * the `getTruckTypes()` and `getStates()` functions are called to populate
    * dropdown options.
    */
    AccountComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.accountType = this.route && this.route.snapshot &&
            this.route.snapshot.url[0].path === 'accounts' ? 'accounts' : 'leased-fleet';
        this.account = new connection_serializer_1.ConnectionSerializer().fromJson({
            oneRate: true,
            organization: new organization_1.Organization({}),
            multipliers: [new multiplier_1.Multiplier({})]
        });
        this.companyTypeOptions = this.apiService.getCompanyTypes().map(function (companyType) {
            return { id: companyType.id, name: companyType.name };
        });
        var organization = this.authenticationService.getOrganization();
        this.posEnabled = organization && organization.posEnabled;
        if (this.route.queryParams) {
            this.route.queryParams.forEach(function (params) {
                if (params['returnTo']) {
                    _this.returnTo = decodeURIComponent(params['returnTo']);
                }
                else {
                    if (_this.accountType !== 'accounts' && _this.account && _this.account.organization) {
                        _this.returnTo = '/leased-fleet';
                    }
                    else {
                        _this.returnTo = '/accounts';
                    }
                }
            });
        }
        this.route.params.forEach(function (params) {
            _this.accountId = params['accountId'];
            if (_this.accountId) {
                _this.getAccount();
            }
            else {
                _this.getPriceLists();
            }
        });
        this.getTruckTypes();
        this.states = this.apiService.getStates();
        this.countryOptions = this.apiService.getCountries().map(function (country) {
            return {
                id: country.abbreviation,
                name: country.name
            };
        });
        if (this.account && this.account.organization && this.account.organization.country) {
            this.selectCountry(this.account.organization.country, 'contact');
        }
        else {
            this.selectCountry('US', 'contact');
        }
        if (this.account && this.account.organization && this.account.organization.billingCountry) {
            this.selectCountry(this.account.organization.billingCountry, 'billing');
        }
        else {
            this.selectCountry('US', 'billing');
        }
    };
    AccountComponent.prototype.ngOnDestroy = function () {
        if (this.truckTypesReq && typeof this.truckTypesReq.unsubscribe === 'function') {
            this.truckTypesReq.unsubscribe();
        }
        if (this.connectionReq && typeof this.connectionReq.unsubscribe === 'function') {
            this.connectionReq.unsubscribe();
        }
        if (this.priceListReq && typeof this.priceListReq.unsubscribe === 'function') {
            this.priceListReq.unsubscribe();
        }
    };
    /**
     * Sets the Account Organization's `state` and/or `billingState` properties to
     * the State selected from the dropdown based on the `type` parameter.
     *
     * @param {} state The state object being selected
     * @param {} type The type or section of the form the selection occurs in
     */
    AccountComponent.prototype.selectState = function (state, type) {
        if (type === void 0) { type = 'contact'; }
        if (type === 'contact') {
            this.account.organization.state = state;
        }
        else {
            this.account.organization.billingState = state;
        }
    };
    /**
    * Sets the Account Organization's `country` and/or `billingCountry` properties
    * to the Country selected from the dropdown based on the `type` parameter.
    *
    * @param {} country The country object being selected
    * @param {} type The type or section of the form the selection occurs in
    */
    AccountComponent.prototype.selectCountry = function (country, area, state) {
        if (area === void 0) { area = 'organization'; }
        if (state === void 0) { state = null; }
        var filteredStates = lodash_1.filter(this.states, { country: country }).map(function (s) {
            return {
                id: s.abbreviation,
                name: s.name
            };
        });
        if (area === 'contact') {
            this.stateOptions = filteredStates;
            this.account.organization.country = country;
            if (state === null) {
                this.account.organization.state = filteredStates && filteredStates.length > 0 ? filteredStates[0].name : null;
            }
        }
        else {
            this.billingStateOptions = filteredStates;
            this.account.organization.billingCountry = country;
            if (state === null) {
                this.account.organization.billingState = filteredStates && filteredStates.length > 0 ? filteredStates[0].name : null;
            }
        }
    };
    /**
     * If true, this function will copy the organization address information to
     * the billing address fields.
     *
     * @param same Checkbox element indicating whether or not to copy the data
     */
    AccountComponent.prototype.copyContactAddress = function (same) {
        if (same.target.checked) {
            this.account.organization.billingAddress1 = this.account.organization.street;
            this.account.organization.billingAddress2 = this.account.organization.address2;
            this.account.organization.billingCity = this.account.organization.city;
            this.account.organization.billingState = this.account.organization.state;
            this.account.organization.billingZipcode = this.account.organization.zipcode;
        }
    };
    /**
     * If true, this function will copy the organization email address to the
     * billing email field.
     *
     * @param same Checkbox element indicating whether or not to copy the data
     */
    AccountComponent.prototype.copyContactEmail = function (same) {
        if (same.target.checked) {
            this.account.organization.billingEmail = this.account.organization.email;
        }
    };
    /**
     * Sets the `selectedPaymentType` and `account.posPaymentType` properties to
     * the Payment Type ID selected from the dropdown.
     *
     * @param {} paymentType The payment type object being selected
     */
    AccountComponent.prototype.selectPaymentType = function (paymentType) {
        this.selectedPaymentType = paymentType;
        this.account.posPaymentType = paymentType && paymentType.id;
    };
    /**
     * Requests the Account from the {@link ConnectionService} and maps the
     * results to `account` for use as the primary focus of this component's form.
     *
     * If the `account.multipliers` attribute is empty, a multiplier object is
     * pre-staged in the array.
     *
     * If the `account` has a {@link PaymentType}, that option is pre-selected
     * in the Payment Type dropdown.
     */
    AccountComponent.prototype.getAccount = function () {
        var _this = this;
        this.errors = [];
        this.loading = true;
        if (this.connectionReq && typeof this.connectionReq.unsubscribe === 'function') {
            this.connectionReq.unsubscribe();
        }
        this.connectionReq = this.connectionService.get(this.accountId).subscribe(function (account) {
            _this.account = JSON.parse(JSON.stringify(account));
            _this.selectCountry(account.organization.country, 'contact', account.organization.state);
            _this.selectCountry(account.organization.billingCountry, 'billing', account.organization.billingState);
            if (!_this.account.multipliers || !_this.account.multipliers.length) {
                _this.account.multipliers = [new multiplier_1.Multiplier({})];
            }
            var paymentType = lodash_1.find(_this.paymentTypeOptions, { id: _this.account.posPaymentType });
            if (paymentType) {
                _this.selectedPaymentType = paymentType;
            }
            _this.getPriceLists();
            _this.loading = false;
        }, function (error) {
            _this.errors = shared_1.parseErrors(error);
        });
    };
    /**
     * Requests the Price Lists from the {@link PriceListService} and maps the
     * results to `priceListOptinos` for use in the Price List dropdown.
     *
     * If the `account.posPriceList` is already set, that option is pre-selected.
     */
    AccountComponent.prototype.getPriceLists = function () {
        var _this = this;
        this.loading = true;
        this.errors = [];
        if (this.priceListReq && typeof this.priceListReq.unsubscribe === 'function') {
            this.priceListReq.unsubscribe();
        }
        this.priceListReq = this.priceListService.list({}).subscribe(function (priceLists) {
            _this.priceListOptions = [{ id: null, name: '-' }].concat(priceLists);
            if (_this.account.posPriceList && _this.account.posPriceList.id) {
                var priceList = lodash_1.find(priceLists, { id: _this.account.posPriceList.id });
                _this.selectedPriceList = priceList;
                if (!priceList) {
                    _this.priceListOptions.unshift(_this.selectedPriceList);
                }
            }
            _this.loading = false;
        }, function (error) {
            _this.errors = shared_1.parseErrors(error);
            _this.loading = false;
        });
    };
    /**
     * Initially, the account is marked as a normal connection. If the route does
     * not include `accounts` then the account's {@link Organization} is marked as
     * a `leasedOrg`.
     *
     * If the account has an {@link Organization}, the Billing Contact object is
     * deleted from the request. TODO: Revise to occur in the serializer.
     *
     * Saves the connection using the {@link ConnectionService} and navigates the
     * user to the appropriate index route for Accounts/Leased Fleet.
     */
    AccountComponent.prototype.submit = function () {
        var _this = this;
        this.errors = [];
        var leasedOrgFlag = false;
        this.loading = true;
        if (this.accountType !== 'accounts' && this.account && this.account.organization) {
            this.account.organization.leasedOrg = true;
            leasedOrgFlag = true;
        }
        if (this.account && this.account.organization) {
            delete this.account.organization.billingContact;
        }
        this.connectionService.save(this.account).subscribe(function (response) {
            _this.loading = false;
            if (_this.returnTo && _this.returnTo.includes('?')) {
                var route = _this.returnTo.split('?')[0];
                var params_1 = {};
                _this.returnTo.split('?')[1].split('&').forEach(function (param) {
                    var paramData = param.split('=');
                    params_1[paramData[0]] = paramData[1];
                });
                _this.router.navigate([route], { queryParams: params_1 });
            }
            else if (_this.returnTo) {
                _this.router.navigate([_this.returnTo]);
            }
            else {
                leasedOrgFlag ? _this.router.navigateByUrl('/leased-fleet') : _this.router.navigateByUrl('/accounts');
            }
        }, function (err) {
            _this.loading = false;
            _this.errors = shared_1.parseErrors(err);
        });
    };
    AccountComponent.prototype.rateSetupChanged = function (oneRate) {
        if (oneRate) {
            this.account.oneRate = true;
        }
        else {
            this.account.oneRate = false;
        }
    };
    /**
     * Requests the Truck Types from the {@link TruckTypeService} and maps the
     * results to `truckTypeOptions` for use in the Truck Type dropdown.
     */
    AccountComponent.prototype.getTruckTypes = function () {
        var _this = this;
        this.errors = [];
        this.truckTypesLoading = true;
        if (this.truckTypesReq && typeof this.truckTypesReq.unsubscribe === 'function') {
            this.truckTypesReq.unsubscribe();
        }
        this.truckTypesReq = this.truckTypeService.list({
            ordering: 'name',
            page_size: 100
        }).subscribe(function (truckTypes) {
            _this.truckTypeOptions = truckTypes.map(function (truckType) {
                return { id: truckType.id, name: truckType.name };
            });
            _this.truckTypesLoading = false;
        }, function (error) {
            _this.errors = shared_1.parseErrors(error);
            _this.truckTypesLoading = false;
        });
    };
    /**
     * Sets the `truckType` for the multiplier at the `index` in the
     * `account.multipliers` array.
     *
     * @param {} truckType The truck type object being selected
     * @param {} index The index for the multiplier we are dealing with
     */
    AccountComponent.prototype.selectTruckType = function (truckType, index) {
        var multiplier = this.account && this.account.multipliers[index];
        if (multiplier) {
            multiplier.truckType = truckType;
        }
    };
    /**
     * Sets the `rateType` for the multiplier at the `index` in the
     * `account.multipliers` array.
     *
     * @param {} rateType The rate type object being selected
     * @param {} index The index for the multiplier we are dealing with
     */
    AccountComponent.prototype.selectRateType = function (rateType, index) {
        var multiplier = this.account && this.account.multipliers[index];
        if (multiplier) {
            multiplier.rateType = rateType;
        }
    };
    /**
     * Pushes a new {@link Multiplier} object onto the `account.multipliers`
     * array.
     *
     * @param index The index in the multiplier for loop that this action is being
     * performed from
     */
    AccountComponent.prototype.addMultiplier = function (index) {
        if (this.account) {
            this.account.multipliers.push(new multiplier_1.Multiplier({}));
        }
    };
    /**
     * Marked the {@link Multiplier} object at `index` for removal by setting its
     * `remove` attribute to the inverse of what it currently is set to (this also
     * allows for an undo action)`false`the `account.multipliers`
     * array.
     *
     * Note: The template does not currently display this option.
     *
     * @param index The index in the multiplier for loop that this action is being
     * performed from
     * @param current The current state of the multiplier
     */
    AccountComponent.prototype.removeMultiplier = function (index, current) {
        var multiplier = this.account && this.account.multipliers[index];
        if (multiplier) {
            multiplier.remove = !current;
        }
    };
    /**
     * Sets the `selectedPriceList` and `account.posPriceList` properties to the
     * Price List selected from the dropdown.
     *
     * @param {} priceList The price list object being selected
     */
    AccountComponent.prototype.selectPriceList = function (priceList) {
        this.selectedPriceList = priceList;
        this.account.posPriceList = priceList;
    };
    return AccountComponent;
}());
exports.AccountComponent = AccountComponent;
