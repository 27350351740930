"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var common_1 = require("@angular/common");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var core_2 = require("@ngx-translate/core");
var lodash_1 = require("lodash");
var moment = require("moment");
// angular material
var material_1 = require("@angular/material");
// components
var fancy_table_component_1 = require("../../shared/fancy-table/fancy-table.component");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var filters_dialog_component_1 = require("../../shared/filters-dialog/filters-dialog.component");
var column_toggle_component_1 = require("../../shared/column-toggle/column-toggle.component");
var edit_shifts_dialog_component_1 = require("../edit-shifts/edit-shifts-dialog.component");
var new_shift_dialog_component_1 = require(".././new-shift-dialog/new-shift-dialog.component");
// types
var filter_option_1 = require("../../shared/filters-panel/filter-option");
// services
var shift_service_1 = require("../shift.service");
var authentication_service_1 = require("../../shared/authentication.service");
// constants
var columns_1 = require("./columns");
var filters_1 = require("./filters");
// utils
var app_utilities_1 = require("../../shared/app-utilities");
var ShiftsComponent = /** @class */ (function () {
    function ShiftsComponent(route, shiftService, driverShiftService, translationService, authenticationService, router, dialog) {
        var _this = this;
        this.route = route;
        this.shiftService = shiftService;
        this.driverShiftService = driverShiftService;
        this.translationService = translationService;
        this.authenticationService = authenticationService;
        this.router = router;
        this.dialog = dialog;
        this.availableColumns = columns_1.AVAILABLECOLUMNS(this.translationService).slice();
        this.displayedColumns = columns_1.DISPLAYEDCOLUMNS.slice();
        this.mappedColumns = columns_1.MAPPEDCOLUMNS.slice();
        this.appliedFilters = [];
        this.search = '';
        this.tableConfig = {
            hasHeader: true,
            pageSize: 25,
            service: shift_service_1.ShiftReportService,
            filterQuery: false,
            preferenceKey: 'shifts',
            preferencesEnabled: true,
            query: {},
            collectionTitle: this.translationService.instant('Shifts'),
            noResultsText: this.translationService.instant('a shift'),
            sortBy: 'driver__profile__last_name,driver__profile__first_name',
            sortDirection: 'asc',
            automaticallyHidePagination: false,
            menuOptions: [
                {
                    name: this.translationService.instant('Edit'),
                    action: 'edit',
                    link: true,
                    external: false,
                },
            ],
            newRecordModal: function () { return _this.openAddShift(); },
        };
        this.multipleActionDropdownOptions = [
            {
                name: this.translationService.instant('Export'),
                action: 'export',
                link: false,
            },
            {
                name: this.translationService.instant('Edit Selected'),
                action: 'edit',
                link: false,
                disabled: true
            },
            {
                name: this.translationService.instant('Driver Shifts Export'),
                action: 'driver-shifts-export',
                link: false,
            },
        ];
        this.datePipe = new common_1.DatePipe('en-US');
        this.loading = true;
        this.errors = [];
        this.allSelected = false;
        this.selectedShifts = [];
        this.excludeShifts = [];
        this.contextMenuEventSubject = new rxjs_1.Subject();
        this.driverShiftsExportEnabled = false;
        this.allSubscriptionsToUnsubscribe = [];
        this.exportCallback = function () { };
        this.editSelectedShiftsCallback = function () {
            _this.fancyTable.getRecords();
        };
    }
    /**
     * Processes the current URL params and applies them as filters / queries to the table data requests
     */
    ShiftsComponent.prototype.ngOnInit = function () {
        var _this = this;
        var enabledFeatures = this.authenticationService.enabledFeatures();
        var driverShiftsExportEnabled = enabledFeatures.includes('hasDriverShiftExport');
        this.driverShiftsExportEnabled = driverShiftsExportEnabled;
        if (!driverShiftsExportEnabled) {
            this.multipleActionDropdownOptions =
                this.multipleActionDropdownOptions.filter(function (option) { return option.action !== 'driver-shifts-export'; });
        }
        var combinedParams = rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); });
        this.allSubscriptionsToUnsubscribe.push(combinedParams.subscribe(function (result) {
            _this.loading = true;
            _this.search = result.qparams['search'] || '';
            if (result.qparams['sortBy']) {
                _this.tableConfig.sortBy = result.qparams['sortBy'] || '';
                _this.tableConfig.sortDirection = result.qparams['sortAsc'] || '';
            }
            else {
                _this.tableConfig.sortBy = 'start_time';
                _this.tableConfig.sortDirection = 'desc';
            }
        }));
        if (this.appliedFilters.length === 0 && !this.search) {
            var startDate = moment();
            startDate = startDate.subtract(1, 'month');
            startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
            var defaultDateFilter = new filter_option_1.FilterOption({
                filterType: 'date',
                default: true,
                key: 'startDate',
                title: this.translationService.instant('Start Date'),
                displayValues: startDate.format('MM/DD/YYYY') || null,
                values: startDate.toISOString(),
                query: {
                    start_time__gte: startDate.toISOString(),
                },
            });
            this.appliedFilters.push(defaultDateFilter);
        }
    };
    ShiftsComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    /**
     * Opens the filter dialog and sets up the callback to handle changes made to any available filters
     */
    ShiftsComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(filters_dialog_component_1.FiltersDialogComponent, {
            width: '430px',
        });
        dialog.componentInstance.filters = filters_1.FILTERS.slice();
        dialog.componentInstance.callback = function (res) { return _this.filterChanges(res); };
        dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce(function (acc, filter) {
            acc[filter.key] = filter.values;
            return acc;
        }, {}));
        var startDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'startDate' }), 'values');
        if (startDate) {
            dialog.componentInstance.model.startDate = new Date(startDate);
        }
        var endDate = lodash_1.get(lodash_1.find(this.appliedFilters, { key: 'endDate' }), 'values');
        if (endDate) {
            dialog.componentInstance.model.endDate = new Date(endDate);
        }
        dialog.componentInstance.model.carrier = null;
        this.filtersDialog = dialog.componentInstance;
    };
    /**
     * Handles the filter changes and applys them to the table data API request
     *
     * @param {any} filterRes The filter change object
     */
    ShiftsComponent.prototype.filterChanges = function (filterRes) {
        var queryKeys = {
            startDate: 'start_time__gte',
            endDate: 'end_time__lte',
            carrier: 'driver__carrier',
            truckChanged: 'truck_changed',
            lunchBreak: 'lunch_break_duration__gte',
            vehicleBreakdown: 'vehicle_breakdown_duration__gte',
            includeLeasedFleetDrivers: 'include_leased_fleet_drivers',
        };
        var falseyFilters = [];
        this.appliedFilters = Object.keys(filterRes).map(function (key) {
            var query = {};
            var values = filterRes[key];
            var displayValues = filterRes[key] && filterRes[key]['name']
                ? filterRes[key]['name']
                : values;
            if (typeof values === 'boolean') {
                if (values) {
                    values = values.toString();
                    values = values.charAt(0).toUpperCase() + values.slice(1);
                    query[queryKeys[key]] = values;
                }
                displayValues = values;
            }
            else if (['startDate', 'endDate'].indexOf(key) > -1 && values) {
                if (typeof values === 'string') {
                    query[queryKeys[key]] = values;
                }
                else {
                    query[queryKeys[key]] = displayValues = values.toISOString();
                }
            }
            else if (filterRes[key]) {
                query[queryKeys[key]] =
                    filterRes[key] && filterRes[key].id
                        ? filterRes[key].id
                        : filterRes[key];
            }
            var filter = new filter_option_1.FilterOption({
                filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
                key: key,
                title: (key.charAt(0).toUpperCase() + key.slice(1))
                    .replace(/([A-Z])/g, ' $1')
                    .trim(),
                displayValues: displayValues || null,
                values: values,
                query: query,
            });
            if (filter.values === 'False' || !filter.values) {
                falseyFilters.push(filter);
            }
            return filter;
        });
        this.appliedFilters = lodash_1.difference(this.appliedFilters, falseyFilters);
    };
    /**
     * Handles the selected UI bulk action and fires the relevant action's class method
     *
     * @param {any} option The selected UI option data
     */
    ShiftsComponent.prototype.setSelectedBulkAction = function (option) {
        switch (option.action) {
            case 'export':
                this.exportSelectedShifts();
                break;
            case 'edit':
                this.editSelectedShifts();
                break;
            case 'driver-shifts-export':
                this.exportDriverShifts();
                break;
        }
    };
    /**
     * Bulk edit the selected shifts
     */
    ShiftsComponent.prototype.editSelectedShifts = function () {
        var _this = this;
        var scope = {};
        var params = new http_1.HttpParams();
        this.shiftService.getExportFields().subscribe(function (fields) {
            if (_this.selectedShifts.length) {
                Object.assign(scope, {
                    include: _this.selectedShifts.map(function (t) { return t.id; }),
                });
            }
            else if (_this.allSelected) {
                Object.assign(scope, {
                    exclude: _this.excludeShifts.map(function (t) { return t.id; }),
                });
                Object.keys(_this.appliedFilters).map(function (key) {
                    if (_this.appliedFilters[key]) {
                        var query_1 = _this.appliedFilters[key]['query'];
                        Object.keys(query_1).map(function (queryKey) {
                            params = params.set(queryKey, query_1[queryKey]);
                        });
                    }
                });
                params = params.set('search', _this.search);
                Object.assign(scope, {
                    include: _this.fancyTable.dataSource.data
                        .map(function (s) { return s.id; })
                        .filter(function (id) { return !scope['exclude'].includes(id); }),
                });
            }
            _this.dialog.open(edit_shifts_dialog_component_1.EditShiftsDialogComponent, {
                width: '475px',
                data: {
                    callback: _this.editSelectedShiftsCallback,
                    shifts: scope['include'],
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    /**
     * Generates a shift export using the pandas export API functionality
     */
    ShiftsComponent.prototype.exportSelectedShifts = function () {
        var _this = this;
        var _a = app_utilities_1.AppUtilities.getExportParamsAndScope(this.appliedFilters, this.selectedShifts, this.excludeShifts, this.allSelected, this.search), params = _a.params, scope = _a.scope;
        this.shiftService.getExportFields().subscribe(function (fields) {
            var orderedColumns = _this.columnToggle.displayedColumns.map(function (col) {
                var mappedCol = _this.mappedColumns.find(function (c) { return c.key === col; });
                return {
                    key: col,
                    value: mappedCol ? mappedCol.value : col,
                    selected: mappedCol ? true : false,
                };
            });
            var displayedColumns = fields.map(function (field) {
                var col = orderedColumns.slice().find(function (c) { return c.value === field.key; });
                return __assign({}, field, { value: col ? col.key : field.key, selected: col ? col.selected : false });
            });
            // so it's in the same order as the displayed columns
            var reversedColumns = _this.columnToggle.displayedColumns.slice().reverse();
            displayedColumns.sort(function (a, b) {
                return reversedColumns.indexOf(b.value) -
                    reversedColumns.indexOf(a.value);
            });
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'shifts',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: _this.exportCallback,
                    fields: fields,
                    params: params,
                    scope: scope,
                    service: _this.shiftService,
                    displayedColumns: displayedColumns,
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    /**
     * Generates a shift export using the pandas export API functionality
     */
    ShiftsComponent.prototype.exportDriverShifts = function () {
        var _this = this;
        var customUrl = 'drivers/shifts/pandas-export/';
        var scope = {};
        var params = new http_1.HttpParams();
        this.shiftService.getExportFields(customUrl).subscribe(function (fields) {
            _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: 'auto',
                data: {
                    type: 'driver-shifts',
                    buttonText: _this.translationService.instant('Export Data to CSV'),
                    callback: _this.exportCallback,
                    fields: fields,
                    params: params,
                    scope: scope,
                    customUrl: customUrl,
                    service: _this.driverShiftService,
                },
            });
        }, function (err) {
            _this.errors = err;
        });
    };
    /**
     * Sets the displayedColumns property on the columnToggle component.
     *
     * @param {} columns List of columns to display (in order)
     */
    ShiftsComponent.prototype.columnsChanged = function (columns) {
        if (this.columnToggle) {
            this.columnToggle.displayedColumns = columns;
            this.columnToggle.ngOnInit();
        }
    };
    /**
     * Drives the row selection functionality, including 'allSelected' states for paginated data
     *
     * @param {{ allSelected: boolean, selection: SelectionModel<ShiftReport>, exclusion: SelectionModel<ShiftReport> }} shift
     * The selection event object
     */
    ShiftsComponent.prototype.selector = function (event) {
        this.allSelected = event.allSelected;
        if (!this.allSelected) {
            this.selectedShifts = event.selection.selected;
            this.excludeShifts = [];
        }
        else {
            this.selectedShifts = [];
            this.excludeShifts = event.exclusion.selected;
        }
        // it works for 99% cases, except when you select all and then manually deselect all. angular material bug?
        this.multipleActionDropdownOptions = this.multipleActionDropdownOptions.map(function (opt) {
            return opt.action === 'edit' ? __assign({}, opt, { disabled: event.selection.selected.length === 0 && !event.allSelected }) : opt;
        });
    };
    /**
     * Makes an edit to either the startTime or endTime field and saves that edit to the API shift record
     *
     * @param {ShiftReport} shift The target shift report
     * @param {string} field The time field we will apply the edit to
     * @param {any} value The edit value for the time adjustment
     */
    ShiftsComponent.prototype.editTime = function (shift, field, value) {
        var _this = this;
        var saveData = { id: shift.id };
        var newTime = moment(value);
        if (field === 'start' && !newTime.isSame(shift.startTime)) {
            saveData.startTime = value;
            if (newTime.isAfter(shift.endTime)) {
                saveData.endTime = value;
            }
        }
        else if (field === 'end' && !newTime.isSame(shift.endTime)) {
            saveData.endTime = value;
            if (newTime.isBefore(shift.startTime)) {
                saveData.startTime = value;
            }
        }
        this.shiftService
            .save(saveData, { include_leased_fleet_drivers: 'True' })
            .subscribe(function (updates) {
            if (shift) {
                shift.startTime = updates.startTime;
                shift.endTime = updates.endTime;
            }
        }, function (err) {
            _this.errors = err;
        });
    };
    /**
     * Makes an edit to the adjustment field and saves that edit to the API shift record
     *
     * @param {ShiftReport} shift The target shift report
     * @param {any} value The edit value for the adjustment
     */
    ShiftsComponent.prototype.editAdjustment = function (shift, value) {
        var _this = this;
        var saveData = { id: shift.id, adjustment: Number(value) };
        if (saveData.adjustment !== shift.adjustment) {
            this.shiftService.save(saveData).subscribe(function (updates) {
                if (shift) {
                    shift.adjustment = updates.adjustment;
                }
            }, function (err) {
                _this.errors = err;
            });
        }
    };
    ShiftsComponent.prototype.openAddShift = function () {
        var _this = this;
        var dialog = this.dialog.open(new_shift_dialog_component_1.NewShiftDialogComponent, {
            width: '455px',
        });
        this.allSubscriptionsToUnsubscribe.push(dialog.componentInstance.shiftCreated.subscribe(function () {
            _this.fancyTable.getRecords();
        }));
    };
    /**
     * Formats an ISO date string into a human readable date format
     *
     * @param {string} dateString The ISO formatted date string
     * @returns {string} The formatted (pretty) date for display
     */
    ShiftsComponent.prototype.prettyDate = function (dateString) {
        var date = new Date(dateString);
        return date instanceof Date && !isNaN(date.getTime())
            ? this.datePipe.transform(date, 'MM/dd/yyyy')
            : '';
    };
    ShiftsComponent.prototype.openContextMenu = function (event, driverId, shiftReport) {
        this.contextMenuEventSubject.next({
            event: event,
            shiftReport: shiftReport,
            driverId: driverId,
        });
    };
    ShiftsComponent.prototype.onShiftEnd = function () {
        this.fancyTable.getRecords();
    };
    return ShiftsComponent;
}());
exports.ShiftsComponent = ShiftsComponent;
