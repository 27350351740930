export const CONSTANTS = {
  defaultTimeFrame: {
    name: 'Seven Days',
    id: 'seven-days',
    lastPeriodColumnName: 'Last 7 day period',
    currentPeriodColumnName: 'Current 7 day period'
  },
  frameDropdownData: {
    frames: [
      {
        name: 'Seven Days',
        id: 'seven-days',
        lastPeriodColumnName: 'Last 7 day period',
        currentPeriodColumnName: 'Current 7 day period'
      },
      { name: 'Three Days',
        id: 'three-days',
        lastPeriodColumnName: 'Last 3 day period',
        currentPeriodColumnName: 'Current 3 day period'
      }
    ],
    config: {
      autocomplete: false,
      searchable: false
    }
  },
  barChartOptions: {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Organizations'
          }
        }
      ],
      yAxes: [
        {
          display: true,
          scaleLabel: {
            display: true,
            labelString: 'Notifications'
          }
        }
      ]
    }
  },
  displayedColumns: [
    'name',
    'lastPeriod',
    'currentPeriod',
    'percentageChange',
    'chart'
  ]
};
