import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material';
import { find as _find, filter as _filter, pick } from 'lodash';
import { Observable, combineLatest as observableCombineLatest, Subscription } from 'rxjs';

import { AuthenticationService } from '../../shared';
import { Trip } from '../../trips/trip';
import { TripDecisionService } from '../../trips/trip-decision.service';
import { Organization } from '../../organizations/organization';
import { TripDecision } from '../../trips/trip-decision';
import { User } from '../../users/user';
import { EditShareDialogComponent } from '../../collaborators/edit-share-dialog.component';
import { JobEventShare } from '../../job-event-shares/job-event-share';
import { JobEvent } from '../../job-events/job-event';
import { Driver } from '../driver';
import { NewTripDialogComponent } from '../../trips/new-trip-dialog/new-trip-dialog.component';
import { Job } from '../../jobs/job';

@Component({
  selector: 'driver-daily-jobs',
  templateUrl: './driver-daily-jobs.component.html',
  styleUrls: ['./driver-daily-jobs.component.scss']
})
export class DriverDailyJobsComponent implements OnInit, OnDestroy {
  @Input() reportDate: Date;
  @Input() driver: Driver;
  @Input() hideApproved = false;
  @Input() showVoided = false;
  @Input() showUnworkedJobs = false;
  @Input() tripTimes: any[] = [];
  @Input() view: 'trips' | 'punch-cards' = 'trips';
  @Input() keyStatus: {driverId: string, key: string, loading: boolean, statuses: string[]}[] = [];
  @Output() shouldAuditDecisions: EventEmitter<string> = new EventEmitter<string>();
  @Output() rateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  approvedStatuses: string[] = [];
  loading = true;
  errors = [];
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  organization: Organization;
  user: User;
  displayedColumns: string[] = [
    'total-time', 'schedule', 'loading-ticket-number',
    'unloading-ticket-number', 'amount', 'approved-by', 'approved', 'actions'
  ];
  invoiceRates: number[] = [];
  haulRates: number[] = [];
  invoiceRatesWeightUnits: string[] = [];
  haulRatesWeightUnits: string[] = [];
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  saveTripCallback = (trip: Trip) => {
    if (this.route.snapshot.queryParamMap.get('refreshPage')) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'refreshPage': null,
        },
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigateByUrl(`${this.router.url}&refreshPage=true`);
    }
  }

  saveShareCallback = (result: {
    jobEventShare: JobEventShare,
    jobEvent: JobEvent,
    change: string
  }) => {
    const jobEventShare = result.jobEventShare;
    const updates = pick(jobEventShare, [
      'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
      'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
    ]);
    updates['rate'] = updates['invoiceRate'];

    const jobIdx = this.driver.jobEventShares
      .findIndex(j => j.jobId === jobEventShare.jobId && j.organizationId === jobEventShare.organizationId);
    if (jobIdx > -1) { Object.assign(this.driver.jobEventShares[jobIdx], updates); }
    this.rateChanged.emit(true);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    private tripDecisionService: TripDecisionService,
    public dialog: MatDialog
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnInit() {
    this.organization = this.authenticationService.getOrganization();
    this.user = this.authenticationService.user();
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  switchView(view: 'trips' | 'punch-cards'): void {
    this.view = view;
  }

  updateTripData(trips: Trip[]) {
    this.driver = Object.assign(this.driver, {
      trips: this.driver.trips.map(t => {
        const matchedIndex = trips.findIndex(trip => (trip.id === t.id));
        if (matchedIndex > -1) {
          return trips[matchedIndex];
        } else {
          return t;
        }
      })
    });
  }

  anyUnapprovedTrips(driver: Driver, jobEventShare: JobEventShare): boolean {
    let status = false;
    if (driver && driver.trips.length) {
      let tripCount = driver.trips.map(trip => {
        return trip.jobEvent.job.id === jobEventShare.jobId && trip.latestDecisionStatus !== 'approved';
      }).filter(Boolean).length;
      status = +tripCount > 0;
    }
    return status;
  }

  approvedTrips(driver: Driver, jobEventShare: JobEventShare): number {
    let count = 0;
    if (driver && driver.trips.length) {
      let tripCount = driver.trips.map(trip => {
        return trip.jobEvent.job.id === jobEventShare.jobId && trip.latestDecisionStatus === 'approved';
      }).filter(Boolean).length;
      count = +tripCount;
    }
    return count;
  }

  unapprove(jobEventShare: JobEventShare): void {
    this.approve(jobEventShare, 'pending');
  }

  approve(jobEventShare: JobEventShare, decisionStatus = 'approved'): void {
    let requests: Observable<TripDecision>[] = [];
    let newStatus = false;
    let status = _find(this.keyStatus, { driverId: this.driver.id, key: jobEventShare.jobId });
    if (status) {
      status.loading = true;
    } else {
      newStatus = true;
      status = {driverId: this.driver.id, key: jobEventShare.jobId, loading: true, statuses: [] };
    }

    if (this.driver) {
      this.driver.trips.forEach((trip: Trip) => {
        let alreadyApproved = trip.latestDecisionStatus === 'approved' && decisionStatus === 'approved';
        if (trip && trip.jobEvent.job.id === jobEventShare.jobId && !alreadyApproved) {
          trip.loading = true;
          let data = {
            trip: trip, decisionStatus: decisionStatus, decidedBy: this.user,
            organization: this.organization, decidedAt: new Date().toISOString()
          };
          requests.push(this.tripDecisionService.save(data));
        }
      });
    }

    this.allSubscriptionsToUnsubscribe.push(
      observableCombineLatest(requests).subscribe((decisions: TripDecision[]) => {
        decisions.forEach(decision => {
          let trip: Trip = <Trip>_find(this.driver.trips, { id: decision.trip && decision.trip.id });
          if (trip) {
            trip.latestDecision = decision.id;
            trip.latestDecisionStatus = decision.decisionStatus;
            trip.latestDecider = decision.decidedBy && decision.decidedBy.id;
            trip.latestDeciderName = decision.decidedBy && decision.decidedBy.name;
            status.statuses = [decision.decisionStatus];
            trip.loading = false;
          }
        });
      }, errors => {
        console.log('Decision Errors: ', errors);
      }, () => {
        status.loading = false;
        this.driver.trips = [...this.driver.trips];
        if (newStatus) { this.keyStatus.push(status); }
        this.auditDecisions();
      })
    );
  }

  auditDecisions() {
    this.shouldAuditDecisions.emit(this.driver.id);
  }

  openEditShare(change: 'invoice' | 'haul', driver: Driver, jobEventShare: JobEventShare): void {
    const dialog = this.dialog.open(EditShareDialogComponent, {
      width: '430px'
    });
    if (dialog) {
      dialog.componentInstance.change = change;
      dialog.componentInstance.callback = this.saveShareCallback;
      if (driver && jobEventShare) {
        dialog.componentInstance.jobEventShare = jobEventShare;
      }
    }
  }

  openAddTrip(driver: Driver, jobEventShare: JobEventShare): void {
    const dialog = this.dialog.open(NewTripDialogComponent, {
      width: '455px'
    });
    const carrier = {
      id: driver.carrierId,
      name: driver.carrierOrganizationName,
      carrier: {
        name: driver.carrierName,
        id: driver.carrierId
      }
    };

    dialog.componentInstance.jobName = jobEventShare && jobEventShare.job ? jobEventShare.job : '';
    dialog.componentInstance.lockedFields = jobEventShare && jobEventShare.job ? true : false;
    dialog.componentInstance.firstLoad = jobEventShare && jobEventShare.job ? false : true;
    dialog.componentInstance.job = {
      id: jobEventShare && jobEventShare.jobId ? jobEventShare.jobId : ''
    } as Job;
    dialog.componentInstance.model.jobevent = jobEventShare && jobEventShare.jobeventId ? jobEventShare.jobeventId : '';
    dialog.componentInstance.model.carrier = carrier;
    dialog.componentInstance.model.driver = driver;
    dialog.componentInstance.model.truck = driver.latestTruck;
    dialog.componentInstance.jobEventDate = this.reportDate;
    dialog.componentInstance.jobId = jobEventShare && jobEventShare.jobId ? jobEventShare.jobId : '';
    let trip = driver.trips
      .find(t => t.jobEvent.job.id === jobEventShare.jobId && t.jobEvent.job.project.ownerOrganization === jobEventShare.organizationId);
    if (trip) {
      dialog.componentInstance.tripJobEvent = trip.jobEvent;
    }
    dialog.componentInstance.callback = this.saveTripCallback;
  }
}
