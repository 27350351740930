import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NotificationsReportService } from '../services/notifications-report.service';
import { ThresholdConfig } from '../services/notifications-report';

@Component({
  selector: 'thresholds-dialog',
  templateUrl: './thresholds-dialog.component.html',
  styleUrls: ['./thresholds-dialog.component.scss']
})
export class ThresholdsDialogComponent implements OnInit, OnDestroy {
  private defaultColor = 'rgb(195, 52, 39)';
  thresholdsForm: FormControl;
  thresholdConfig$: BehaviorSubject<ThresholdConfig[]> = new BehaviorSubject([]);
  callback: Function;
  newRuleColor: string = this.defaultColor;
  duplicatedThreshold = false;
  saveEnabled = false;
  formSub$: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ThresholdsDialogComponent>,
    public notificationsReportService: NotificationsReportService
  ) { }

  ngOnInit() {
    this.setForm();
  }

  ngOnDestroy(): void {
    this.formSub$.unsubscribe();
  }

  setForm() {
    this.thresholdsForm = new FormControl(null, [Validators.required, Validators.min(0)]);
    this.formSub$ = this.thresholdsForm.valueChanges.subscribe(
      (value) => this.duplicatedThreshold = this.thresholdConfig$.value.some(el => el.value === (value / 100))
    );
  }

  addRule() {
    const currentRules = new Set(this.thresholdConfig$.value);
    const newRule: ThresholdConfig = {
      value: this.thresholdsForm.value / 100,
      color: this.newRuleColor
    };
    currentRules.add(newRule);
    const sortedRules = Array.from(currentRules).sort((a, b) => b.value - a.value);
    this.thresholdConfig$.next(sortedRules);
    this.thresholdsForm.reset();
    this.newRuleColor = this.defaultColor;
    this.saveEnabled = true;
  }

  removeRule(value: number) {
    let filteredRules = this.thresholdConfig$.value.filter(rule => rule.value !== value);
    filteredRules = filteredRules.sort((a, b) => b.value - a.value);
    this.thresholdConfig$.next(filteredRules);
    this.saveEnabled = true;
  }

  saveChanges() {
    const currentRules = this.thresholdConfig$.value;
    this.notificationsReportService.saveReportConfig(currentRules);
    this.callback(currentRules);
    this.dialogRef.close();
  }
}
