import {
  Component,
  Input,
  ViewChild,
  Output,
  EventEmitter,
  OnDestroy,
  OnInit,
  Renderer2,
  AfterViewInit,
  ElementRef,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

// libraries
import * as moment from 'moment';

// services
import { ShiftService, ShiftReportService } from '../../shifts/shift.service';

// models
import { ShiftData } from './interfaces/shift-data';
import { MenuItem } from './interfaces/menu-item';
import { Shift } from '../../shifts/shift';
import { ShiftReport } from '../../shifts/shift-report';
import { DriverContextEvent } from './interfaces/driver-context-event';
import { MenuSelectionEvent } from './interfaces/menu-selection-event';

// utils
import { AppUtilities } from '../../shared/app-utilities';
import { FleetHealthOptionAction } from './data/options';

// data
import {
  shiftMenuOptions,
  ViewAssignmentsOptionAction,
  ViewDriverProfileAction,
  ViewEndShiftAction,
} from './data/options';

@Component({
  selector: 'app-driver-context-menu',
  templateUrl: './driver-context-menu.component.html',
  styleUrls: ['./driver-context-menu.component.scss'],
})
export class DriverContextMenuComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatMenuTrigger, { static: true }) contextMenu: MatMenuTrigger;
  @Input() contextMenuEventSubject: Subject<DriverContextEvent>;
  @Input() emitActions: string[] = [];
  @Input() useDefaultCoordinates = false;
  @Input() additionalMenuItems: MenuItem[] = [];
  @Output() onShiftEnd: EventEmitter<null> = new EventEmitter();
  @Output() menuSelection: EventEmitter<MenuSelectionEvent> =
    new EventEmitter();

  contextMenuPosition = { x: '100px', y: '100px' };
  contextMenuDriverId: string;
  driverName: string;
  contextMenuOptions: MenuItem[] = [];
  currentDriverShiftData: ShiftData;
  event: any;
  showError = false;

  constructor(
    private translateService: TranslateService,
    private shiftService: ShiftService,
    private shiftReportService: ShiftReportService,
    private router: Router,
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {}

  ngOnInit() {
    this.contextMenuOptions = [
      ...shiftMenuOptions,
      ...this.additionalMenuItems,
    ];
    this.contextMenuEventSubject.subscribe(
      (driverContextEvent: DriverContextEvent) => {
        this.onContextMenuRightClick(driverContextEvent);
      }
    );
  }

  ngAfterViewInit() {
    const height = this.contextMenuOptions.length * 48 + 74;
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'height',
      height + 'px'
    );
  }

  ngOnDestroy() {
    this.contextMenuEventSubject.complete();
  }

  onContextMenuRightClick(driverContextEvent: DriverContextEvent) {
    if (driverContextEvent.shiftReport) {
      this.currentDriverShiftData = this.getShiftDataObject([
        driverContextEvent.shiftReport,
      ]);
    } else {
      this.getShiftData(driverContextEvent.driverId);
    }
    this.openContextMenu(driverContextEvent.event);
    this.contextMenuDriverId = driverContextEvent.driverId;
    this.driverName = driverContextEvent.name;
  }

  openContextMenu(event: MouseEvent) {
    event.preventDefault();
    if (!this.useDefaultCoordinates) {
      this.contextMenuPosition.x = event.clientX + 'px';
      this.contextMenuPosition.y = event.clientY + 'px';
    }
    this.contextMenu.menuData = this.contextMenuOptions;
    this.contextMenu.openMenu();
  }

  onContextMenuAction(event, item: MenuItem) {
    if (item.disabled) {
      return;
    }
    event.stopPropagation();
    if (this.emitActions.includes(item.action)) {
      this.menuSelection.emit({
        menuItem: item,
        driverId: this.contextMenuDriverId,
      });
      this.onContextMenuClose();
    } else {
      this.onContextMenuSelection(item);
    }
  }

  onContextMenuClose() {
    this.event = null;
    this.contextMenuDriverId = null;
    this.contextMenu.closeMenu();
  }

  onContextMenuSelection(menu: MenuItem) {
    switch (menu.action) {
      case ViewEndShiftAction:
        this.endShift();
        break;
      case ViewDriverProfileAction:
        this.router.navigate(
          ['drivers', this.contextMenuDriverId, 'details', 'details'],
          {
            queryParams: { returnTo: this.router.url },
          }
        );
        break;
      case ViewAssignmentsOptionAction:
        this.router.navigate(['jobs', 'drivers'], {
          queryParams: {
            driver_assignments: this.contextMenuDriverId,
            returnTo: this.router.url,
          },
        });
        break;
      case FleetHealthOptionAction:
        this.router.navigate(['fleet-health'], {
          queryParams: {
            search: this.driverName,
          },
        });
    }
  }

  getShiftData(driverId: string) {
    this.shiftReportService
      .list({
        driver: driverId,
        page_size: 1,
        ordering: '-end_time',
        include_leased_fleet_drivers: 'True',
      })
      .subscribe((shifts) => {
        this.currentDriverShiftData = this.getShiftDataObject(shifts);
      });
  }

  getShiftDataObject(shifts: ShiftReport[]): ShiftData {
    if (shifts && shifts.length) {
      const shift = shifts[0];
      if (shift.endTime) {
        // shift ended
        this.contextMenuOptions[0].disabled = true;
        const totalMinutes = moment
          .duration(moment(shift.endTime).diff(shift.startTime))
          .asMinutes();
        const newShiftData: ShiftData = {
          value: `${moment(shift.endTime).format(
            'MMM DD, YYYY'
          )} - ${AppUtilities.getFormattedHoursAndMinutesFromMinutes(
            totalMinutes
          )}`,
          text: this.translateService.instant('Shift Ended'),
          shiftStatus: 'ended',
          shiftId: shift.id,
        };
        this.currentDriverShiftData = newShiftData;
        return newShiftData;
      } else {
        // shift still in progress
        this.contextMenuOptions[0].disabled = false;
        const totalMinutes = moment
          .duration(moment(undefined).diff(shift.startTime))
          .asMinutes();
        const isLong = totalMinutes > 1440;
        const newShiftData = {
          value:
            AppUtilities.getFormattedHoursAndMinutesFromMinutes(totalMinutes),
          text: this.translateService.instant(
            isLong ? 'Active Over 24 Hours' : 'On Shift'
          ),
          shiftStatus: isLong ? 'long' : 'active',
          shiftId: shift.id,
        };
        this.currentDriverShiftData = newShiftData;
        return newShiftData;
      }
    } else {
      this.contextMenuOptions[0].disabled = true;
      const newShiftData = {
        value: this.translateService.instant('No Shifts'),
        text: null,
        shiftStatus: null,
        shiftId: null,
      };
      this.currentDriverShiftData = newShiftData;
      return newShiftData;
    }
  }

  endShift() {
    const shiftData = { ...this.currentDriverShiftData };
    const endTime = new Date();
    const end = moment(endTime).toISOString();
    const shift = <Shift>{
      id: shiftData.shiftId,
      endTime: end,
    };
    this.shiftService
      .save(shift, { include_leased_fleet_drivers: 'True' })
      .subscribe(
        () => {
          this.showError = false;
          if (this.onShiftEnd) {
            this.onShiftEnd.emit();
          }
          this.onContextMenuClose();
        },
        () => {
          this.showError = true;
        }
      );
  }
}
