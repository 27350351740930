import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';

import { UserService } from './user.service';
import { parseErrors } from '../shared/api.service';
import { User } from './user';

@Component({
  selector: 'ruckit-edit-user-notifications',
  templateUrl: './edit-user-notifications.component.html',
  styleUrls: ['./edit-user-notifications.component.scss']
})
export class EditUserNotificationsComponent implements OnInit {
  @Input() user: User;
  loading = true;
  errors = [];
  model = {
    newJobs: { web: false, email: false, sms: false },
    changedJobs: { web: false, email: false, sms: false },
    truckTicketedWithoutAssignmentNotification: { web: false, email: false, sms: false },
    driverRejectionAssignmentNotification: { web: false, email: false, sms: false },
    newDrivers: { web: false, email: false, sms: false },
    newDispatches: { web: false, email: false, sms: false },
    truckGpsDispatch: { web: false, email: false, sms: false },
    newAutoPilotTickets: { web: false, email: false, sms: false },
    newAutoPilotShifts: { web: false, email: false, sms: false },
    ticketNotification: { web: false, email: false, sms: false },
    truckGpsDispatchNotification: { web: false, email: false, sms: false },
  };

  constructor(
    private userService: UserService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.loading = false;
    if (this.user) {
      Object.assign(this.model, {
        newJobs: Object.assign(
          this.model.newJobs,
          this.user.notifications && this.user.notifications.jobs && this.user.notifications.jobs.jobNotification
        ),
        changedJobs: Object.assign(
          this.model.changedJobs,
          this.user.notifications && this.user.notifications.jobs && this.user.notifications.jobs.jobChangedNotification
        ),
        truckTicketedWithoutAssignmentNotification: Object.assign(
          this.model.truckTicketedWithoutAssignmentNotification,
          this.user.notifications && this.user.notifications.jobs && this.user.notifications.jobs.truckTicketedWithoutAssignmentNotification
        ),
        driverRejectionAssignmentNotification: Object.assign(
          this.model.driverRejectionAssignmentNotification,
          this.user.notifications && this.user.notifications.jobs && this.user.notifications.jobs.driverRejectionAssignmentNotification
        ),
        newDrivers: Object.assign(
          this.model.newDrivers,
          this.user.notifications && this.user.notifications.drivers && this.user.notifications.drivers.driverNotification
        ),
        newDispatches: Object.assign(
          this.model.newDispatches,
          this.user.notifications && this.user.notifications.dispatch && this.user.notifications.dispatch.afCarrierDispatchNotification
        ),
        newAutoPilotTickets: Object.assign(
          this.model.newAutoPilotTickets,
          this.user.notifications && this.user.notifications.autoPilot && this.user.notifications.autoPilot.driverNewTicketNotification
        ),
        newAutoPilotShifts: Object.assign(
          this.model.newAutoPilotShifts,
          this.user.notifications && this.user.notifications.autoPilot && this.user.notifications.autoPilot.driverShiftStartedNotification
        ),
        truckGpsDispatchNotification: Object.assign(
          this.model.truckGpsDispatchNotification,
          this.user.notifications && this.user.notifications.dispatch && this.user.notifications.dispatch.truckGpsDispatchNotification
        ),
        ticketNotification: Object.assign(
          this.model.ticketNotification,
          this.user.notifications && this.user.notifications.dispatch && this.user.notifications.dispatch.ticketNotification
        ),
      });
    }
  }

  saveNotifications() {
    this.loading = true;
    let _user = {
      id: this.user.id,
      driverNotificationEmail: this.model.newDrivers.email,
      jobNotificationEmail: this.model.newJobs.email,
      jobChangeNotificationEmail: this.model.changedJobs.email,
      afCarrierDispatchNotificationEmail: this.model.newDispatches.email,
      notifications: {
        jobs: {
          jobNotification: this.model.newJobs,
          jobChangedNotification: this.model.changedJobs,
          truckTicketedWithoutAssignmentNotification: this.model.truckTicketedWithoutAssignmentNotification,
          driverRejectionAssignmentNotification: this.model.driverRejectionAssignmentNotification
        },
        drivers: { driverNotification: this.model.newDrivers },
        dispatch: {
          afCarrierDispatchNotification: this.model.newDispatches,
          ticketNotification: this.model.ticketNotification,
          truckGpsDispatchNotification: this.model.truckGpsDispatchNotification
        },
        autoPilot: {
          driverNewTicketNotification: this.model.newAutoPilotTickets,
          driverShiftStartedNotification: this.model.newAutoPilotShifts
        }
      }
    };

    this.userService.save(_user).subscribe(() => {
      this.loading = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }
}
