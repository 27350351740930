"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var rxjs_1 = require("rxjs");
var operators_1 = require("rxjs/operators");
var http_1 = require("@angular/common/http");
var moment = require("moment");
var lodash_1 = require("lodash");
var trip_1 = require("./trip");
var condensed_trip_1 = require("./condensed-trip");
var environment_1 = require("../../environments/environment");
var index_1 = require("../shared/index");
var decamelizeKeysDeep = require('decamelize-keys-deep');
var TripService = /** @class */ (function () {
    function TripService(http) {
        this.http = http;
        this.baseUrl = environment_1.environment.serverUrl;
    }
    TripService.prototype.getTrip = function (tripId) {
        var _this = this;
        var tripUrl = this.baseUrl + 'trips/' + tripId + '/';
        return this.http.get(tripUrl, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.save = function (trip) {
        var tripUrl = this.baseUrl + 'trips/';
        var _trip = decamelizeKeysDeep(lodash_1.clone(trip));
        if (typeof _trip.assignment === 'object') {
            _trip.assignment = _trip.assignment.id;
        }
        if (typeof _trip.driver === 'object') {
            _trip.driver = _trip.driver.id;
        }
        else if (_trip.driver_id) {
            _trip.driver = _trip.driver_id;
        }
        if (typeof _trip.truck === 'object') {
            _trip.truck = _trip.truck.id;
        }
        else if (_trip.truck_id) {
            _trip.truck = _trip.truck_id;
        }
        if (typeof _trip.job_event === 'object') {
            _trip.jobevent = _trip.job_event.id;
        }
        if (_trip._start_time_timestamp) {
            _trip.start_time = _trip._start_time_timestamp;
        }
        if (_trip._end_time_timestamp) {
            _trip.end_time = _trip._end_time_timestamp;
        }
        if (!trip.id) {
            return this.http.post(tripUrl, _trip, { headers: index_1.requestHeaders() });
        }
        else {
            return this.http.put(tripUrl + trip.id + '/', _trip, { headers: index_1.requestHeaders() });
        }
    };
    TripService.prototype.patchTrip = function (tripId, trip) {
        var tripUrl = this.baseUrl + "trips/" + tripId + "/";
        var _trip = decamelizeKeysDeep(lodash_1.clone(trip));
        return this.http.patch(tripUrl, _trip, { headers: index_1.requestHeaders() });
    };
    TripService.prototype.undoRetakeRequest = function (trip) {
        var tripUrl = this.baseUrl + 'trips/';
        var _trip = decamelizeKeysDeep(lodash_1.clone(trip));
        return this.http.put(tripUrl + trip.id + '/', {
            retake_status: null
        }, { headers: index_1.requestHeaders() });
    };
    TripService.prototype.legacySaveWithCheckins = function (trip, loadingCheckinFile, unloadingCheckinFile) {
        var _this = this;
        if (trip) {
            var _trip_1 = lodash_1.clone(trip);
            if (typeof _trip_1.loadingCheckin === 'object' &&
                _trip_1.loadingCheckin.date &&
                typeof _trip_1.loadingCheckin.date.getMonth === 'function') {
                _trip_1.loadingCheckin.date = _trip_1.loadingCheckin.date.toISOString();
            }
            if (typeof _trip_1.unloadingCheckin === 'object' &&
                _trip_1.unloadingCheckin.date &&
                typeof _trip_1.unloadingCheckin.date.getMonth === 'function') {
                _trip_1.unloadingCheckin.date = _trip_1.unloadingCheckin.date.toISOString();
            }
            if (typeof _trip_1.assignment === 'object') {
                _trip_1.assignment = _trip_1.assignment.id;
            }
            if (typeof _trip_1.driver === 'object') {
                _trip_1.driver = _trip_1.driver.id;
            }
            else if (_trip_1.driverId) {
                _trip_1.driver = _trip_1.driverId;
            }
            if (typeof _trip_1.truck === 'object') {
                _trip_1.truck = _trip_1.truck.id;
            }
            else if (_trip_1.truckId) {
                _trip_1.truck = _trip_1.truckId;
            }
            if (typeof _trip_1.jobEvent === 'object') {
                _trip_1.jobevent = _trip_1.jobEvent.id;
            }
            else if (_trip_1.jobEventId) {
                _trip_1.jobevent = _trip_1.jobEventId;
            }
            if (typeof _trip_1.jobevent === 'object') {
                _trip_1.jobevent = _trip_1.jobevent.id;
            }
            else if (_trip_1.jobeventId) {
                _trip_1.jobevent = _trip_1.jobeventId;
            }
            if (typeof _trip_1.carrier === 'object') {
                _trip_1.carrier = _trip_1.carrier.id;
            }
            else if (_trip_1.carrierId) {
                _trip_1.carrier = _trip_1.carrierId;
            }
            if (typeof _trip_1.job === 'object') {
                _trip_1.job = _trip_1.job.id;
            }
            else if (_trip_1.jobId) {
                _trip_1.job = _trip_1.jobId;
            }
            if (_trip_1.loadingCheckin && _trip_1.loadingCheckin.checkinTime) {
                var value = _trip_1.loadingCheckin.checkinTime;
                var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
                var d = moment(_trip_1.loadingCheckin.date).format('YYYY-MM-DD');
                _trip_1.loadingCheckin.date = moment(d + ' ' + value, pattern).toDate();
            }
            if (_trip_1.unloadingCheckin && _trip_1.unloadingCheckin.checkinTime) {
                var value = _trip_1.unloadingCheckin.checkinTime;
                var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
                var d = moment(_trip_1.unloadingCheckin.date).format('YYYY-MM-DD');
                _trip_1.unloadingCheckin.date = moment(d + ' ' + value, pattern).toDate();
            }
            delete _trip_1.loadingTicketImage;
            delete _trip_1.unloadingTicketImage;
            delete _trip_1.loadingCheckin.ticketImage;
            delete _trip_1.loadingCheckin.signatureImage;
            delete _trip_1.unloadingCheckin.ticketImage;
            delete _trip_1.unloadingCheckin.signatureImage;
            _trip_1 = decamelizeKeysDeep(_trip_1);
            _trip_1.start_time = _trip_1.start_time_timestamp || _trip_1._start_time_timestamp;
            _trip_1.end_time = _trip_1.end_time_timestamp || _trip_1._end_time_timestamp;
            return rxjs_1.Observable.create(function (observer) {
                var formData = new FormData(), xhr = new XMLHttpRequest();
                formData.append('json', JSON.stringify(_trip_1));
                if (loadingCheckinFile) {
                    formData.append('loading_checkin_ticket_image', loadingCheckinFile, loadingCheckinFile.name);
                }
                if (unloadingCheckinFile) {
                    formData.append('unloading_checkin_ticket_image', unloadingCheckinFile, unloadingCheckinFile.name);
                }
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200 || xhr.status === 201) {
                            observer.next(JSON.parse(xhr.response));
                            observer.complete();
                        }
                        else {
                            observer.error(xhr.response);
                        }
                    }
                };
                if (_trip_1.id) {
                    var tripUrl = _this.baseUrl + 'trips/' + _trip_1.id + '/with_checkins/';
                    xhr.open('PUT', tripUrl, true);
                }
                else {
                    var tripUrl = _this.baseUrl + 'trips/with_checkins/';
                    xhr.open('POST', tripUrl, true);
                }
                index_1.requestHeaders(xhr);
                xhr.send(formData);
            });
        }
    };
    TripService.prototype.saveWithCheckinsAndAttachments = function (trip) {
        if (trip) {
            var _trip = lodash_1.clone(trip);
            if (_trip.endTime === null) {
                delete _trip.endTime;
            }
            if (_trip.loadingCheckin && _trip.loadingCheckin.date === null) {
                delete _trip.loadingCheckin.date;
            }
            if (_trip.unloadingCheckin && _trip.unloadingCheckin.date === null) {
                delete _trip.unloadingCheckin.date;
            }
            _trip = decamelizeKeysDeep(_trip);
            return _trip.id
                ? this.http.put(this.baseUrl + "trips/" + _trip.id + "/with_checkins/", _trip, {
                    headers: index_1.requestHeaders(),
                })
                : this.http.post(this.baseUrl + "trips/with_checkins/", _trip, {
                    headers: index_1.requestHeaders(),
                });
        }
        return null;
    };
    TripService.prototype.saveWithCheckins = function (trip, loadingCheckinFile, unloadingCheckinFile) {
        var _this = this;
        if (trip) {
            var _trip_2 = lodash_1.clone(trip);
            if (typeof _trip_2.loadingCheckin === 'object' &&
                _trip_2.loadingCheckin.time &&
                typeof _trip_2.loadingCheckin.time.getMonth === 'function') {
                _trip_2.loadingCheckin.date = _trip_2.loadingCheckin.time.toISOString();
            }
            if (typeof _trip_2.unloadingCheckin === 'object' &&
                _trip_2.unloadingCheckin.time &&
                typeof _trip_2.unloadingCheckin.time.getMonth === 'function') {
                _trip_2.unloadingCheckin.date = _trip_2.unloadingCheckin.time.toISOString();
            }
            if (typeof _trip_2.startTime === 'object' && typeof _trip_2.startTime.getMonth === 'function') {
                _trip_2.startTime = _trip_2.startTime.toISOString();
            }
            if (typeof _trip_2.endTime === 'object' && typeof _trip_2.endTime.getMonth === 'function') {
                _trip_2.endTime = _trip_2.endTime.toISOString();
            }
            if (typeof _trip_2.assignment === 'object') {
                _trip_2.assignment = _trip_2.assignment.id;
            }
            if (typeof _trip_2.driver === 'object') {
                _trip_2.driver = _trip_2.driver.id;
            }
            else if (_trip_2.driverId) {
                _trip_2.driver = _trip_2.driverId;
            }
            if (typeof _trip_2.truck === 'object') {
                _trip_2.truck = _trip_2.truck.id;
            }
            else if (_trip_2.truckId) {
                _trip_2.truck = _trip_2.truckId;
            }
            if (typeof _trip_2.jobEvent === 'object') {
                _trip_2.jobevent = _trip_2.jobEvent.id;
            }
            else if (_trip_2.jobEventId) {
                _trip_2.jobevent = _trip_2.jobEventId;
            }
            if (typeof _trip_2.jobevent === 'object') {
                _trip_2.jobevent = _trip_2.jobevent.id;
            }
            else if (_trip_2.jobeventId) {
                _trip_2.jobevent = _trip_2.jobeventId;
            }
            if (typeof _trip_2.carrier === 'object') {
                _trip_2.carrier = _trip_2.carrier.id;
            }
            else if (_trip_2.carrierId) {
                _trip_2.carrier = _trip_2.carrierId;
            }
            if (typeof _trip_2.job === 'object') {
                _trip_2.job = _trip_2.job.id;
            }
            else if (_trip_2.jobId) {
                _trip_2.job = _trip_2.jobId;
            }
            delete _trip_2.loadingTicketImage;
            delete _trip_2.unloadingTicketImage;
            delete _trip_2.loadingCheckin.ticketImage;
            delete _trip_2.loadingCheckin.signatureImage;
            delete _trip_2.unloadingCheckin.ticketImage;
            delete _trip_2.unloadingCheckin.signatureImage;
            _trip_2 = decamelizeKeysDeep(_trip_2);
            return rxjs_1.Observable.create(function (observer) {
                var formData = new FormData(), xhr = new XMLHttpRequest();
                formData.append('json', JSON.stringify(_trip_2));
                if (loadingCheckinFile) {
                    formData.append('loading_checkin_ticket_image', loadingCheckinFile, loadingCheckinFile.name);
                }
                if (unloadingCheckinFile) {
                    formData.append('unloading_checkin_ticket_image', unloadingCheckinFile, unloadingCheckinFile.name);
                }
                xhr.onreadystatechange = function () {
                    if (xhr.readyState === 4) {
                        if (xhr.status === 200 || xhr.status === 201) {
                            observer.next(JSON.parse(xhr.response));
                            observer.complete();
                        }
                        else {
                            observer.error(xhr.response);
                        }
                    }
                };
                if (_trip_2.id) {
                    var tripUrl = _this.baseUrl + 'trips/' + _trip_2.id + '/with_checkins/';
                    xhr.open('PUT', tripUrl, true);
                }
                else {
                    var tripUrl = _this.baseUrl + 'trips/with_checkins/';
                    xhr.open('POST', tripUrl, true);
                }
                index_1.requestHeaders(xhr);
                xhr.send(formData);
            });
        }
    };
    TripService.prototype.getTrips = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        var tripsUrl = this.baseUrl + 'trips/';
        return this.http.get(tripsUrl, {
            headers: index_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.getCondensedTrips = function (query) {
        var _this = this;
        if (query === void 0) { query = null; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString()) {
                    params = params.set(key, query[key] && query[key].toString());
                }
            });
        }
        var tripsUrl = this.baseUrl + 'trips/condensed/';
        return this.http.get(tripsUrl, {
            headers: index_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.getAllCondensedTrips = function (pageSize, query) {
        var _this = this;
        if (pageSize === void 0) { pageSize = 20; }
        var params = new http_1.HttpParams();
        if (query) {
            Object.keys(query).forEach(function (key) {
                if (typeof query[key] !== 'undefined' && query[key] && query[key].toString) {
                    params = params.set(key, query[key].toString());
                }
            });
        }
        params = params.set('page_size', '1');
        var tripsUrl = this.baseUrl + 'trips/condensed/';
        var requestCount = 0;
        return this.http.get(tripsUrl, {
            headers: index_1.requestHeaders(),
            params: params
        }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.mergeMap(function () {
            params = params.set('page_size', pageSize.toString());
            var nextReqs = [];
            if (_this.count < pageSize) {
                nextReqs.push(_this.http.get(tripsUrl, {
                    headers: index_1.requestHeaders(),
                    params: params
                }).map(function (res) {
                    return _this.extractData(res, true);
                }));
            }
            else {
                for (var i = 1; i <= Math.ceil(_this.count / pageSize); i++) {
                    params = params.set('page', i.toString());
                    nextReqs.push(_this.http.get(tripsUrl, {
                        headers: index_1.requestHeaders(),
                        params: params
                    }).pipe(operators_1.map(function (res) {
                        requestCount++;
                        return res;
                    }), operators_1.map(function (res) { return _this.extractData(res, true); })));
                }
            }
            return rxjs_1.combineLatest(nextReqs);
        }), operators_1.map(function (data) {
            var mergedList = [];
            data.forEach(function (list) {
                mergedList = mergedList.concat(list);
            });
            return mergedList;
        }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.getCondensedTrip = function (tripId) {
        var _this = this;
        var tripUrl = this.baseUrl + 'trips/' + tripId + '/condensed/';
        return this.http.get(tripUrl, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.getExportFields = function () {
        var tripsUrl = this.baseUrl + 'trips/pandas-export/';
        return this.http.get(tripsUrl, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return lodash_1.sortBy(res, 'label'); }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.export = function (scope, params) {
        if (params === void 0) { params = null; }
        var tripsUrl = this.baseUrl + 'trips/pandas-export/';
        var _scope = lodash_1.clone(decamelizeKeysDeep(scope));
        return this.http.post(tripsUrl + '?' + params, _scope, {
            headers: index_1.requestHeaders()
        }).pipe(operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.getNext = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res); }), operators_1.catchError(index_1.handleError));
        }
        else {
            return null;
        }
    };
    TripService.prototype.getNextCondensed = function () {
        var _this = this;
        if (this.nextUri) {
            return this.http.get(this.nextUri, { headers: index_1.requestHeaders() }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(index_1.handleError));
        }
        else {
            return null;
        }
    };
    TripService.prototype.approveTicket = function (ticketId) {
        var _this = this;
        return this.http.post(this.baseUrl + "trips/" + ticketId + "/approve/", null, {
            headers: index_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.rejectTicket = function (ticketId) {
        var _this = this;
        return this.http.post(this.baseUrl + "trips/" + ticketId + "/reject/", null, {
            headers: index_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.reviewTicket = function (ticketId, field, edits) {
        var _this = this;
        return this.http.put(this.baseUrl + "trips/" + ticketId + "/review/" + field.replace('_', '-') + "/", edits, {
            headers: index_1.requestHeaders()
        }).pipe(operators_1.map(function (res) { return _this.extractData(res, true); }), operators_1.catchError(index_1.handleError));
    };
    TripService.prototype.extractData = function (res, condensed) {
        if (condensed === void 0) { condensed = false; }
        var resObj = res;
        this.nextUri = resObj['next'];
        this.count = resObj['count'];
        var body = resObj['results'];
        if (condensed) {
            if (body) {
                return body.map(function (trip) {
                    return new condensed_trip_1.CondensedTrip(trip);
                });
            }
            else if (resObj) {
                return new condensed_trip_1.CondensedTrip(resObj);
            }
        }
        else if (body) {
            return body.map(function (trip) {
                return new trip_1.Trip(trip);
            });
        }
        else if (resObj) {
            return new trip_1.Trip(resObj);
        }
        else {
            return {};
        }
    };
    return TripService;
}());
exports.TripService = TripService;
