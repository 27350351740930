import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { MatDialog } from '@angular/material';
import { find as _find, filter as _filter, pick, clone, sumBy } from 'lodash';
import { Observable, combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import moment = require('moment-timezone');

import { AuthenticationService } from '../../shared';
import { Trip } from '../../trips/trip';
import { Job } from '../../jobs/job';
import { PunchCard } from '../../punch-cards/punch-card';
import { PunchCardDecision } from '../../punch-cards/punch-card-decision';
import { PunchCardDecisionService } from '../../punch-cards/punch-card-decision.service';
import { Organization } from '../../organizations/organization';
import { User } from '../../users/user';
import { EditShareDialogComponent } from '../../collaborators/edit-share-dialog.component';
import { JobEventShare } from '../../job-event-shares/job-event-share';
import { JobEvent } from '../../job-events/job-event';
import { Driver } from '../driver';
import { NewPunchCardDialogComponent } from '../../punch-cards/new-punch-card-dialog.component';

@Component({
  selector: 'driver-daily-punch-cards',
  templateUrl: './driver-daily-punch-cards.component.html',
  styleUrls: ['./driver-daily-punch-cards.component.scss'],
  providers: [PunchCardDecisionService]
})
export class DriverDailyPunchCardsComponent implements OnInit, OnDestroy {
  @Input() reportDate: Date;
  @Input() driver: Driver;
  @Input() hideApproved = false;
  @Input() showVoided = false;
  @Input() showUnworkedJobs = false;
  @Input() tripTimes: any[] = [];
  @Input() view: 'trips' | 'punch-cards' = 'trips';
  @Input() keyStatus: {driverId: string, key: string, loading: boolean, statuses: string[]}[] = [];
  @Output() shouldAuditDecisions: EventEmitter<string> = new EventEmitter<string>();
  @Output() rateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  approvedStatuses: string[] = [];
  loading = true;
  errors = [];
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  organization: Organization;
  user: User;
  displayedColumns: string[] = [
    'total-time', 'schedule', 'loading-ticket-number',
    'unloading-ticket-number', 'amount', 'approved-by', 'approved', 'actions'
  ];
  invoiceRates: number[] = [];
  haulRates: number[] = [];
  invoiceRatesWeightUnits: string[] = [];
  haulRatesWeightUnits: string[] = [];
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  savePunchCardCallback = () => {
    if (this.route.snapshot.queryParamMap.get('refreshPage')) {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          'refreshPage': null,
        },
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigateByUrl(`${this.router.url}&refreshPage=true`);
    }
  }

  saveShareCallback = (result: {
    jobEventShare: JobEventShare,
    jobEvent: JobEvent,
    change: string
  }) => {
    const jobEventShare = result.jobEventShare;
    const updates = pick(jobEventShare, [
      'invoiceRate', 'invoiceType', 'invoiceWeightUnit',
      'haulRate', 'haulType', 'haulWeightUnit', 'numTrucks'
    ]);
    updates['rate'] = updates['invoiceRate'];

    const jobIdx = this.driver.jobEventShares
      .findIndex(j => j.jobId === jobEventShare.jobId && j.organizationId === jobEventShare.organizationId);
    if (jobIdx > -1) { Object.assign(this.driver.jobEventShares[jobIdx], updates); }
    this.rateChanged.emit(true);
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private deviceDetectorService: DeviceDetectorService,
    public punchCardDecisionService: PunchCardDecisionService,
    public dialog: MatDialog
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnInit() {
    this.organization = this.authenticationService.getOrganization();
    this.user = this.authenticationService.user();
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  switchView(view: 'trips' | 'punch-cards'): void {
    this.view = view;
  }

  updatePunchCardData(punchCards: PunchCard[]) {
    this.driver = Object.assign(this.driver, {
      punchCards: this.driver.punchCards.map(p => {
        const matchedIndex = punchCards.findIndex(punchCard => (punchCard.id === p.id));
        if (matchedIndex > -1) {
          return punchCards[matchedIndex];
        } else {
          return p;
        }
      })
    });
  }

  approvedPunchCards(driver: Driver, jobEventShare: JobEventShare): number {
    let count = 0;
    if (driver && driver.punchCards.length) {
      let punchCardCount = driver.punchCards.map(p => {
        return (
          p.job.id === jobEventShare.jobId &&
          p.job.project.ownerOrganization === jobEventShare.organizationId &&
          p.latestDecisionStatus === 'approved'
        );
      }).filter(Boolean).length;
      count = +punchCardCount;
    }
    return count;
  }

  unapprove(jobEventShare: JobEventShare): void {
    this.approve(jobEventShare, 'pending');
  }

  approve(jobEventShare: JobEventShare, decisionStatus = 'approved'): void {
    let requests: Observable<PunchCardDecision>[] = [];
    let newStatus = false;
    let status = _find(this.keyStatus, { driverId: this.driver.id, key: jobEventShare.jobId });
    if (status) {
      status.loading = true;
    } else {
      newStatus = true;
      status = {driverId: this.driver.id, key: jobEventShare.jobId, loading: true, statuses: [] };
    }

    if (this.driver) {
      this.driver.punchCards.forEach((punchCard: PunchCard) => {
        let alreadyApproved = punchCard.latestDecisionStatus === 'approved' && decisionStatus === 'approved';
        if (
          punchCard &&
          punchCard.job.id === jobEventShare.jobId &&
          punchCard.job.project.ownerOrganization === jobEventShare.organizationId &&
          !alreadyApproved
        ) {
          punchCard.loading = true;
          let data = {
            punchcard: punchCard,
            decisionStatus: decisionStatus,
            decidedBy: this.user,
            organization: this.organization,
            decidedAt: new Date().toISOString()
          };
          requests.push(this.punchCardDecisionService.save(data));
        }
      });
    }

    this.allSubscriptionsToUnsubscribe.push(
      observableCombineLatest(requests).subscribe((decisions: PunchCardDecision[]) => {
        decisions.forEach(decision => {
          let punchCard: PunchCard = <PunchCard>_find(this.driver.punchCards, { id: decision.punchcard && decision.punchcard.id });
          if (punchCard) {
            punchCard.latestDecision = decision.id;
            punchCard.latestDecisionStatus = decision.decisionStatus;
            punchCard.latestDecider = decision.decidedBy && decision.decidedBy.id;
            punchCard.latestDeciderName = decision.decidedBy && decision.decidedBy.name;
            status.statuses = [decision.decisionStatus];
            punchCard.loading = false;
          }
        });
      }, errors => {
        console.log('Decision Errors: ', errors);
      }, () => {
        status.loading = false;
        this.driver.punchCards = [...this.driver.punchCards];
        if (newStatus) { this.keyStatus.push(status); }
        this.auditDecisions();
      })
    );
  }

  /**
   * Determines if a Job (key) is in a loading state as defined by functions
   * creating grouped requests.
   *
   * @param {string} key The Job name to consider when finding the loading state
   * @returns {boolean} Loading state for the provided Job
   */
  keyIsLoading(key: string): boolean {
    let status = _find(this.keyStatus, { driverId: this.driver.id, key: key });
    if (status && status.loading) { return status.loading; }

    return false;
  }

  auditDecisions() {
    this.shouldAuditDecisions.emit(this.driver.id);
  }

  openEditShare(change: 'invoice' | 'haul', driver: Driver, jobEventShare: JobEventShare): void {
    const dialog = this.dialog.open(EditShareDialogComponent, {
      width: '430px'
    });
    if (dialog) {
      dialog.componentInstance.change = change;
      dialog.componentInstance.callback = this.saveShareCallback;

      if (driver && jobEventShare) {
        dialog.componentInstance.jobEventShare = jobEventShare;
      }
    }
  }

  openAddPunchCard(driver: Driver, jobEventShare: JobEventShare): void {
    const dialog = this.dialog.open(NewPunchCardDialogComponent, {
      width: '455px'
    });
    const carrier = {
      id: driver.carrierId,
      name: driver.carrierOrganizationId === this.organization.id ? 'My Drivers' : driver.carrierOrganizationName,
      carrier: {
        name: driver.carrierName,
        id: driver.carrierId
      }
    };

    dialog.componentInstance.jobName = jobEventShare && jobEventShare.job ? jobEventShare.job : '';
    dialog.componentInstance.lockedFields = jobEventShare && jobEventShare.job ? true : false;
    dialog.componentInstance.firstload = jobEventShare && jobEventShare.job ? false : true;
    dialog.componentInstance.model.jobEvent = jobEventShare && jobEventShare.jobeventId ? jobEventShare.jobeventId : '';
    dialog.componentInstance.model.jobevent = jobEventShare && jobEventShare.jobeventId ? jobEventShare.jobeventId : '';

    dialog.componentInstance.model.driver = driver;
    dialog.componentInstance.model.carrier = carrier;
    dialog.componentInstance.model.truck = driver.latestTruck;
    dialog.componentInstance.jobEventDate = this.reportDate;
    // dialog.componentInstance.model.job = this.getDriverJobById(driver, jobEventShare);
    dialog.componentInstance.model.job = {
      id: jobEventShare && jobEventShare.jobId ? jobEventShare.jobId : ''
    } as Job;
    dialog.componentInstance.callback = this.savePunchCardCallback;
  }

  getDriverJobById(driver: Driver, jobEventShare: JobEventShare): void {
    let job = null;
    let trip = driver.trips
      .find(t => t.jobEvent.job.id === jobEventShare.jobId && t.jobEvent.job.project.ownerOrganization === jobEventShare.organizationId);
    if (trip && trip.jobEvent) {
      job = trip.jobEvent.job;
    } else {
      let punchCard = driver.punchCards
        .find(p => p.job.id === jobEventShare.jobId && p.job.project.ownerOrganization === jobEventShare.organizationId);
      if (punchCard && punchCard.jobEvent) {
        job = punchCard.jobEvent.job;
      }  else {
        job = {id: jobEventShare.jobId};
      }
    }
    return job;
  }
}
