"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var http_1 = require("@angular/common/http");
var resource_service_1 = require("../../shared/resource.service");
var product_items_serializer_1 = require("./product-items.serializer");
var i0 = require("@angular/core");
var i1 = require("@angular/common/http");
var ProductItemsService = /** @class */ (function (_super) {
    __extends(ProductItemsService, _super);
    function ProductItemsService(http) {
        return _super.call(this, http, 'productitems/', new product_items_serializer_1.ProductItemSerializer()) || this;
    }
    ProductItemsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ProductItemsService_Factory() { return new ProductItemsService(i0.ɵɵinject(i1.HttpClient)); }, token: ProductItemsService, providedIn: "root" });
    return ProductItemsService;
}(resource_service_1.ResourceService));
exports.ProductItemsService = ProductItemsService;
