import { Component, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material';
import { HttpParams } from '@angular/common/http';
import {
  Observable, Subscription, combineLatest as observableCombineLatest,
  timer as observableTimer
} from 'rxjs';
import { distinctUntilChanged, debounceTime } from 'rxjs/operators';
import { clone, remove, find as _find } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

import { LocationService } from '../location.service';
import { LocationTypeService } from '../location-type.service';
import { AuthenticationService } from '../../shared';
import { LocationStat } from '../location-stat';
import { CollaboratorService } from '../../collaborators/collaborator.service';
import { ExportDialogComponent, ExportDialogData } from '../../shared/export-dialog/export-dialog.component';
import { AssignmentService } from '../../assignments/assignment.service';
import { LocationType } from '../location-type';
import { FilterOption } from '../../shared/filters-panel/filter-option';
import { LocationManagerFiltersDialogComponent } from './location-manager-filters-dialog.component';

@Component({
  selector: 'location-manager',
  templateUrl: './location-manager.component.html',
  styleUrls: ['./location-manager.component.scss']
})
export class LocationManagerComponent implements OnInit, OnChanges, OnDestroy {
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();

  search = '';
  availableFilters = [
    new FilterOption({
      key: 'location_type', filterType: 'select',
      title: this.translationService.instant('Location Type'),
      service: LocationTypeService
    })
  ];
  appliedFilters = [];
  filtersDialog;

  locationStats: LocationStat[] = [];
  loading = true;
  errors = [];
  count = 0;
  view = 'cards';
  hasAllDriversEnabled = false;
  pendingJobsCount = 0;
  multipleActionDropdownOptions = [
    { name: this.translationService.instant('Export'), action: 'export' },
    { name: this.translationService.instant('Operator\'s Export'), action: 'operators_export' }
  ];
  locationTypeDropdownConfig = {
    inline: true,
    searchable: true,
    showLoading: true,
    nameProperty: 'name',
    sortBy: 'name',
    selectText: this.translationService.instant('Select Location Type'),
    loadingText: this.translationService.instant('Loading Location Types...'),
    noResultsText: this.translationService.instant('No Location Types'),
    service: LocationTypeService,
    query: {}
  };
  locationStatsTimer: Observable<number>;

  locationStatsReq: Subscription;
  pendingCountReq: Subscription;
  locationStatsTimerSub: Subscription;
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  selectedTimeRange: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private locationService: LocationService,
    private assignmentService: AssignmentService,
    private collaboratorService: CollaboratorService,
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    public dialog: MatDialog,
  ) {
    this.allSubscriptionsToUnsubscribe.push(
      this.searchChange.pipe(
        debounceTime(300), distinctUntilChanged()
      ).subscribe(search => {
        this.search = search;
        this.updateUrl({ search: this.search, page: 1 });
      })
    );
  }

  ngOnInit() {
    let query = {};
    if (this.authenticationService.hasFavoriteTags()) { query['user_tags'] = 'True'; }
    this.getPendingCount();

    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.loading = true;
        this.view = result.params['view'] || 'cards';
        this.search = result.qparams['search'] || '';

        this.locationStatsTimer = observableTimer(1, 120000);
        this.locationStatsTimerSub = this.locationStatsTimer.subscribe(t => {
          this.getLocationStats();
        });
      })
    );

    this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
    this.selectedTimeRange = 24; // 24 hours
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['search']) {
      this.searchChange.emit(changes['search'].currentValue);
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  switchView(view = 'list') {
    this.router.navigate(['/'], { skipLocationChange: true }).then(() => {
      this.router.navigate(['/locations/manager/' + view]);
    });
  }

  // NOTE: This will be necessary in the future, however, the exact implementation hasn't been completed/determiend.
  // onScroll(e) {
  //   if (!this.loading &&
  //     e.target.scrollTop > e.target.scrollHeight - e.target.clientHeight * 3) {
  //     let o = this.locationService.listNext();
  //     if (o) {
  //       this.loading = true;
  //       o.subscribe(locationStats => {
  //         this.locationStats = this.locationStats.concat(locationStats);
  //         this.loading = false;
  //       }, err => {
  //         this.errors = err;
  //       }, () => {
  //         this.loading = false;
  //       });
  //     }
  //   }
  // }

  getLocationStats(query = {}, append = false, select = null) {
    if (!append) { this.locationStats = []; }

    this.loading = true;

    if (this.locationStatsReq) { this.locationStatsReq.unsubscribe(); }

    if (this.selectedTimeRange) {
      const startDate = moment(new Date()).subtract(this.selectedTimeRange, 'hours').toISOString();
      const endDate = new Date().toISOString();
      query['trip_range'] = `${startDate},${endDate}`;
    }

    if (this.appliedFilters && this.appliedFilters.length) {
      query['filters'] = this.mergeFilters(this.appliedFilters);
    }

    this.locationStatsReq = this.locationService.averageTimeSpent({
      ordering: 'name',
      search: this.search,
      ...query
    }).subscribe(locationStats => {
      if (append) {
        this.locationStats = this.locationStats.concat(locationStats);
      } else {
        this.locationStats = locationStats;
      }
      this.count = this.locationService.count;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  getPendingCount(query = {}, append = false): void {
    this.pendingCountReq = this.collaboratorService.getPending({ page_size: 1 }).subscribe(invitations => {
      this.pendingJobsCount = this.collaboratorService.count;
    }, err => {
      this.errors = err;
    });
  }

  changeSearch(term?: string) {
    this.searchChange.emit(term || '');
  }

  menuAction(action: string): void {
    switch (action) {
      case 'export':
        this.createExport();
        break;
      case 'operators_export':
        this.createOperatorsExport();
        break;
    }
  }

  updateUrl(params) {
    params['search'] = params['search'] ? params['search'] : this.search;

    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams,
        ...params
      }
    });
  }

  createExport(): void {
    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    const filters = {
      'jobevent__shift1_start__gte': startDate && startDate.toISOString(),
      'jobevent__shift1_start__lte': endDate && endDate.toISOString()
    };

    this.assignmentService.export({}, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments'
        }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          scope: {},
          params: params,
          service: this.assignmentService,
          buttonText: this.translationService.instant('Try to Export Again')
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  createOperatorsExport() {
    let scope = {
      endpoint: 'operators'
    };

    let startDate = new Date();
    startDate.setHours(0, 0, 0, 0);
    let endDate = clone(startDate);
    endDate.setHours(23, 59, 59, 999);

    const filters = {
      'active_range': `${startDate && startDate.toISOString()},${endDate && endDate.toISOString()}`,
      'ordering': 'jobevent__job__start__location__name,jobevent__job__order_number'
    };

    this.assignmentService.export(scope, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          endpoint: 'operators'
        }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          scope: scope,
          params: params,
          service: this.assignmentService,
          buttonText: this.translationService.instant('Try to Export Again')
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  selectLocationType(locationType: LocationType, locationStat: LocationStat, userSelection = true): void {
    if (!userSelection) { return; }

    this.loading = true;
    locationStat.locationType = locationType;
    const location = { id: locationStat.id, location_type: locationType.id };
    this.locationService.save(location, {}, true).subscribe(() => {
      this.loading = false;
    }, err => {
      this.loading = false;
      this.errors = err;
    });
  }

  updateTimeTarget(locationStat: LocationStat, type: string, event) {
    this.loading = true;
    let location = { id: locationStat.id };
    switch (type) {
      case 'loading':
        location['average_loading_time'] = event.target.value;
        break;
      case 'unloading':
        location['average_unloading_time'] = event.target.value;
        break;
    }

    this.locationService.save(location, {}, true).subscribe(() => {
      this.loading = false;
    }, err => {
      this.loading = false;
      this.errors = err;
    });
  }

  openFilters() {
    const dialog = this.dialog.open(LocationManagerFiltersDialogComponent, {
      width: '430px',
      data: { model: {} }
    });
    dialog.componentInstance.callback = res => this.filterChanges(res);

    dialog.componentInstance.model = this.appliedFilters.reduce((acc, filter) => {
      if (filter.filterType === 'checkbox') {
        if (filter.values === true) { filter.values = ['True']; }
        acc[filter.key] = filter.values && filter.values[0] === 'True';
      } else if (filter.filterType === 'select') {
        acc[filter.key] = {
          id: filter.values && filter.values[0],
          name: filter.displayValues && filter.displayValues[0]
        };
      } else if (filter.filterType === 'date') {
        acc[filter.key] = filter.values;
      } else {
        acc[filter.key] = filter.value;
      }
      return acc;
    }, {});
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    let triggerRefresh = false;

    if (filterRes) {
      Object.keys(filterRes).forEach(key => {
        if (filterRes[key] && filterRes[key] !== undefined) {
          remove(this.appliedFilters, _filter => _filter.key === key);
          let filter = _find(this.availableFilters, { key: key });
          if (!filter) { return; }

          filter.values = [filterRes[key].id];
          filter.displayValues = [filterRes[key].name];

          this.appliedFilters = this.appliedFilters.concat(filter);
          triggerRefresh = true;
        }
      });
    }
    if (triggerRefresh) { this.getLocationStats(); }
    this.changeSearch(this.search);
  }

  filtersReset(): void {
    this.appliedFilters = [];
    this.search = '';
    this.getLocationStats();
  }

  removeFilter(filter): void {
    remove(this.appliedFilters, filter);
  }

  private mergeFilters(filters): string {
    return filters.map(filter => {
      if (filter.multiple && filter.values) {
        return filter.values.map(value => {
          const _value = [filter.key, value].join('=');
          return `(${_value})`;
        }).filter(Boolean).join('|');
      } else if (filter.values) {
        let values = filter.values;
        if (values === true) { values = 'True'; }
        if (values === false) { values = 'False'; }
        const _value = [filter.key, values].join('=');
        return `(${_value})`;
      }
    }).filter(Boolean).join('&');
  }

  public onChangeTimeRange(selectedHours: number) {
    this.selectedTimeRange = selectedHours;
    this.getLocationStats();
  }
}
