"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var material_1 = require("@angular/material");
var lodash_1 = require("lodash");
var router_1 = require("@angular/router");
var core_2 = require("@ngx-translate/core");
var location_service_1 = require("../../locations/location.service");
var location_serializer_1 = require("../../locations/location.serializer");
var authentication_service_1 = require("../../shared/authentication.service");
var job_service_1 = require("../job.service");
var ruckit_confirm_dialog_component_1 = require("../../shared/dialogs/ruckit-confirm-dialog/ruckit-confirm-dialog.component");
var EditSelectedJobsComponent = /** @class */ (function () {
    function EditSelectedJobsComponent(dialogRef, locationService, dialog, router, route, authenticationService, jobService, translateService, data) {
        this.dialogRef = dialogRef;
        this.locationService = locationService;
        this.dialog = dialog;
        this.router = router;
        this.route = route;
        this.authenticationService = authenticationService;
        this.jobService = jobService;
        this.translateService = translateService;
        this.data = data;
        this.editSuccess = new core_1.EventEmitter();
        this.selectedJobs = [];
        this.excludeJobs = [];
        this.weightOptions = [
            { id: 'ton', name: 'Ton' },
            { id: 'metric-tons', name: 'Metric Ton' },
            { id: 'pound', name: 'Pound' },
            { id: 'cuyds', name: 'Cubic Yard' },
            { id: 'bushel', name: 'Bushel' },
            { id: 'bag', name: 'Bag' }
        ];
        this.jobWeightOptions = lodash_1.clone(this.weightOptions);
        this.haulWeightOptions = lodash_1.clone(this.weightOptions);
        this.orderTypeOptions = [
            { id: 'tons', name: 'Tons' },
            { id: 'metric-tons', name: 'Metric Tons' },
            { id: 'tonnes', name: 'Tonnes' },
            { id: 'loads', name: 'Loads' },
            { id: 'lbs', name: 'Pounds' },
            { id: 'kgs', name: 'Kilograms' },
            { id: 'cuyds', name: 'Cubic Yards' },
            { id: 'bushels', name: 'Bushels' },
            { id: 'bags', name: 'Bags' }
        ];
        this.deliveryIntervalUnits = [{
                id: 'hours', name: 'Hours'
            }, {
                id: 'minutes', name: 'Minutes', selected: true
            }];
        this.locationsConfig = {
            selectText: this.translateService.instant('Select Location'),
            loadingText: this.translateService.instant('Loading Locations...'),
            noResultsText: this.translateService.instant('No Locations'),
            service: location_service_1.LocationService,
            query: {
                archived: 'False'
            },
            pageSize: null,
            sortDirection: 'desc'
        };
        this.locationsDropdownConfig = {
            start: { searchable: true, nameProperty: 'displayName' },
            end: { searchable: true, nameProperty: 'displayName' }
        };
        this.configOptions = [
            { name: 'Required', id: 'required' },
            { name: 'Optional', id: 'optional' },
            { name: 'Hidden', id: 'hidden' },
        ];
        this.model = {
            invoiceType: null,
            rate: null,
            invoiceWeightUnit: null,
            cloneHaulRate: undefined,
            haulType: null,
            haulRate: null,
            haulWeightUnit: null,
            dates: [],
            material: null,
            amountNeeded: null,
            totalAmountType: null,
            dailyDeliveryTarget: null,
            dailyDeliveryTargetType: null,
            deliveryInterval: null,
            deliveryIntervalUnit: null,
            startLocation: null,
            checkinOptions: {
                loadImageConfig: null,
                qrConfig: null,
                signatureImageConfig: null,
                ticketImageConfig: null,
                ticketNumberConfig: null,
                weightConfig: null
            },
            endLocation: null,
            checkoutOptions: {
                loadImageConfig: null,
                qrConfig: null,
                signatureImageConfig: null,
                ticketImageConfig: null,
                ticketNumberConfig: null,
                weightConfig: null
            },
            include: [],
            exclude: undefined,
        };
        this.archiveJob = false;
        this.errors = [];
        this.jobsCountWarningTranslationKey = '{{jobsCount}} {{jobtext}} will be edited based on changes made.';
    }
    EditSelectedJobsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.authenticationService.user();
        this.locationService.getLocationByIP().subscribe(function (res) {
            _this.defaultLocation = res;
        });
    };
    EditSelectedJobsComponent.prototype.tagUrlWith = function (fragment) {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: __assign({}, this.route.snapshot.queryParams),
            fragment: fragment
        });
    };
    EditSelectedJobsComponent.prototype.invoiceTypeChanged = function () {
        this.changeCloneHaulRate();
    };
    EditSelectedJobsComponent.prototype.changeCloneHaulRate = function () {
        if (this.model.cloneHaulRate) {
            this.model.haulWeightUnit = this.model.invoiceWeightUnit;
            this.model.haulType = this.model.invoiceType;
            this.model.haulRate = this.model.rate;
        }
    };
    EditSelectedJobsComponent.prototype.weightUnitChange = function (tracking, unit) {
        this.model[tracking + 'WeightUnit'] = unit.id;
        if (tracking === 'invoice') {
            this.changeCloneHaulRate();
        }
    };
    EditSelectedJobsComponent.prototype.onDateChanged = function (values) {
        this.model.dates = values;
    };
    EditSelectedJobsComponent.prototype.changeTotalAmountType = function (type) {
        this.model.totalAmountType = type.id;
    };
    EditSelectedJobsComponent.prototype.changeDailyDeliveryTargetType = function (type) {
        this.model.dailyDeliveryTargetType = type.id;
    };
    EditSelectedJobsComponent.prototype.changeLocation = function (location, place) {
        this.model[place + 'Location'] = location;
    };
    EditSelectedJobsComponent.prototype.getPaths = function (which) {
        var paths = [];
        var location = (new location_serializer_1.LocationSerializer).fromJson(which === 'start' ? this.model.startLocation : this.model.endLocation);
        if (location && location.geofence) {
            paths = location.geofence.coordinates[0].map(function (path) {
                return { lat: path[1], lng: path[0] };
            });
        }
        return [paths];
    };
    EditSelectedJobsComponent.prototype.getCoord = function (which, index) {
        var defaultCoords = [
            lodash_1.get(this.defaultLocation, 'longitude', -97.6023238),
            lodash_1.get(this.defaultLocation, 'latitude', 30.2178214)
        ];
        if (which === 'start') {
            return lodash_1.get(this.model.startLocation, 'location.coordinates[' + index + ']', defaultCoords[index]);
        }
        else {
            return lodash_1.get(this.model.endLocation, 'location.coordinates[' + index + ']', defaultCoords[index]);
        }
    };
    EditSelectedJobsComponent.prototype.setDeliveryInterval = function () {
        if (!this.model.deliveryIntervalUnit && (this.model.deliveryInterval || this.model.deliveryInterval === 0)) {
            this.model.deliveryIntervalUnit = this.deliveryIntervalUnits[1].id;
        }
    };
    EditSelectedJobsComponent.prototype.isSubmitBtnDisabled = function (editJobsForm) {
        var _this = this;
        var hasCheckinOptions = Object.keys(this.model.checkinOptions)
            .findIndex(function (k) { return _this.model.checkinOptions[k] !== null; }) > -1 ? true : false;
        var hasCheckoutOptions = Object.keys(this.model.checkoutOptions)
            .findIndex(function (k) { return _this.model.checkoutOptions[k] !== null; }) > -1 ? true : false;
        return ((editJobsForm.untouched || !editJobsForm.dirty) &&
            this.model.dates.length === 0 && !this.model.startLocation &&
            !this.model.endLocation && !hasCheckinOptions &&
            !hasCheckoutOptions && !this.archiveJob);
    };
    EditSelectedJobsComponent.prototype.submit = function () {
        var _this = this;
        var confirmDialog = this.dialog.open(ruckit_confirm_dialog_component_1.RuckitConfirmDialogComponent, {
            width: '430px',
            height: '250px'
        });
        var message = this.translateService.instant('{{jobsCount}} {{jobsText}} will be edited with the changes made. Are you sure you want to make these changes?', {
            jobsCount: this.allJobsCount,
            jobsText: this.allJobsCount > 1 ? 'jobs' : 'job'
        });
        confirmDialog.componentInstance.attributes = {
            title: this.translateService.instant('Save edits?'),
            body: message,
            close: this.translateService.instant('Cancel'),
            accept: this.translateService.instant('Save'),
            isErrorBtn: true
        };
        confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                var model = __assign({}, _this.model);
                if (_this.isAllJobsSelected) {
                    model.include = null;
                    model.exclude = _this.excludeJobs.map(function (job) { return job.id; });
                }
                else {
                    model.include = _this.selectedJobs.map(function (job) { return job.id; });
                }
                if (model.checkinOptions) {
                    _this.removeEmptyValuesFromObject(model.checkinOptions);
                    if (Object.keys(model.checkinOptions).length === 0) {
                        delete model.checkinOptions;
                    }
                }
                if (model.checkoutOptions) {
                    _this.removeEmptyValuesFromObject(model.checkoutOptions);
                    if (Object.keys(model.checkoutOptions).length === 0) {
                        delete model.checkoutOptions;
                    }
                }
                if (_this.archiveJob) {
                    _this.query['archived'] = true;
                }
                else {
                    if (_this.query.hasOwnProperty('archived')) {
                        delete _this.query['archived'];
                    }
                }
                _this.jobService.bulkUpdate(model, _this.query).subscribe(function (response) {
                    _this.editSuccess.emit();
                    _this.dialog.closeAll();
                }, function (err) {
                    _this.errors = [err];
                });
            }
        });
    };
    EditSelectedJobsComponent.prototype.removeEmptyValuesFromObject = function (obj) {
        Object.keys(obj).forEach(function (k) {
            if (obj[k] === null || obj[k] === undefined || obj[k] === '') {
                delete obj[k];
            }
        });
        return obj;
    };
    EditSelectedJobsComponent.prototype.ngOnDestroy = function () {
        this.selectedJobs = [];
        this.allJobsCount = null;
        this.excludeJobs = [];
    };
    return EditSelectedJobsComponent;
}());
exports.EditSelectedJobsComponent = EditSelectedJobsComponent;
