"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var driver_assignment_1 = require("./driver-assignment");
var trip_1 = require("../trips/trip");
var truck_serializer_1 = require("../trucks/truck.serializer");
var assignment_serializer_1 = require("./assignment.serializer");
var lodash_1 = require("lodash");
var resizer_1 = require("../images/resizer");
var job_event_1 = require("../job-events/job-event");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var decamelizeKeysDeep = require('decamelize-keys-deep');
var DriverAssignmentSerializer = /** @class */ (function () {
    function DriverAssignmentSerializer() {
    }
    /**
    * @param  {any} json
    * @returns DriverAssignment
    */
    DriverAssignmentSerializer.prototype.fromJson = function (json) {
        json = camelcaseKeysDeep(json);
        var driverAssignment = new driver_assignment_1.DriverAssignment();
        if (!json) {
            return driverAssignment;
        }
        driverAssignment.id = json.id;
        driverAssignment.name = json.name;
        driverAssignment.organization = json.organization;
        driverAssignment.organizationId = json.organizationId;
        if (json.assignments && json.assignments instanceof Array) {
            driverAssignment.assignments = json.assignments.map(function (assignment) { return new assignment_serializer_1.AssignmentSerializer().fromJson(assignment); });
            driverAssignment.assignmentCount = lodash_1.filter(driverAssignment.assignments, { completed: false }).length;
        }
        else {
            driverAssignment.assignments = [];
        }
        driverAssignment.hasActiveShift = json.hasActiveShift;
        if (json.activeTrips && json.activeTrips instanceof Array && json.activeTrips.length > 0) {
            driverAssignment.activeTrips = json.activeTrips.map(function (trip) { return new trip_1.Trip(Object.assign(trip, { currentTrip: true })); });
            var trips = lodash_1.sortBy(driverAssignment.activeTrips, 'startTime');
            driverAssignment.latestTrip = trips[0];
        }
        else {
            driverAssignment.activeTrips = [];
            driverAssignment.latestTrip = new trip_1.Trip({});
        }
        if (json.activeGeotrips && json.activeGeotrips instanceof Array && json.activeGeotrips.length > 0) {
            driverAssignment.activeGeoTrips = json.activeGeotrips.map(function (trip) { return new trip_1.Trip(Object.assign(trip, { currentGeoTrip: true })); });
            var trips = lodash_1.sortBy(driverAssignment.activeGeoTrips, 'startTime');
            driverAssignment.latestGeoTrip = trips[0];
        }
        else {
            driverAssignment.activeGeoTrips = [];
            driverAssignment.latestGeoTrip = new trip_1.Trip({});
        }
        if (typeof (json.latestTruck) === 'object') {
            driverAssignment.latestTruck = (new truck_serializer_1.TruckSerializer().fromJson(json.latestTruck));
        }
        else {
            driverAssignment.latestTruck = (new truck_serializer_1.TruckSerializer().fromJson({ id: json.latestTruck }));
        }
        driverAssignment.invoiceTotal = json.invoiceTotal;
        driverAssignment.expenseTotal = json.expenseTotal;
        driverAssignment.loadCount = json.loadCount;
        driverAssignment.assignmentAccepted = json.assignmentAccepted;
        driverAssignment.driverStatus = json.driverStatus;
        driverAssignment.driverRejectionNote = json.driverRejectionNote;
        if (json.kind === 'job') {
            driverAssignment.numberOfLoadsType = 'allDay';
        }
        else if (json.kind === 'load') {
            if (json.maxNumberOfLoads === 0 || json.maxNumberOfLoads === null) {
                driverAssignment.numberOfLoadsType = 'allDay';
            }
            else {
                driverAssignment.numberOfLoadsType = 'numbered';
            }
        }
        else {
            driverAssignment.numberOfLoadsType = json.maxNumberOfLoads === 0 ? 'allDay' : 'numbered';
        }
        if (!json.maxNumberOfLoads) {
            // driverAssignment.maxNumberOfLoads = driverAssignment.assignments, { completed: false }).length;
        }
        else {
            driverAssignment.maxNumberOfLoads = json.maxNumberOfLoads;
        }
        driverAssignment.numberOfLoadsLeft = json.numberOfLoadsLeft;
        driverAssignment.completed = json.completed;
        driverAssignment.kind = json.kind;
        driverAssignment.driverRequireConfirmation = json.driverRequireConfirmation;
        driverAssignment.image = json.image;
        driverAssignment.imageKey = json.imageKey;
        driverAssignment.trips = [];
        driverAssignment.geoTrips = [];
        driverAssignment.activeJobs = [];
        driverAssignment.notes = json.notes;
        if (driverAssignment.activeTrips && Array.isArray(driverAssignment.activeTrips)) {
            driverAssignment.activeTrips.forEach(function (trip) {
                if (trip && trip.job) {
                    driverAssignment.activeJobs.push(trip.job);
                }
            });
        }
        if (json.assignments && json.assignments.length) {
            json.assignments.forEach(function (assignment) {
                if (assignment && assignment['completedTrips'] instanceof Array) {
                    driverAssignment.totalTrips += assignment['completedTrips'].length;
                    driverAssignment.trips = driverAssignment.trips.concat(assignment['completedTrips'].map(function (trip) {
                        return new trip_1.Trip(Object.assign(trip, { job: assignment.job, jobEvent: assignment.jobevent }));
                    }));
                }
                if (assignment && assignment['completedGeotrips'] instanceof Array) {
                    driverAssignment.totalGeoTrips += assignment['completedGeotrips'].length;
                    driverAssignment.geoTrips = driverAssignment.geoTrips.concat(assignment['completedGeotrips'].map(function (trip) {
                        return new trip_1.Trip(Object.assign(trip, { job: assignment.job, jobEvent: assignment.jobevent }));
                    }));
                }
                if (assignment && assignment['id'] === driverAssignment.latestTrip.assignment) {
                    driverAssignment.latestTrip.job = assignment.job;
                    driverAssignment.latestTrip.jobEvent = assignment.jobevent;
                    driverAssignment.latestGeoTrip.job = assignment.job;
                    driverAssignment.latestGeoTrip.jobEvent = assignment.jobevent;
                }
            });
        }
        driverAssignment.trips = lodash_1.orderBy(driverAssignment.trips, 'startTimeTimestamp').reverse();
        driverAssignment.trips.forEach(function (trip, index) {
            var currentIndex = driverAssignment.trips.length - index;
            trip.index = currentIndex;
            driverAssignment.totalTrips += 1;
        });
        driverAssignment.geoTrips = lodash_1.orderBy(driverAssignment.geoTrips, 'startTimeTimestamp').reverse();
        driverAssignment.geoTrips.forEach(function (trip, index) {
            var currentIndex = driverAssignment.geoTrips.length - index;
            trip.index = currentIndex;
            driverAssignment.totalGeoTrips += 1;
        });
        // listImage
        if (driverAssignment.image && driverAssignment.imageKey) {
            driverAssignment.listImage = resizer_1.ImageResizer.getResizedUrl(driverAssignment.imageKey, 52, 52);
        }
        // status
        if (driverAssignment.driverRequireConfirmation) {
            driverAssignment.status = 'Pending';
        }
        else if (!driverAssignment.driverStatus) {
            driverAssignment.status = 'Waiting';
        }
        else {
            driverAssignment.status = driverAssignment.driverStatus;
        }
        // tripStatus
        var status = 'Waiting';
        if (driverAssignment.latestTrip) {
            switch (driverAssignment.latestTrip.tripStatus) {
                case 'enroute_loading':
                    status = 'Enroute Loading';
                    break;
                case 'loading':
                    status = 'Loading';
                    break;
                case 'enroute_unloading':
                    status = 'Enroute Unloading';
                    break;
                case 'unloading':
                    status = 'Unloading';
                    break;
                case 'paused':
                    status = 'Paused';
                    break;
                case 'loading_complete':
                    status = 'Loading Complete';
                    break;
                case 'unloading_complete':
                    status = 'Unloading Complete';
                    break;
                case 'waiting_to_load':
                    status = 'Waiting to Load';
                    break;
                case 'active_loading':
                    status = 'Active Loading';
                    break;
                case 'waiting_to_unload':
                    status = 'Waiting to Unload';
                    break;
                case 'active_unloading':
                    status = 'Active Unloading';
                    break;
                default:
                    status = '';
                    break;
            }
        }
        driverAssignment.tripStatus = status;
        // geoTripStatus
        var geostatus = 'Waiting';
        if (driverAssignment.latestGeoTrip) {
            switch (driverAssignment.latestGeoTrip.tripStatus) {
                case 'enroute_loading':
                    geostatus = 'Enroute Loading';
                    break;
                case 'loading':
                    geostatus = 'Loading';
                    break;
                case 'enroute_unloading':
                    geostatus = 'Enroute Unloading';
                    break;
                case 'unloading':
                    geostatus = 'Unloading';
                    break;
                case 'paused':
                    geostatus = 'Paused';
                    break;
                case 'loading_complete':
                    geostatus = 'Loading Complete';
                    break;
                case 'unloading_complete':
                    geostatus = 'Unloading Complete';
                    break;
                case 'waiting_to_load':
                    geostatus = 'Waiting to Load';
                    break;
                case 'active_loading':
                    geostatus = 'Active Loading';
                    break;
                case 'waiting_to_unload':
                    geostatus = 'Waiting to Unload';
                    break;
                case 'active_unloading':
                    geostatus = 'Active Unloading';
                    break;
                default:
                    break;
            }
        }
        driverAssignment.geoTripStatus = geostatus;
        // statusClass
        // red=rejected; yellow=pending,read; green=confirmed; grey=no-assignment
        var statusClass = 'grey-status';
        var incompleteAssignmnets = lodash_1.filter(driverAssignment.assignments, { completed: false });
        if (lodash_1.find(incompleteAssignmnets, { driverStatus: 'rejected' })) {
            statusClass = 'red-status';
        }
        else if (lodash_1.find(incompleteAssignmnets, { driverStatus: 'pending' })) {
            statusClass = 'yellow-status';
        }
        else if (lodash_1.find(incompleteAssignmnets, { driverStatus: 'read' })) {
            statusClass = 'yellow-status';
        }
        else if (lodash_1.find(incompleteAssignmnets, { driverStatus: 'accepted' })) {
            statusClass = 'green-status';
        }
        else if (lodash_1.find(incompleteAssignmnets, { driverStatus: 'confirmed' })) {
            statusClass = 'green-status';
        }
        driverAssignment.statusClass = statusClass;
        // statusIcon
        var iconClass = '';
        switch (driverAssignment.driverStatus) {
            case 'read':
                iconClass = 'icon-read';
                break;
            case 'accepted':
                iconClass = 'icon-accepted';
                break;
        }
        driverAssignment.statusIcon = iconClass;
        if (driverAssignment.latestTrip) {
            var loadingEnRouteComplete = driverAssignment.latestTrip.loadingTime ? true : false;
            var loadingEnRouteInProgress = driverAssignment.latestTrip.loadingEnRouteTime && !loadingEnRouteComplete ? true : false;
            driverAssignment.enRoute1 = {
                value: driverAssignment.latestTrip.loadingEnRouteTime,
                inProgress: loadingEnRouteInProgress,
                complete: loadingEnRouteComplete
            };
            var loadingComplete = driverAssignment.latestTrip.loadingCompleteTime ? true : false;
            var loadingInProgress = driverAssignment.latestTrip.loadingTime && !loadingComplete ? true : false;
            driverAssignment.tripLoading = {
                value: driverAssignment.latestTrip.loadingTime,
                inProgress: loadingInProgress,
                complete: loadingComplete
            };
            var unloadingEnRouteComplete = driverAssignment.latestTrip.unloadingTime ? true : false;
            var unloadingEnRouteInProgress = driverAssignment.latestTrip.unloadingEnRouteTime && !unloadingEnRouteComplete ? true : false;
            driverAssignment.enRoute2 = {
                value: driverAssignment.latestTrip.unloadingEnRouteTime,
                inProgress: unloadingEnRouteInProgress,
                complete: unloadingEnRouteComplete
            };
            var unloadingComplete = driverAssignment.latestTrip.unloadingCompleteTime ? true : false;
            var unloadingInProgress = driverAssignment.latestTrip.unloadingTime && !unloadingComplete ? true : false;
            driverAssignment.tripUnloading = {
                value: driverAssignment.latestTrip.unloadingTime,
                inProgress: unloadingInProgress,
                complete: unloadingComplete
            };
            var durationValue = driverAssignment.enRoute1.inProgress && !driverAssignment.enRoute1.complete ?
                driverAssignment.enRoute1.value :
                driverAssignment.tripLoading.inProgress && !driverAssignment.tripLoading.complete ?
                    driverAssignment.tripLoading.value :
                    driverAssignment.enRoute2.inProgress && !driverAssignment.enRoute2.complete ?
                        driverAssignment.enRoute2.value :
                        driverAssignment.tripUnloading.inProgress && !driverAssignment.tripUnloading.complete ?
                            driverAssignment.tripUnloading.value : '0';
            driverAssignment.duration = {
                value: durationValue.includes('hrs') ?
                    Number(durationValue.split(' hrs, ')[0]) * 60 +
                        Number(durationValue.replace(' mins', '').slice(-2)) :
                    Number(durationValue.replace(' mins', '').slice(-2))
            };
        }
        else {
            driverAssignment.enRoute1 = { value: '', inProgress: false, complete: false };
            driverAssignment.tripLoading = { value: '', inProgress: false, complete: false };
            driverAssignment.enRoute2 = { value: '', inProgress: false, complete: false };
            driverAssignment.tripUnloading = { value: '', inProgress: false, complete: false };
            driverAssignment.duration = { value: 0 };
        }
        // duration status data
        if (driverAssignment.latestGeoTrip) {
            var loadingEnRouteComplete = driverAssignment.latestGeoTrip.loadingTime ? true : false;
            var loadingEnRouteInProgress = driverAssignment.latestGeoTrip.loadingEnRouteTime && !loadingEnRouteComplete ? true : false;
            driverAssignment.geoEnRoute1 = {
                value: driverAssignment.latestGeoTrip.loadingEnRouteTime,
                inProgress: loadingEnRouteInProgress,
                complete: loadingEnRouteComplete
            };
            var loadingComplete = driverAssignment.latestGeoTrip.loadingCompleteTime ? true : false;
            var loadingInProgress = driverAssignment.latestGeoTrip.loadingTime && !loadingComplete ? true : false;
            driverAssignment.geoTripLoading = {
                value: driverAssignment.latestGeoTrip.loadingTime,
                inProgress: loadingInProgress,
                complete: loadingComplete
            };
            var unloadingEnRouteComplete = driverAssignment.latestGeoTrip.unloadingTime ? true : false;
            var unloadingEnRouteInProgress = driverAssignment.latestGeoTrip.unloadingEnRouteTime && !unloadingEnRouteComplete ? true : false;
            driverAssignment.geoEnRoute2 = {
                value: driverAssignment.latestGeoTrip.unloadingEnRouteTime,
                inProgress: unloadingEnRouteInProgress,
                complete: unloadingEnRouteComplete
            };
            var unloadingComplete = driverAssignment.latestGeoTrip.unloadingCompleteTime ? true : false;
            var unloadingInProgress = driverAssignment.latestGeoTrip.unloadingTime && !unloadingComplete ? true : false;
            driverAssignment.geoTripUnloading = {
                value: driverAssignment.latestGeoTrip.unloadingTime,
                inProgress: unloadingInProgress,
                complete: unloadingComplete
            };
            var durationValue = driverAssignment.geoEnRoute1.inProgress && !driverAssignment.geoEnRoute1.complete ?
                driverAssignment.geoEnRoute1.value :
                driverAssignment.geoTripLoading.inProgress && !driverAssignment.geoTripLoading.complete ?
                    driverAssignment.geoTripLoading.value :
                    driverAssignment.geoEnRoute2.inProgress && !driverAssignment.geoEnRoute2.complete ?
                        driverAssignment.geoEnRoute2.value :
                        driverAssignment.geoTripUnloading.inProgress && !driverAssignment.geoTripUnloading.complete ?
                            driverAssignment.geoTripUnloading.value : '0';
            driverAssignment.geoDuration = {
                value: durationValue.includes('days') ?
                    Number(durationValue.split(' days, ')[0]) * 1440 +
                        Number(durationValue.split(' hrs, ')[0].substr(-2)) * 60 +
                        Number(durationValue.replace(' mins', '').slice(-2)) :
                    durationValue.includes('hrs') ?
                        Number(durationValue.split(' hrs, ')[0]) * 60 +
                            Number(durationValue.replace(' mins', '').slice(-2)) :
                        Number(durationValue.replace(' mins', '').slice(-2))
            };
        }
        else {
            driverAssignment.geoEnRoute1 = { value: '', inProgress: false, complete: false };
            driverAssignment.geoTripLoading = { value: '', inProgress: false, complete: false };
            driverAssignment.geoEnRoute2 = { value: '', inProgress: false, complete: false };
            driverAssignment.geoTripUnloading = { value: '', inProgress: false, complete: false };
            driverAssignment.geoDuration = { value: 0 };
        }
        // load
        if (driverAssignment.loadCount === 1) {
            driverAssignment.load = driverAssignment.loadCount + ' load';
        }
        else {
            driverAssignment.load = driverAssignment.loadCount + ' loads';
        }
        // job
        if (driverAssignment.latestTrip && driverAssignment.latestTrip.assignment) {
            var assignment = lodash_1.find(driverAssignment.assignments, { id: driverAssignment.latestTrip.assignment.toString() });
            driverAssignment.job = assignment && assignment.job && assignment.job.name;
        }
        else {
            driverAssignment.job = '';
        }
        // jobId
        if (driverAssignment.latestTrip && driverAssignment.latestTrip.assignment) {
            var assignment = lodash_1.find(driverAssignment.assignments, { id: driverAssignment.latestTrip.assignment.toString() });
            driverAssignment.jobId = assignment && assignment.job && assignment.job.id;
        }
        else {
            driverAssignment.jobId = '';
        }
        // jobEvent
        if (driverAssignment.latestTrip && driverAssignment.latestTrip.assignment) {
            var assignment = lodash_1.find(driverAssignment.assignments, { id: driverAssignment.latestTrip.assignment.toString() });
            if (assignment && assignment.job) {
                driverAssignment.jobEvent = new job_event_1.JobEvent(assignment.jobevent);
            }
            else {
                driverAssignment.jobEvent = '';
            }
        }
        else {
            driverAssignment.jobEvent = '';
        }
        if (driverAssignment.jobEvent) {
            driverAssignment.jobDisplayName = [
                driverAssignment.jobEvent.externalIdentifier,
                driverAssignment.jobEvent.jobName
            ].filter(Boolean).join(': ');
        }
        else if (driverAssignment.job) {
            driverAssignment.jobDisplayName = driverAssignment.job.name;
        }
        // Active Location
        // NOTE: this is a hacky temp solution to map the correct active location to the current assignment
        if (driverAssignment.latestTrip && driverAssignment.latestTrip.job) {
            if (driverAssignment.tripStatus === 'Loading') {
                driverAssignment.activeLocation = driverAssignment.latestTrip.job.startLocation;
            }
            else if (driverAssignment.geoTripStatus === 'Unloading') {
                driverAssignment.activeLocation = driverAssignment.latestTrip.job.endLocation;
            }
        }
        if (driverAssignment.latestGeoTrip && driverAssignment.latestGeoTrip.job) {
            if (driverAssignment.geoTripStatus === 'Loading') {
                driverAssignment.activeLocation = driverAssignment.latestGeoTrip.job.startLocation;
            }
            else if (driverAssignment.geoTripStatus === 'Unloading') {
                driverAssignment.activeLocation = driverAssignment.latestGeoTrip.job.endLocation;
            }
        }
        driverAssignment.allDecisionsApproved = json.allDecisionsApproved || false;
        driverAssignment.allTripsApproved = json.allTripsApproved || [];
        return driverAssignment;
    };
    /**
    * @param  {DriverAssignment} driverAssignment
    * @returns any
    */
    DriverAssignmentSerializer.prototype.toJson = function (driverAssignment) {
        var json = {
            id: driverAssignment.id,
            name: driverAssignment.name,
            organization: driverAssignment.organization,
            activeJobs: driverAssignment.activeJobs,
            latestTruck: driverAssignment.latestTruck && driverAssignment.latestTruck.id,
            assignments: driverAssignment.assignments,
            assignmentCount: driverAssignment.assignmentCount,
            hasActiveShift: driverAssignment.hasActiveShift,
            activeTrips: driverAssignment.activeTrips,
            latestTrip: driverAssignment.latestTrip && driverAssignment.latestTrip.id,
            trips: driverAssignment.trips,
            invoiceTotal: driverAssignment.invoiceTotal,
            expenseTotal: driverAssignment.expenseTotal,
            loadCount: driverAssignment.loadCount,
            assignmentAccepted: driverAssignment.assignmentAccepted,
            driverStatus: driverAssignment.driverStatus,
            driverRejectionNote: driverAssignment.driverRejectionNote,
            maxNumberOfLoads: driverAssignment.maxNumberOfLoads || 0,
            completed: driverAssignment.completed,
            kind: driverAssignment.kind,
            driverRequireConfirmation: driverAssignment.driverRequireConfirmation,
            totalTrips: driverAssignment.totalTrips,
            image: driverAssignment.image,
            imageKey: driverAssignment.imageKey,
            notes: driverAssignment.notes,
            numberOfLoadsLeft: driverAssignment.numberOfLoadsLeft
        };
        return decamelizeKeysDeep(json);
    };
    return DriverAssignmentSerializer;
}());
exports.DriverAssignmentSerializer = DriverAssignmentSerializer;
