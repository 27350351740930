import { Component, OnInit, Output, EventEmitter, ViewChild, TemplateRef, OnDestroy } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { pull, difference, get, find as _find, uniqBy, findIndex, remove } from 'lodash';
import { SelectionModel } from '@angular/cdk/collections';
import { combineLatest as observableCombineLatest, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { CondensedTripService } from '../condensed-trip.service';
import { ExportDialogComponent, ExportDialogData, FieldOption } from '../../shared/export-dialog/export-dialog.component';
import { TripFiltersDialogComponent } from '../trip-filters-dialog.component';
import { ViewSignaturesDialogComponent } from '../../checkins/view-signatures-dialog.component';
import { RuckitConfirmDialogComponent } from '../../shared/dialogs/index';
import { AuthenticationService } from '../../shared/authentication.service';
import { FilterOption } from '../../shared/filters-panel/filter-option';
import { ColumnToggleComponent } from '../../shared/column-toggle/column-toggle.component';
import { FancyTableComponent } from '../../shared/fancy-table/fancy-table.component';
import { RuckitCondensedTrip } from '../ruckit-condensed-trip';
import { TripService } from '../trip.service';
import { DriverContextEvent } from '../../drivers/driver-context-menu/interfaces/driver-context-event';
import { TRIPSIGNATUREIMAGETYPE } from '../../app.constants';

// utils
import { AppUtilities } from '../../shared/app-utilities';

@Component({
  selector: 'ruckit-condensed-trips',
  templateUrl: './condensed-trips.component.html',
  styleUrls: ['./condensed-trips.component.scss']
})
export class CondensedTripsComponent implements OnInit, OnDestroy {
  availableColumns = [
    { key: 'select' },
    { key: 'job-date', title: this.translationService.instant('Job Date'), sortable: true, sortBy: 'jobevent__shift1_start' },
    { key: 'job', title: this.translationService.instant('Job'), sortable: true, sortBy: 'jobevent__job__name' },
    { key: 'job-number', title: this.translationService.instant('Job #'), sortable: false },
    { key: 'phase-code', title: this.translationService.instant('Phase Code'), sortable: false },
    { key: 'load-ticket-number', title: this.translationService.instant('Load Ticket #'), sortable: false },
    { key: 'load-ticket-weight', title: this.translationService.instant('Load Wt'), sortable: false },
    { key: 'load-ticket-image', title: this.translationService.instant('Load Ticket'), sortable: false },
    { key: 'load-signature-image', title: this.translationService.instant('Load Signature'), sortable: false },
    { key: 'unload-ticket-number', title: this.translationService.instant('Unload Ticket #'), sortable: false },
    { key: 'unload-ticket-weight', title: this.translationService.instant('Unload Wt'), sortable: false },
    { key: 'unload-ticket-image', title: this.translationService.instant('Unload Ticket'), sortable: false },
    { key: 'unload-signature-image', title: this.translationService.instant('Unload Signature'), sortable: false },
    { key: 'carrier', title: this.translationService.instant('Carrier'), sortable: true, sortBy: 'driver__carrier__name' },
    { key: 'driver', title: this.translationService.instant('Driver'),
      sortable: true, sortBy: 'driver__profile__last_name,driver__profile__first_name' },
    { key: 'truck', title: this.translationService.instant('Truck'), sortable: true, sortBy: 'truck__name' },
    { key: 'time', title: this.translationService.instant('Time'), sortable: false },
    { key: 'duration', title: this.translationService.instant('Duration'), sortable: false },
    { key: 'payable-to', title: this.translationService.instant('Payable To'),
      sortable: true, sortBy: 'jobevent__owner_organization__name' },
    { key: 'customer', title: this.translationService.instant('Customer'),
      sortable: true, sortBy: 'jobevent__customer_organization__name' },
    { key: 'invoice', title: this.translationService.instant('Invoice Rate'), sortable: false },
    { key: 'invoice-total', title: this.translationService.instant('Invoice Total'), sortable: false },
    { key: 'haul', title: this.translationService.instant('Haul Rate'), sortable: false },
    { key: 'haul-total', title: this.translationService.instant('Haul Total'), sortable: false },
    { key: 'invoice-number', title: this.translationService.instant('Invoice #'), sortable: false },
    { key: 'expense-title', title: this.translationService.instant('Expense'), sortable: false },
    { key: 'paystub-title', title: this.translationService.instant('Paystub'), sortable: false },
    { key: 'billing-id', title: this.translationService.instant('Billing ID'), sortable: false },
    { key: 'reviewed-status', title: this.translationService.instant('Reviewed Status'), sortable: false },
    { key: 'reviewed-by', title: this.translationService.instant('Reviewed By'), sortable: false },
    { key: 'connex-paver-start-unloading', title: this.translationService.instant('Paver Start Unloading'), sortable: false },
    { key: 'connex-paver-end-unloading', title: this.translationService.instant('Paver End Unloading'), sortable: false },
    { key: 'loading-location', title: this.translationService.instant('Job Loading Location'), sortable: false },
    { key: 'geo-loading-location', title: this.translationService.instant('Geo Loading Location'), sortable: false },
    { key: 'unloading-location', title: this.translationService.instant('Job Unloading Location'), sortable: false },
    { key: 'geo-unloading-location', title: this.translationService.instant('Geo Unloading Location'), sortable: false },
    { key: 'actions', title: this.translationService.instant('Actions'), sortable: false }
  ];
  displayedColumns = [
    'select', 'job-date', 'job', 'job-number', 'load-ticket-number', 'load-ticket-weight',
    'load-ticket-image', 'load-signature-image', 'unload-ticket-number',
    'unload-ticket-weight', 'unload-ticket-image', 'unload-signature-image',
    'carrier', 'driver', 'truck', 'time', 'duration', 'payable-to', 'customer',
    'invoice', 'invoice-total', 'haul', 'haul-total', 'invoice-number',
    'expense-title', 'paystub-title', 'billing-id', 'reviewed-status',
    'reviewed-by', 'actions'
  ];
  availableFilters: any[] = [];
  appliedFilters: any[] = [];
  appliedFilterQueryKeys = {
    customer: 'jobevent__customer_organization',
    project: 'jobevent__job__project',
    payableTo: 'jobevent__owner_organization',
    job: 'jobevent__job',
    jobNumber: 'jobevent__job__job_number__icontains',
    driver: 'driver',
    truck: 'truck',
    startDate: 'jobevent__shift1_start__gte',
    endDate: 'jobevent__shift1_start__lte',
    edited: 'edited',
    incomplete: 'completed',
    retake: 'retake_status',
    carrier: 'driver__carrier',
    duplicates: 'only_dupes',
    uninvoiced: 'uninvoiced',
    missedAssignedLocations: 'missed_assigned_locations',
    unvoid: 'void'
  };
  search = '';
  loading = true;
  allSelected = false;
  checkingDuplicates = false;
  selectedTrips: RuckitCondensedTrip[] = [];
  excludeTrips: RuckitCondensedTrip[] = [];
  enabledFeatures: string[] = [];
  selectedCount = 0;
  signatureImageType = TRIPSIGNATUREIMAGETYPE;
  filtersDialog: TripFiltersDialogComponent;
  confirmDialog: MatDialogRef<any>;
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();

  errors = [];
  tableConfig = {
    hasHeader: true,
    hasCustomFields: true,
    filterQuery: false,
    service: CondensedTripService,
    preferenceKey: 'CondensedTripsComponent-CondensedTripService',
    query: {
      serializer: 'AllTrips',
      exclude_cf: true
    },
    collectionTitle: this.translationService.instant('Trips'),
    noResultsText: this.translationService.instant('a trip'),
    newRecordRoute: ['/trips/new'],
    sortBy: '-start_time',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false, returnTo: '/trips' },
      { name: this.translationService.instant('Void'), action: 'void', link: false, external: false },
      { name: this.translationService.instant('Unvoid'), action: 'unvoid', link: false, external: false },
      { name: this.translationService.instant('Details'), action: '', link: true, external: false, returnTo: '/trips' }
    ]
  };
  multipleActionDropdownOptions = [
    { name: this.translationService.instant('Export'), action: 'export', link: false },
    { name: this.translationService.instant('Void'), action: 'void', link: false }
  ];
  lockedExportFields = null;

  /**
   * Template reference for the FancyTable columns.
   */
  @ViewChild('columnTemplates', { static: false }) columnTemplates: TemplateRef<any>;
  /**
   * Template reference for the ColumnToggle component.
   */
  @ViewChild('columnToggle', { static: false }) columnToggle: ColumnToggleComponent;
  /**
   * Template reference for the FancyTable component.
   */
  @ViewChild('tripsTable', { static: false }) tripsTable: FancyTableComponent;

  contextMenuEventSubject = new Subject<DriverContextEvent>();
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  viewSignaturesCallback = () => {
    // Update Signature Status Icon
  }
  exportCallback = () => {
    this.allSelected = false;
    this.selectedCount = 0;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private tripService: TripService,
    private authenticationService: AuthenticationService,
    private translationService: TranslateService,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.loading = true;
        this.search = result.qparams['search'] || '';
      })
    );

    this.enabledFeatures = this.authenticationService.enabledFeatures();

    if (this.enabledFeatures.includes('hasTicketQcVt')) {
      this.availableColumns.splice(-1, 0, ...[
        { key: 'plant-qc', title: this.translationService.instant('Plant QC'), sortable: false },
        { key: 'plant-vt', title: this.translationService.instant('Plant VT'), sortable: false },
        { key: 'roadway-qc', title: this.translationService.instant('Roadway QC'), sortable: false },
        { key: 'roadway-vt', title: this.translationService.instant('Roadway VT'), sortable: false }
      ]);
    }

    if (this.enabledFeatures.includes('tripExportFields')) {
      this.lockedExportFields = this.authenticationService.getFeature('tripExportFields');
      this.multipleActionDropdownOptions.push(
        { name: 'Spectrum Export', action: 'locked-export', link: false }
      );
    }

    if (this.appliedFilters.length === 0) {
      let startDate = moment();
      const tripDays = this.authenticationService.getFeature('hasDefaultStartFilterForTrips');
      if (tripDays) {
        startDate = startDate.subtract(tripDays, 'day');
      } else {
        startDate = startDate.subtract(1, 'month');
      }
      startDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      let defaultFilter = new FilterOption({
        filterType: 'date',
        default: true,
        key: 'startDate',
        title: this.translationService.instant('Start Date'),
        displayValues: startDate.format('MM/DD/YYYY') || null,
        values: startDate.toISOString(),
        query: {
          jobevent__shift1_start__gte: startDate.toISOString()
        }
      });
      this.appliedFilters.push(defaultFilter);
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  openSignatures(trip: RuckitCondensedTrip): void {
    this.tripService.getTrip(trip.id).subscribe(_trip => {
      this.dialog.open(ViewSignaturesDialogComponent, {
        width: '722px',
        data: {
          trip: _trip,
          callback: this.viewSignaturesCallback
        }
      });
    }, err => {
      console.error(err);
    });
  }

  duplicateCheck(): void {
    const duplicateKey = 'duplicates';
    this.checkingDuplicates = !this.checkingDuplicates;

    if (this.checkingDuplicates) {
      let filter = new FilterOption({
        filterType: 'checkbox',
        key: duplicateKey,
        title: this.translationService.instant('Duplicates'),
        displayValues: 'True' || null,
        values: true,
        query: {
          only_dupes: 'True'
        }
      });

      this.appliedFilters.push(filter);
    } else {
      let filter = _find(this.appliedFilters, { key: duplicateKey });
      if (filter) { this.appliedFilters = pull(this.appliedFilters, filter); }
    }
    this.appliedFilters = [ ...this.appliedFilters ];
  }

  menuAction(event: [string, RuckitCondensedTrip]): void {
    switch (event[0]) {
      case 'void':
        this.void(event[1]);
        break;
      case 'unvoid':
        this.unvoid(event[1]);
        break;
    }
  }

  setSelectedBulkAction(option) {
    switch (option.action) {
      case 'export':
        this.exportSelectedTrips();
        break;
      case 'locked-export':
        this.exportSelectedTrips(true);
        break;
      case 'void':
        this.voidSelectedTrips();
        break;
    }
  }

  voidSelectedTrips(trips = null) {
    trips = trips ? trips : this.selectedTrips;
    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Void Trips?'),
      body: this.translationService.instant('These trips will be marked as \'Void\' and will not be visible for the Job.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Void')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        trips.forEach((trip, index) => {
          this.tripService.save({ id: trip.id, void: true }).subscribe((result) => {
            trip['void'] = true;
            trip['loading'] = false;
            if (index === trips.length - 1) {
              this.allSelected = false;
              this.selectedTrips = [];
              this.selectedCount = 0;
              setTimeout(() => {
                this.changeSearch();
              }, 2000);
            }
          }, (err) => {
            this.errors = err;
          });
        });
      }
      this.confirmDialog = null;
    });
  }

  exportSelectedTrips(locked = false) {
    let {params, scope } = AppUtilities.getExportParamsAndScope(
      this.appliedFilters,
      this.selectedTrips,
      this.excludeTrips,
      this.allSelected,
      this.search
    );
    if (this.enabledFeatures.includes('hasExportTracking')) {
      scope['markAsExported'] = true;
    }
    if (this.enabledFeatures.includes('hasExportProtection')) {
      params = params.set('unexported', 'True');
    }

    this.tripService.getExportFields().subscribe((fields: FieldOption[]) => {
      this.dialog.open(ExportDialogComponent, {
        width: 'auto',
        data: <ExportDialogData>{
          type: 'trips',
          buttonText: this.translationService.instant('Export Data to CSV'),
          callback: this.exportCallback,
          fields,
          params,
          scope,
          service: this.tripService,
          lockedFields: locked ? this.lockedExportFields : null
        }
      });
    }, (err) => { this.errors = err; });
  }

  selector(event: {
    allSelected: boolean,
    selection: SelectionModel<RuckitCondensedTrip>,
    exclusion: SelectionModel<RuckitCondensedTrip>
  }) {
    this.allSelected = event.allSelected;
    if (!this.allSelected) {
      this.selectedTrips = event.selection.selected;
      this.excludeTrips = [];
    } else {
      this.selectedTrips = [];
      this.excludeTrips = event.exclusion.selected;
    }
  }

  openFilters() {
    const dialog = this.dialog.open(TripFiltersDialogComponent, {
      width: '430px'
    });

    if (dialog) {
      dialog.componentInstance.callback = res => this.filterChanges(res);
      dialog.componentInstance.model.job = get(_find(this.appliedFilters, { key: 'job' }), 'values');
      dialog.componentInstance.model.project = get(_find(this.appliedFilters, { key: 'project' }), 'values');
      dialog.componentInstance.model.customer = get(_find(this.appliedFilters, { key: 'customer' }), 'values');
      dialog.componentInstance.model.driver = get(_find(this.appliedFilters, { key: 'driver' }), 'values');
      dialog.componentInstance.model.truck = get(_find(this.appliedFilters, { key: 'truck' }), 'values');
      dialog.componentInstance.model.payableTo = get(_find(this.appliedFilters, { key: 'payableTo' }), 'values');
      dialog.componentInstance.model.carrier = get(_find(this.appliedFilters, { key: 'carrier' }), 'values');

      let startDate = get(_find(this.appliedFilters, { key: 'startDate' }), 'values');
      if (startDate) {
        dialog.componentInstance.model.startDate = new Date(startDate);
      }
      let endDate = get(_find(this.appliedFilters, { key: 'endDate' }), 'values');
      if (endDate) {
        dialog.componentInstance.model.endDate = new Date(endDate);
      }

      dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce((acc, filter) => {
        acc[filter.key] = filter.values;
        return acc;
      }, {}));
      this.filtersDialog = dialog.componentInstance;
    }
  }

  filterChanges(filterRes): void {
    let falseyFilters = [];
    Object.keys(filterRes).forEach((key) => {
      if (filterRes[key]) {
        const query = {};
        let values, displayValues;
        values = filterRes[key] && key === 'tags' ? filterRes[key].map(v => (v.name)).join(',') : filterRes[key];
        displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
        if (typeof (values) === 'boolean') {
          if (key === 'incomplete' && values) {
            displayValues = values.toString();
            displayValues = displayValues.charAt(0).toUpperCase() + displayValues.slice(1);
            values = !values;
            let filterValue = values.toString();
            filterValue = filterValue.charAt(0).toUpperCase() + filterValue.slice(1);
            query[this.appliedFilterQueryKeys[key]] = filterValue;
          } else if (key === 'retake' && values) {
            values = 'requested';
            query[this.appliedFilterQueryKeys[key]] = values;
          } else if (key === 'unvoid' && values) {
            query[this.appliedFilterQueryKeys[key]] = 'False';
          } else if (values) {
            values = values.toString();
            values = values.charAt(0).toUpperCase() + values.slice(1);
            query[this.appliedFilterQueryKeys[key]] = values;
          }
        } else if (values) {
          query[this.appliedFilterQueryKeys[key]] = filterRes[key] && (filterRes[key].id || filterRes[key]);
        }

        const filterName = key === 'unvoid' ? 'Not voided' : key.charAt(0).toUpperCase() + key.slice(1);
        let filter = new FilterOption({
          filterType: ['startDate', 'endDate'].indexOf(key) === -1 ? 'text' : 'date',
          key: key,
          title: filterName,
          displayValues: displayValues || null,
          values: values,
          query: query
        });

        if (key !== 'incomplete' && (filter.values === 'False' || !filter.values)) {
          falseyFilters.push(filter);
        }

        let existingFilterIndex = findIndex(this.appliedFilters, { key: key });
        if (existingFilterIndex !== -1) {
          this.appliedFilters[existingFilterIndex] = filter;
        } else {
          this.appliedFilters.push(filter);
        }
      } else {
        let existingFilter = _find(this.appliedFilters, { key: key });
        if (existingFilter) {
          remove(this.appliedFilters, existingFilter);
        }
      }
    });

    this.appliedFilters = uniqBy(difference(this.appliedFilters, falseyFilters), 'key');
  }

  void(trip) {
    trip.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Void Trip?'),
      body: this.translationService.instant('This trip will be marked as \'Void\' and will not be visible for the Job.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Void')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.tripService.save({ id: trip.id, void: true }).subscribe(result => {
          trip.void = true;
          trip.updateStatus();
          trip.loading = false;
        }, err => {
          this.errors = err;
          trip.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  unvoid(trip) {
    trip.loading = true;

    this.confirmDialog = this.dialog.open(RuckitConfirmDialogComponent, {
      width: '430px',
      height: '250px'
    });
    this.confirmDialog.componentInstance.attributes = {
      title: this.translationService.instant('Unvoid Trip?'),
      body: this.translationService.instant('This trip will be unmarked as \'Void\' and will be visible for the Job.'),
      close: this.translationService.instant('Cancel'),
      accept: this.translationService.instant('Unvoid')
    };

    this.confirmDialog.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.tripService.save({ id: trip.id, void: false }).subscribe(result => {
          trip.void = false;
          trip.updateStatus();
          trip.loading = false;
        }, err => {
          this.errors = err;
          trip.loading = false;
        });
      }
      this.confirmDialog = null;
    });
  }

  /**
   * Sets the displayedColumns property on the columnToggle component.
   *
   * @param {} columns List of columns to display (in order)
   */
  columnsChanged(columns): void {
    if (this.columnToggle) {
      this.columnToggle.displayedColumns = columns;
      this.columnToggle.ngOnInit();
    }
  }

  changeSearch(term?: string): void {
    this.search = term || '';
  }

  updateMarkets(): void {
    this.changeSearch();
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId,
    });
  }
}
