import * as moment from 'moment';
import { UserSerializer } from '../users/user.serializer';
import { Job, MultipleItems } from '../jobs/job';
import { TruckType } from '../trucks/truck-type';
import { uniq } from 'lodash';
import { JobSerializer } from '../jobs/job.serializer';
import { JobEventStat } from '../job-event-stats/job-event-stat';
import { Location } from '../locations/location';
import { LocationSerializer } from '../locations/location.serializer';
import { Organization } from '../organizations/organization';

const camelcaseKeysDeep = require('camelcase-keys-deep');

export class JobEvent {
  id: string;
  name: string;
  nameWithJob: string;
  shift1Start: string;
  shift1End: string;
  shift1Status: string;
  shift2Start: string;
  shift2End: string;
  shift2Status: string;
  shift1StartTimestamp: string;
  shift1EndTimestamp: string;
  shift2StartTimestamp: string;
  shift2EndTimestamp: string;
  shift1Overnight: boolean;
  shift2Overnight: boolean;
  jobEnd: string;
  endLocation: Location;
  startLocation: Location;
  deliveryInterval: string;
  rate: string;
  phaseCode: string;
  job: Job;
  jobDisplayName: string;
  displayName: string;
  ownerOrganization: Organization;
  invoiceType: string;
  invoiceWeightUnit: string;
  haulRate: string;
  haulType: string;
  haulWeightUnit: string;
  numTrucks: number;
  dispatchedCount: number;
  assignmentLoadCount: number;
  loadDeliveryTarget: string;
  dailyDeliveryTarget: string;
  dailyDeliveryTargetType: string;
  totalAmountType: string;
  lastDispatchedBy: any;
  lastDispatchedTime: any;
  externalIdentifier: string;
  estimatedNumberOfLoads: string;
  estimatedNumberOfTons: string;
  assignmentsCount: string;
  amountDelivered: number;
  dollarValue: number;
  routePolyline: string;
  stats: JobEventStat;
  truckTypes: TruckType[];
  allowAnyTruckType: boolean;
  shareId: string;
  canShare: boolean;
  canEdit: boolean;
  canAutoAssign: boolean;
  isShared: boolean;
  multipliers: any[];
  notes: string;
  status: string;
  cancelled: boolean;
  startCoordinates: any;
  endCoordinates: any;
  directions: any;
  mapImageURL: string;
  workOrderTemplate: string;
  hasGeofences: boolean;
  staggerStartTime: string;
  dsorderId: string;
  slorderId: string;
  pin: string;
  jobNameWithDsOrderId: string;
  requestedAmount: number;
  requestedUnit: { key: string, name: string, active: boolean };
  multipleItemsData: MultipleItems;

  // buffer times
  defaultRoundTripTime: number;
  defaultYardPrepTime: number;
  defaultYardBufferTime: number;
  defaultYardBufferMinutes: number;

  constructor(jobEventInfo: any) {
    jobEventInfo = camelcaseKeysDeep(jobEventInfo);

    this.id = jobEventInfo.id;
    this.shift1Start = jobEventInfo.shift1Start;
    this.shift1End = jobEventInfo.shift1End;
    this.shift1StartTimestamp = jobEventInfo.shift1Start;
    this.shift1EndTimestamp = jobEventInfo.shift1End;
    this.shift2StartTimestamp = jobEventInfo.shift2Start;
    this.shift2EndTimestamp = jobEventInfo.shift2End;
    this.staggerStartTime = moment(this.shift1StartTimestamp).format('H:mm A');
    this.jobEnd = this.shift2EndTimestamp ? this.shift2EndTimestamp : this.shift1EndTimestamp;
    this.shift1Status = jobEventInfo.shift1Status;
    this.shift2Status = jobEventInfo.shift2Status;
    if (this.shift1StartTime && this.shift1EndTime) {
      const startTime = moment(this.shift1StartTime, 'h:mm a');
      const endTime = moment(this.shift1EndTime, 'h:mm a');
      this.shift1Overnight = endTime.isBefore(startTime);
    } else if (this.shift2StartTime && this.shift2EndTime) {
      const startTime = moment(this.shift2StartTime, 'h:mm a');
      const endTime = moment(this.shift2EndTime, 'h:mm a');
      this.shift2Overnight = endTime.isBefore(startTime);
    }
    this.deliveryInterval = jobEventInfo.deliveryInterval;
    this.rate = jobEventInfo.rate;
    if (typeof(jobEventInfo.job) === 'object') {
      this.job = new JobSerializer().fromJson(jobEventInfo.job);
    } else {
      this.job = new JobSerializer().fromJson({id: jobEventInfo.job});
    }
    this.phaseCode = jobEventInfo.phaseCode || this.job.phaseCode;
    if (jobEventInfo.startLocation && typeof (jobEventInfo.startLocation) === 'object') {
      this.startLocation = new LocationSerializer().fromJson(jobEventInfo.startLocation);
    } else if (jobEventInfo.startLocation) {
      this.startLocation = new LocationSerializer().fromJson({ id: jobEventInfo.startLocation });
    }
    if (jobEventInfo.endLocation && typeof (jobEventInfo.endLocation) === 'object') {
      this.endLocation = new LocationSerializer().fromJson(jobEventInfo.endLocation);
    } else if (jobEventInfo.endLocation) {
      this.endLocation = new LocationSerializer().fromJson({ id: jobEventInfo.endLocation });
    }
    this.invoiceType = jobEventInfo.invoiceType;
    this.invoiceWeightUnit = jobEventInfo.invoiceWeightUnit;
    this.haulRate = jobEventInfo.haulRate;
    this.haulWeightUnit = jobEventInfo.haulWeightUnit;
    this.haulType = jobEventInfo.haulType;
    this.numTrucks = jobEventInfo.numTrucks;
    this.loadDeliveryTarget = jobEventInfo.loadDeliveryTarget;
    this.dailyDeliveryTarget = jobEventInfo.dailyDeliveryTarget;
    this.dailyDeliveryTargetType = jobEventInfo.dailyDeliveryTargetType;
    this.totalAmountType = jobEventInfo.totalAmountType;
    if (jobEventInfo.lastDispatchedTime) {
      this.lastDispatchedTime = new Date(jobEventInfo.lastDispatchedTime);
    }
    if (jobEventInfo.lastDispatchedBy) {
      this.lastDispatchedBy = (new UserSerializer).fromJson(jobEventInfo.lastDispatchedBy);
    }
    this.externalIdentifier = jobEventInfo.externalIdentifier;
    if (jobEventInfo.ownerOrganization && typeof (jobEventInfo.ownerOrganization) === 'object') {
      this.ownerOrganization = new Organization(jobEventInfo.ownerOrganization);
    } else if (jobEventInfo.ownerOrganization) {
      this.ownerOrganization = new Organization({ id: jobEventInfo.ownerOrganization });
    }
    this.displayName = jobEventInfo.displayName;
    // this.jobDisplayName = [this.externalIdentifier, this.job.displayName].filter(Boolean).join(': ');
    this.jobDisplayName = this.job.displayName;
    this.estimatedNumberOfLoads = jobEventInfo.estimatedNumberOfLoads;
    this.estimatedNumberOfTons = jobEventInfo.estimatedNumberOfTons;
    this.assignmentsCount = jobEventInfo.assignmentsCount;
    this.routePolyline = jobEventInfo.routePolyline || this.job.routePolyline;
    this.truckTypes = jobEventInfo.truckTypes;
    this.allowAnyTruckType = jobEventInfo.allowAnyTruckType;
    this.shareId = jobEventInfo.shareId;
    this.canShare = jobEventInfo.canShare;
    this.canEdit = jobEventInfo.canEdit;
    this.canAutoAssign = true;
    this.isShared = jobEventInfo.isShared;
    this.multipliers = jobEventInfo.multipliers;
    this.notes = jobEventInfo.notes || jobEventInfo.job && jobEventInfo.job.notes;
    this.status = jobEventInfo.status;
    this.cancelled = jobEventInfo.cancelled;
    this.dispatchedCount = jobEventInfo.assignmentsCount || jobEventInfo.dispatchedCount || 0;
    this.dsorderId = jobEventInfo.dsorderId;
    this.slorderId = jobEventInfo.slorderId;
    this.pin = jobEventInfo.pin;
    this.requestedAmount = jobEventInfo.requestedAmount;
    this.requestedUnit = jobEventInfo.requestedUnit;

    this.defaultRoundTripTime = jobEventInfo.defaultRoundTripTime;
    this.defaultYardPrepTime = jobEventInfo.defaultYardPrepTime;
    this.defaultYardBufferTime = jobEventInfo.defaultYardBufferTime;
    if (jobEventInfo.defaultYardBufferTime && parseFloat(jobEventInfo.defaultYardBufferTime) > 0) {
      this.defaultYardBufferMinutes = parseFloat(jobEventInfo.defaultYardBufferTime) / 60;
    }
    this.multipleItemsData = jobEventInfo.multipleItemsData || this.multipleItemsData;

    this.assignmentLoadCount = jobEventInfo.assignmentLoadCount;
    if (this.job.startLocation && this.job.startLocation.location && typeof this.job.startLocation.location === 'object') {
      this.startCoordinates = {
        longitude: this.job.startLocation.location.coordinates[0],
        latitude: this.job.startLocation.location.coordinates[1]
      };
    } else if (this.job.startLocation) {
      let location = this.job.startLocation.location;
      if (location) {
        this.startCoordinates = {
          longitude: location.coordinates[0],
          latitude: location.coordinates[1]
        };
      }
    }

    if (this.job.endLocation && this.job.endLocation.location && typeof this.job.endLocation.location === 'object') {
      this.endCoordinates = {
        longitude: this.job.endLocation.location.coordinates[0],
        latitude: this.job.endLocation.location.coordinates[1]
      };
    } else if (this.job.endLocation) {
      let location = this.job.endLocation.location;
      if (location) {
        this.endCoordinates = {
          longitude: location.coordinates[0],
          latitude: location.coordinates[1]
        };
      }
    }

    if (
      this.startCoordinates && this.startCoordinates.longitude &&
      this.endCoordinates && this.endCoordinates.longitude
    ) {
      this.directions = {
        origin: this.startCoordinates.latitude + ',' + this.startCoordinates.longitude,
        destination: this.endCoordinates.latitude + ',' + this.endCoordinates.longitude,
        travelMode: 'DRIVING'
      };
    }

    if (this.routePolyline) {
      this.mapImageURL = 'https://maps.googleapis.com/maps/api/staticmap?size=418x178&path=color:0x002649|weight:4|enc:';
      this.mapImageURL += this.routePolyline;
      this.mapImageURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
    }

    this.name = `${moment(this.shift1StartTimestamp).format('MM/DD/YYYY h:mm a')} - ${moment(this.jobEnd).format('MM/DD/YYYY h:mm a')}`;
    this.nameWithJob = [
      this.name, this.job && this.jobDisplayName
    ].filter(Boolean).join(': ');
    this.jobNameWithDsOrderId = [this.externalIdentifier, this.job.name].filter(Boolean).join(': ');

    this.workOrderTemplate = jobEventInfo.workOrderTemplate;

    if (this.job && this.job.startLocation && this.job.startLocation.geofence && this.job.endLocation && this.job.endLocation.geofence) {
      this.hasGeofences = true;
    } else {
      this.hasGeofences = false;
    }
  }

  get jobStart(): Date {
    if (this.shift1StartTimestamp) {
      const start = this.shift1StartTimestamp.split('T')[0];
      return moment(start).toDate();
    }
  }

  get endTime(): string {
    let d = new Date(this.jobEnd);
    return moment(d).format('h:mm a');
  }

  get shifts(): number {
    if (this.shift2EndTimestamp) {
      return 2;
    } else {
      return 1;
    }
  }

  get shift1StartTime(): string {
    let d = new Date(this.shift1StartTimestamp);
    return moment(d).format('h:mm a');
  }

  get shift1EndTime(): string {
    let d = new Date(this.shift1EndTimestamp);
    return moment(d).format('h:mm a');
  }

  get shift2StartTime(): string {
    let d = new Date(this.shift2StartTimestamp);
    return moment(d).format('h:mm a');
  }

  get shift2EndTime(): string {
    let d = new Date(this.shift2EndTimestamp);
    return moment(d).format('h:mm a');
  }

  get dateRange(): string {
    let range = [];
    let endDate = new Date(this.jobEnd);
    if (this.jobStart) { range.push(moment(this.jobStart).format('dddd, MMMM Qo')); }
    if (endDate) { range.push(moment(endDate).format('dddd, MMMM Qo')); }

    return uniq(range.filter(Boolean)).join(' - ');
  }

  get deliveryIntervalMinutes(): number {
    let intervalNumber = this.deliveryInterval ? Number(this.deliveryInterval) : 0;
    return Math.round(intervalNumber);
  }

  get deliveryIntervalHours(): string {
    let intervalNumber = this.deliveryInterval ? Number.parseFloat(this.deliveryInterval) : 0;
    intervalNumber /= 60;
    return intervalNumber.toFixed(2);
  }

  set shift1StartTime(value: string) {
    let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
    let date = moment(this.shift1StartTimestamp).format('YYYY-MM-DD');
    let parsedDate = moment(date + ' ' + value, pattern);
    this.shift1Start = parsedDate.toISOString();
  }

  set shift1EndTime(value: string) {
    let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
    let date = moment(this.shift1EndTimestamp).format('YYYY-MM-DD');
    let parsedDate = moment(date + ' ' + value, pattern);
    this.shift1End = parsedDate.toISOString();
  }

  set shift2StartTime(value: string) {
    let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
    let date = moment(this.shift2StartTimestamp || this.shift1StartTimestamp).format('YYYY-MM-DD');
    let parsedDate = moment(date + ' ' + value, pattern);
    this.shift2Start = parsedDate.toISOString();
  }

  set shift2EndTime(value: string) {
    let pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
    let date = moment(this.shift2EndTimestamp || this.shift1EndTimestamp).format('YYYY-MM-DD');
    let parsedDate = moment(date + ' ' + value, pattern);
    this.shift2End = parsedDate.toISOString();
  }

  get notice(): any {
    let values = [];
    let statusType = 'standard';
    if (this.job.project && this.job.project.name) {
      values.push(this.job.project.name);
    }

    let statusText = values.filter(function(v) {
      return (v !== '' && v !== null);
    }).join(' - ');

    return {statusText: statusText, statusType: statusType};
  }

  get invoiceRateUnit(): string {
    if (this.invoiceType === 'weight') {
      return this.invoiceWeightUnit;
    } else {
      return this.invoiceType;
    }
  }

  get haulRateUnit(): string {
    if (this.haulType === 'weight') {
      return this.haulWeightUnit;
    } else {
      return this.haulType;
    }
  }

  get loadStatus(): string {
    return '';
  }

  get dayEstimate(): string {
    let values = ['Estimated Day:'];
    let estimates = [];

    if (this.estimatedNumberOfTons !== undefined) { estimates.push(this.estimatedNumberOfTons + ' tons'); }
    if (this.estimatedNumberOfLoads !== undefined) { estimates.push(this.estimatedNumberOfLoads + ' loads'); }
    if (estimates.length === 0) { estimates.push('0'); }

    values.push(estimates.filter(function(v) {
      return (v !== '' && v !== null);
    }).join(', '));

    return values.filter(function(v) {
      return (v !== '' && v !== null);
    }).join(' ');
  }

  get shiftOneDuration(): string {
    let startTime = moment(this.shift1StartTimestamp);
    let endTime = moment(this.shift1EndTimestamp);
    let duration = moment.duration(endTime.diff(startTime));
    let value = duration.humanize().replace(/s?$/, '');

    return value;
  }

  get shiftTwoDuration(): string {
    let startTime = moment(this.shift2StartTimestamp);
    let endTime = moment(this.shift2EndTimestamp);
    let duration = moment.duration(endTime.diff(startTime));
    let value = duration.humanize().replace(/s?$/, '');

    return value;
  }

  get mapCenter(): string {
    if (this.startCoordinates) {
      return this.startCoordinates.latitude + ',' + this.startCoordinates.longitude;
    } else {
      return '';
    }
  }

  get mapCenterCoordinates(): any {
    if (this.startCoordinates && this.startCoordinates.latitude && this.startCoordinates.longitude) {
      return {
        lat: parseFloat(this.startCoordinates.latitude),
        lng: parseFloat(this.startCoordinates.longitude)
      };
    } else {
      return null;
    }
  }

  get statusText() {
    let statusText;
    if ((this.shift1Status === 'cancelled' && this.shift2Status === 'cancelled') || this.status === 'cancelled') {
      statusText = 'Day Cancelled';
    } else if (this.shift1Status === 'cancelled') {
      statusText = 'Shift 1 Cancelled';
    } else if (this.shift2Status === 'cancelled') {
      statusText = 'Shift 2 Cancelled';
    }
    return statusText;
  }

  get truckTypeNames(): string {
    let names = [];
    if (this.allowAnyTruckType) {
      return 'Any Type';
    } else if (this.truckTypes) {
      names = this.truckTypes.map((truckType) => {
        return truckType.name;
      });
    }

    return names.filter(Boolean).join(', ');
  }

  get future(): boolean {
    let _date = new Date();
    _date.setHours(23, 59, 59, 999);
    return this.jobStart > _date;
  }
}
