import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Observable,
  forkJoin,
  combineLatest as observableCombineLatest,
  Subscription,
} from 'rxjs';
import { first } from 'rxjs/operators';

// libraries
import * as moment from 'moment';
import { flatten } from 'lodash';

// services
import { parseErrors } from '../../shared/api.service';
import { AssignmentFilterService } from '../../assignments/assignment.filter.service';
import { DriverService } from '../../drivers/driver.service';
import { AssignmentService } from '../../assignments/assignment.service';
import { JobEventService } from '../../job-events/job-event.service';
import { LocationUpdateService } from '../../jobs/locationUpdate.service';
import { LocationService } from '../../locations/location.service';
import {
  DateTimeRange,
  DropdownComponent,
  TimeInterval,
  AuthenticationService,
  TimelineLabel,
} from '../../shared';

// types
import { JobEvent } from '../../job-events/job-event';
import { Location as RuckitLocation } from '../../locations/location';
import { Assignment } from '../../assignments/assignment';
import { Driver } from '../../drivers/driver';
import { LocationUpdate } from '../../jobs/locationUpdate';

// components
import { RuckitDropdownComponent } from '../../shared/ruckit-dropdown/ruckit-dropdown.component';

@Component({
  selector: 'ruckit-replay',
  templateUrl: './ruckit-replay.component.html',
  styleUrls: ['./ruckit-replay.component.scss']
})

export class RuckitReplayComponent implements OnInit, OnDestroy {
  loading = false;
  loadingLocationUpdates = true;
  loadingLocations = true;
  user = this.authenticationService.user();
  legend = false;
  showVoided = false;
  jobEvents: JobEvent[] = [];
  locations: RuckitLocation[] = [];
  assignments: Assignment[] = [];
  selectedDate: moment.Moment;
  selectedTime: string;
  timeRange: DateTimeRange;
  playIcons = [0];
  playbackSpeed = 1;
  assignmentsReq: Subscription;
  locationUpdatesReq: Subscription;
  locationsReq: Subscription;

  timelineLabels: TimelineLabel[] = [
    {
      name: 'Punchcards',
      color: '#09366c'
    },
    {
      name: 'Trips',
      color: '#015BC5'
    },
    {
      name: 'Scale Sync',
      color: '#7e34bf'
    },
    {
      name: 'Geofence Duration',
      color: '#f5a623'
    },
    {
      name: 'GeoTrip Duration',
      color: '#fde0af'
    },
    {
      name: 'Stopped',
      color: 'white',
      icon: 'stop-hand'
    },
    {
      name: 'Signal Lost',
      color: '#565554',
      icon: 'no-signal'
    },
  ];

  timeInterval: TimeInterval;
  @ViewChild('intervalSelect', { static: true }) intervalSelect: DropdownComponent;
  timeIntervalConfig = { nameProperty: 'title' };
  timeIntervalOptions = [
    { title: '1 Hour', amount: 60 },
    { title: 'Half Hour', amount: 30 },
    { title: 'Quarter Hour', amount: 15 }
  ];
  playing = false;

  @ViewChild('driverDropdownWrapper', { static: false }) driverDropdownWrapper: RuckitDropdownComponent;
  selectedDriver: Driver;
  driverLocationUpdates: LocationUpdate[] = [];
  driverId: string;
  driverDropdownConfig = {
    searchable: true,
    showLoading: true,
    group: true,
    groupProperty: 'organizationName',
    nameProperty: 'name',
    sortBy: 'carrier__organization__name,carrier__name,profile__first_name,profile__last_name',
    selectText: 'Select Driver',
    loadingText: 'Loading Drivers...',
    noResultsText: 'No Drivers',
    service: DriverService,
    serviceFunction: 'listCondensed',
    query: {},
    customOptionKeys: ['truck', 'carrier', 'organizationName']
  };

  errors;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    public assignmentFilterService: AssignmentFilterService,
    public locationService: LocationService,
    public assignmentsService: AssignmentService,
    public jobEventService: JobEventService,
    private authenticationService: AuthenticationService,
    public locationUpdateService: LocationUpdateService
  ) { }

  ngOnInit() {
    this.loading = true;
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        if (result && result.params) {
          this.driverId = result.qparams['driver'];
        }
      })
    );

    this.selectedDate = this.route.snapshot.queryParams.date ?
                        (this.route.snapshot.queryParams.date.length === 8 ?
                         moment(this.route.snapshot.queryParams.date, 'YYYYMMDD') :
                         moment(this.route.snapshot.queryParams.date)) : moment();
    this.selectedTime = this.selectedDate.startOf('day').toISOString();
    this.getLocations();
    if (this.intervalSelect && this.timeIntervalOptions) {
      this.intervalSelect.selectedOption = this.timeIntervalOptions[1];
    }
    this.timeInterval = this.timeIntervalOptions[1].amount;
    this.driverDropdownConfig.query = {
      ...this.driverDropdownConfig.query,
      ...{ activity: this.selectedDate.startOf('day').toISOString() + ',' + this.selectedDate.endOf('day').toISOString() }
    };
    this.setTimeRange();
  }

  ngOnDestroy() {
    if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
      this.assignmentsReq.unsubscribe();
    }
    if (this.locationUpdatesReq && typeof this.locationUpdatesReq.unsubscribe === 'function') {
      this.locationUpdatesReq.unsubscribe();
    }
    if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
      this.locationsReq.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  onDateChanged(e: string[]): void {
    if (!this.loading) {
      this.selectedDate = moment(e[0]);
      this.selectedTime = this.selectedDate.startOf('day').toISOString();
      const url = this.router.createUrlTree([], {
        relativeTo: this.route,
        queryParams: {
          ...this.route.snapshot.queryParams,
          date: this.selectedDate.format('YYYYMMDD')
        }
      }).toString();
      this.location.go(url);
      this.timeRange = {
        startDatetime: this.selectedDate.startOf('day').toISOString(),
        endDatetime: this.selectedDate.endOf('day').toISOString()
      };
      if (this.driverDropdownWrapper) {
        this.driverDropdownConfig.query = {
          ...this.driverDropdownConfig.query,
          ...{ activity: this.selectedDate.startOf('day').toISOString() + ',' + this.selectedDate.endOf('day').toISOString() }
        };
        if (this.driverDropdownWrapper.config) {
          this.driverDropdownWrapper.config.query = this.driverDropdownConfig.query;
        }
        if (typeof this.driverDropdownWrapper.getRecords === 'function') {
          this.driverDropdownWrapper.getRecords();
        }
      }
      this.getLocations();
      if (this.selectedDriver) { this.getDriverAssignments(this.selectedDriver); }
    }
    this.loading = false;
  }

  getDriverAssignments(selectedDriver: Driver): void {
    this.loadingLocationUpdates = true;
    this.selectedDriver = selectedDriver;
    this.jobEvents = [];
    if (selectedDriver) {
      if (this.assignmentsReq && typeof this.assignmentsReq.unsubscribe === 'function') {
        this.assignmentsReq.unsubscribe();
      }
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          ...this.route.snapshot.queryParams,
          driver: selectedDriver.id
        }
      });
      this.assignmentsReq = this.assignmentsService.list({
        active_range: this.selectedDate.startOf('day').toISOString() + ',' + this.selectedDate.endOf('day').toISOString(),
        driver: selectedDriver.id,
        include_trips: 'True',
        can_dispatch: 'True'
      }).subscribe(assignments => {
        let jobEventReqs: Observable<JobEvent>[] = [];
        assignments.forEach(assignment => {
          if (!this.jobEvents.find(jobEvent => (jobEvent.id === assignment.jobevent.id))) {
            jobEventReqs.push(this.jobEventService.getJobEvent(assignment.jobevent.id));
          }
        });
        forkJoin(jobEventReqs).subscribe(jobevents => {
          this.assignments = assignments;
          this.jobEvents = jobevents;
          this.setTimeRange(jobevents);
          this.getDriverLocationUpdates(selectedDriver, assignments);
        }, err => this.errors = parseErrors(err));
      }, err => this.errors = parseErrors(err));
    }
  }

  getLocations(): void {
    this.loadingLocations = true;
    this.locations = [];
    if (this.locationsReq && typeof this.locationsReq.unsubscribe === 'function') {
      this.locationsReq.unsubscribe();
    }
    this.locationsReq = this.locationService.list({
      active_range: this.selectedDate.startOf('day').toISOString() + ',' + this.selectedDate.endOf('day').toISOString()
    }).subscribe(locations => {
      this.locations = locations;
    }, err => {
      this.errors = parseErrors(err);
    });
  }

  getDriverLocationUpdates(driver: Driver, assignments: Assignment[]): void {
    this.driverLocationUpdates = [];
    if (this.locationUpdatesReq && typeof this.locationUpdatesReq.unsubscribe === 'function') {
      this.locationUpdatesReq.unsubscribe();
    }
    const currentDate = moment(this.selectedTime);
    let startDate = currentDate.clone().startOf('day');
    let endDate = currentDate.clone().endOf('day');
    let startDates = [
      startDate,
      ...flatten(assignments.map(a => (
        [
          moment(a.uniqueStart),
          ...a.shifts.map(s => (s ? moment(s.startTime) : moment.invalid())),
          ...a.trips.map(t => (t ? moment(t.startTimeTimestamp) : moment.invalid())),
          ...a.predictedTrips.map(p => (p ? moment(p.startTimeTimestamp) : moment.invalid())),
          ...a.punchCards.map(p => (p ? moment(p.startTimeTimestamp) : moment.invalid())),
        ]
      ))).filter(t => t.isValid())
    ];
    let endDates = [
      endDate,
      ...flatten(assignments.map(a => (
        [
          ...a.shifts.map(s => (s ? moment(s.endTime) : moment.invalid())),
          ...a.trips.map(t => (t ? moment(t.endTimeTimestamp) : moment.invalid())),
          ...a.predictedTrips.map(p => (p ? moment(p.endTimeTimestamp) : moment.invalid())),
          ...a.punchCards.map(p => (p ? moment(p.endTimeTimestamp) : moment.invalid())),
        ]
      ))).filter(t => t.isValid())
    ];
    // Add safety check for dates way beyond acceptable range
    startDates = startDates.filter(_startDate => {
      return startDate.clone().subtract(1, 'days').isBefore(moment(_startDate));
    });
    endDates = endDates.filter(_endDate => {
      return endDate.clone().add(1, 'days').isAfter(moment(_endDate));
    });
    if (endDates.length === 0) {
      endDates = [moment.min(startDates).add(26, 'hours')];
    }
    const requestRange = {
      start: moment.min(startDates).clone().subtract(2, 'hours').toISOString(),
      end: moment.max(endDates).clone().add(2, 'hours').toISOString()
    };

    this.locationUpdatesReq = this.locationUpdateService.getAll(1000, {
      date__gte: requestRange.start,
      date__lte: requestRange.end,
      driver: driver.id,
      ordering: 'date'
    }).pipe(first()).subscribe(locationUpdates => {
      this.driverLocationUpdates = locationUpdates;
      this.loadingLocationUpdates = false;
    });
  }

  play(speed = 0): void {
    this.playing = true;

    if (speed !== 0) {
      this.playbackSpeed += 1;
      if (this.playIcons.length > 2) {
        this.playIcons = [0];
        this.playbackSpeed = 1;
      } else {
        this.playIcons.push(0);
      }
    }
    let time = moment(this.selectedTime).add(10 * Math.pow(this.playbackSpeed, 2), 'seconds');
    this.selectedTime = time.toISOString();
    setTimeout(() => {
      if (time.isBefore(this.timeRange.endDatetime) && this.playing) {
        this.play();
      }
    }, 100);
  }

  pause(): void { this.playing = false; }

  setTimeRange(jobEvents?: JobEvent[]): void {
    let startTime, endTime;
    if (jobEvents) {
      jobEvents.forEach(jobEvent => {
        if (this.timeRange) {
          startTime = moment(jobEvent.shift1StartTimestamp).isBefore(moment(this.timeRange.startDatetime)) ?
                      jobEvent.shift1StartTimestamp : this.timeRange.startDatetime;
          endTime = jobEvent.shift2EndTimestamp ?
                    (moment(jobEvent.shift2EndTimestamp).isAfter(moment(this.timeRange.endDatetime)) ?
                    jobEvent.shift2EndTimestamp : this.timeRange.endDatetime) :
                    (moment(jobEvent.shift1EndTimestamp).isAfter(moment(this.timeRange.endDatetime)) ?
                    jobEvent.shift1EndTimestamp : this.timeRange.endDatetime);
        } else {
          startTime = jobEvent.shift1StartTimestamp;
          endTime = jobEvent.shift2EndTimestamp ? jobEvent.shift2EndTimestamp : jobEvent.shift1EndTimestamp;
        }
      });
    } else if (this.selectedDate) {
      startTime = this.selectedDate.startOf('day').toISOString();
      endTime = this.selectedDate.endOf('day').toISOString();
    }
    this.selectedTime = startTime;
    this.timeRange = {
      startDatetime: startTime,
      endDatetime: endTime
    };
  }

  toggleVoided(): void {
    this.showVoided = !this.showVoided;
  }

  onRefresh() {
    this.getDriverAssignments(this.selectedDriver);
  }
}
