import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { combineLatest as observableCombineLatest, Subscription } from 'rxjs';
import { clone, remove, get as _get, find as _find } from 'lodash';
import { MatDialog } from '@angular/material';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as moment from 'moment';

import { DailyBoardFiltersDialogComponent } from './daily-board-filters-dialog.component';
import { CollaboratorService } from '../collaborators/collaborator.service';
import { AuthenticationService } from '../shared/index';
import { AssignmentService } from '../assignments/assignment.service';
import { ExportDialogComponent, ExportDialogData } from '../shared/export-dialog/export-dialog.component';
import { Preference } from '../preferences/preference';
import { PreferenceService } from '../preferences/preference.service';

@Component({
  selector: 'ruckit-daily-board',
  templateUrl: './daily-board.component.html',
  styleUrls: ['./daily-board.component.scss']
})
export class DailyBoardComponent implements OnInit, OnDestroy {
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  loading = true;
  errors = [];
  pendingJobsCount = 0;
  count = 0;
  canCreateJobs = true;
  hideCancelled = true;
  hasAllDriversEnabled = false;
  search = '';
  sortBy: string;
  sortAsc = true;
  stringify = JSON.stringify;
  actionDropdownOptions = [
    { name: 'Edit', button: true }
  ];
  table = 'job-stats';
  view = 'grid';
  displayAsGrid = true;
  filters = [];
  jobEventDate: Date;
  filtersDialog;
  enabledFeatures: string[] = [];
  carrierMode = false;

  pendingCountReq: Subscription;
  preferenceKey = 'daily-board-component-preferences';
  preference: Preference;
  @ViewChild('dailyBoardGrid', { static: false }) dailyBoardGrid;
  @ViewChild('dailyBoardList', { static: false }) dailyBoardList;
  @ViewChild('mobileListTable', { static: false }) mobileListTable;
  @ViewChild('collaboratorStats', { static: false }) collaboratorStats;
  allSubscriptionsToUnsubscribe: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private collaboratorService: CollaboratorService,
    private authenticationService: AuthenticationService,
    private assignmentService: AssignmentService,
    private deviceDetectorService: DeviceDetectorService,
    private preferenceService: PreferenceService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };

    this.getPendingCount();
    this.getPreferences();

    this.canCreateJobs = this.authenticationService.canCreateJobs();
    this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
    this.enabledFeatures = this.authenticationService.enabledFeatures();
    this.carrierMode = !this.enabledFeatures.includes('ruckitBilling');

    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.loading = true;
        this.hideCancelled = this.authenticationService.getFilterProperty('hideCancelledJobs');
        this.search = result.qparams['search'] || '';
        this.view = result.qparams['view'] ? result.qparams['view'] : this.view;
        this.table = result.params['table'] ? result.params['table'] : 'job-stats';
        if (result.qparams['date']) {
          let date = moment(result.qparams['date'], 'YYYYMMDD').toDate();
          this.jobEventDate = date || new Date();
        } else {
          this.setDefaultDate();
        }
        this.sortBy = 'job__name';
        this.loading = false;
      })
    );
  }

  ngOnDestroy() {
    if (this.pendingCountReq && typeof this.pendingCountReq.unsubscribe === 'function') {
      this.pendingCountReq.unsubscribe();
    }
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  switchView(view = 'grid'): void {
    this.view = view !== 'grid' && (this.device.desktop || this.device.tablet) && !this.carrierMode ? view : 'grid';
    let params = { view: view };
    let currentParams = this.route.snapshot.queryParams;
    this.router.navigate([], { skipLocationChange: true }).then(() => {
      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          ...currentParams,
          ...params
        }
      });
    });
    if (
      this.preference && this.preference.blob &&
      this.preference.blob['view'] && this.preference.blob['view'] !== this.view
    ) {
        this.savePreferences();
    } else if (!this.preference) {
      this.savePreferences();
    }
  }

  // otherwise it crashes when changing views - expressionChangedAfterItHasBeenCheckedError
  ngAfterViewChecked(): void {
    this.cd.detectChanges();
}

  savePreferences(): void {
    if (this.preferenceKey) {
      let currentUser = this.authenticationService.user();
      this.preference = {
        ...this.preference,
        name: this.preferenceKey,
        type: 'user',
        profile: currentUser.id,
        blob: { view: this.view, table: this.table }
      };
      this.preferenceService.save(this.preference).subscribe(preference => {
        this.preference = preference;
        this.parsePreferences();
      });
    }
  }

  getPreferences(): void {
    let currentUser = this.authenticationService.user();
    if (this.preference && this.preference.id) {
      this.preferenceService.get(this.preference.id).subscribe(preference => {
        this.preference = preference;
        this.parsePreferences();
      });
    } else {
      this.preferenceService.list({
        name: this.preferenceKey,
        type: 'user',
        profile: currentUser.id
      }).subscribe(preferences => {
        if (preferences && preferences.length) {
          this.preference = preferences[0];
          this.parsePreferences();
        }
      });
    }
  }

  parsePreferences(): void {
    if (this.preference.blob && this.preference.blob['view']) {
      let view = this.preference.blob['view'];
      this.switchView(view);
    }
  }

  getPendingCount(query = {}, append = false): void {
    this.pendingCountReq = this.collaboratorService.getPending({ page_size: 1 }).subscribe(invitations => {
      this.pendingJobsCount = this.collaboratorService.count;
    }, err => {
      this.errors = err;
    });
  }

  selectListStyle(style: string): void {
    this.displayAsGrid = (style === 'grid');
  }

  setDefaultDate(): void {
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    let date = moment();
    queryParams['date'] = date.format('YYYYMMDD');
    this.jobEventDate = date.toDate();
    this.router.navigate(['jobs', 'daily'], { queryParams: queryParams });
  }

  onDateChanged(dates: Date[]): void {
    this.jobEventDate = dates[0];
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    let date = moment(this.jobEventDate).format('YYYYMMDD');
    queryParams['date'] = date;
    queryParams['search'] = this.search;
    this.router.navigate([], { queryParams: queryParams });
  }

  openFilters(): void {
    const dialog = this.dialog.open(DailyBoardFiltersDialogComponent, {
      width: '430px'
    });
    dialog.componentInstance.callback = res => this.filterChanges(res);
    dialog.componentInstance.model = this.filters.reduce((acc, filter) => {
      acc[filter.key] = filter.value;
      return acc;
    }, {});
    dialog.componentInstance.model.apexCustomerJob = _get(_find(this.filters, {key: 'customerJob'}), 'value');
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    // Callback from the filter dialog. Creates a collection of filters with the format: {key, value, query},
    // where 'key' is the filter type such as 'customer',
    // 'value' is the original object for the options that was select from the dropdown,
    // and 'query' is an object representing the query fragment associated with that filter setting.
    const queryKeys = {
      customer: 'customer_organization',
      project: 'job__project',
      startLocation: 'job__start_location',
      endLocation: 'job__end_location',
      apexCustomerJob: 'apex_customer_job',
    };
    this.filters = Object.keys(filterRes).filter((key) => {
      return !!filterRes[key];
    }).map((key) => {
      const query = {};
      let displayKey = key;
      if (key === 'apexCustomerJob') { displayKey = 'customerJob'; }

      query[queryKeys[key]] = filterRes[key].id || filterRes[key];
      return {
        key: displayKey,
        value: filterRes[key],
        query: query
      };
    });
    this.dailyBoardGrid.getJobs();
    this.loading = false;
  }

  removeFilter(filter): void {
    remove(this.filters, filter);
    this.dailyBoardGrid.getJobs();
  }

  exportAssignments(): void {
    let startOfDay = new Date(this.jobEventDate);
    startOfDay.setHours(0, 0, 0, 0);
    let endOfDay = clone(startOfDay);
    endOfDay.setHours(23, 59, 59, 999);

    let filters = {
      'jobevent__shift1_start__gte': startOfDay && startOfDay.toISOString(),
      'jobevent__shift1_start__lte': endOfDay && endOfDay.toISOString()
    };

    this.assignmentService.export({}, filters).subscribe(response => {
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{ type: 'assignments' }
      });
      dialog.componentInstance.exportSubmitted = true;
    }, err => {
      let params = new HttpParams();
      Object.keys(filters).map(key => params = params.set(key, filters[key]));
      const dialog = this.dialog.open(ExportDialogComponent, {
        width: '430px',
        data: <ExportDialogData>{
          type: 'assignments',
          params: params,
          service: this.assignmentService,
          buttonText: 'Try to Export Again'
        }
      });
      dialog.componentInstance.exportSubmitted = false;
      dialog.componentInstance.errors.push(err);
      console.error(err);
    });
  }

  toggleCancelled(): void {
    this.authenticationService.setFilterProperty('hideCancelledJobs', this.hideCancelled);
    if (this.dailyBoardGrid) { this.dailyBoardGrid.getJobs(); }
    if (this.dailyBoardList) { this.dailyBoardList.refreshTable(); }
  }

  updatedMarkets(): void {
    if (this.dailyBoardGrid) { this.dailyBoardGrid.getJobs(); }
    if (this.dailyBoardList) { this.dailyBoardList.refreshTable(); }
  }

  changeSearch(term = ''): void {
    this.search = term;
  }
}
