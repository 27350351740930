import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SelectionModel } from '@angular/cdk/collections';
import { combineLatest as observableCombineLatest, Subject, Subscription } from 'rxjs';
import { difference, find as _find } from 'lodash';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';

import { FancyTableComponent } from '../shared/fancy-table/fancy-table.component';
import { TableConfig } from '../shared/fancy-table/table.types';
import { DriverJobReportService } from './driver-job-report.service';
import { DriverJobReport } from './driver-job-report';
import { ExportDialogComponent, ExportDialogData, FieldOption } from '../shared/export-dialog/export-dialog.component';
import { FilterOption } from '../shared/filters-panel/filter-option';
import { DriverJobReportFiltersDialogComponent } from './driver-job-report-filters-dialog.component';
import { NewJobReportDialogComponent } from './new-job-report-dialog.component';
import { DriverContextEvent } from '../drivers/driver-context-menu/interfaces/driver-context-event';

@Component({
  selector: 'ruckit-driver-job-reports',
  templateUrl: './driver-job-reports.component.html',
  styleUrls: ['./driver-job-reports.component.scss']
})
export class DriverJobReportsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('driverJobReportsTable', { static: false }) driverJobReportsTable: FancyTableComponent;
  @Input() availableColumns = [
    { key: 'select' },
    { key: 'driver', title: this.translationService.instant('Driver / Truck') },
    { key: 'company', title: this.translationService.instant('Company') },
    { key: 'job', title: this.translationService.instant('Job') },
    { key: 'job-number', title: this.translationService.instant('Job #') },
    { key: 'customer', title: this.translationService.instant('Customer') },
    { key: 'customer-address', title: this.translationService.instant('customer-address') },
    { key: 'clock-in-time', title: this.translationService.instant('Clock In') },
    { key: 'first-load-time', title: this.translationService.instant('First Load') },
    { key: 'loading-wait-time', title: this.translationService.instant('Loading Wait Time') },
    { key: 'unloading-wait-time', title: this.translationService.instant('Unloading Wait Time') },
    { key: 'average-round-trip-time', title: this.translationService.instant('AVG Round Trip') },
    { key: 'trip-count', title: this.translationService.instant('Trip Count') },
    // { key: 'shift-end', title: this.translationService.instant('Shift End') },
    { key: 'last-load-time', title: this.translationService.instant('Last Load') },
    // { key: 'at-yard', title: this.translationService.instant('At Yard') },
    { key: 'freight-bill-time', title: this.translationService.instant('Freight Bill Time') },
    { key: 'shift-time', title: this.translationService.instant('Shift Time') },
    { key: 'deficient-time', title: this.translationService.instant('Deficient Time') },
  ];
  @Input() displayedColumns = [
    'select', 'driver', 'company', 'job', 'job-number', 'customer',
    'customer-address', 'clock-in-time', 'first-load-time', 'loading-wait-time',
    'unloading-wait-time', 'average-round-trip-time', 'trip-count',
    'last-load-time', 'freight-bill-time', 'shift-time', 'deficient-time'
  ];
  @Input() availableFilters = [];
  @Input() appliedFilters = [];
  @Input() search = '';
  @Output() availableColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() displayedColumnsChange: EventEmitter<string[]> = new EventEmitter();
  @Output() availableFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() appliedFiltersChange: EventEmitter<any[]> = new EventEmitter();
  @Output() searchChange: EventEmitter<string> = new EventEmitter();
  searchChanged: Subject<string> = new Subject<string>();

  // config for fancy table
  tableConfig: TableConfig = <TableConfig>{
    hasHeader: true,
    service: DriverJobReportService,
    preferenceKey: 'DriverJobReportsComponent-DriverJobReportService',
    query: {},
    collectionTitle: this.translationService.instant('Driver Job Reports'),
    noResultsText: this.translationService.instant('a driver job report'),
    sortBy: 'date',
    sortDirection: 'asc',
    menuOptions: [
      { name: this.translationService.instant('Edit'), action: 'edit', link: true, external: false }
    ]
  };
  multipleActionDropdownOptions = [
    { name: this.translationService.instant('Export'), action: 'export', link: false }
  ];
  query: any = {};

  loading = true;
  errors = [];
  reportDate: Date;
  allSelected = false;
  selectedDriverJobReports: DriverJobReport[] = [];
  excludeDriverJobReports: DriverJobReport[] = [];
  filters = [];
  filtersDialog;
  type: string;
  currentDate = moment().format('YYYY-MM-DD');
  contextMenuEventSubject = new Subject<DriverContextEvent>();
  allSubscriptionsToUnsubscribe: Subscription[] = [];
  exportCallback = () => { };
  saveJobReportCallback = () => {
    if (this.driverJobReportsTable) { this.driverJobReportsTable.getRecords(); }
  }


  constructor(
    private driverJobReportService: DriverJobReportService,
    private route: ActivatedRoute,
    private router: Router,
    private translationService: TranslateService,
    public dialog: MatDialog,
  ) { }

  ngOnInit() {
    let query = {};
    let combinedParams = observableCombineLatest(
      this.route.params, this.route.queryParams,
      (params, qparams) => ({ params, qparams })
    );

    this.allSubscriptionsToUnsubscribe.push(
      combinedParams.subscribe(result => {
        this.loading = true;
        this.search = result.qparams['search'] || '';
        if (result.qparams['date']) {
          let date = moment(result.qparams['date'], 'YYYYMMDD').toDate();
          this.reportDate = date || new Date();
        } else {
          this.setDefaultDate();
        }
        let startOfRange = moment(this.reportDate).startOf('day');
        let endOfRange = moment(this.reportDate).endOf('day');
        query['active_range'] = [startOfRange.toISOString(), endOfRange.toISOString()];
        this.tableConfig['query'] = {
          ...this.tableConfig['query'], ...query
        };
        if (this.driverJobReportsTable) {
          this.driverJobReportsTable.config = <TableConfig>{ ...this.tableConfig };
          this.driverJobReportsTable.getRecords();
        }
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['reportDate']) {
      const reportDate = changes['reportDate'].currentValue;
      if (reportDate) {
        this.reportDate = new Date(reportDate);
      }
    }

    this.tableConfig['query'] = {
      active_range: this.reportDate.toISOString()
    };

    if (this.driverJobReportsTable) {
      this.driverJobReportsTable.config = <TableConfig>{ ...this.tableConfig };
      this.driverJobReportsTable.getRecords();
    }
  }

  ngOnDestroy(): void {
    this.allSubscriptionsToUnsubscribe.forEach(sub => {
      sub.unsubscribe();
    });
  }

  openReport([e, row]: [any, DriverJobReport]) {
    this.router.navigate(['driver-job-report', row.id]);
  }

  selector(event: { allSelected: boolean, selection: SelectionModel<DriverJobReport>, exclusion: SelectionModel<DriverJobReport> }) {
    this.allSelected = event.allSelected;
    if (!this.allSelected) {
      this.selectedDriverJobReports = event.selection.selected;
      this.excludeDriverJobReports = [];
    } else {
      this.selectedDriverJobReports = [];
      this.excludeDriverJobReports = event.exclusion.selected;
    }
  }

  changeSearch(term?: string): void {
    this.searchChanged.next(term);
  }

  expandSearch(): void {
    this.loading = true;
    setTimeout(() => {
      this.search = '';
      this.changeSearch();
    }, 1000);
  }

  openFilters(): void {
    const dialog = this.dialog.open(DriverJobReportFiltersDialogComponent, {
      width: '430px'
    });

    dialog.componentInstance.callback = res => this.filterChanges(res);
    // dialog.componentInstance.model.carrier = null;

    dialog.componentInstance.model = Object.assign(dialog.componentInstance.model, this.appliedFilters.reduce((acc, filter) => {
      acc[filter.key] = filter.values;
      return acc;
    }, {}));
    this.filtersDialog = dialog.componentInstance;
  }

  filterChanges(filterRes): void {
    const queryKeys = {
      reportDate: 'active_range',
      company: 'company',
      customer: 'customer'
    };
    let falseyFilters = [];
    this.appliedFilters = Object.keys(filterRes).map((key) => {
      const query = {};
      let values = filterRes[key];
      let displayValues = filterRes[key] && filterRes[key]['name'] ? filterRes[key]['name'] : values;
      if (typeof (values) === 'boolean') {
        if (values) {
          values = values.toString();
          values = values.charAt(0).toUpperCase() + values.slice(1);
          query[queryKeys[key]] = values;
        }
        displayValues = values;
      } else if (filterRes[key]) {
        query[queryKeys[key]] = filterRes[key] && filterRes[key].id ? filterRes[key].id : filterRes[key];
      }
      let filter = new FilterOption({
        filterType: ['active_range'].indexOf(key) === -1 ? 'text' : 'date',
        key: key,
        title: key.charAt(0).toUpperCase() + key.slice(1),
        displayValues: displayValues || null,
        values: values,
        query: query
      });
      if (filter.values === 'False' || !filter.values) { falseyFilters.push(filter); }
      return filter;
    });
    this.appliedFilters = difference(this.appliedFilters, falseyFilters);
  }


  setSelectedAction(option): void {
    switch (option.action) {
      case 'export':
        this.exportSelectedReports();
        break;
    }
  }

  exportSelectedReports(trips = null) {
    let scope = {};
    let params = new HttpParams();
    this.driverJobReportService.getExportFields().subscribe((fields: FieldOption[]) => {
      if (trips || this.selectedDriverJobReports.length) {
        Object.assign(scope, { include: trips || this.selectedDriverJobReports });
      } else if (this.allSelected) {
        Object.assign(scope, { exclude: this.excludeDriverJobReports });
        Object.keys(this.filters).map(key => {
          if (this.filters[key]) {
            let query = this.filters[key]['query'];
            Object.keys(query).map(queryKey => {
              params = params.set(queryKey, query[queryKey]);
            });
          }
        });
        params = params.set('search', this.search);
      }
      this.dialog.open(ExportDialogComponent, {
        width: 'auto',
        data: <ExportDialogData>{
          type: 'trips',
          buttonText: this.translationService.instant('Export Data to CSV'),
          callback: this.exportCallback,
          fields: fields,
          params: params,
          scope: scope,
          service: this.driverJobReportService
        }
      });
    }, (err) => { this.errors = err; });
  }

  onDateChanged(dates: Date[]): void {
    this.reportDate = dates[0];
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    let date = moment(this.reportDate).format('YYYYMMDD');
    queryParams['date'] = date;
    queryParams['search'] = this.search;
    this.router.navigate([], { queryParams: queryParams });
  }

  setDefaultDate(): void {
    const queryParams: Params = Object.assign({}, this.route.snapshot.queryParams);
    let date = moment();
    queryParams['date'] = date.format('YYYYMMDD');
    this.reportDate = date.toDate();
    this.router.navigate(['driver-reports'], { queryParams: queryParams });
  }

  openAddJobReport(): void {
    const dialog = this.dialog.open(NewJobReportDialogComponent, {
      width: '444px'
    });
    dialog.componentInstance.reportDate = this.reportDate;
    dialog.componentInstance.callback = this.saveJobReportCallback;
  }

  openContextMenu(event: any, driverId: string) {
    this.contextMenuEventSubject.next({
      event,
      driverId,
    });
  }
}
