"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
// Start time: Required, always
// Loading Checkin, optional overall, date/time must be later than start time and earlier than unloading date/time and end time
// Unloading Checkin, optional overall, date/time must be later than start time and loading checkin date/time, and earlier than end time
// End time: Optional, must be later than start time, loading / unloading date/time.
exports.globalTripTimeValidator = function (c) {
    if (!c) {
        return null;
    }
    var startDate = c.get('start').value.date;
    var startTime = c.get('start').value.time;
    var endDate = c.get('end').value.date;
    var endTime = c.get('end').value.time;
    var startLoadingDate = c.get('loadingCheckin').value.date;
    var startLoadingTime = c.get('loadingCheckin').value.time;
    var startUnloadingDate = c.get('unloadingCheckin').value.date;
    var startUnloadingTime = c.get('unloadingCheckin').value.time;
    if (endDate == null &&
        startLoadingDate == null &&
        startUnloadingDate == null) {
        return null;
    }
    var start = moment(moment(startDate).format('YYYY-MM-DD') + " " + startTime);
    var end = moment(moment(endDate).format('YYYY-MM-DD') + " " + endTime);
    var startLoading = moment(moment(startLoadingDate).format('YYYY-MM-DD') + " " + startLoadingTime);
    var endLoading = moment(moment(startUnloadingDate).format('YYYY-MM-DD') + " " + startUnloadingTime);
    // conditionally add date to array if not null or undefined by correct order
    var array = [
        { date: start, name: 'start' }
    ].concat((!!startLoadingDate && !!startLoadingTime
        ? [{ date: startLoading, name: 'startLoadingTime' }]
        : []), (!!startUnloadingDate && !!startUnloadingTime
        ? [{ date: endLoading, name: 'endLoadingTime' }]
        : []), (!!endDate && !!endTime ? [{ date: end, name: 'end' }] : []));
    // check if date is before previous date and add an globalTripTimeError to the form
    for (var i = 1; i < array.length; i++) {
        if (array[i].date.isBefore(array[i - 1].date)) {
            return {
                globalTripTimeError: array[i].name,
            };
        }
    }
    return null;
};
