"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var moment = require("moment");
var user_serializer_1 = require("../users/user.serializer");
var lodash_1 = require("lodash");
var job_serializer_1 = require("../jobs/job.serializer");
var location_serializer_1 = require("../locations/location.serializer");
var organization_1 = require("../organizations/organization");
var camelcaseKeysDeep = require('camelcase-keys-deep');
var JobEvent = /** @class */ (function () {
    function JobEvent(jobEventInfo) {
        jobEventInfo = camelcaseKeysDeep(jobEventInfo);
        this.id = jobEventInfo.id;
        this.shift1Start = jobEventInfo.shift1Start;
        this.shift1End = jobEventInfo.shift1End;
        this.shift1StartTimestamp = jobEventInfo.shift1Start;
        this.shift1EndTimestamp = jobEventInfo.shift1End;
        this.shift2StartTimestamp = jobEventInfo.shift2Start;
        this.shift2EndTimestamp = jobEventInfo.shift2End;
        this.staggerStartTime = moment(this.shift1StartTimestamp).format('H:mm A');
        this.jobEnd = this.shift2EndTimestamp ? this.shift2EndTimestamp : this.shift1EndTimestamp;
        this.shift1Status = jobEventInfo.shift1Status;
        this.shift2Status = jobEventInfo.shift2Status;
        if (this.shift1StartTime && this.shift1EndTime) {
            var startTime = moment(this.shift1StartTime, 'h:mm a');
            var endTime = moment(this.shift1EndTime, 'h:mm a');
            this.shift1Overnight = endTime.isBefore(startTime);
        }
        else if (this.shift2StartTime && this.shift2EndTime) {
            var startTime = moment(this.shift2StartTime, 'h:mm a');
            var endTime = moment(this.shift2EndTime, 'h:mm a');
            this.shift2Overnight = endTime.isBefore(startTime);
        }
        this.deliveryInterval = jobEventInfo.deliveryInterval;
        this.rate = jobEventInfo.rate;
        if (typeof (jobEventInfo.job) === 'object') {
            this.job = new job_serializer_1.JobSerializer().fromJson(jobEventInfo.job);
        }
        else {
            this.job = new job_serializer_1.JobSerializer().fromJson({ id: jobEventInfo.job });
        }
        this.phaseCode = jobEventInfo.phaseCode || this.job.phaseCode;
        if (jobEventInfo.startLocation && typeof (jobEventInfo.startLocation) === 'object') {
            this.startLocation = new location_serializer_1.LocationSerializer().fromJson(jobEventInfo.startLocation);
        }
        else if (jobEventInfo.startLocation) {
            this.startLocation = new location_serializer_1.LocationSerializer().fromJson({ id: jobEventInfo.startLocation });
        }
        if (jobEventInfo.endLocation && typeof (jobEventInfo.endLocation) === 'object') {
            this.endLocation = new location_serializer_1.LocationSerializer().fromJson(jobEventInfo.endLocation);
        }
        else if (jobEventInfo.endLocation) {
            this.endLocation = new location_serializer_1.LocationSerializer().fromJson({ id: jobEventInfo.endLocation });
        }
        this.invoiceType = jobEventInfo.invoiceType;
        this.invoiceWeightUnit = jobEventInfo.invoiceWeightUnit;
        this.haulRate = jobEventInfo.haulRate;
        this.haulWeightUnit = jobEventInfo.haulWeightUnit;
        this.haulType = jobEventInfo.haulType;
        this.numTrucks = jobEventInfo.numTrucks;
        this.loadDeliveryTarget = jobEventInfo.loadDeliveryTarget;
        this.dailyDeliveryTarget = jobEventInfo.dailyDeliveryTarget;
        this.dailyDeliveryTargetType = jobEventInfo.dailyDeliveryTargetType;
        this.totalAmountType = jobEventInfo.totalAmountType;
        if (jobEventInfo.lastDispatchedTime) {
            this.lastDispatchedTime = new Date(jobEventInfo.lastDispatchedTime);
        }
        if (jobEventInfo.lastDispatchedBy) {
            this.lastDispatchedBy = (new user_serializer_1.UserSerializer).fromJson(jobEventInfo.lastDispatchedBy);
        }
        this.externalIdentifier = jobEventInfo.externalIdentifier;
        if (jobEventInfo.ownerOrganization && typeof (jobEventInfo.ownerOrganization) === 'object') {
            this.ownerOrganization = new organization_1.Organization(jobEventInfo.ownerOrganization);
        }
        else if (jobEventInfo.ownerOrganization) {
            this.ownerOrganization = new organization_1.Organization({ id: jobEventInfo.ownerOrganization });
        }
        this.displayName = jobEventInfo.displayName;
        // this.jobDisplayName = [this.externalIdentifier, this.job.displayName].filter(Boolean).join(': ');
        this.jobDisplayName = this.job.displayName;
        this.estimatedNumberOfLoads = jobEventInfo.estimatedNumberOfLoads;
        this.estimatedNumberOfTons = jobEventInfo.estimatedNumberOfTons;
        this.assignmentsCount = jobEventInfo.assignmentsCount;
        this.routePolyline = jobEventInfo.routePolyline || this.job.routePolyline;
        this.truckTypes = jobEventInfo.truckTypes;
        this.allowAnyTruckType = jobEventInfo.allowAnyTruckType;
        this.shareId = jobEventInfo.shareId;
        this.canShare = jobEventInfo.canShare;
        this.canEdit = jobEventInfo.canEdit;
        this.canAutoAssign = true;
        this.isShared = jobEventInfo.isShared;
        this.multipliers = jobEventInfo.multipliers;
        this.notes = jobEventInfo.notes || jobEventInfo.job && jobEventInfo.job.notes;
        this.status = jobEventInfo.status;
        this.cancelled = jobEventInfo.cancelled;
        this.dispatchedCount = jobEventInfo.assignmentsCount || jobEventInfo.dispatchedCount || 0;
        this.dsorderId = jobEventInfo.dsorderId;
        this.slorderId = jobEventInfo.slorderId;
        this.pin = jobEventInfo.pin;
        this.requestedAmount = jobEventInfo.requestedAmount;
        this.requestedUnit = jobEventInfo.requestedUnit;
        this.defaultRoundTripTime = jobEventInfo.defaultRoundTripTime;
        this.defaultYardPrepTime = jobEventInfo.defaultYardPrepTime;
        this.defaultYardBufferTime = jobEventInfo.defaultYardBufferTime;
        if (jobEventInfo.defaultYardBufferTime && parseFloat(jobEventInfo.defaultYardBufferTime) > 0) {
            this.defaultYardBufferMinutes = parseFloat(jobEventInfo.defaultYardBufferTime) / 60;
        }
        this.multipleItemsData = jobEventInfo.multipleItemsData || this.multipleItemsData;
        this.assignmentLoadCount = jobEventInfo.assignmentLoadCount;
        if (this.job.startLocation && this.job.startLocation.location && typeof this.job.startLocation.location === 'object') {
            this.startCoordinates = {
                longitude: this.job.startLocation.location.coordinates[0],
                latitude: this.job.startLocation.location.coordinates[1]
            };
        }
        else if (this.job.startLocation) {
            var location_1 = this.job.startLocation.location;
            if (location_1) {
                this.startCoordinates = {
                    longitude: location_1.coordinates[0],
                    latitude: location_1.coordinates[1]
                };
            }
        }
        if (this.job.endLocation && this.job.endLocation.location && typeof this.job.endLocation.location === 'object') {
            this.endCoordinates = {
                longitude: this.job.endLocation.location.coordinates[0],
                latitude: this.job.endLocation.location.coordinates[1]
            };
        }
        else if (this.job.endLocation) {
            var location_2 = this.job.endLocation.location;
            if (location_2) {
                this.endCoordinates = {
                    longitude: location_2.coordinates[0],
                    latitude: location_2.coordinates[1]
                };
            }
        }
        if (this.startCoordinates && this.startCoordinates.longitude &&
            this.endCoordinates && this.endCoordinates.longitude) {
            this.directions = {
                origin: this.startCoordinates.latitude + ',' + this.startCoordinates.longitude,
                destination: this.endCoordinates.latitude + ',' + this.endCoordinates.longitude,
                travelMode: 'DRIVING'
            };
        }
        if (this.routePolyline) {
            this.mapImageURL = 'https://maps.googleapis.com/maps/api/staticmap?size=418x178&path=color:0x002649|weight:4|enc:';
            this.mapImageURL += this.routePolyline;
            this.mapImageURL += '&format=png&key=AIzaSyBAu5NOBPntTu3dxvuS1WDjEuY4XhueVdQ';
        }
        this.name = moment(this.shift1StartTimestamp).format('MM/DD/YYYY h:mm a') + " - " + moment(this.jobEnd).format('MM/DD/YYYY h:mm a');
        this.nameWithJob = [
            this.name, this.job && this.jobDisplayName
        ].filter(Boolean).join(': ');
        this.jobNameWithDsOrderId = [this.externalIdentifier, this.job.name].filter(Boolean).join(': ');
        this.workOrderTemplate = jobEventInfo.workOrderTemplate;
        if (this.job && this.job.startLocation && this.job.startLocation.geofence && this.job.endLocation && this.job.endLocation.geofence) {
            this.hasGeofences = true;
        }
        else {
            this.hasGeofences = false;
        }
    }
    Object.defineProperty(JobEvent.prototype, "jobStart", {
        get: function () {
            if (this.shift1StartTimestamp) {
                var start = this.shift1StartTimestamp.split('T')[0];
                return moment(start).toDate();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "endTime", {
        get: function () {
            var d = new Date(this.jobEnd);
            return moment(d).format('h:mm a');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "shifts", {
        get: function () {
            if (this.shift2EndTimestamp) {
                return 2;
            }
            else {
                return 1;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "shift1StartTime", {
        get: function () {
            var d = new Date(this.shift1StartTimestamp);
            return moment(d).format('h:mm a');
        },
        set: function (value) {
            var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
            var date = moment(this.shift1StartTimestamp).format('YYYY-MM-DD');
            var parsedDate = moment(date + ' ' + value, pattern);
            this.shift1Start = parsedDate.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "shift1EndTime", {
        get: function () {
            var d = new Date(this.shift1EndTimestamp);
            return moment(d).format('h:mm a');
        },
        set: function (value) {
            var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
            var date = moment(this.shift1EndTimestamp).format('YYYY-MM-DD');
            var parsedDate = moment(date + ' ' + value, pattern);
            this.shift1End = parsedDate.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "shift2StartTime", {
        get: function () {
            var d = new Date(this.shift2StartTimestamp);
            return moment(d).format('h:mm a');
        },
        set: function (value) {
            var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
            var date = moment(this.shift2StartTimestamp || this.shift1StartTimestamp).format('YYYY-MM-DD');
            var parsedDate = moment(date + ' ' + value, pattern);
            this.shift2Start = parsedDate.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "shift2EndTime", {
        get: function () {
            var d = new Date(this.shift2EndTimestamp);
            return moment(d).format('h:mm a');
        },
        set: function (value) {
            var pattern = value.match(/[ap]m/i) ? 'YYYY-MM-DD h:mm a' : 'YYYY-MM-DD h:mm';
            var date = moment(this.shift2EndTimestamp || this.shift1EndTimestamp).format('YYYY-MM-DD');
            var parsedDate = moment(date + ' ' + value, pattern);
            this.shift2End = parsedDate.toISOString();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "dateRange", {
        get: function () {
            var range = [];
            var endDate = new Date(this.jobEnd);
            if (this.jobStart) {
                range.push(moment(this.jobStart).format('dddd, MMMM Qo'));
            }
            if (endDate) {
                range.push(moment(endDate).format('dddd, MMMM Qo'));
            }
            return lodash_1.uniq(range.filter(Boolean)).join(' - ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "deliveryIntervalMinutes", {
        get: function () {
            var intervalNumber = this.deliveryInterval ? Number(this.deliveryInterval) : 0;
            return Math.round(intervalNumber);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "deliveryIntervalHours", {
        get: function () {
            var intervalNumber = this.deliveryInterval ? Number.parseFloat(this.deliveryInterval) : 0;
            intervalNumber /= 60;
            return intervalNumber.toFixed(2);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "notice", {
        get: function () {
            var values = [];
            var statusType = 'standard';
            if (this.job.project && this.job.project.name) {
                values.push(this.job.project.name);
            }
            var statusText = values.filter(function (v) {
                return (v !== '' && v !== null);
            }).join(' - ');
            return { statusText: statusText, statusType: statusType };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "invoiceRateUnit", {
        get: function () {
            if (this.invoiceType === 'weight') {
                return this.invoiceWeightUnit;
            }
            else {
                return this.invoiceType;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "haulRateUnit", {
        get: function () {
            if (this.haulType === 'weight') {
                return this.haulWeightUnit;
            }
            else {
                return this.haulType;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "loadStatus", {
        get: function () {
            return '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "dayEstimate", {
        get: function () {
            var values = ['Estimated Day:'];
            var estimates = [];
            if (this.estimatedNumberOfTons !== undefined) {
                estimates.push(this.estimatedNumberOfTons + ' tons');
            }
            if (this.estimatedNumberOfLoads !== undefined) {
                estimates.push(this.estimatedNumberOfLoads + ' loads');
            }
            if (estimates.length === 0) {
                estimates.push('0');
            }
            values.push(estimates.filter(function (v) {
                return (v !== '' && v !== null);
            }).join(', '));
            return values.filter(function (v) {
                return (v !== '' && v !== null);
            }).join(' ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "shiftOneDuration", {
        get: function () {
            var startTime = moment(this.shift1StartTimestamp);
            var endTime = moment(this.shift1EndTimestamp);
            var duration = moment.duration(endTime.diff(startTime));
            var value = duration.humanize().replace(/s?$/, '');
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "shiftTwoDuration", {
        get: function () {
            var startTime = moment(this.shift2StartTimestamp);
            var endTime = moment(this.shift2EndTimestamp);
            var duration = moment.duration(endTime.diff(startTime));
            var value = duration.humanize().replace(/s?$/, '');
            return value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "mapCenter", {
        get: function () {
            if (this.startCoordinates) {
                return this.startCoordinates.latitude + ',' + this.startCoordinates.longitude;
            }
            else {
                return '';
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "mapCenterCoordinates", {
        get: function () {
            if (this.startCoordinates && this.startCoordinates.latitude && this.startCoordinates.longitude) {
                return {
                    lat: parseFloat(this.startCoordinates.latitude),
                    lng: parseFloat(this.startCoordinates.longitude)
                };
            }
            else {
                return null;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "statusText", {
        get: function () {
            var statusText;
            if ((this.shift1Status === 'cancelled' && this.shift2Status === 'cancelled') || this.status === 'cancelled') {
                statusText = 'Day Cancelled';
            }
            else if (this.shift1Status === 'cancelled') {
                statusText = 'Shift 1 Cancelled';
            }
            else if (this.shift2Status === 'cancelled') {
                statusText = 'Shift 2 Cancelled';
            }
            return statusText;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "truckTypeNames", {
        get: function () {
            var names = [];
            if (this.allowAnyTruckType) {
                return 'Any Type';
            }
            else if (this.truckTypes) {
                names = this.truckTypes.map(function (truckType) {
                    return truckType.name;
                });
            }
            return names.filter(Boolean).join(', ');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(JobEvent.prototype, "future", {
        get: function () {
            var _date = new Date();
            _date.setHours(23, 59, 59, 999);
            return this.jobStart > _date;
        },
        enumerable: true,
        configurable: true
    });
    return JobEvent;
}());
exports.JobEvent = JobEvent;
