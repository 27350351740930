"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var router_1 = require("@angular/router");
var material_1 = require("@angular/material");
var http_1 = require("@angular/common/http");
var rxjs_1 = require("rxjs");
var moment = require("moment");
var shared_1 = require("../../shared");
var preference_service_1 = require("../../preferences/preference.service");
var driver_service_1 = require("../../drivers/driver.service");
var assignment_service_1 = require("../../assignments/assignment.service");
var condensed_job_event_service_1 = require("../../job-events/condensed-job-event.service");
var job_event_service_1 = require("../../job-events/job-event.service");
var connection_service_1 = require("../../connections/connection.service");
var event_alert_service_1 = require("../../shared/event-alert/event-alert.service");
var export_dialog_component_1 = require("../../shared/export-dialog/export-dialog.component");
var driver_assignments_list_component_1 = require("../../assignments/driver-assignments-list.component");
var dispatch_schedule_table_component_1 = require("./schedule-table/dispatch-schedule-table.component");
var assignment_error_dialog_component_1 = require("../../messages/assignment-error-dialog.component");
var dispatch_schedule_filters_dialog_component_1 = require("./filters-dialog/dispatch-schedule-filters-dialog.component");
var assignment_truck_management_dialog_component_1 = require("../../messages/assignment-truck-management-dialog/assignment-truck-management-dialog.component");
var collaborator_service_1 = require("../../collaborators/collaborator.service");
var dialogs_1 = require("../../shared/dialogs");
var confirm_change_job_loads_service_1 = require("../dispatch-by-job/confirm-change-job-loads.service");
var DispatchScheduleComponent = /** @class */ (function () {
    function DispatchScheduleComponent(dialog, route, router, authenticationService, preferenceService, condensedJobEventService, jobEventService, driverService, collaboratorService, assignmentService, connectionService, eventAlertService, confirmChangeJobLoadsService) {
        this.dialog = dialog;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.preferenceService = preferenceService;
        this.condensedJobEventService = condensedJobEventService;
        this.jobEventService = jobEventService;
        this.driverService = driverService;
        this.collaboratorService = collaboratorService;
        this.assignmentService = assignmentService;
        this.connectionService = connectionService;
        this.eventAlertService = eventAlertService;
        this.confirmChangeJobLoadsService = confirmChangeJobLoadsService;
        this.preferenceKey = 'GeneralUserPreferences';
        this.hasAllDriversEnabled = false;
        this.view = 'jobEvents';
        this.activeLoadType = 'Loads Ordered';
        this.driverLoadingProgress = 0;
        this.jobEventLoadingProgress = 0;
        this.connectionLoadingProgress = 0;
        this.assignmentLoadingProgress = 0;
        this.shareLoadingProgress = 0;
        this.errors = [];
        this.selectedFilters = {};
        this.filterOptions = {};
        this.showCancelledJobEvents = false;
        this.processingUpdate = false;
        this.assigningTrucks = false;
        this.driverQuery = { all_carriers: true };
        this.selectedDrivers = [];
        this.updatedJobEvents = [];
        this.updatedAssignments = [];
        this.jobEventEditPanelOpen = false;
        this.selectedJobEvents = [];
        this.connectionQuery = {
            ordering: 'organization__name',
            leased_org: 'False',
            is_carrier: 'True',
            customer_only: 'False',
            status: 'active'
        };
        this.selectedConnections = [];
        this.regionOptions = [{ name: 'Austin', value: 'austin' }];
        this.regionsDropdownConfig = {
            nameProperty: 'name',
            showLoading: true
        };
        this.carrierDropdownData = {
            carriers: [],
            config: {
                nameProperty: 'name',
                searchable: true,
                loadingOptions: false
            },
            loading: false
        };
        this.loadStats = { remaining: 0, assigned: 0,
            ordered: { loads: 0, tons: 0, tonnes: 0, lbs: 0, kgs: 0, bushels: 0, bags: 0 }
        };
        this.truckStats = { remaining: 0, assigned: 0, requested: 0 };
        this.truckTypeStats = { required: 0, assigned: 0, available: 0, needed: 0 };
        this.tonStats = { remaining: 0, assigned: 0, requested: 0 };
        this.allSubscriptionsToUnsubscribe = [];
    }
    DispatchScheduleComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.user = this.authenticationService.user();
        this.hasAllDriversEnabled = this.authenticationService.hasAllDriversEnabled();
        this.getPreferences();
        this.getCarriers();
        this.allSubscriptionsToUnsubscribe.push(rxjs_1.combineLatest(this.route.params, this.route.queryParams, function (params, qparams) { return ({ params: params, qparams: qparams }); }).subscribe(function (result) {
            if (result.qparams['date']) {
                _this.selectDate([moment(result.qparams['date'], 'YYYYMMDD').toDate()]);
            }
            if (result.qparams['view']) {
                _this.switchView(result.qparams['view']);
            }
            else {
                _this.switchView('dispatch');
            }
            if (result.qparams['jobEvent']) {
                _this.autoOpenJobEvent = result.qparams['jobEvent'];
            }
        }));
        this.allSubscriptionsToUnsubscribe.push(this.driverService.listAllProgress.subscribe(function (progress) {
            _this.driverLoadingProgress = Math.ceil(progress * 100);
        }), this.condensedJobEventService.listAllProgress.subscribe(function (progress) {
            if (!_this.processingUpdate) {
                _this.jobEventLoadingProgress = Math.ceil(progress * 100);
            }
        }), this.connectionService.listAllProgress.subscribe(function (progress) {
            _this.connectionLoadingProgress = Math.ceil(progress * 100);
        }), this.assignmentService.listAllProgress.subscribe(function (progress) {
            if (!_this.processingUpdate) {
                _this.assignmentLoadingProgress = Math.ceil(progress * 100);
            }
        }), this.collaboratorService.listAllProgress.subscribe(function (progress) {
            _this.shareLoadingProgress = Math.ceil(progress * 100);
        }));
    };
    DispatchScheduleComponent.prototype.ngOnDestroy = function () {
        this.allSubscriptionsToUnsubscribe.forEach(function (sub) {
            sub.unsubscribe();
        });
    };
    DispatchScheduleComponent.prototype.switchView = function (view) {
        this.view = view === 'collaborate' ? 'collaborators' : view === 'dispatch' ? 'jobEvents' : 'drivers';
        var queryParams = Object.assign({}, this.route.snapshot.queryParams);
        if (queryParams['view'] !== view) {
            this.selectedDrivers = [];
            this.selectedJobEvents = [];
            this.selectedConnections = [];
        }
        queryParams['view'] = view;
        this.router.navigate([], { relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge' });
    };
    DispatchScheduleComponent.prototype.selectDate = function (dates) {
        if (dates && dates[0] && (!!!this.selectedDate || this.selectedDate.getUTCDate() !== dates[0].getUTCDate())) {
            this.selectedDrivers = [];
            this.selectedDate = dates[0];
            this.selectedDateObj = {
                year: this.selectedDate.getFullYear(),
                month: this.selectedDate.getMonth() + 1,
                day: this.selectedDate.getDate()
            };
            var queryParams = Object.assign({}, this.route.snapshot.queryParams);
            this.getJobEvents();
            this.selectedDateParam = moment(this.selectedDate).format('YYYYMMDD');
            queryParams['date'] = this.selectedDateParam;
            if (!(this.preference && this.preference.blob && this.preference.blob['date']) ||
                (this.preference.blob['date'] !== this.selectedDate.toISOString())) {
                this.savePreferences({ date: this.selectedDate.toISOString() });
            }
            this.router.navigate([], { relativeTo: this.route, queryParams: queryParams, queryParamsHandling: 'merge' });
        }
    };
    DispatchScheduleComponent.prototype.toggleCancelled = function (e) {
        this.showCancelledJobEvents = e.target['checked'];
        this.getJobEvents();
    };
    DispatchScheduleComponent.prototype.getJobEvents = function (jobEventQuery) {
        var _this = this;
        if (jobEventQuery === void 0) { jobEventQuery = {}; }
        var query = __assign({ shift1_start__gte: moment(this.selectedDate).startOf('day').toISOString(), shift1_start__lte: moment(this.selectedDate).endOf('day').toISOString(), exclude_pending: 'True', cancelled: this.showCancelledJobEvents ? undefined : 'False' }, jobEventQuery);
        this.jobEventsLastRequestedAt = moment().toISOString();
        this.condensedJobEventService.listAll(50, query).subscribe(function (jobEvents) {
            _this.jobEvents = jobEvents.map(function (jobEvent) { return (Object.assign(jobEvent, { assignments: [], shares: [], selected: false })); });
            _this.filterJobEvents();
            if (!_this.drivers) {
                _this.getDrivers();
            }
            else {
                _this.getAssignments(_this.drivers);
            }
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DispatchScheduleComponent.prototype.getModifiedJobEvents = function (jobEventQuery) {
        var _this = this;
        if (jobEventQuery === void 0) { jobEventQuery = {}; }
        if (!jobEventQuery['last_modified__gt']) {
            this.getJobEvents(jobEventQuery);
            return;
        }
        this.processingUpdate = true;
        var query = __assign({ shift1_start__gte: moment(this.selectedDate).startOf('day').toISOString(), shift1_start__lte: moment(this.selectedDate).endOf('day').toISOString(), exclude_pending: 'True', cancelled: this.showCancelledJobEvents ? undefined : 'False', page_size: 10 }, jobEventQuery);
        this.condensedJobEventService.list(query).subscribe(function (jobEvents) {
            // this.jobEventsLastRequestedAt = moment().subtract(1, 'minutes').toISOString();
            _this.getAssignmentUpdates(jobEventQuery);
            jobEvents.forEach(function (jobEvent) {
                var existingJobEventIdx = _this.jobEvents.findIndex(function (j) { return j.id === jobEvent.id; });
                var mergedJobEvent = Object.assign(__assign({}, _this.jobEvents[existingJobEventIdx]), jobEvent);
                var existingJobEventUnchanged = existingJobEventIdx > -1
                    ? JSON.stringify(mergedJobEvent) === JSON.stringify(_this.jobEvents[existingJobEventIdx]) : false;
                var existingUpdateIdx = _this.updatedJobEvents.findIndex(function (j) { return j.id === jobEvent.id; });
                var mergedUpdate = Object.assign(__assign({}, _this.updatedJobEvents[existingUpdateIdx]), jobEvent);
                // Check if jobEvent is already in jobEvents array and if it has changes
                if (!existingJobEventUnchanged) {
                    // Check if jobEvent is in the updates array already and if it has changes
                    if (existingUpdateIdx > -1 && JSON.stringify(mergedUpdate) !== JSON.stringify(jobEvent)) {
                        _this.updatedJobEvents.splice(existingUpdateIdx, 1, jobEvent);
                    }
                    else {
                        _this.updatedJobEvents.push(jobEvent);
                    }
                }
            });
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DispatchScheduleComponent.prototype.showUpdateAlert = function () {
        var _this = this;
        var hasUpdatedJobEvents = this.hasUpdatedJobEvents();
        var hasUpdatedAssignments = this.hasUpdatedAssignments();
        if (hasUpdatedJobEvents || hasUpdatedAssignments) {
            this.allSubscriptionsToUnsubscribe.push(this.eventAlertService.showAlert('Jobs have been changed.', 'Show Changes').subscribe(function (clicked) {
                if (clicked) {
                    _this.onShowChanges();
                }
            }));
        }
    };
    DispatchScheduleComponent.prototype.onShowChanges = function () {
        var modifiedJobEvents = this.jobEvents.slice();
        this.updatedJobEvents.forEach(function (jobEvent) {
            var existingJobEventIdx = modifiedJobEvents.findIndex(function (j) { return j.id === jobEvent.id; });
            if (existingJobEventIdx > -1) {
                modifiedJobEvents[existingJobEventIdx]['updates'] = true;
                Object.assign(modifiedJobEvents[existingJobEventIdx], jobEvent);
            }
        });
        this.jobEvents = modifiedJobEvents;
        this.filterJobEvents();
        this.mergeAssignmentUpdates();
        this.updatedJobEvents = [];
    };
    DispatchScheduleComponent.prototype.getJobEventStats = function (jobEvents) {
        var _this = this;
        this.loadStats = { remaining: 0, assigned: 0,
            ordered: { loads: 0, tons: 0, tonnes: 0, lbs: 0, kgs: 0, bushels: 0, bags: 0 }
        };
        this.truckStats = { remaining: 0, assigned: 0, requested: 0 };
        this.tonStats = { remaining: 0, assigned: 0, requested: 0 };
        this.truckTypeStats = { required: 0, assigned: 0, available: 0, needed: 0 };
        jobEvents.forEach(function (j) {
            _this.loadStats.assigned += Number(j.assignedLoads);
            _this.loadStats.ordered[j.dailyDeliveryTargetType] += Number(j.dailyDeliveryTarget);
            _this.truckStats.assigned += j.assignments.length;
            _this.truckStats.requested += Number(j.numTrucks);
            if (j.requestedUnit && j.requestedUnit.key !== 'Tons') {
                _this.truckTypeStats.required += Number(j.requestedAmount);
            }
            _this.truckTypeStats.assigned += j.assignments.length;
            if (j.dailyDeliveryTargetType === 'tons') {
                _this.tonStats.requested += Number(j.dailyDeliveryTarget);
            }
        });
        this.loadStats.remaining = this.loadStats.ordered.loads - this.loadStats.assigned;
        this.truckStats.remaining = this.truckStats.requested - this.truckStats.assigned;
        this.truckTypeStats.available = this.filteredDrivers && this.filteredDrivers.length
            ? this.filteredDrivers.length - this.filteredDrivers.filter(function (driver) { return (driver.assignments.length > 0); }).length : 0;
        this.truckTypeStats.needed = this.truckTypeStats.required > this.truckTypeStats.available
            ? this.truckTypeStats.required - this.truckTypeStats.available : 0;
    };
    DispatchScheduleComponent.prototype.changeDriverQuery = function (query) {
        this.driverQuery = Object.assign(this.driverQuery, query);
        this.getDrivers();
    };
    DispatchScheduleComponent.prototype.getDrivers = function () {
        var _this = this;
        this.driverService.listAll(100, this.driverQuery).subscribe(function (drivers) {
            if (_this.drivers && _this.assignmentsDate.toISOString() === _this.selectedDate.toISOString()) {
                var fullAssignmentList_1 = [];
                _this.drivers.forEach(function (d) {
                    fullAssignmentList_1.push.apply(fullAssignmentList_1, d.assignments);
                });
                _this.assignmentsList.forEach(function (a) {
                    if (fullAssignmentList_1.findIndex(function (existingAssignment) { return (existingAssignment.id === a.id); })) {
                        fullAssignmentList_1.push(a);
                    }
                });
                _this.assignmentsList = fullAssignmentList_1.sort(function (a, b) { return (moment(a.uniqueStart).diff(b.uniqueStart, 'minutes')); });
                var dispatchDrivers_1 = drivers.map(function (driver) { return (Object.assign(driver, { assignments: [], loadCount: 0 })); });
                _this.assignmentsList.forEach(function (assignment) {
                    var driverIndex = drivers.findIndex(function (driver) { return (driver.id === assignment.driver.id); });
                    if (driverIndex > -1) {
                        dispatchDrivers_1[driverIndex].assignments.push(assignment);
                        dispatchDrivers_1[driverIndex].loadCount += Number(assignment.maxNumberOfLoads);
                    }
                });
                _this.drivers = dispatchDrivers_1.slice();
                _this.filteredDrivers = _this.drivers.slice();
            }
            else {
                var dispatchDrivers = drivers.map(function (driver) { return (Object.assign(driver, { assignments: [], loadCount: 0 })); });
                _this.getAssignments(dispatchDrivers);
            }
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DispatchScheduleComponent.prototype.getConnections = function () {
        // this.connectionService.listAll(100, this.connectionQuery).subscribe(connections => {
        //   if (this.connections && this.assignmentsDate.toISOString() === this.selectedDate.toISOString()) {
        //     let fullShareList = [];
        //     this.connections = connections.map(connection => (Object.assign(connection, { shares: [], truckCount: 0 })));
        //     this.connections.forEach(c => {
        //       fullShareList.push(...c.shares);
        //     });
        //     this.sharesList.forEach(s => {
        //       if (fullShareList.findIndex(existingShare => (existingShare.id === s.id)) < 0) {
        //         fullShareList.push(s);
        //       }
        //     });
        //     this.sharesList = fullShareList;
        //     let dispatchConnections: DispatchConnection[] = connections.map(connection => (
        //       Object.assign(connection, { shares: [], truckCount: 0 })
        //     ));
        //     this.sharesList.forEach(share => {
        //       const connectionIndex = connections.findIndex(connection => (connection.organization.id === share.organizationId));
        //       if (connectionIndex > -1) {
        //         dispatchConnections[connectionIndex].shares.push(share);
        //         dispatchConnections[connectionIndex].truckCount += share.numTrucks;
        //       }
        //     });
        //     this.connections = [...dispatchConnections];
        //   } else {
        //     let dispatchConnections: DispatchConnection[] = connections.map(connection => (
        //       Object.assign(connection, { shares: [], truckCount: 0 })
        //     ));
        //     this.getShares(dispatchConnections);
        //   }
        // }, (err) => this.errors = parseErrors(err));
    };
    DispatchScheduleComponent.prototype.getAssignments = function (drivers) {
        var _this = this;
        this.filteredDrivers = drivers.map(function (driver) { return (Object.assign(driver, { assignments: [], loadCount: 0 })); });
        this.filteredJobEvents = this.filteredJobEvents.map(function (jobEvent) { return (Object.assign(jobEvent, { assignments: [], assignedLoads: 0, assignedTons: 0, truckList: '', shareList: '' })); });
        this.assignmentService.listAll(10, {
            jobevent__shift1_start__gte: moment(this.selectedDate).startOf('day').toISOString(),
            jobevent__shift1_start__lte: moment(this.selectedDate).endOf('day').toISOString(),
            can_dispatch: true
        }).subscribe(function (assignments) {
            _this.assignmentsDate = _this.selectedDate;
            _this.assignmentsList = assignments.sort(function (a, b) { return (moment(a.uniqueStart).diff(b.uniqueStart, 'minutes')); });
            _this.assignmentsList.forEach(function (assignment) {
                var driverIndex = drivers.findIndex(function (driver) { return (driver.id === assignment.driver.id); });
                if (driverIndex > -1) {
                    drivers[driverIndex].assignments.push(assignment);
                    drivers[driverIndex].loadCount += Number(assignment.maxNumberOfLoads);
                }
                var jobIndex = _this.filteredJobEvents.findIndex(function (jobEvent) { return (jobEvent['id'] === assignment.jobevent.id); });
                if (jobIndex > -1) {
                    var found = _this.filteredJobEvents[jobIndex] &&
                        _this.filteredJobEvents[jobIndex].assignments &&
                        _this.filteredJobEvents[jobIndex].assignments.length ?
                        _this.filteredJobEvents[jobIndex].assignments.some(function (a) { return a.id === assignment.id; }) : false;
                    // only push to assignments if it is not already there
                    if (!found) {
                        _this.filteredJobEvents[jobIndex].assignments.push(assignment);
                        _this.filteredJobEvents[jobIndex].assignedLoads += Number(assignment.maxNumberOfLoads);
                        _this.filteredJobEvents[jobIndex].assignedTons = Number(_this.filteredJobEvents[jobIndex].loadDeliveryTarget) *
                            _this.filteredJobEvents[jobIndex].assignedLoads;
                        _this.filteredJobEvents[jobIndex].truckList += assignment.truck.name && assignment.truck.name + '\n';
                    }
                }
            });
            _this.getJobEventStats(_this.filteredJobEvents);
            _this.filteredDrivers = drivers.slice();
            _this.filteredJobEvents = _this.filteredJobEvents.slice();
            if (_this.autoOpenJobEvent) {
                _this.selectedJobEvents = [_this.autoOpenJobEvent];
                _this.openJobEventEditPanel(_this.filteredJobEvents[_this.filteredJobEvents.findIndex(function (j) { return (j.id === _this.autoOpenJobEvent); })]);
                _this.autoOpenJobEvent = undefined;
            }
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DispatchScheduleComponent.prototype.getAssignmentUpdates = function (assignmentsQuery) {
        var _this = this;
        if (assignmentsQuery === void 0) { assignmentsQuery = {}; }
        var query = __assign({ jobevent__shift1_start__gte: moment(this.selectedDate).startOf('day').toISOString(), jobevent__shift1_start__lte: moment(this.selectedDate).endOf('day').toISOString(), page_size: 10, can_dispatch: true }, assignmentsQuery);
        this.assignmentService.list(query).subscribe(function (assignments) {
            _this.assignmentsDate = _this.selectedDate;
            var existingAssignments = _this.getExistingAssignments();
            assignments.forEach(function (assignment) {
                var existingAssignmentIdx = existingAssignments.findIndex(function (a) { return a.id === assignment.id; });
                var updateIdx = _this.updatedAssignments
                    .findIndex(function (a) { return a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id; });
                var mergedAssignmentUpdate = Object.assign(__assign({}, _this.updatedAssignments[updateIdx]), assignment);
                // Check if assignment is already in assignmentList array and if it has changes
                if (existingAssignmentIdx === -1) {
                    // Check if assignment is in the updates array already and if it has changes
                    if (updateIdx > -1 && JSON.stringify(_this.updatedAssignments[updateIdx]) !== JSON.stringify(mergedAssignmentUpdate)) {
                        _this.updatedAssignments.splice(updateIdx, 1, assignment);
                    }
                    else {
                        _this.updatedAssignments.push(assignment);
                    }
                }
            });
            if (_this.authenticationService.getFeature('dispatchSchedulerChangeTracking')) {
                _this.showUpdateAlert();
            }
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DispatchScheduleComponent.prototype.mergeAssignmentUpdates = function () {
        var _this = this;
        this.updatedAssignments.forEach(function (assignment) {
            var assignmentIdx = _this.assignmentsList
                .findIndex(function (a) { return a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id; });
            if (assignmentIdx > -1) {
                _this.assignmentsList.splice(assignmentIdx, 1, assignment);
            }
            else {
                _this.assignmentsList.push(assignment);
            }
        });
        this.assignmentsList.sort(function (a, b) { return (moment(a.uniqueStart).diff(b.uniqueStart, 'minutes')); });
        this.assignmentsList.forEach(function (assignment) {
            var driverIndex = _this.filteredDrivers.findIndex(function (driver) { return (driver.id === assignment.driver.id); });
            if (driverIndex > -1) {
                var assignmentIdx = _this.filteredDrivers[driverIndex].assignments
                    .findIndex(function (a) { return a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id; });
                if (assignmentIdx === -1) {
                    _this.filteredDrivers[driverIndex].assignments.push(assignment);
                    _this.filteredDrivers[driverIndex].loadCount += Number(assignment.maxNumberOfLoads);
                }
            }
            var jobIndex = _this.filteredJobEvents.findIndex(function (jobEvent) { return (jobEvent.id === assignment.jobevent.id); });
            if (jobIndex > -1) {
                var assignmentIdx = _this.filteredJobEvents[jobIndex].assignments
                    .findIndex(function (a) { return a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id; });
                if (assignmentIdx === -1) {
                    _this.filteredJobEvents[jobIndex].assignments.push(assignment);
                    _this.filteredJobEvents[jobIndex].assignedLoads += Number(assignment.maxNumberOfLoads);
                    _this.filteredJobEvents[jobIndex].assignedTons = Number(_this.filteredJobEvents[jobIndex].loadDeliveryTarget) *
                        _this.filteredJobEvents[jobIndex].assignedLoads;
                    _this.filteredJobEvents[jobIndex].truckList += assignment.truck.name && assignment.truck.name + '\n';
                    _this.filteredJobEvents[jobIndex]['updates'] = true;
                }
            }
        });
        this.getJobEventStats(this.filteredJobEvents);
        this.filteredDrivers = this.filteredDrivers.slice();
        this.filteredJobEvents = this.filteredJobEvents.slice();
        if (this.autoOpenJobEvent) {
            this.selectedJobEvents = [this.autoOpenJobEvent];
            this.openJobEventEditPanel(this.filteredJobEvents[this.filteredJobEvents.findIndex(function (j) { return (j.id === _this.autoOpenJobEvent); })]);
            this.autoOpenJobEvent = undefined;
        }
        this.updatedAssignments = [];
        this.processingUpdate = false;
    };
    DispatchScheduleComponent.prototype.getShares = function (connections) {
        // this.collaboratorService.getAllShares(20, {
        //   start__gte: moment(this.selectedDate).startOf('day').toISOString(),
        //   start__lte: moment(this.selectedDate).endOf('day').toISOString()
        // }).subscribe(shares => {
        //   this.sharesList = shares;
        //   this.sharesList.forEach(share => {
        //     const connectionIndex = connections.findIndex(connection => (connection.organization.id === share.organizationId));
        //     if (connectionIndex > -1) {
        //       connections[connectionIndex].shares.push(share);
        //       connections[connectionIndex].truckCount += share.numTrucks;
        //     }
        //     const jobIndex = this.filteredJobEvents.findIndex(jobEvent => (jobEvent['id'] === share.jobEventId));
        //     if (jobIndex > -1) {
        //       this.filteredJobEvents[jobIndex].shares.push(share);
        //       this.filteredJobEvents[jobIndex].shareList += share.organization + '\n';
        //     }
        //   });
        //   this.connections = [...connections];
        //   this.filteredJobEvents = [...this.filteredJobEvents];
        // });
    };
    DispatchScheduleComponent.prototype.updateAssignment = function (assignments) {
        var _this = this;
        this.assignmentService.bulkUpdate(assignments).subscribe(function (bulkRes) {
            _this.applyAssignmentChanges(bulkRes.assignments);
            if (bulkRes.errors && bulkRes.errors.length) {
                _this.openDisplayErrorModal(bulkRes.errors);
            }
        }, function (err) {
            if (err.errors && err.errors.length) {
                _this.openDisplayErrorModal(err.errors);
            }
        });
    };
    DispatchScheduleComponent.prototype.applyAssignmentChanges = function (assignments, action) {
        var _this = this;
        if (action === void 0) { action = 'edit'; }
        assignments.forEach(function (assignment) {
            var driverIndex = _this.filteredDrivers.findIndex(function (d) { return (d.id === assignment.driver.id); });
            var matchedDriverAssignment = _this.filteredDrivers[driverIndex] &&
                _this.filteredDrivers[driverIndex].assignments.findIndex(function (a) { return a.id === assignment.id; });
            var jobEventIndex = _this.filteredJobEvents.findIndex(function (d) { return (d.id === assignment.jobevent.id); });
            var matchedJobEventAssignment = _this.filteredJobEvents[jobEventIndex] &&
                _this.filteredJobEvents[jobEventIndex].assignments.findIndex(function (a) { return a.id === assignment.id; });
            if (action === 'edit') {
                if (matchedDriverAssignment > -1) {
                    _this.filteredDrivers[driverIndex].assignments[matchedDriverAssignment] = assignment;
                }
                else {
                    _this.filteredDrivers[driverIndex].assignments.push(assignment);
                }
                if (matchedJobEventAssignment > -1) {
                    _this.filteredJobEvents[jobEventIndex].assignments[matchedJobEventAssignment] = assignment;
                }
                else {
                    _this.filteredJobEvents[jobEventIndex].assignments.push(assignment);
                }
            }
            else {
                _this.filteredDrivers[driverIndex].assignments.splice(matchedDriverAssignment, 1);
                _this.filteredJobEvents[jobEventIndex].assignments.splice(matchedJobEventAssignment, 1);
            }
        });
        this.filteredDrivers = this.filteredDrivers.map(function (d) {
            d.loadCount = 0;
            d.assignments.forEach(function (a) { return d.loadCount += Number(a.maxNumberOfLoads); });
            return d;
        }).slice();
        this.filteredJobEvents = this.filteredJobEvents.map(function (j) {
            j.assignedLoads = 0;
            j.truckList = '';
            j.assignments.forEach(function (a) {
                j.assignedLoads += Number(a.maxNumberOfLoads);
                if (a.truck.name) {
                    j.truckList += a.truck.name + '\n';
                }
            });
            j.assignedTons = Number(j.loadDeliveryTarget) * j.assignedLoads;
            return j;
        }).slice();
        this.assignmentService.listAllProgress.next(100);
        this.getJobEventStats(this.filteredJobEvents);
    };
    DispatchScheduleComponent.prototype.assignSelectedDrivers = function (jobEvent) {
        var _this = this;
        if (this.selectedDrivers && !this.assigningTrucks) {
            this.assigningTrucks = true;
            var uniqueStart_1 = this.getLastAssignmentTime(jobEvent);
            var newAssignments_1 = [];
            var assignmentsPending_1 = [];
            var countSameChunk_1 = 0;
            this.selectedDrivers.forEach(function (driverId, index) {
                var driver = _this.filteredDrivers.find(function (d) { return (d.id === driverId); });
                var maxNumberOfLoads = 1;
                if (_this.hasAllDriversEnabled) {
                    if (!_this.preference || !_this.preference.hasOwnProperty('blob') || _this.preference.blob['dispatchLoadType'] === 'all-day') {
                        maxNumberOfLoads = 0;
                    }
                    else if (_this.preference.blob['dispatchLoadType'] === 'by-load') {
                        maxNumberOfLoads = _this.preference.blob['dispatchLoadCount'] ?
                            Number(_this.preference.blob['dispatchLoadCount']) : 1;
                    }
                }
                var disabledStaggeredTime = _this.preference &&
                    _this.preference.blob &&
                    _this.preference.blob['staggerAssignmentTime'] &&
                    _this.preference.blob['staggerAssignmentTime'] === 'shift1_start'
                    ? true
                    : false;
                uniqueStart_1 = Number(jobEvent.deliveryInterval) > 0 && index > 0 && !disabledStaggeredTime ?
                    moment(uniqueStart_1).add(Number(jobEvent.deliveryInterval), 'minutes').toISOString() : uniqueStart_1;
                var assignment = {
                    driver: driver,
                    truck: driver.truck,
                    jobevent: jobEvent,
                    uniqueStart: uniqueStart_1,
                    shift: 'shift1',
                    maxNumberOfLoads: maxNumberOfLoads
                };
                var validationParams = {
                    jobEvent: jobEvent,
                    countSameChunk: countSameChunk_1,
                    currentAssignation: {
                        numberOfLoadsType: assignment.maxNumberOfLoads ? 'numbered' : 'allDay',
                        maxNumberOfLoads: assignment.maxNumberOfLoads,
                    },
                };
                countSameChunk_1 += assignment.maxNumberOfLoads;
                if (_this.confirmChangeJobLoadsService.isGreaterAssigned(validationParams)) {
                    assignmentsPending_1.push(assignment);
                }
                else {
                    newAssignments_1.push(assignment);
                }
            });
            if (assignmentsPending_1.length) {
                var loadsAssigned = assignmentsPending_1.reduce(function (total, pending) { return total + Number(pending.maxNumberOfLoads); }, 0);
                var validationParams = {
                    jobEvent: jobEvent,
                    currentAssignation: {
                        numberOfLoadsType: loadsAssigned ? 'numbered' : 'allDay',
                        maxNumberOfLoads: loadsAssigned,
                    },
                };
                this.allSubscriptionsToUnsubscribe.push(this.confirmChangeJobLoadsService.validateLoadsRequested(validationParams).subscribe(function (response) {
                    var query = response['byPass'] ? { bypass_limit_validation: 'True' } : undefined;
                    _this.createAssignments(assignmentsPending_1, function () { return _this.assigningTrucks = false; }, query);
                }, function () { return _this.assigningTrucks = false; }));
            }
            if (!newAssignments_1.length) {
                return;
            }
            /// Validate the trucks assigned vs trucks requested
            var countCurrentAssignments = jobEvent.assignments.length;
            var totalAssignments = countCurrentAssignments + newAssignments_1.length;
            var assignDrivers = function () {
                var successCallback = function () {
                    _this.filteredJobEvents.map(function (j) { return (__assign({}, j, { selected: false })); });
                    _this.assigningTrucks = false;
                };
                _this.createAssignments(newAssignments_1, successCallback);
            };
            if (jobEvent.numTrucks && totalAssignments > jobEvent.numTrucks) {
                this.confirmUpdateTrucksRequested({
                    numTrucksAssigned: countCurrentAssignments,
                    numTrucksToAssign: newAssignments_1.length,
                    totalAssignments: totalAssignments,
                    jobEventId: jobEvent.id,
                    successCallback: assignDrivers
                });
            }
            else {
                assignDrivers();
            }
        }
    };
    DispatchScheduleComponent.prototype.confirmUpdateTrucksRequested = function (_a) {
        var _this = this;
        var numTrucksToAssign = _a.numTrucksToAssign, numTrucksAssigned = _a.numTrucksAssigned, totalAssignments = _a.totalAssignments, jobEventId = _a.jobEventId, successCallback = _a.successCallback, _b = _a.jobEventName, jobEventName = _b === void 0 ? '' : _b;
        var jobText = jobEventName ? jobEventName : 'this job';
        var messageAssigned = numTrucksAssigned > 0 ? " and the " + numTrucksAssigned + " previously assigned" : '';
        var confirmDialog = this.dialog.open(dialogs_1.RuckitConfirmDialogComponent, {
            width: '530px',
            data: {
                title: "Trucks Requested Exceeded",
                message: "The number of trucks required has exceeded by the " + numTrucksToAssign + " truck(s) selected" + messageAssigned + ".\n        Do you want to assign the selected truck(s) and increase the total number for " + jobText + " to " + totalAssignments + "?",
                acceptText: 'Assign & Update Trucks'
            }
        });
        confirmDialog.afterClosed().subscribe(function (dialogResult) {
            if (dialogResult) {
                _this.jobEventService.save({ id: jobEventId, numTrucks: totalAssignments }).subscribe(function () {
                    successCallback();
                    var indexUpdated = _this.filteredJobEvents.findIndex(function (jobEvent) { return jobEvent.id === jobEventId; });
                    _this.filteredJobEvents[indexUpdated].numTrucks = totalAssignments;
                }, function (err) { return _this.errors = err; });
            }
            else {
                _this.assigningTrucks = false;
            }
        });
    };
    DispatchScheduleComponent.prototype.createAssignments = function (newAssignments, successCallback, query) {
        var _this = this;
        this.assignmentService.bulkCreate(newAssignments, query).subscribe(function (bulkRes) {
            if (successCallback) {
                successCallback();
            }
            _this.applyAssignmentChanges(bulkRes.assignments);
            if (bulkRes.errors && bulkRes.errors.length) {
                _this.openDisplayErrorModal(bulkRes.errors);
            }
        }, function (err) {
            _this.assigningTrucks = false;
            if (err.errors && err.errors.length) {
                _this.openDisplayErrorModal(err.errors);
            }
        });
    };
    DispatchScheduleComponent.prototype.assignSelectedJobEvents = function (driver) {
        var _this = this;
        if (this.selectedJobEvents) {
            var newAssignments_2 = [];
            this.dispatchTable.getUnassignedIds(driver, this.selectedJobEvents).forEach(function (jobEventId) {
                var jobEvent = _this.filteredJobEvents.find(function (j) { return (j.id === jobEventId); });
                var uniqueStart = _this.getLastAssignmentTime(jobEvent);
                var maxNumberOfLoads = 1;
                if (_this.hasAllDriversEnabled) {
                    if (!_this.preference || !_this.preference.hasOwnProperty('blob') || _this.preference.blob['dispatchLoadType'] === 'all-day') {
                        maxNumberOfLoads = 0;
                    }
                    else if (_this.preference.blob['dispatchLoadType'] === 'by-load') {
                        maxNumberOfLoads = 1;
                    }
                }
                uniqueStart = Number(jobEvent.deliveryInterval) > 0 && (jobEvent.assignments && jobEvent.assignments.length > 0) ?
                    moment(uniqueStart).add(Number(jobEvent.deliveryInterval), 'minutes').toISOString() : uniqueStart;
                var assignment = {
                    driver: driver,
                    truck: driver.truck,
                    jobevent: jobEvent,
                    uniqueStart: uniqueStart,
                    shift: 'shift1',
                    maxNumberOfLoads: maxNumberOfLoads
                };
                var countCurrentAssignments = jobEvent.assignments.length;
                var totalAssignments = countCurrentAssignments + 1;
                if (jobEvent.numTrucks && totalAssignments > jobEvent.numTrucks) {
                    _this.confirmUpdateTrucksRequested({
                        numTrucksToAssign: 1,
                        numTrucksAssigned: countCurrentAssignments,
                        totalAssignments: totalAssignments,
                        jobEventId: jobEvent.id,
                        jobEventName: jobEvent.jobDisplayName,
                        successCallback: function () { return _this.createAssignments([assignment]); }
                    });
                }
                else {
                    newAssignments_2.push(assignment);
                }
            });
            if (newAssignments_2.length) {
                this.createAssignments(newAssignments_2);
            }
        }
    };
    DispatchScheduleComponent.prototype.exportAssignments = function () {
        var _this = this;
        var filters = {
            'jobevent__shift1_start__gte': moment(this.selectedDate).startOf('day').toISOString(),
            'jobevent__shift1_start__lte': moment(this.selectedDate).endOf('day').toISOString()
        };
        this.assignmentService.export({}, filters).subscribe(function () {
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: { type: 'assignments' }
            });
            dialog.componentInstance.exportSubmitted = true;
        }, function (err) {
            var params = new http_1.HttpParams();
            Object.keys(filters).map(function (key) { return params = params.set(key, filters[key]); });
            var dialog = _this.dialog.open(export_dialog_component_1.ExportDialogComponent, {
                width: '430px',
                data: {
                    type: 'assignments',
                    params: params,
                    service: _this.assignmentService,
                    buttonText: 'Try to Export Again'
                }
            });
            dialog.componentInstance.exportSubmitted = false;
            dialog.componentInstance.errors.push(err);
        });
    };
    DispatchScheduleComponent.prototype.getLastAssignmentTime = function (jobEvent) {
        var assignmentMoments = jobEvent.assignments.map(function (a) { return (moment(a.uniqueStart)); });
        return jobEvent.assignments && jobEvent.assignments.length > 0 ?
            moment.max(assignmentMoments).toISOString() :
            jobEvent.shift1Start;
    };
    DispatchScheduleComponent.prototype.openDriverEditPanel = function (driver) {
        this.selectedDriver = driver;
        this.driverAssignmentsList.setOpen(driver);
    };
    DispatchScheduleComponent.prototype.openJobEventEditPanel = function (jobEvent) {
        this.selectedJobEvent = jobEvent;
        this.jobEventEditPanelOpen = true;
    };
    DispatchScheduleComponent.prototype.closeEditPanel = function (e) {
        this.selectedDrivers = [];
        this.jobEventEditPanelOpen = false;
    };
    DispatchScheduleComponent.prototype.editPanelCallback = function (jobEvent) {
        jobEvent.assignedLoads = jobEvent.assignments.reduce(function (a, obj) { return (a + Number(obj.maxNumberOfLoads)); }, 0);
        jobEvent.assignedTons = Number(jobEvent.loadDeliveryTarget) * jobEvent.assignedLoads;
        jobEvent.truckList = jobEvent.assignments.reduce(function (a, obj) { return (a + (obj.truck.name && obj.truck.name + '\n')); }, '');
        this.filteredJobEvents[this.filteredJobEvents.findIndex(function (j) { return (j.id === jobEvent.id); })] = jobEvent;
        this.getJobEventStats(this.filteredJobEvents);
    };
    DispatchScheduleComponent.prototype.driverEditPanelCallback = function (assignment) {
        var assignmentIndex;
        var updatedDriver = this.filteredDrivers.find(function (d) {
            var foundAssignment = d.assignments.findIndex(function (a) { return (a.id === assignment.id); });
            if (foundAssignment > -1) {
                assignmentIndex = foundAssignment;
                return true;
            }
            else {
                return false;
            }
        });
        updatedDriver.assignments[assignmentIndex] = assignment;
        this.updateAssignment(updatedDriver.assignments);
    };
    // preference methods
    DispatchScheduleComponent.prototype.getPreferences = function () {
        var _this = this;
        this.preferenceService.list({
            name: this.preferenceKey,
            type: 'user',
            profile: this.user && this.user.id
        }).subscribe(function (preferences) {
            if (preferences && preferences.length) {
                _this.preference = preferences[0];
                if (_this.preference.blob) {
                    _this.activeLoadType = _this.preference.blob['activeLoadType'] ? _this.preference.blob['activeLoadType'] :
                        _this.preference.blob['dispatchLoadType'] === 'by-load' ? 'Loads Ordered' : 'Trucks Requested';
                    if (_this.preference.blob['date'] && !_this.selectedDateParam) {
                        _this.selectDate([new Date(_this.preference.blob['date'])]);
                    }
                    else if (!_this.selectedDateParam) {
                        _this.selectDate([new Date()]);
                    }
                }
            }
            else if (!_this.selectedDateParam) {
                _this.selectDate([(new Date())]);
            }
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DispatchScheduleComponent.prototype.savePreferences = function (updates) {
        var _this = this;
        if (this.preference && this.preference.blob &&
            (updates['date'] || !updates['activeLoadType'] || this.preference.blob['activeLoadType'] !== updates['activeLoadType'])) {
            this.preferenceService.save({
                name: this.preferenceKey,
                type: 'user',
                profile: this.user && this.user.id,
                blob: this.preference && this.preference.blob ? Object.assign(this.preference.blob, updates) : updates
            }).subscribe(function (preference) { return _this.preference = preference; }, function (err) { return _this.errors = shared_1.parseErrors(err); });
        }
    };
    DispatchScheduleComponent.prototype.getCarriers = function (query) {
        var _this = this;
        if (query === void 0) { query = {}; }
        if (this.carriersReq && typeof this.carriersReq.unsubscribe === 'function') {
            this.carriersReq.unsubscribe();
        }
        this.carrierDropdownData.carriers = [
            { name: 'My Drivers', id: 'my_drivers' },
            { name: 'All Carriers', id: 'all_carriers' },
            { name: 'Leased', id: 'all_leased' }
        ];
        this.carriersReq = this.connectionService.list(__assign({ ordering: 'organization__name', allow_dispatch: 'True', is_carrier: 'True' }, query)).subscribe(function (connections) {
            var _carriers = connections.map(function (connection) {
                return {
                    name: connection.organization.name,
                    id: connection.organization.carrier.id
                };
            });
            _this.carrierDropdownData.carriers = _this.carrierDropdownData.carriers.concat(_carriers);
            _this.carrierDropdownData.loading = false;
        }, function (err) { return _this.errors = shared_1.parseErrors(err); });
    };
    DispatchScheduleComponent.prototype.dropdownNextPage = function (type) {
        var _this = this;
        var config, service, options;
        switch (type) {
            case 'carrier':
                config = this.carrierDropdownData.config;
                service = this.connectionService;
                options = this.carrierDropdownData.carriers;
                break;
        }
        if (!config.loadingOptions) {
            var o = service && service.listNext();
            if (o) {
                config.loadingOptions = true;
                o.subscribe(function (results) {
                    switch (type) {
                        case 'carrier':
                            var _carriers = results.map(function (connection) {
                                return {
                                    name: connection.organization.name,
                                    id: connection.organization.carrier.id
                                };
                            });
                            _this.carrierDropdownData.carriers = _this.carrierDropdownData.carriers.concat(_carriers);
                            break;
                    }
                }, function (err) { return _this.errors = shared_1.parseErrors(err); }, function () { return config.loadingOptions = false; });
            }
        }
    };
    DispatchScheduleComponent.prototype.selectCarrier = function (carrier) {
        this.selectedDrivers = [];
        this.carrierDropdownData.carrier = carrier;
        var carrierQuery = {
            all_carriers: null,
            all_leased: null,
            carrier: null
        };
        switch (carrier.id) {
            case 'my_drivers':
                break;
            case 'all_carriers':
                carrierQuery.all_carriers = true;
                break;
            case 'all_leased':
                carrierQuery.all_leased = true;
                break;
            default:
                carrierQuery.carrier = carrier.id;
                break;
        }
        if (this.driverQuery.all_carriers && carrierQuery.carrier && this.drivers) {
            this.filteredDrivers = this.drivers.filter(function (d) { return (d.carrier.id === carrierQuery.carrier); });
        }
        else if (this.driverQuery.all_carriers && carrierQuery.all_carriers && this.drivers) {
            this.filteredDrivers = this.drivers.slice();
        }
        else {
            this.changeDriverQuery(carrierQuery);
        }
    };
    DispatchScheduleComponent.prototype.openDisplayErrorModal = function (errors) {
        var _this = this;
        this.assignmentService.listAllProgress.next(100);
        var _errors = [];
        var _truckErrors = [];
        if (errors && errors.length) {
            errors.forEach(function (error) {
                var driver, truck, jobEvent;
                if (error.item && _this.filteredDrivers) {
                    driver = error.item.driver && _this.filteredDrivers.find(function (d) { return (d.id === (error.item && error.item.driver)); });
                    truck = error.item.truck && driver.truck;
                    jobEvent = error.item.jobevent && _this.jobEvents.find(function (j) { return (j.id === (error.item && error.item.jobevent)); });
                }
                else if (error.data && _this.filteredDrivers) {
                    driver = _this.filteredDrivers.find(function (d) { return (d.id === (error.data && error.data.driver)); });
                }
                var errorMessage = error.errors && error.errors.non_field_errors;
                if (error.errors && (error.errors.truck || (error.errors.non_field_errors && error.errors.non_field_errors.join(' ').includes('Invalid truck type')))) {
                    _truckErrors.push({
                        errors: error.errors,
                        assignment: Object.assign(error.item, {
                            driver: driver,
                            truck: truck,
                            jobevent: jobEvent
                        })
                    });
                }
                else if (driver) {
                    _errors.push({
                        error: error.errors.non_field_errors,
                        driverName: driver.name,
                        truckName: driver.truck && driver.truck.displayName
                    });
                }
                else if (errorMessage) {
                    _errors.push({
                        error: errorMessage,
                    });
                }
            });
        }
        if (_truckErrors.length) {
            var dialog = this.dialog.open(assignment_truck_management_dialog_component_1.AssignmentTruckManagementDialogComponent, {
                width: '430px'
            });
            dialog.componentInstance.assignmentErrors = _truckErrors;
            dialog.componentInstance.callback = function (assignments) {
                _this.assignmentService.bulkCreate(assignments).subscribe(function (bulkRes) {
                    _this.filteredJobEvents.map(function (j) { return (__assign({}, j, { selected: false })); });
                    _this.applyAssignmentChanges(bulkRes.assignments);
                    _this.selectedDrivers = [];
                    if (bulkRes.errors && bulkRes.errors.length) {
                        _this.openDisplayErrorModal(bulkRes.errors);
                    }
                }, function (err) {
                    if (err.errors && err.errors.length) {
                        _this.openDisplayErrorModal(err.errors);
                    }
                });
            };
            dialog.componentInstance.refreshGrid = function () {
                _this.filteredDrivers = _this.filteredDrivers.slice();
            };
        }
        else if (_errors.length) {
            var dialog = this.dialog.open(assignment_error_dialog_component_1.AssignmentErrorDialogComponent, {
                width: '430px'
            });
            dialog.componentInstance.errors = _errors;
        }
    };
    DispatchScheduleComponent.prototype.openFilters = function () {
        var _this = this;
        var dialog = this.dialog.open(dispatch_schedule_filters_dialog_component_1.DispatchScheduleFiltersDialogComponent, {
            width: '430px',
            data: {
                filterSelection: this.selectedFilters,
                filterOptions: this.filterOptions
            }
        });
        if (this.selectedFilters && this.selectedFilters.length) {
            if (Object.keys(this.filterOptions)) {
                var selectedJobFilter_1 = this.selectedFilters.find(function (filter) { return filter.title === 'Job'; });
                var selectedProjectFilter_1 = this.selectedFilters.find(function (filter) { return filter.title === 'Project'; });
                var selectedCustomerFilter_1 = this.selectedFilters.find(function (filter) { return filter.title === 'Customer'; });
                var selectedStartLocationFilter_1 = this.selectedFilters.find(function (filter) { return filter.title === 'Start Location'; });
                var selectedEndLocationFilter_1 = this.selectedFilters.find(function (filter) { return filter.title === 'End Location'; });
                if (selectedJobFilter_1 && selectedJobFilter_1.values && selectedJobFilter_1.values.length) {
                    dialog.componentInstance.selectedJob = this.filterOptions.jobs &&
                        this.filterOptions.jobs.find(function (job) { return job.id === selectedJobFilter_1.values[0]; });
                }
                if (selectedProjectFilter_1 && selectedProjectFilter_1.values && selectedProjectFilter_1.values.length) {
                    dialog.componentInstance.selectedProject =
                        this.filterOptions.projects && this.filterOptions.projects.find(function (project) { return project.name === selectedProjectFilter_1.values[0]; });
                }
                if (selectedCustomerFilter_1 && selectedCustomerFilter_1.values && selectedCustomerFilter_1.values.length) {
                    dialog.componentInstance.selectedCustomer =
                        this.filterOptions.customers && this.filterOptions.customers.find(function (customer) { return customer.id === selectedCustomerFilter_1.values[0]; });
                }
                if (selectedStartLocationFilter_1 && selectedStartLocationFilter_1.values && selectedStartLocationFilter_1.values.length) {
                    dialog.componentInstance.selectedStartLocation =
                        this.filterOptions.startLocations &&
                            this.filterOptions.startLocations.find(function (loc) { return loc.id === selectedStartLocationFilter_1.values[0]; });
                }
                if (selectedEndLocationFilter_1 && selectedEndLocationFilter_1.values && selectedEndLocationFilter_1.values.length) {
                    dialog.componentInstance.selectedEndLocation =
                        this.filterOptions.endLocations && this.filterOptions.endLocations.find(function (loc) { return loc.id === selectedEndLocationFilter_1.values[0]; });
                }
            }
            var jobNumberFilter = this.selectedFilters.find(function (filter) { return filter.title === 'Job #'; });
            if (jobNumberFilter && jobNumberFilter.values && jobNumberFilter.values.length) {
                dialog.componentInstance.model.jobNumber = jobNumberFilter.values[0];
            }
        }
        dialog.componentInstance.callback = function (res) {
            _this.selectedFilters = res;
            _this.filterChanges(res);
        };
    };
    DispatchScheduleComponent.prototype.filterJobEvents = function () {
        var _this = this;
        this.filteredJobEvents = this.jobEvents.slice();
        this.filterOptions = {
            jobs: Array.from(new Set(this.jobEvents.map(function (j) { return (j.jobId); }))).map(function (id) { return ({ id: id, name: _this.jobEvents.find(function (j) { return (j.jobId === id); }).jobName }); }),
            projects: Array.from(new Set(this.jobEvents.map(function (j) { return (j.projectName); }))).map(function (name) { return ({ name: name }); }),
            customers: Array.from(new Set(this.jobEvents.map(function (j) { return (j.customerId); }))).map(function (id) { return ({ id: id, name: _this.jobEvents.find(function (j) { return (j.customerId === id); }).customer }); }),
            startLocations: Array.from(new Set(this.jobEvents.map(function (j) { return (j.startLocation); }))).map(function (id) { return ({ id: id, name: _this.jobEvents.find(function (j) { return (j.startLocation === id); }).startLocationName }); }),
            endLocations: Array.from(new Set(this.jobEvents.map(function (j) { return (j.endLocation); }))).map(function (id) { return ({ id: id, name: _this.jobEvents.find(function (j) { return (j.endLocation === id); }).endLocationName }); })
        };
    };
    DispatchScheduleComponent.prototype.filterChanges = function (filters) {
        var _this = this;
        this.filteredJobEvents = this.jobEvents.slice();
        filters.forEach(function (filter) {
            _this.filteredJobEvents = _this.filteredJobEvents.filter(function (row) {
                if (filter.title === 'Job') {
                    return filter.values[0] === row.jobId;
                }
                if (filter.title === 'Project') {
                    return filter.displayValues[0] === row.projectName;
                }
                if (filter.title === 'Customer') {
                    return filter.values[0] === row.customerId;
                }
                if (filter.title === 'Start Location') {
                    return filter.values[0] === row.startLocation;
                }
                if (filter.title === 'End Location') {
                    return filter.values[0] === row.endLocation;
                }
                if (filter.title === 'Job #') {
                    return row.jobNumber && row.jobNumber.toLowerCase().includes(filter.values[0].toLowerCase());
                }
            });
        });
    };
    DispatchScheduleComponent.prototype.filtersActive = function () {
        return this.selectedFilters.length > 0;
    };
    DispatchScheduleComponent.prototype.handleErrors = function (errors) {
        this.errors = this.errors.concat(errors);
    };
    DispatchScheduleComponent.prototype.getExistingAssignments = function () {
        var existingAssignments = [];
        if (this.jobEvents) {
            this.jobEvents.forEach(function (jobEvent) {
                if (jobEvent.assignments && jobEvent.assignments.length) {
                    existingAssignments = existingAssignments.concat(jobEvent.assignments);
                }
            });
        }
        return existingAssignments;
    };
    DispatchScheduleComponent.prototype.hasUpdatedJobEvents = function () {
        var _this = this;
        var hasUpdates = false;
        if (this.jobEvents && this.updatedJobEvents.length) {
            this.updatedJobEvents.forEach(function (jobEvent) {
                var existingJobEventIdx = _this.jobEvents.findIndex(function (j) { return j.id === jobEvent.id; });
                var mergedJobEvent = Object.assign(__assign({}, _this.jobEvents[existingJobEventIdx]), jobEvent);
                if (_this.jobEvents[existingJobEventIdx].hasOwnProperty('updates')) {
                    mergedJobEvent['updates'] = _this.jobEvents[existingJobEventIdx];
                }
                var existingJobEventUnchanged = existingJobEventIdx > -1
                    ? JSON.stringify(mergedJobEvent) === JSON.stringify(_this.jobEvents[existingJobEventIdx])
                    : false;
                if (!existingJobEventUnchanged) {
                    hasUpdates = true;
                }
            });
        }
        return hasUpdates;
    };
    DispatchScheduleComponent.prototype.hasUpdatedAssignments = function () {
        var _this = this;
        var hasUpdates = false;
        if (this.filteredJobEvents && this.updatedAssignments.length) {
            this.updatedAssignments.forEach(function (assignment) {
                var jobIndex = _this.filteredJobEvents.findIndex(function (jobEvent) { return (jobEvent.id === assignment.jobevent.id); });
                if (jobIndex > -1) {
                    var assignmentIdx = _this.filteredJobEvents[jobIndex].assignments
                        .findIndex(function (a) { return a.jobevent.id === assignment.jobevent.id && a.truck.id === assignment.truck.id; });
                    if (assignmentIdx === -1) {
                        hasUpdates = true;
                    }
                }
            });
        }
        return hasUpdates;
    };
    return DispatchScheduleComponent;
}());
exports.DispatchScheduleComponent = DispatchScheduleComponent;
