import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { clone, reject, find as _find } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';

import { parseErrors } from '../shared/api.service';
import { JobService } from './job.service';
import { ProjectService } from '../projects/project.service';
import { ConnectionService } from '../connections/connection.service';
import { NewCustomerDialogComponent } from '../customers/new-customer-dialog.component';
import { LocationService } from '../locations/location.service';
import { AuthenticationService } from '../shared/authentication.service';
import { NewLocationDialogComponent } from '../locations/new-location-dialog.component';
import { CustomField, CustomFieldKind } from '../custom-fields/custom-field';
import { CustomFieldService } from '../custom-fields/custom-field.service';
import { WorkOrderTemplate } from '../work-orders/work-order-templates/work-order-template';
import { WorkOrderTemplateService } from '../work-orders/work-order-templates/work-order-template.service';
import { User } from '../users/user';
import { Subscription } from 'rxjs';
import { Organization } from '../organizations/organization';
import { EditLocationDialogComponent } from '../locations/edit-location-dialog.component';
import { Tag } from '../tags/tag';
import { ProductItem } from './product-items-service/product-items';
import { ProductItemsService } from './product-items-service/product-items.service';

@Component({
  selector: 'edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.scss'],
  providers: [
    JobService,
    ProjectService,
    ConnectionService,
    FormBuilder,
    AuthenticationService,
    LocationService,
    CustomFieldService,
  ]
})

export class EditJobComponent implements OnInit, OnDestroy {
  device = {
    info: null,
    mobile: false,
    tablet: false,
    desktop: false
  };
  user: User;
  hasWorkOrder = false;
  job;
  defaultLocation;
  cloneHaulRate;
  jobTypeOptions = {
    loading: [],
    unloading: []
  };
  model = {
    id: '',
    customFieldData: {},
    name: '',
    poNumber: '',
    orderNumber: '',
    jobNumber: '',
    qrJobType: 'loading_import',
    rate: 0,
    haulRate: 0,
    haulType: 'weight',
    haulWeightUnit: 'ton',
    invoiceType: 'weight',
    invoiceWeightUnit: 'ton',
    material: '',
    materialCode: null,
    phaseCode: '',
    dailyDeliveryTarget: '',
    dailyDeliveryTargetType: '',
    totalAmountType: '',
    deliveryInterval: 0,
    deliveryIntervalUnit: '',
    checkinOptions: {},
    checkoutOptions: {},
    checkinOptionsDisabled: {},
    checkoutOptionsDisabled: {},
    startLocation: { id: '', name: '' },
    endLocation: { id: '', name: '' },
    notes: '',
    amountNeeded: '',
    dates: [],
    tags: [],
    workOrderTemplate: null,
    defaultYardBufferTime: null,
    defaultYardBufferMinutes: null,
    multipleMaterials: {
      isMultipleMaterials: false,
    },
  };
  projectName: String;
  customerEditEnabled = false;
  customerDialogRef: MatDialogRef<NewCustomerDialogComponent>;
  customerOrganization: Organization;
  newCustomer = false;
  customerSearch = '';
  customerOptions = [];
  customerLoading = false;
  customerDropdownConfig: any = {
    nameProperty: 'name'
  };
  selectedCustomer;
  selectedDeliveryIntervalUnit;
  selectedDailyDeliveryTargetType;
  selectedTotalAmountType;
  selectedInvoiceUnit;
  selectedHaulUnit;
  @ViewChild('customerDropdown', { static: false }) customerDropdown;
  loading = false;
  loadingLocations = [{
    displayName: 'Add a New Address',
    id: 'new-loading-location',
    button: true
  }];
  unloadingLocations = [{
    displayName: 'Add a New Address',
    id: 'new-unloading-location',
    button: true
  }];
  weightOptions = [
    { id: 'ton', name: 'Ton' },
    { id: 'metric-tons', name: 'Metric Ton' },
    { id: 'pound', name: 'Pound' },
    { id: 'cuyds', name: 'Cubic Yard' },
    { id: 'bushel', name: 'Bushel' },
    { id: 'bag', name: 'Bag' }
  ];
  jobWeightOptions = clone(this.weightOptions);
  haulWeightOptions = clone(this.weightOptions);
  locationsDropdownConfig = {
    start: { searchable: true, nameProperty: 'displayName' },
    end: { searchable: true, nameProperty: 'displayName' }
  };
  materialDropdownConfig = {
    searchable: false,
    autocomplete: true,
    nameProperty: 'description',
    loadingOptions: false
  };
  materialDropdownOptions: ProductItem[] = [];
  returnTo: string;
  orderTypeOptions = [
    { id: 'tons', name: 'Tons' },
    { id: 'metric-tons', name: 'Metric Tons' },
    { id: 'tonnes', name: 'Tonnes' },
    { id: 'loads', name: 'Loads' },
    { id: 'lbs', name: 'Pounds' },
    { id: 'kgs', name: 'Kilograms' },
    { id: 'cuyds', name: 'Cubic Yards' },
    { id: 'bushels', name: 'Bushels' },
    { id: 'bags', name: 'Bags' }
  ];
  deliveryIntervalUnits = [{
    id: 'hours',
    name: 'Hours'
  }, {
    id: 'minutes',
    name: 'Minutes',
  }];
  private paramsSub;
  private queryParamsSub;
  errors = [];
  tags = [];
  isCreator = false;

  configOptions = [
    { name: 'Required', id: 'required' },
    { name: 'Optional', id: 'optional' },
    { name: 'Hidden', id: 'hidden' },
  ];

  workOrderTemplateOptions: WorkOrderTemplate[] = [];

  workOrderTemplateDropdownConfig: any = {
    nameProperty: 'name'
  };

  tagsReq: Subscription;
  jobDaysReq: Subscription;
  searchReq: Subscription;
  locationsReq: Subscription;
  connectionsReq: Subscription;
  workOrderTemplatesReq: Subscription;

  @ViewChild('startLocationDropdown', { static: false }) startLocationDropdown;
  @ViewChild('endLocationDropdown', { static: false }) endLocationDropdown;
  @ViewChild('materialDropdown', { static: false }) materialDropdown;
  customFields: { [key: string]: CustomField };

  saveCustomerCallback = (customer) => {
    this.reloadCustomerOptions();
    this.customerOrganization = customer;
  }

  constructor(
    private route: ActivatedRoute,
    private jobService: JobService,
    private projectService: ProjectService,
    private connectionService: ConnectionService,
    private locationService: LocationService,
    private authenticationService: AuthenticationService,
    private workOrderTemplateService: WorkOrderTemplateService,
    private router: Router,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private customFieldService: CustomFieldService,
    private deviceDetectorService: DeviceDetectorService,
    private productItemsService: ProductItemsService
  ) {
    this.device = {
      info: this.deviceDetectorService.getDeviceInfo(),
      mobile: this.deviceDetectorService.isMobile(),
      tablet: this.deviceDetectorService.isTablet(),
      desktop: this.deviceDetectorService.isDesktop()
    };
  }

  ngOnInit() {
    this.customerDropdownConfig = { searchable: true, loadingOptions: false };
    this.reloadCustomerOptions();

    this.queryParamsSub = this.route.queryParams.forEach((params: Params) => {
      if (params['returnTo'] && params['returnTo'].length) {
        this.returnTo = params['returnTo'];
      }
    });

    this.paramsSub = this.route.params.subscribe((params: Params) => {
      if (params['id']) { this.getJob(params['id']); }
    });

    this.getCustomFields();
    this.user = this.authenticationService.user();
    this.hasWorkOrder = this.authenticationService.hasWorkOrder();
    if (this.hasWorkOrder) { this.getWorkOrderTemplates(); }
  }

  ngOnDestroy() {
    if (this.paramsSub && typeof this.paramsSub.unsubscribe === 'function') {
      this.paramsSub.unsubscribe();
    }
    if (this.queryParamsSub && typeof this.queryParamsSub.unsubscribe === 'function') {
      this.queryParamsSub.unsubscribe();
    }
    if (this.workOrderTemplatesReq && typeof this.workOrderTemplatesReq.unsubscribe === 'function') {
      this.workOrderTemplatesReq.unsubscribe();
    }
  }

  getCustomFields() {
    this.customFieldService.getIndexedFieldsForKind(CustomFieldKind.Job).subscribe(fields => {
      this.customFields = fields;
    });
  }

  getProductItems() {
    this.productItemsService.list({}).subscribe(
      (products) => {
        this.materialDropdownOptions = products.filter(product => product.description);
        const matchOption = this.materialDropdownOptions.find(opt => opt.description === this.job.material);
        if (matchOption) {
          this.materialDropdown.selectedOption = matchOption;
        }
        this.materialDropdown.autocompleteTerm = this.model.material;
      }
    );
  }

  materialDropdownNextPage() {
    this.materialDropdownConfig.loadingOptions = true;
    const next = this.productItemsService.listNext();
    if (next) {
      next.subscribe(
        (products) => {
          this.materialDropdownOptions.concat(products.filter(product => product.description));
          this.materialDropdownConfig.loadingOptions = false;
        }
      );
    } else {
      this.materialDropdownConfig.loadingOptions = false;
    }
  }

  handleMaterial(material: string, materialCode?: string) {
    this.model.material = material;
    this.model['materialCode'] = materialCode ? materialCode : null;
  }

  changeTotalAmountType(type) {
    this.model.totalAmountType = type.id;
    this.selectedTotalAmountType = type;
  }

  changeDailyDeliveryTargetType(type) {
    this.model.dailyDeliveryTargetType = type.id;
    this.selectedDailyDeliveryTargetType = type;
  }

  changeDeliveryIntervalUnits(interval) {
    this.model.deliveryIntervalUnit = interval.id;
    this.selectedDeliveryIntervalUnit = interval;
  }

  weightUnitChange(tracking: string, unit) {
    this.model[tracking + 'WeightUnit'] = unit.id;
    this['selected' + tracking + 'Unit'] = unit;
    if (tracking === 'invoice') {
      this.changeCloneHaulRate();
    }
  }

  getJob(id: string): void {
    this.loading = true;
    this.jobService.get(id).subscribe((res) => {
      this.job = res;
      for (const key in this.model) {
        if (this.job.hasOwnProperty(key)) {
          this.model[key] = this.job[key];
        }
      }
      if (this.model && this.model.qrJobType === undefined) {
        this.model.qrJobType = 'loading_import';
      }
      let myOrganization = this.authenticationService.getOrganization();
      if (this.job.project) {
        this.isCreator = this.job.project.ownerOrganization === myOrganization.id;
        this.projectName = this.job.project.name;
      }
      if (this.job.customerOrganization) {
        this.customerOrganization = this.job.customerOrganization;
        const customer = { id: this.customerOrganization.id, name: this.job.customerOrganization.name };
        if (!_find(this.customerOptions, { id: customer.id })) {
          this.customerOptions.push(customer);
        }
        this.selectedCustomer = customer;
      }
      if (this.job.multipleItemsData) {
        this.model.multipleMaterials = {
          ...this.model.multipleMaterials,
          ...this.job.multipleItemsData,
        };
      }
      this.selectedDeliveryIntervalUnit = _find(this.deliveryIntervalUnits, { id: this.job.deliveryIntervalUnit });
      this.selectedDailyDeliveryTargetType = _find(this.orderTypeOptions, { id: this.job.dailyDeliveryTargetType });
      this.selectedTotalAmountType = _find(this.orderTypeOptions, { id: this.job.totalAmountType });
      this.selectedInvoiceUnit = _find(this.jobWeightOptions, { id: this.job.invoiceWeightUnit });
      this.selectedHaulUnit = _find(this.haulWeightOptions, { id: this.job.haulWeightUnit });

      this.getJobDays();
      this.getLocations();
      if (this.model && this.user && this.user.organization && !this.user.organization.qrEnabled) {
        this.model.qrJobType = 'other';
      }
      this.jobTypeChanged();
      if (!this.model.defaultYardBufferTime || !this.model.defaultYardBufferMinutes) {
        this.loadOrganizationDefaults();
      }
      this.getProductItems();
      this.loading = false;
    }, err => {
      this.errors = parseErrors(err);
      this.loading = false;
    });
  }

  selectCustomer(customer) {
    if (customer.name === 'New Customer') {
      this.openNewCustomer();
    } else {
      this.customerOrganization = customer;
    }
  }

  reloadCustomerOptions(query = {}, append = false) {
    if (!append) {
      this.customerOptions = [{ name: 'New Customer', id: 'new-customer', button: true }];
    }
    this.customerLoading = true;
    if (this.connectionsReq) { this.connectionsReq.unsubscribe(); }
    this.connectionsReq = this.connectionService.list({
      ordering: 'organization__name',
      search: this.customerSearch
    }).subscribe(connections => {
      if (append) {
        this.customerOptions = this.customerOptions.concat(connections.map(connection => {
          return { name: connection.organization.name, id: connection.organization.id };
        }));
      } else {
        this.customerOptions = this.customerOptions.concat(connections.map(connection => {
          return { name: connection.organization.name, id: connection.organization.id };
        }));
      }
      this.customerLoading = false;
      this.customerDropdownConfig.loadingOptions = false;
    }, (err) => {
      this.errors = parseErrors(err);
      this.customerLoading = false;
      this.customerDropdownConfig.loadingOptions = false;
    });
  }

  customerDropdownSearch(term) {
    this.customerOptions = [];
    this.customerDropdownConfig.loadingOptions = true;
    this.customerSearch = term;
    this.reloadCustomerOptions();
    this.customerDropdownConfig.loadingOptions = false;
  }

  customerDropdownNextPage() {
    if (!this.customerDropdownConfig.loadingOptions) {
      let o = this.connectionService.listNext();
      if (o) {
        this.customerDropdownConfig.loadingOptions = true;
        o.subscribe(connections => {
          this.customerOptions = this.customerOptions.concat(connections.map(connection => {
            return { name: connection.organization.name, id: connection.organization.id };
          }));
          this.customerDropdownConfig.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          this.customerDropdownConfig.loadingOptions = false;
        });
      }
    }
  }

  openNewCustomer() {
    this.newCustomer = true;
    this.customerDialogRef = this.dialog.open(NewCustomerDialogComponent, {
      width: '444px'
    });
    this.customerDialogRef.componentInstance.callback = this.saveCustomerCallback;
    this.customerDialogRef.afterClosed().subscribe((result: string) => {
      if (this.job.project && this.job.project.customer) {
        this.selectCustomer({ name: this.job.project.customer.name, id: this.job.project.customer.id });
      } else {
        this.customerDropdown.deselectAll();
      }
    });
  }

  getWorkOrderTemplates(query = {}, next = false): void {
    if (this.workOrderTemplatesReq && typeof this.workOrderTemplatesReq.unsubscribe === 'function') {
      this.workOrderTemplatesReq.unsubscribe();
    }

    let request = this.workOrderTemplateService.list(query);
    if (next) {
      request = this.workOrderTemplateService.listNext();
    } else {
      this.workOrderTemplateOptions = [];
    }
    if (request) {
      this.workOrderTemplatesReq = request.subscribe(res => {
        this.workOrderTemplateOptions = this.workOrderTemplateOptions.concat(res);
      }, err => {
        this.errors = err;
      });
    }
  }

  getJobDays(): void {
    if (this.jobDaysReq && typeof this.jobDaysReq.unsubscribe === 'function') {
      this.jobDaysReq.unsubscribe();
    }

    this.loading = true;
    this.jobDaysReq = this.jobService.getDays(this.job.id).subscribe(days => {
      this.model.dates = days;
      this.loading = false;
    }, err => {
      this.errors = err;
      this.loading = false;
    });
  }

  changeLoadingLocation(location, place = 'start') {
    if (location['id'] === 'new-loading-location' || location['id'] === 'new-unloading-location') {
      // deselect
      location.selected = false;
      const dialog = this.dialog.open(NewLocationDialogComponent, {
        width: '100%',
        height: '100%',
        disableClose: true
      });
      this.tagUrlWith('new-location');
      dialog.afterClosed().subscribe(() => {
        this.tagUrlWith(null);
      });
      dialog.componentInstance.callback = (newLocation) => {
        newLocation.displayName = newLocation.street ? (newLocation.displayName + ' - ' + newLocation.street) : newLocation.displayName;

        if (place === 'start') {
          this.loadingLocations.forEach((_location) => {
            _location['selected'] = false;
          });
          newLocation.selected = true;
          this.loadingLocations.push(newLocation);
          if (this.startLocationDropdown) {
            this.startLocationDropdown.toggleOption(this.loadingLocations.find(l => (l.id === newLocation.id)), false);
          }

          let _newLocation = clone(newLocation);
          _newLocation.selected = false;

          this.unloadingLocations.push(_newLocation);
        } else {
          this.unloadingLocations.forEach((_location) => {
            _location['selected'] = false;
          });
          newLocation.selected = true;
          this.unloadingLocations.push(newLocation);
          if (this.endLocationDropdown) {
            this.endLocationDropdown.toggleOption(this.unloadingLocations.find(l => (l.id === newLocation.id)), false);
          }

          let _newLocation = clone(newLocation);
          _newLocation.selected = false;

          this.loadingLocations.push(_newLocation);
        }

        this.model[place + 'Location'] = newLocation;
      };
    } else {
      this.model[place + 'Location'] = location;
    }
  }

  changeUnloadingLocation(location) {
    this.changeLoadingLocation(location, 'end');
  }

  editLocation(location, place = 'start'): void {
    location.selected = false;
    const dialog = this.dialog.open(EditLocationDialogComponent, {
      width: '100%',
      height: '100%',
      disableClose: true,
      data: { locationId: location && location.id }
    });
    this.tagUrlWith('edit-location');
    dialog.afterClosed().subscribe(() => {
      this.tagUrlWith(null);
    });
    dialog.componentInstance.callback = (editLocation) => {
      editLocation.displayName = editLocation.street ? (editLocation.displayName + ' - ' + editLocation.street) : editLocation.displayName;

      if (place === 'start') {
        this.loadingLocations.forEach((_location) => {
          _location['selected'] = false;
        });
        editLocation.selected = true;
        this.loadingLocations.push(editLocation);
        if (this.startLocationDropdown) {
          this.startLocationDropdown.toggleOption(this.loadingLocations.find(l => (l.id === editLocation.id)), false);
        }

        let _editLocation = clone(editLocation);
        _editLocation.selected = false;

        this.unloadingLocations.push(_editLocation);
      } else {
        this.unloadingLocations.forEach((_location) => {
          _location['selected'] = false;
        });
        editLocation.selected = true;
        this.unloadingLocations.push(editLocation);
        if (this.endLocationDropdown) {
          this.endLocationDropdown.toggleOption(this.unloadingLocations.find(l => (l.id === editLocation.id)), false);
        }

        let _editLocation = clone(editLocation);
        _editLocation.selected = false;

        this.loadingLocations.push(_editLocation);
      }

      this.model[place + 'Location'] = editLocation;
    };
  }

  tagUrlWith(fragment: string) {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {
        ...this.route.snapshot.queryParams
      },
      fragment: fragment
    });
  }

  invoiceTypeChanged(): void {
    this.changeCloneHaulRate();
    this.jobTypeChanged();
  }

  haulTypeChanged(): void {
    this.jobTypeChanged();
  }

  changeCloneHaulRate(): void {
    if (this['cloneHaulRate']) {
      this.job['haulType'] = this.model.invoiceType;
      this.job['haulWeightUnit'] = this.model['invoiceWeightUnit'];
      this.job['haulRate'] = this.model['rate'];
    }
  }

  locationDropdownSearch(place, term: string): void {
    if (this.searchReq) { this.searchReq.unsubscribe(); }
    let optionsKey = place === 'start' ? 'loadingLocations' : 'unloadingLocations';
    this.locationsDropdownConfig[place].loadingOptions = true;
    this.searchReq = this.locationService.list({
      search: term,
      archived: 'False'
    }).subscribe((res) => {
      this[optionsKey].splice(0, this[optionsKey].length);
      res = res.map((loc) => {
        loc['name'] = loc.name + ' - ' + loc.street;
        return loc;
      });
      if (optionsKey === 'loadingLocations') {
        this[optionsKey] = [{
          displayName: 'Add a New Address',
          id: 'new-loading-location',
          button: true
        }];
      } else if (optionsKey === 'unloadingLocations') {
        this[optionsKey] = [{
          displayName: 'Add a New Address',
          id: 'new-unloading-location',
          button: true
        }];
      }
      this[optionsKey] = this[optionsKey].concat(res);
      this.locationsDropdownConfig[place].loadingOptions = false;
    });
  }

  locationDropdownNextPage(place): void {
    if (!this.locationsReq) {
      let optionsKey = place === 'start' ? 'loadingLocations' : 'unloadingLocations';
      this.locationsDropdownConfig[place].loadingOptions = true;
      let next = this.locationService.listNext();
      if (next) {
        this.locationsReq = next.subscribe(res => {
          res = res.map((loc) => {
            loc['displayName'] = loc.displayName + ' - ' + loc.street;
            return loc;
          });
          this[optionsKey] = this[optionsKey].concat(res);
          this.locationsDropdownConfig[place].loadingOptions = false;
          this.locationsReq = null;
        });
      } else {
        this.locationsDropdownConfig[place].loadingOptions = false;
      }
    }
  }

  getLocations(): void {
    this.locationService.list({
      archived: 'False'
    }).subscribe(res => {
      res = res.map(loc => {
        loc['displayName'] = loc.displayName + ' - ' + loc.street;
        return loc;
      });
      this.loadingLocations = this.loadingLocations.concat(res);
      this.unloadingLocations = this.unloadingLocations.concat(clone(res));

      this.setStartLocation();
      this.setEndLocation();
    });
    this.locationService.getLocationByIP().subscribe(res => {
      this.defaultLocation = res;
    });
  }

  setStartLocation(): void {
    if (this.model.startLocation && this.model.startLocation.id) {
      let _location = _find(this.loadingLocations, { id: this.model.startLocation.id });
      if (_location) {
        this.loadingLocations = reject(this.loadingLocations, _location);
      } else {
        _location = {
          id: this.model.startLocation.id, displayName: this.model.startLocation.name, button: false
        };
      }

      this.loadingLocations.unshift(_location);
      if (this.startLocationDropdown) {
        this.startLocationDropdown.selectedOption = _location;
      }
    }
  }

  setEndLocation(): void {
    if (this.model.endLocation && this.model.endLocation.id) {
      let _location = _find(this.unloadingLocations, { id: this.model.endLocation.id });
      if (_location) {
        this.unloadingLocations = reject(this.unloadingLocations, _location);
      } else {
        _location = {
          id: this.model.endLocation.id, displayName: this.model.endLocation.name, button: false
        };
      }

      this.unloadingLocations.unshift(_location);
      if (this.endLocationDropdown) {
        this.endLocationDropdown.selectedOption = _location;
      }
    }
  }

  jobTypeChanged(): void {
    if (this.model && this.model.qrJobType === 'loading_import') {
      this.model.checkinOptionsDisabled['ticket_number'] = true;
      this.model.checkinOptionsDisabled['ticket_image'] = true;
      this.model.checkinOptionsDisabled['signature_image'] = false;
      this.model.checkinOptionsDisabled['active_tracking'] = false;
      this.model.checkinOptionsDisabled['log_weight'] = false;
      this.model.checkinOptionsDisabled['qr_code'] = true;
      this.model.checkoutOptionsDisabled['ticket_number'] = false;
      this.model.checkoutOptionsDisabled['ticket_image'] = true;
      this.model.checkoutOptionsDisabled['signature_image'] = false;
      this.model.checkoutOptionsDisabled['active_tracking'] = false;
      this.model.checkoutOptionsDisabled['log_weight'] = true;
      this.model.checkoutOptionsDisabled['qr_code'] = true;
    } else if (this.model && this.model.qrJobType === 'unloading_import') {
      this.model.checkinOptionsDisabled['ticket_number'] = true;
      this.model.checkinOptionsDisabled['ticket_image'] = true;
      this.model.checkinOptionsDisabled['signature_image'] = false;
      this.model.checkinOptionsDisabled['active_tracking'] = false;
      this.model.checkinOptionsDisabled['log_weight'] = true;
      this.model.checkinOptionsDisabled['qr_code'] = true;
      this.model.checkoutOptionsDisabled['ticket_number'] = true;
      this.model.checkoutOptionsDisabled['ticket_image'] = true;
      this.model.checkoutOptionsDisabled['signature_image'] = false;
      this.model.checkoutOptionsDisabled['active_tracking'] = false;
      this.model.checkoutOptionsDisabled['log_weight'] = true;
      this.model.checkoutOptionsDisabled['qr_code'] = true;
    } else if (this.model && this.model.qrJobType === 'export') {
      this.model.checkinOptionsDisabled['ticket_number'] = true;
      this.model.checkinOptionsDisabled['ticket_image'] = true;
      this.model.checkinOptionsDisabled['signature_image'] = false;
      this.model.checkinOptionsDisabled['active_tracking'] = false;
      this.model.checkinOptionsDisabled['log_weight'] = true;
      this.model.checkinOptionsDisabled['qr_code'] = true;
      this.model.checkoutOptionsDisabled['ticket_number'] = false;
      this.model.checkoutOptionsDisabled['ticket_image'] = false;
      this.model.checkoutOptionsDisabled['signature_image'] = false;
      this.model.checkoutOptionsDisabled['active_tracking'] = false;
      this.model.checkoutOptionsDisabled['log_weight'] = false;
      this.model.checkoutOptionsDisabled['qr_code'] = true;
    } else {
      this.model.checkinOptionsDisabled['ticket_number'] = false;
      this.model.checkinOptionsDisabled['ticket_image'] = false;
      this.model.checkinOptionsDisabled['signature_image'] = false;
      this.model.checkinOptionsDisabled['active_tracking'] = false;
      this.model.checkinOptionsDisabled['log_weight'] = false;
      this.model.checkinOptionsDisabled['qr_code'] = true;
      this.model.checkoutOptionsDisabled['ticket_number'] = false;
      this.model.checkoutOptionsDisabled['ticket_image'] = false;
      this.model.checkoutOptionsDisabled['signature_image'] = false;
      this.model.checkoutOptionsDisabled['active_tracking'] = false;
      this.model.checkoutOptionsDisabled['log_weight'] = false;
      this.model.checkoutOptionsDisabled['qr_code'] = true;
    }
  }

  isValid() {
    return !!this.model['name'] && this.model['material'] &&
    this.job.rate !== null && this.job.rate !== undefined && parseFloat(this.job.rate) >= 0 &&
    (this.cloneHaulRate || (this.job.haulRate !== null && this.job.haulRate !== undefined && parseFloat(this.job.haulRate) >= 0));
  }

  submit(dispatch = false): void {
    if (this.loading || !this.isValid()) { return; }
    this.loading = true;

    if (this.model['tags'] && this.model['tags'].length) {
      this.model['tags'] = this.model['tags'].map(t => (t.name));
    }

    let projectEdited = this.job.project && this.job.project.name !== this.projectName;
    let customerChanged = this.job.customerOrganization &&
      this.job.customerOrganization.id !== this.customerOrganization.id;

    if (projectEdited || customerChanged) {
      if (customerChanged) {
        this.job.customerOrganization = this.customerOrganization;
        // TODO(jlee): Remove legacy setters
        this.job.project.customerOrganization = this.customerOrganization;
      } else {
        delete this.job.customerOrganization;
        delete this.job.project.customerOrganization;
      }
      if (projectEdited) { this.job.project.name = this.projectName; }
      this.projectService.save(this.job.project).subscribe((project) => {
        this.jobService.save(this.model).subscribe((res) => {
          this.loading = false;
          if (this.returnTo && this.returnTo.length) {
            if (this.returnTo.includes('?')) {
              let route = this.returnTo.split('?')[0];
              let params = {};
              this.returnTo.split('?')[1].split('&').forEach(param => {
                let paramData = param.split('=');
                params[paramData[0]] = paramData[1];
              });
              this.router.navigate([route], { queryParams: params });
            } else { this.router.navigate([this.returnTo]); }
          } else if (dispatch) {
            this.router.navigate(['/dispatch', this.job.id]);
          } else {
            this.router.navigate(['/daily']);
          }
        }, (err) => {
          this.loading = false;
          this.errors = parseErrors(err);
        });
      }, (err) => {
        this.errors = parseErrors(err);
      });
    } else {
      this.jobService.save(this.model).subscribe((res) => {
        this.loading = false;
        if (this.returnTo && this.returnTo.length) {
          if (this.returnTo.includes('?')) {
            let route = this.returnTo.split('?')[0];
            let params = {};
            this.returnTo.split('?')[1].split('&').forEach(param => {
              let paramData = param.split('=');
              params[paramData[0]] = paramData[1];
            });
            this.router.navigate([route], { queryParams: params });
          } else { this.router.navigate([this.returnTo]); }
        } else if (dispatch) {
          this.router.navigate(['/dispatch', this.job.id]);
        } else {
          this.router.navigate(['/daily']);
        }
      }, (err) => {
        this.loading = false;
        this.errors = parseErrors(err);
      });
    }
  }

  onSelect(dropdownType: string, e): void {
    switch (dropdownType) {
      case 'workOrderTemplate':
        this.job.workOrderTemplate = e;
        break;
    }
    this.model[dropdownType] = e;
  }

  dropdownSearch(term: string, type: string): void {
    switch (type) {
      case 'workOrderTemplate':
        this.getWorkOrderTemplates({ search: term });
        break;
    }
  }

  dropdownNextPage(type: string, e): void {
    let config, service, options, o;

    switch (type) {
      case 'workOrderTemplate':
        config = this.workOrderTemplateDropdownConfig;
        service = this.workOrderTemplateService;
        options = this.workOrderTemplateOptions;
        o = service.listNext();
        break;
    }

    if (!config.loadingOptions) {
      if (o) {
        config.loadingOptions = true;
        o.subscribe(results => {
          switch (type) {
            case 'workOrderTemplate':
              this.workOrderTemplateOptions = this.workOrderTemplateOptions.concat(results);
              break;
          }
          config.loadingOptions = false;
        }, (err) => {
          this.errors = parseErrors(err);
          config.loadingOptions = false;
        });
      }
    }
  }

  loadOrganizationDefaults(): void {
    if (this.user && this.user.organization) {
      this.model.defaultYardBufferTime = this.user.organization.defaultYardBufferTime;
      this.model.defaultYardBufferMinutes = this.user.organization.defaultYardBufferMinutes;
    }
  }
}
