"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var core_1 = require("@angular/core");
var GetPunchCardsByStatusAndSharePipe = /** @class */ (function () {
    function GetPunchCardsByStatusAndSharePipe() {
        this.getPunchCardsByStatus = function (punchCards, share, status) {
            return punchCards
                .map(function (punchCard) {
                return punchCard && punchCard.job && punchCard.job.project
                    ? punchCard.job.id === share.jobId &&
                        punchCard.job.project.ownerOrganization === share.organizationId &&
                        punchCard.latestDecisionStatus === status
                    : false;
            })
                .filter(Boolean).length;
        };
        this.getUnapprovedPunchCards = function (punchCards, share) {
            return punchCards
                .map(function (punchCard) {
                return punchCard && punchCard.job && punchCard.job.project
                    ? punchCard.job.id === share.jobId &&
                        punchCard.job.project.ownerOrganization === share.organizationId &&
                        punchCard.latestDecisionStatus !== 'approved'
                    : false;
            })
                .filter(Boolean).length;
        };
    }
    GetPunchCardsByStatusAndSharePipe.prototype.transform = function (punchCards, share, status, shares) {
        var _this = this;
        if (punchCards && punchCards.length && status) {
            if (share) {
                if (status === 'unapproved') {
                    return this.getUnapprovedPunchCards(punchCards, share);
                }
                return this.getPunchCardsByStatus(punchCards, share, status);
            }
            else if (shares && shares.length) {
                return shares
                    .map(function (mapShare) {
                    return status === 'unapproved'
                        ? _this.getUnapprovedPunchCards(punchCards, mapShare)
                        : _this.getPunchCardsByStatus(punchCards, mapShare, status);
                })
                    .reduce(function (a, b) { return a + b; }, 0);
            }
            else {
                return 0;
            }
        }
        else {
            return 0;
        }
    };
    return GetPunchCardsByStatusAndSharePipe;
}());
exports.GetPunchCardsByStatusAndSharePipe = GetPunchCardsByStatusAndSharePipe;
